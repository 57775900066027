import { useFetchSingleLiveMessage } from "../../utils/RecordingBackendUtils";
import { useState, useEffect } from "react";
import { Subject, take, BehaviorSubject } from "rxjs";
import useLiveMessageReplay from "../recording/useLiveMessageReplay";
import useLiveMessageRecording, {onAbortCurrentRecording, onHandleRecordingStop} from "../recording/useLiveMessageRecording";
import recordingState$ from "../../streams/recordingState";
const emptyLiveMessage = {};
const selectedLiveMessageStream = new BehaviorSubject(emptyLiveMessage);
// selectedLiveMessageStream.next(emptyLiveMessage);
// const LRU = require('lru-cache')
// const option = {
//     max: 100
// }
// const cache = new LRU(option);
const useFetchSingleLiveMessageById = (lmId) => {
  // TODO implement local caching / db sync etc.
  return useFetchSingleLiveMessage(lmId);
};

export const selectLiveMessage = (liveMessage) => {
  if (recordingState$.getState().isRecording) {
    onHandleRecordingStop();
  }
  // TODO we might want to add some alert here?
  onAbortCurrentRecording();
  selectedLiveMessageStream.next(liveMessage);
};

const unselectActiveLiveMessage = () => {
  selectedLiveMessageStream.next(emptyLiveMessage);
};

const getCurrentSelectedLiveMessage = (setResult) => {
  selectedLiveMessageStream.pipe(take(1)).subscribe((lm) => {
    setResult(lm);
  });
};
const useLiveMessageViewModel = () => {
  const [selectedLiveMessage, setSelectedLiveMessage] =
    useState(emptyLiveMessage);

  useEffect(() => {
    let subscription = selectedLiveMessageStream.subscribe(
      setSelectedLiveMessage
    );
    return () => {
      subscription.unsubscribe();
    };
  }, []);
  return {
    selectedLiveMessage,
    useFetchSingleLiveMessageById,
    selectLiveMessage,
    unselectActiveLiveMessage,
    getCurrentSelectedLiveMessage,
  };
};

export default useLiveMessageViewModel;
