import {
  Box,
  Stack,
  IconButton,
  Tooltip,
  Button,
  Divider,
} from "@mui/material";

import { useState, useEffect } from "react";
import FiberManualRecordRoundedIcon from "@mui/icons-material/FiberManualRecordRounded";
import StopRoundedIcon from "@mui/icons-material/StopRounded";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const recordButtonTitle = "Click or hold option to record";
const stopButtonTitle = "Stop recording";
const ActiveRecordStopButton = ({
  recordButtonId,
  recordingState,
  onClickToRecord,
  onClickToStop,
  startHoldToRecordProgressStream,
  enableTooltip = true,
  bottomRecorderMode = false,
}) => {
  const [progress, setProgress] = useState(0);
  useEffect(() => {
    if (startHoldToRecordProgressStream) {
      const sub = startHoldToRecordProgressStream.subscribe(setProgress);
      return () => {
        sub.unsubscribe();
      };
    }
  }, []);
  if (recordingState.isRecording) {
    return (
      <Tooltip title={enableTooltip ? stopButtonTitle : null} placement="top">
        <Box
          // size="small"
          onClick={onClickToStop}
          // disableRipple
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#ED6C4B",
            borderRadius:"5px",
            "&:hover": {
              background:bottomRecorderMode?'#666':"#ddd",
            }
          }}
        >
          <StopRoundedIcon />
        </Box>
      </Tooltip>
    );
  } else {
    return (
      <Tooltip title={enableTooltip ? recordButtonTitle : null} placement="top">
        <Box
          size="small"
          onClick={onClickToRecord}
          // disableRipple
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#ED6C4B",
            // padding:"3px",
            borderRadius:"5px",
            "&:hover": {
              background:bottomRecorderMode?'#666':"#ddd",
            }
          }}
        >
          <Box
            width="24px"
            height="24px"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position:"absolute",
            }}
          >
            <CircularProgressbar
              value={progress}
              strokeWidth={8}
              styles={buildStyles({
                // Rotation of path and trail, in number of turns (0-1)
                // rotation: 0.25,

                // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                // strokeLinecap: 'butt',

                // Text size
                // textSize: '16px',

                // How long animation takes to go from one percentage to another, in seconds
                pathTransitionDuration: 0.02,

                // Can specify path transition in more detail, or remove it entirely
                // pathTransition: 'none',

                // Colors
                pathColor: `rgba(249, 89, 89, 1)`,
                // textColor: '#f88',
                trailColor: "#00000000",
                // backgroundColor: '#3e98c7',
              })}
            />
          </Box>
          <FiberManualRecordRoundedIcon />
        </Box>
      </Tooltip>
    );
  }
};

export default ActiveRecordStopButton;
