import { useEffect, useState } from "react";
import { useUserInfo } from "../../utils/useUserInfo";
import { documentVisit } from "../../utils/DocumentBackendUtils";
import useCursorState from "../cursor/useCursorState";
import { useFetchVisitedDocuments } from "../../utils/DocumentBackendUtils";

// Global state to store prev document Id
let prevDocumentId = null;
const DocumentVisitUpdater = ({ documentId }) => {
    const [currentUser] = useUserInfo();
    const { cursorState } = useCursorState();
    const { mutate } = useFetchVisitedDocuments(currentUser.userId, 'DocumentVisitUpdater');

    useEffect(() => {
        if (cursorState.showTextCursorLabel && documentId !== prevDocumentId) {
            console.log('visited');
            prevDocumentId = documentId;
            documentVisit(currentUser.userId, documentId).then(() => {
                mutate();
            });
        }
    }, [cursorState.showTextCursorLabel, documentId]);

    return <></>
};


export default DocumentVisitUpdater;