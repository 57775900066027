import * as React from 'react';

import {
  useParams,
  useNavigate
} from "react-router-dom";
import ShareSpace from "./ShareSpace";
import { getSingleDocumentShare } from '../utils/DocumentShareBackendUtils';
import { createNewShareTarget, useListShareTargets } from '../utils/ShareTargetBackendUtils';

import { decodeRoutePath } from "../utils/DocumentRoutingUtils";
import useAuth from '../utils/useAuth';

const ShareApp = () => {
  const [documentId, setDocumentId] = React.useState(null);
  const [curDocument, setCurDocument] = React.useState(null);
  const params = useParams();
  const navigate = useNavigate();
  const { currentUser, loading } = useAuth();
  const { shareTargets, mutate } = useListShareTargets();

  React.useEffect(() => {
    // query documentId
    if (loading) {
      console.log('loading');
      return;
    }

    // Experience for non logged in user
    if (params.sharePath && !currentUser) {
      let documentShareId = decodeRoutePath(params.sharePath);
      getSingleDocumentShare(documentShareId).then((documentShare) => {
        setDocumentId(documentShare.documentId);
        setCurDocument(documentShare.document)
      }, (err) => {
        console.log('err', err);
        if (err.message === 'No documentShare found') {
          navigate(`/login`);
        }
      });
    }

    if (params.sharePath && currentUser && shareTargets) {
      let documentShareId = decodeRoutePath(params.sharePath);
      getSingleDocumentShare(documentShareId).then((documentShare) => {
        let hasShareAlready = false;
        for (let shareTarget of shareTargets) {
          if (shareTarget.documentShareId === documentShareId) {
            hasShareAlready = true;
          }
        }

        if (!hasShareAlready) {
          console.log('add to my shared space');
          console.log('navigate to my space and open it');
          // see if it's my account lol
          if (documentShare.document.teamId !== currentUser.username) {
            createNewShareTarget(documentShareId).then(() => {
              mutate().then(() => {
                // Navigate after update is complete!
                navigate(`/mine/${documentShare.document.documentId}`)
              })
            });
            return;
          }
        } else {
          console.log('already has share, skipping');
        }
        navigate(`/mine/${documentShare.document.documentId}`)
      }, (err) => {
        console.log('err', err);
        if (err.message === 'No documentShare found') {
          navigate(`/mine`);
        }
      });

    }
  }, [params.sharePath, loading, shareTargets]);

  return (
    <>
      {curDocument && <ShareSpace documentId={documentId} curDocument={curDocument} />}
    </>
  )
};

export default ShareApp;