import React from "react";
import useLiveMessageReplay from "./useLiveMessageReplay";
import replayAwareness from "../../streams/replayAwareness";
import EventReplayer from "../../utils/EventReplayer";
import { REPLAY_CURSOR_COLOR } from "../../common/Constants"

const LiveMessageTextCursorReplayer = () => {
    const [replayState] = useLiveMessageReplay();


    // Handle replay
    React.useEffect(() => {
        if (!replayState.activeRecording) {
            // do nothing if cur playing is empty. 
            return;
        }
        if (replayState.activeRecordingPaused) {
            // do nothing if cur playing is paused. 
            return;
        }
        let curRecording = replayState.activeRecording;
        let curTrack = curRecording.textCursor;

        if (!curTrack) {
            return;
        }

        let eventReplayer = new EventReplayer();
        eventReplayer.startReplay('textCursor',
            curTrack,
            replayState.activeRecordingSeekTime,
            replayState.speed,
            () => {
                /* onStarted */
                replayAwareness.startReplay(curRecording.recordingId, REPLAY_CURSOR_COLOR);

            },
            () => {
                /* onStopped */
                replayAwareness.stopReplay(curRecording.recordingId);
            },
            () => {
                /* onEndOfTrack */
            },
            (event) => {
                /* onEvent */
                replayAwareness.notifyUpdate(event);
            }
        );

        return () => {
            if (eventReplayer) {
                eventReplayer.signalStop();
            }
        }
    }, [replayState.activeRecording, replayState.activeRecordingSeekTime, replayState.activeRecordingPaused, replayState.speed]);

    return <></>;
};

export default LiveMessageTextCursorReplayer;