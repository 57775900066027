import * as React from 'react';

import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import useLiveMessageRecordingController from "./useLiveMessageRecordingController";

const AudioGrantDialog = () => {
    const {
        recordingState,
      } = useLiveMessageRecordingController();
    return (
        <Dialog open={!recordingState.permissionGranted}>
            <DialogTitle sx={{fontSize: '18px'}}>Please grant microphone permission to use live message</DialogTitle>
        </Dialog>
    );
}

export default AudioGrantDialog;