// https://stackabuse.com/making-asynchronous-http-requests-in-javascript-with-axios/
import axios from "axios";
import { TooManyRequestError, BadRequestError, UnexpectedError, ForbiddenError } from "../lib/exceptions";

const DEV_URL = "https://bcr66it30c.execute-api.us-west-2.amazonaws.com/dev";
const PROD_URL = "https://fefcm86pbk.execute-api.us-west-2.amazonaws.com/prod";

export const listLiveMessages = async (documentId) => {
    let params = {};
    params.documentId = documentId;

    try {
        const resp = await axios({
            method: "GET",
            url: getFullUrl("/liveMessage"),
            params: params,
            headers: { 'x-api-key': getApiKey() },
        });

        return resp.data.liveMessages;
    } catch (err) {
        handleError(err);
    }
};

export const getLiveMessage = async (liveMessageId) => {
    let params = {};

    try {
        const resp = await axios({
            method: "GET",
            url: getFullUrl(`/liveMessage/${liveMessageId}`),
            params: params,
            headers: { 'x-api-key': getApiKey() },
        });

        return resp.data.liveMessage;
    } catch (err) {
        handleError(err);
    }
};

export const deleteLiveMessage = async (liveMessageId) => {
    let params = {};

    try {
        await axios({
            method: "DELETE",
            url: getFullUrl(`/liveMessage/${liveMessageId}`),
            params: params,
            headers: { 'x-api-key': getApiKey() },
        });
    } catch (err) {
        handleError(err);
    }
};


export const createRecording = async (accountId, audioMetadata, textCursorMetadata, mouseCursorMetadata, mouseCursorV2Metadata) => {

    let data = {
        audioMetadata: audioMetadata,
        textCursorMetadata: textCursorMetadata,
        mouseCursorMetadata: mouseCursorMetadata,
        mouseCursorV2Metadata: mouseCursorV2Metadata,
    };

    // console.log('createRecording', data);

    try {
        const resp = await axios({
            method: "POST",
            url: getFullUrl("/recording"),
            data: data,
            params: { accountId },
            headers: { 'x-api-key': getApiKey() },
        });
        return resp.data.recording;
    } catch (err) {
        handleError(err);
    }
};

export const updateRecording = async (accountId, recordingId, audioStorageKey, textCursorStorageKey, mouseCursorStorageKey, mouseCursorV2StorageKey) => {
    let data = {
        audioStorageKey: audioStorageKey,
        textCursorStorageKey: textCursorStorageKey,
        mouseCursorStorageKey: mouseCursorStorageKey,
        mouseCursorV2StorageKey: mouseCursorV2StorageKey,
    };


    try {
        const resp = await axios({
            method: "PATCH",
            url: getFullUrl(`/recording/${recordingId}`),
            data: data,
            params: { accountId },
            headers: { 'x-api-key': getApiKey() },
        });
        return resp.data.recording;
    } catch (err) {
        handleError(err);
    }
}


export const createLiveMessage = async (accountId, documentId, recordingIds) => {
    // console.log('createLiveMessage', accountId, documentId, recordingIds);
    let data = {
        documentId: documentId,
        recordingIds: recordingIds,
    };

    try {
        const resp = await axios({
            method: "POST",
            url: getFullUrl("/liveMessage"),
            data: data,
            params: { accountId },
            headers: { 'x-api-key': getApiKey() },
        });
        console.log('saved to the cloud')
        console.log(resp)
        return resp.data.liveMessage;
    } catch (err) {
        handleError(err);
    }
};


export const listDocuments = async (teamId) => {
    let params = {};
    params.teamId = teamId;

    try {
        const resp = await axios({
            method: "GET",
            url: getFullUrl("/document"),
            params: params,
            headers: { 'x-api-key': getApiKey() },
        });

        return resp.data.documents;
    } catch (err) {
        handleError(err);
    }
};


export const listVisitedDocuments = async (accountId) => {
    let params = {};
    params.accountId = accountId;

    try {
        const resp = await axios({
            method: "GET",
            url: getFullUrl("/visitedDocument"),
            params: params,
            headers: { 'x-api-key': getApiKey() },
        });
        return resp.data.documents;
    } catch (err) {
        handleError(err);
    }
};

export const recordDocumentVisit = async (accountId, documentId) => {
    let data = {
        documentId: documentId,
    };

    try {
        const resp = await axios({
            method: "POST",
            url: getFullUrl("/documentVisit"),
            data: data,
            params: { accountId },
            headers: { 'x-api-key': getApiKey() },
        });
        return resp.data.documentVisit;
    } catch (err) {
        handleError(err);
    }
};

export const createDocument = async (teamId, accountId, documentName) => {
    let data = {
        documentName: documentName,
        teamId: teamId,
    };


    try {
        const resp = await axios({
            method: "POST",
            url: getFullUrl("/document"),
            data: data,
            params: { accountId },
            headers: { 'x-api-key': getApiKey() },
        });
        return resp.data.document;
    } catch (err) {
        handleError(err);
    }
};

export const createDocumentAuthed = async (authToken, teamId, documentName) => {
    let data = {
        documentName: documentName,
        teamId: teamId,
    };

    try {
        const resp = await axios({
            method: "POST",
            url: getFullUrl("/documentAuthed"),
            data: data,
            headers: { authorization: authToken },
        });
        return resp.data.document;
    } catch (err) {
        handleError(err);
    }
};

export const deleteDocument = async (documentId) => {
    let params = {};

    try {
        await axios({
            method: "DELETE",
            url: getFullUrl(`/document/${documentId}`),
            params: params,
            headers: { 'x-api-key': getApiKey() },
        });
    } catch (err) {
        handleError(err);
    }
};

export const updateDocument = async (documentId, documentName, editStatus, archiveStatus) => {
    let data = {};

    data.documentName = documentName;
    data.editStatus = editStatus;
    data.archiveStatus = archiveStatus;
    try {
        const resp = await axios({
            method: "PATCH",
            url: getFullUrl(`/document/${documentId}`),
            headers: { 'x-api-key': getApiKey() },
            data: data,
        });

        return resp.data.document;
    } catch (err) {
        handleError(err);
    }
};


export const createOnboardingDocument = async (teamId, accountId, documentName) => {
    let data = {
        documentName: documentName,
        teamId: teamId,
    };

    try {
        const resp = await axios({
            method: "POST",
            url: getFullUrl("/onboardingDocument"),
            data: data,
            params: { accountId },
            headers: { 'x-api-key': getApiKey() },
        });
        return resp.data.document;
    } catch (err) {
        handleError(err);
    }
};

export const createMedia = async (accountId) => {
    try {
        const resp = await axios({
            method: "POST",
            url: getFullUrl("/media"),
            params: { accountId },
            headers: { 'x-api-key': getApiKey() },
        });
        return resp.data.media;
    } catch (err) {
        handleError(err);
    }
};

export const getMedia = async (accountId, mediaId) => {
    try {
        const resp = await axios({
            method: "GET",
            url: getFullUrl(`/media/${mediaId}`),
            params: { accountId },
            headers: { 'x-api-key': getApiKey() },
        });
        return resp.data.media;
    } catch (err) {
        handleError(err);
    }
}


export const updateMedia = async (accountId, mediaId, mediaStorageKey) => {
    let data = {
        mediaStorageKey: mediaStorageKey,
    };


    try {
        const resp = await axios({
            method: "PATCH",
            url: getFullUrl(`/media/${mediaId}`),
            data: data,
            params: { accountId },
            headers: { 'x-api-key': getApiKey() },
        });
        return resp.data.media;
    } catch (err) {
        handleError(err);
    }
}

export const listTeamUsers = async (teamId) => {
    let params = {};
    params.teamId = teamId;

    try {
        const resp = await axios({
            method: "GET",
            url: getFullUrl("/teamUser"),
            params: params,
            headers: { 'x-api-key': getApiKey() },
        });

        return resp.data.teamUsers;
    } catch (err) {
        handleError(err);
    }
};


export const createTeamUser = async (accountId, teamId, displayName) => {
    let data = {
        accountId: accountId,
        teamId: teamId,
        displayName: displayName,
    };

    try {
        const resp = await axios({
            method: "POST",
            url: getFullUrl("/teamUser"),
            data: data,
            params: { accountId },
            headers: { 'x-api-key': getApiKey() },
        });
        return resp.data.teamUser;
    } catch (err) {
        handleError(err);
    }
};


/*
    Stripe API
 */
export const createCheckoutSession = async (
    authToken,
    priceId,
    couponId,
    successUrl,
    cancelUrl
) => {
    try {
        const resp = await axios({
            method: "POST",
            url: getFullUrl("/stripe/checkout_session"),
            headers: { authorization: authToken },
            data: {
                priceId: priceId,
                couponId: couponId,
                successUrl: successUrl,
                cancelUrl: cancelUrl,
            },
        });

        return resp.data.url;
    } catch (err) {
        handleError(err);
    }
};

export const createBillingSession = async (authToken, returnUrl) => {
    try {
        const resp = await axios({
            method: "POST",
            url: getFullUrl("/stripe/billing_session"),
            headers: { authorization: authToken },
            data: {
                returnUrl: returnUrl,
            },
        });

        return resp.data.url;
    } catch (err) {
        handleError(err);
    }
};

export const getAccountStatus = async (authToken) => {
    try {
        const resp = await axios({
            method: "GET",
            url: getFullUrl("/account/status"),
            headers: { authorization: authToken },
        });

        return resp.data.accountStatus;
    } catch (err) {
        handleError(err);
    }
};

export const createDocumentShare = async (authToken, documentId) => {
    try {
        const resp = await axios({
            method: "POST",
            url: getFullUrl("/documentShare"),
            headers: { authorization: authToken },
            data: {
                documentId: documentId,
            },
        });

        return resp.data.documentShare;
    } catch (err) {
        handleError(err);
    }
};

export const getDocumentShare = async (documentShareId) => {
    try {
        const resp = await axios({
            method: "GET",
            url: getFullUrl("/documentShare/" + documentShareId),
            headers: { 'x-api-key': getApiKey() },
        });

        return resp.data.documentShare;
    } catch (err) {
        handleError(err);
    }
}; 


export const listDocumentShare = async (authToken, documentId) => {
    try {
        const resp = await axios({
            method: "GET",
            url: getFullUrl("/documentShare/"),
            params: { documentId },
            headers: { authorization: authToken },
        });

        return resp.data.documentShares;
    } catch (err) {
        handleError(err);
    }
}; 

export const deleteDocumentShare = async (authToken, documentShareid) => {
    try {
         await axios({
            method: "DELETE",
            url: getFullUrl("/documentShare/" + documentShareid),
            headers: { authorization: authToken },
        });

    } catch (err) {
        handleError(err);
    }
};


export const createShareTarget = async (authToken, documentShareId) => {
    try {
        const resp = await axios({
            method: "POST",
            url: getFullUrl("/shareTarget"),
            headers: { authorization: authToken },
            data: {
                documentShareId: documentShareId,
            },
        });

        return resp.data.shareTarget;
    } catch (err) {
        handleError(err);
    }
};


export const listShareTargets = async (authToken) => {
    try {
        const resp = await axios({
            method: "GET",
            url: getFullUrl("/shareTarget/"),
            headers: { authorization: authToken },
        });

        return resp.data.shareTargets;
    } catch (err) {
        handleError(err);
    }
}; 

export const deleteShareTarget = async (authToken, documentShareId) => {
    try {
         await axios({
            method: "DELETE",
            url: getFullUrl("/shareTarget/" + documentShareId),
            headers: { authorization: authToken },
        });

    } catch (err) {
        handleError(err);
    }
};


function handleError(err, logToConsole = true) {
    if (logToConsole) {
        console.log({ err });
    }

    if (err.response && err.response.status === 429) {
        throw new TooManyRequestError("Quota exceeded. Please try later.");
    } else if (err.response && err.response.status === 400 && err.response.data && err.response.data.message) {
        throw new BadRequestError(err.response.data.message)
    } else if (err.response && err.response.status === 403 && err.response.data && err.response.data.message) {
        throw new ForbiddenError(err.response.data.message)
    } else {
        throw new UnexpectedError("Unexpected error happened. Please try later.")
    }
}

function getFullUrl(query) {
    var url = process.env.REACT_APP_PUBLIC_API_ENV === "dev" ? DEV_URL : PROD_URL;
    return url + query;
}


function getApiKey() {
    return process.env.REACT_APP_BACKEND_API_KEY;
}
