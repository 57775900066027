import React from "react";
import useLiveMessageRecording from "./useLiveMessageRecording";
import textCursorBuffer from "../../singleton/selectionBuffer";
import recordingState$ from "../../streams/recordingState";

const LiveMessageTextCursorRecorder = () => {
   const [recordingState] = useLiveMessageRecording();
   const [isRecording, setIsRecording] = React.useState(false);

    // Handle audio recording
    React.useEffect(() => {
        let recordingId = null;
        if (recordingState.isRecording && !isRecording) {
            recordingId = recordingState.curRecordingId;
            textCursorBuffer.startRecord();
            setIsRecording(true);
        }

        return () => {
            if (recordingId) {
                let recording = textCursorBuffer.stopRecord();
                recordingState$.addTextCursorRecording(recordingId, recording);
                setIsRecording(false);
            }
        }
    }, [recordingState.isRecording]);

    return (
        <>
        </>
    )
};

export default LiveMessageTextCursorRecorder;