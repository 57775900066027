import { EditorState, Plugin, PluginKey } from "prosemirror-state";
import { EditorView } from "prosemirror-view";
import applyDevTools from "prosemirror-dev-tools";


export let shouldAdd = [false];
let added = false;

export const DebugPlugin = (options) => {
  return new Plugin({
    key: new PluginKey("DebugPlugin"),
    view() {
      return {
        update: async (editorView, _prevState) => {
          if (!added && shouldAdd[0]) {
            applyDevTools(editorView);
            console.log('Debug Mode Enabled');
            added = true;
          }
        },
      };
    },
  });
};
