import { useState, useEffect } from "react";
import { Paper, Box, Button, IconButton, Stack, Tooltip } from "@mui/material";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import FiberManualRecordRoundedIcon from "@mui/icons-material/FiberManualRecordRounded";
import InLinePlaybackBlock from "../liveMessage/InLinePlaybackBlock";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

const CommentInput = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      // borderColor: 'red',
      padding: "0px",
      border: "none",
    },
    "&.Mui-focused fieldset": {
      padding: "0px",
      border: "none",
    },
  },
});
export const CommentInputView = ({
  attachedLm,
  commentText,
  autoFocus,
  onCommentButtonClick,
  onRecordLmButtonClick,
  onRemoveLmButtonClick
}) => {
  return (
    <Stack direction="column">
      <CommentInput
        placeholder={
          attachedLm ? "Add additional message..." : "Add a comment..."
        }
        multiline
        minRows={1}
        autoFocus={autoFocus}
        inputRef={commentText}
      />
      {attachedLm && (
        <Stack direction="row" sx={{ display: "flex", alignItems: "center" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#F5F5F5",
              minHeight: "30px",
              minWidth: "80px",
              maxWidth: "90px",
              marginTop: "2px",
              marginBottom: "2px",
              borderRadius: "20px",
              borderStyle: "solid",
              borderColor: "#ddd",
              borderWidth: "1px",
              marginLeft: "12px",
            }}
          >
            {attachedLm.isUploading ? ( 
              <Box sx={{color:"#000", margin:"4px"}}>Saving...</Box>
            ) : (
              <InLinePlaybackBlock
                selected={true}
                lmId={attachedLm.liveMessageId}
                lmDuration={attachedLm.duration}
              />
            )}
          </Box>
          {!attachedLm.isUploading && <IconButton
          onClick={onRemoveLmButtonClick}
            sx={{
              width: "30px",
              height: "30px",
              marginLeft: "2px",
              color: "#ccc",
              "&:hover": { background: "#FEEEEE", color: "#F95959" },
            }}
          >
            <CloseRoundedIcon fontSize="small" />
          </IconButton>}
        </Stack>
      )}
      <Stack
        direction="row"
        sx={{
          paddingRight: "8px",
          paddingBottom: "8px",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        {!attachedLm && (
          <Tooltip title="Record a live message">
            <Button
              variant="outlined"
              startIcon={
                <FiberManualRecordRoundedIcon
                  sx={{ color: "#F95959" }}
                  fontSize="large"
                />
              }
              onClick={onRecordLmButtonClick}
              sx={{
                marginRight: "8px",
                opacity: "0.8",
                border: "none",
                color: "#333",
                textTransform: "none",
                "&:hover": {
                  opacity: "1",
                  border: "none",
                  background: "#eee",
                },
              }}
            >
              Record
            </Button>
          </Tooltip>
        )}

        <Button
          size="small"
          variant="contained"
          sx={{ textTransform: "none" }}
          disableElevation
          onClick={onCommentButtonClick}
          disabled={
            (!commentText?.current?.value ||
              commentText.current.value.length == 0) &&
            (!attachedLm || !attachedLm.liveMessageId)
          }
        >
          Comment
        </Button>
      </Stack>
    </Stack>
  );
};
