import useSWR from 'swr';
import {
    createShareTarget,
    deleteShareTarget,
    listShareTargets,
} from "../lib/backend";
import { Auth } from "@aws-amplify/auth";

export const createNewShareTarget = async (documentShareId) => {
    const currentSession = await Auth.currentSession();
    const authToken = currentSession.idToken.jwtToken;
    let shareTarget = await createShareTarget(authToken, documentShareId);
    return shareTarget;
};

export const deleteSingleShareTarget = async (documentShareId) => {
    const currentSession = await Auth.currentSession();
    const authToken = currentSession.idToken.jwtToken;
    await deleteShareTarget(authToken, documentShareId);
};

export function useListShareTargets() {
    const { data, error, mutate } = useSWR('shareTargets', () => listMyShareTargets());
    return {
        shareTargets: (data) ? data : [],
        isLoading: !error && !data,
        isError: error,
        mutate: mutate
    }
}

export const listMyShareTargets = async () => {
    const currentSession = await Auth.currentSession();
    const authToken = currentSession.idToken.jwtToken;
    let shareTargets = await listShareTargets(authToken);
    return shareTargets;
};