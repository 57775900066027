import * as React from 'react';
import { useEffect, useState } from 'react';
import Stack from "@mui/material/Stack";
import DocumentRoom from "./DocumentRoom";
import { trackEvent } from "../utils/AmplitudeTrack";
import { editorState$ } from "../streams/editorState";

/**
 * Represents team level states. Upon sign in, a user should be long to a team.
 * Loads states at team level (e.g. team users, list of documents)
 */
const ShareSpace = ({ documentId, curDocument }) => {
  const [curDocumentId, setCurDocumentId] = React.useState(null);
  const [documents, setDocuments] = useState([]);

  // Determine room routing based on documentPath
  React.useEffect(() => {

    if (curDocument) {
      document.title = `${curDocument.documentName} - Notemad`;
      setCurDocumentId(documentId);
      setDocuments([curDocument]);
      trackEvent("Open share doc", { documentId: documentId })
      return;
    }
  }, [curDocument]);

  const handleDocumentChangeCallback = () => {
    editorState$.notifyDocumentChange('SharedSpace', documentId);

  }
  // Here we load states at team-level
  return (
    <Stack direction="row">
      <DocumentRoom documents={documents} documentId={curDocumentId} onDocumentChangeCallback={handleDocumentChangeCallback} isSharedView={true}/>
    </Stack>
  )
};

export default ShareSpace;