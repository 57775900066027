import { Box, Stack, Button, Card, Typography } from "@mui/material";
import EventReplayer from "../../utils/EventReplayer";
import getTimeString from "../../utils/ComputeUtil";
import useLiveMessageReplay from "../recording/useLiveMessageReplay";
import { useEffect, useState } from "react";
import useLiveMessageViewModel from "./useLiveMessageViewModel";

let wordId = 0;
const LmCaptionView = ({
  sideSpace = "16px",
}) => {
  const [replayState, { onHandleSeekToTarget, onHandleReplayingStart, onPlayLiveMessage, onHandleReplayingResumed }] = useLiveMessageReplay();

  const [caption, setCaption] = useState([]);
  // Handle replay
  useEffect(() => {
    if (!replayState.activeRecording) {
      // do nothing if cur playing is empty.
      return;
    }
    if (replayState.activeRecordingPaused) {
      // do nothing if cur playing is paused.
      return;
    }
    let curRecording = replayState.activeRecording;
    let curTrack = curRecording.audioTranscribe;
    // console.log("curRecording", curRecording);
    if (!curTrack) {
      return;
    }

    let eventReplayer = new EventReplayer();

    eventReplayer.startReplay(
      "audioTranscribe",
      curTrack,
      replayState.activeRecordingSeekTime,
      replayState.speed,
      () => {
        /* onStarted */
        setCaption([]);
      },
      () => {
        /* onStopped */
        setCaption([]);
      },
      () => {
        /* onEndOfTrack */
        setCaption([]);
      },
      (event) => {
        setCaption(prev => { return [...prev, event.text]; });
      }
    );

    return () => {
      if (eventReplayer) {
        eventReplayer.signalStop();
      }
    };
  }, [
    replayState.activeRecording,
    replayState.activeRecordingSeekTime,
    replayState.activeRecordingPaused,
    replayState.speed,
  ]);

  return (
    <>
      {replayState.closedCaptionEnabled && caption.length > 0 &&
        (
          <Box width="500px" sx={{ marginLeft: "16px", marginTop: "16px", marginBottom: "16px" }}>
            <Box
              sx={{
                userSelect: "none",

                opacity: 0.8,
                overflow: 'hidden',
                height: '56px',
                position: 'relative',
              }}
            >
              <Box sx={{ position: 'absolute', bottom: 0, left: 0 }}>
                {
                  caption.map((text) => {
                    return <span key={wordId++} style={{
                      color: "#FFF",
                      background: "#000",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                      paddingLeft: "5px",
                      marginLeft: "-5px",
                      paddingRight: "5px",
                      marginRight: "-5px",
                      lineHeight: "29px",
                      fontSize: "18px",
                    }}>
                      {text}{" "}
                    </span>
                  })
                }
              </Box>
            </Box>
          </Box>
        )
      }
    </>
  );
};

export default LmCaptionView;
