import './MentionList.scss'

import React, {
  forwardRef, useEffect, useImperativeHandle,
  useState,
} from 'react'
import { teamUsersState$ } from '../../../../streams/teamUserState';

export default forwardRef((props, ref) => {
  const [selectedIndex, setSelectedIndex] = useState(0)

  const selectItem = index => {
    const item = props.items[index]

    if (item) {
      props.command({ id: item })
    }
  }

  const upHandler = () => {
    if (props.items.length === 0) {
      return false;
    } else {
      // Select is real
      setSelectedIndex((selectedIndex + props.items.length - 1) % props.items.length)
      return true;
    }
  }

  const downHandler = () => {
    if (props.items.length === 0) {
      return false;
    } else {
      // Select is real
      setSelectedIndex((selectedIndex + 1) % props.items.length)
      return true;
    }
  }

  const enterHandler = () => {
    if (props.items.length === 0) {
      return false;
    } else {
      // Select is real
      selectItem(selectedIndex)
      return true;
    }
  }

  useEffect(() => setSelectedIndex(0), [props.items])

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }) => {
      if (event.key === 'ArrowUp') {
        return upHandler();
      }

      if (event.key === 'ArrowDown') {
        return downHandler();
      }

      if (event.key === 'Enter') {
        return enterHandler();
      }

      return false
    },
  }))

  const getDisplayName = (item) => {
    for (let teamUser of teamUsersState$.getState().teamUsers) {
      if (teamUser.accountId === item) {
        return teamUser.displayName;
      }
    }
    return item;
  }

  return (
    <div className="items">
      {props.items.length
        ? props.items.map((item, index) => (
          <button
            className={`item ${index === selectedIndex ? 'is-selected' : ''}`}
            key={index}
            onClick={() => selectItem(index)}
          >
            {getDisplayName(item)}
          </button>
        ))
        : <></>
      }
    </div>
  )
})