import { useEffect } from "react";
import { useUserInfo } from "../../utils/useUserInfo";
import useCursorState from "../cursor/useCursorState";

const UserStateUpdater = ({ editor }) => {
    const [currentUser] = useUserInfo();
    const { cursorState } = useCursorState();

    // Save current user to localStorage and emit to editor
    useEffect(() => {
        // If we'd like to execute a function asynchronously (after the current code), but before changes are rendered or new events handled, we can schedule it with queueMicrotask 
        queueMicrotask(() => {
            if (editor && currentUser) {
                // NOTE: This calls flushSync methods it seems 
                editor.chain().updateUser({ ...currentUser, cursorState }).run();
            }
        })
    }, [editor, currentUser, cursorState]);

    return <></>
};

export default UserStateUpdater;