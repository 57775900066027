import React, { Profiler, useCallback, useEffect, useState } from "react";
import { v4 } from "uuid";
import useAuth from '../utils/useAuth';

const colors = [
  "#958DF1",
  "#F98181",
  "#FBBC88",
  "#FAF594",
  "#70CFF8",
  "#94FADB",
  "#B9F18D",
  "#E0D873",
  "#FFFF7E",
  "#BEE554",
  "#9DB68C",
  "#C0D9AF",
  "#C1CDC1",
  "#7BE1AE",
  "#DAF4F0",
  "#66CCCC",
  "#7CA8CC",
  "#BCD2EE",
  "#AAAAFF",
  "#CDB5CD",
  "#F7B3DA",
  "#F6A8B6",
  "#FFE34D",
  "#E2DDB5",
];

const names = [
  "Lea Thompson",
  "Cyndi Lauper",
  "Tom Cruise",
  "Madonna",
  "Jerry Hall",
  "Joan Collins",
  "Winona Ryder",
  "Christina Applegate",
  "Alyssa Milano",
  "Molly Ringwald",
  "Ally Sheedy",
  "Debbie Harry",
  "Olivia Newton-John",
  "Elton John",
  "Michael J. Fox",
  "Axl Rose",
  "Emilio Estevez",
  "Ralph Macchio",
  "Rob Lowe",
  "Jennifer Grey",
  "Mickey Rourke",
  "John Cusack",
  "Matthew Broderick",
  "Justine Bateman",
  "Lisa Bonet",
];

const getRandomElement = (list) =>
  list[Math.floor(Math.random() * list.length)];

const getRandomColor = () => getRandomElement(colors);
const getRandomName = () => getRandomElement(names);
const getRandomUserId = () => v4();
export const getUserInfo = () => {
  return (
    JSON.parse(localStorage.getItem("currentUser")) || {
      // TODO: Get Auth Info From Cognito
      name: getRandomName(),
      color: getRandomColor(),
      // Basically guest mode
      userId: 'guest_' + getRandomUserId(),
    }
  );
};

// TODO: Read from cloud
export const useUserInfo = () => {
  const [currentUser, setCurrentUser] = useState(getUserInfo);
  const { currentUser: authUser, signOut, loading, mutate } = useAuth();

  useEffect(() => {
    if (!loading && authUser) {
      // user logged in
      currentUser.userId = authUser.username;
    }
  }, [loading, authUser]);

  // Save current user to localStorage
  useEffect(() => {
    if (currentUser) {
      localStorage.setItem("currentUser", JSON.stringify(currentUser));
    }
  }, [currentUser]);


  const onSaveAvatarInfo = (name) => {
    if (name) {
      setCurrentUser({ ...currentUser, name });
    }
    // TODO: Save to cloud
  }

  const onSignOut = () => {
    signOut();
    mutate();
  }
  return [currentUser, { onSaveAvatarInfo, onSignOut }, authUser];
};