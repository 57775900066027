import axios from 'axios';
import useSWR from 'swr';
import { v4 } from "uuid";
import {
  createRecording,
  createLiveMessage,
  listLiveMessages,
  getLiveMessage,
  deleteLiveMessage,
  updateRecording,
} from "../lib/backend";

var Buffer = require("buffer/").Buffer;

export function useFetchLiveMessages(documentId) {
  const { data, error, mutate } = useSWR(
    "liveMessages_" + documentId,
    () => fetchLiveMessages(documentId),
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  return {
    liveMessages: data ? data : [],
    isLoading: !error && !data,
    isError: error,
    mutate: mutate,
  };
}

const fetchLiveMessages = async (documentId) => {
  let liveMessages = await listLiveMessages(documentId);

  let fullLiveMessages = [];
  for (let i = 0; i < liveMessages.length; i++) {
    // TODO: Make this faster
    let recordings = await extractRecordings(liveMessages[i]);
    fullLiveMessages.push({
      liveMessageId: liveMessages[i].liveMessageId,
      createdTime: liveMessages[i].createdTime,
      recordings,
    });
  }

  return fullLiveMessages;
};

export const useFetchSingleLiveMessage = (liveMessageId) => {
  const { data, error, mutate } = useSWR(
    "singleLm_" + liveMessageId,
    () => fetchSingleLiveMessageInternal(liveMessageId),
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  return {
    liveMessage: data,
    isLoading: !error && !data,
    isError: error,
    mutate: mutate,
  };
};

export const fetchSingleLiveMessageInternal = async (liveMessageId) => {
  let liveMessage = await getLiveMessage(liveMessageId);
  let recordings = await extractRecordings(liveMessage);
  liveMessage.recordings = recordings;
  const transcripts = buildTranscriptParagraphs(recordings);
  liveMessage.transcripts = transcripts.length > 0 ? transcripts : null;
  return liveMessage;
};
const buildTranscriptParagraphs = (recordings) => {
  let paragraphs = [];

  for (let recording of recordings) {
    if (recording.audioTranscribe) {
      let recordingId = recording.recordingId
      let sentences = buildTranscriptSentences(recording.audioTranscribe);
      if (sentences.length > 0) {
        paragraphs.push({ recordingId, sentences });
      }
    }
  }

  return paragraphs;
}

function isASCII(str) {
  return /^[\x00-\x7F]*$/.test(str);
}

const buildTranscriptSentences = (curTrack) => {
  let sentences = [{}];
  for (let event of curTrack.events) {
    if (sentences[sentences.length - 1].start === undefined) {
      sentences[sentences.length - 1].start = event.start;
    }
    if (sentences[sentences.length - 1].text === undefined) {
      sentences[sentences.length - 1].text = "";
    }
    sentences[sentences.length - 1].text = sentences[sentences.length - 1].text + (isASCII(event.text) ? " " : "") + event.text;

    if (sentences[sentences.length - 1].text.includes(".") ||
      sentences[sentences.length - 1].text.includes("?") ||
      sentences[sentences.length - 1].text.includes("。") ||
      sentences[sentences.length - 1].text.includes("？")) {
      sentences[sentences.length - 1].end = event.end;
      sentences.push({});
    }
  }

  if (sentences[sentences.length - 1].start === undefined) {
    sentences.pop();
  }

  // console.log('sentences', sentences);
  return sentences;
};

export const deleteSingleLiveMessage = async (liveMessageId) => {
  console.log("deleteLiveMessage liveMessageId", liveMessageId);
  await deleteLiveMessage(liveMessageId);
};

const extractRecordings = async (liveMessage) => {
  let recordings = [];
  for (let recording of liveMessage.recordings) {
    let curRecording = {};
    curRecording.recordingId = recording.recordingId;
    if (recording.textCursorUrl) {
      let textCursorEvents = await downloadJson(recording.textCursorUrl);
      curRecording.textCursor = recording.textCursorMetadata;
      curRecording.textCursor.events = textCursorEvents;
    }

    if (recording.mouseCursorUrl) {
      let mouseCursorEvents = await downloadJson(recording.mouseCursorUrl);
      curRecording.mouseCursor = recording.mouseCursorMetadata;
      curRecording.mouseCursor.events = mouseCursorEvents;
    }

    if (recording.mouseCursorV2Url) {
      let mouseCursorV2Events = await downloadJson(recording.mouseCursorV2Url);
      curRecording.mouseCursorV2 = recording.mouseCursorV2Metadata;
      curRecording.mouseCursorV2.events = mouseCursorV2Events;
    }

    if (recording.audioUrl) {
      let audioUrl = recording.audioUrl;
      curRecording.audio = recording.audioMetadata;
      curRecording.audio.audioUrl = audioUrl;
    }

    if (recording.audioTranscribeUrl) {
      let audioTranscribeEvents = await downloadJson(
        recording.audioTranscribeUrl
      );
      curRecording.audioTranscribe = recording.audioMetadata;
      curRecording.audioTranscribe.events = audioTranscribeEvents;
      for (let event of curRecording.audioTranscribe.events) {
        event.ts = event.start;
      }
    }
    recordings.push(curRecording);
  }

  return recordings;
};

const processLmEmbeddings = (lm, recordings) => {
  // for now we use text cusor as the embedding signal, maybe more in the future?
  console.log("processEmbedding lm recordings:", lm, recordings);
  const result = recordings
    .filter((recording) => recording?.textCursor?.events?.length > 0)
    .flatMap((recording, idx) =>
      recording.textCursor.events.map((event) => {
        return { blockId: event.blockId, ts: event.ts, recordingIndex: idx };
      })
    )
    .reduce((accumulator, current) => {
      // here we assume events recording is chronological
      if (!(current.blockId in accumulator)) {
        accumulator[current.blockId] = {
          ...lm,
          embeddingType:"liveMessage",
          embeddingId:v4(),
          startTs: current.ts,
          endTs: current.ts,
          recordingIndex: current.recordingIndex
        };
      }
      accumulator[current.blockId].endTs = current.ts;
      return accumulator;
    }, {});
  const lmEmbedding = []
  for (var key in result) {
    lmEmbedding.push({blockId:key, ...result[key] })
  }
  console.log("result", lmEmbedding);
  return lmEmbedding
}

export const createLiveMessages = async (accountId, documentId, recordings, languageType) => {
  // create recording for each of records
  let createdRecordings = [];
  for (let recording of recordings) {
    let created = await createRecording(
      accountId,
      recording.audio ? {
        startTime: recording.audio.startTime,
        endTime: recording.audio.endTime,
        languageType: languageType, // 0: English, 1: Chinese
      } : null,
      recording.textCursor ? {
        startTime: recording.textCursor.startTime,
        endTime: recording.textCursor.endTime,
      } : null,
      recording.mouseCursor ? {
        startTime: recording.mouseCursor.startTime,
        endTime: recording.mouseCursor.endTime,
      } : null,
      recording.mouseCursorV2 ? {
        startTime: recording.mouseCursorV2.startTime,
        endTime: recording.mouseCursorV2.endTime,
      } : null,
    );

    // upload files for each of records
    let textCursorStorageKey = null;
    if (recording.textCursor && created.textCursorUploadUrl) {
      uploadJson(recording.textCursor.events, created.textCursorUploadUrl);
      textCursorStorageKey = created.textCursorStorageKey;
    }
    let mouseCursorStorageKey = null;
    if (recording.mouseCursor && created.mouseCursorUploadUrl) {
      uploadJson(recording.mouseCursor.events, created.mouseCursorUploadUrl);
      mouseCursorStorageKey = created.mouseCursorStorageKey;
    }
    let mouseCursorV2StorageKey = null;
    if (recording.mouseCursorV2 && created.mouseCursorV2UploadUrl) {
      uploadJson(recording.mouseCursorV2.events, created.mouseCursorV2UploadUrl);
      mouseCursorV2StorageKey = created.mouseCursorV2StorageKey;
    }
    let audioStorageKey = null;
    if (recording.audio && created.audioUploadUrl) {
      uploadAudio(recording.audio.audiodata, created.audioUploadUrl);
      audioStorageKey = created.audioStorageKey;
    }

    // update storage keys
    await updateRecording(accountId, created.recordingId, audioStorageKey, textCursorStorageKey, mouseCursorStorageKey, mouseCursorV2StorageKey)
    createdRecordings.push(created);
  }

  let recordingIds = createdRecordings.map(
    (recording) => recording.recordingId
  );
  console.log(
    "createdRecordings",
    createdRecordings,
    "recordingIds",
    recordingIds,
  );
  // create lm
  const lm = await createLiveMessage(accountId, documentId, recordingIds);
  // TODO HACK? is it safe to use the audio time to represent the duration?
  lm.duration = createdRecordings
    .map(
      (recording) =>
        recording.audioMetadata.endTime - recording.audioMetadata.startTime
    )
    .reduce((acc, duration) => acc + duration, 0);
  lm.blockEmbeddings = processLmEmbeddings(lm, recordings);
  return lm;
};

const uploadAudio = async (audiodata, url) => {
  const buffer = Buffer.from(await audiodata.arrayBuffer());

  const res = await axios({
    method: "PUT",
    url: url,
    data: buffer,
    // headers: {
    //     Origin: "https://www.owl3d.com",
    // },
    maxContentLength: Infinity,
    maxBodyLength: Infinity,
  });

  console.log("uploadAudio complete", res);
};

const uploadJson = async (jsondata, url) => {
  const res = await axios({
    method: "PUT",
    url: url,
    // headers: {
    //     Origin: "https://www.owl3d.com",
    // },
    data: JSON.stringify(jsondata),
  });
  console.log("uploadJson complete", res);
};

const downloadJson = async (url) => {
  const res = await axios({
    method: "GET",
    url: url,
  });

  // console.log("downloadJson complete", res.data);
  return res.data;
};
