import { Extension } from "@tiptap/core";
import { Plugin, PluginKey } from "prosemirror-state";

const getValidLiveMessageNodes = (doc) => {
  const lms = [];
  doc.descendants((node, pos, parent) => {
    if (node.type.name === "liveMessageBlock" && node.attrs.liveMessageId) {
      // console.log('lm node', node)
      lms.push(node.attrs);
    }
  });
  return lms;
};
const LiveMessageEmbeddingExtension = Extension.create({
  name: "LiveMessageEmbeddingExtension",

  addProseMirrorPlugins() {
    const plugin = new PluginKey(this.name);

    return [
      new Plugin({
        key: plugin,
        appendTransaction: (_transactions, oldState, newState) => {
          if (_transactions.some((transaction) => transaction.docChanged)) {
            const oldLms = getValidLiveMessageNodes(oldState.doc);
            const newLms = getValidLiveMessageNodes(newState.doc);
            // console.log('oldLms', oldLms, 'newLms', newLms);

            // const tr = newState.tr;
            // let isModified = false;
            // const lmEmbeddings = newLms.flatMap((lm) => lm.blockEmbeddings);
            // newState.doc.descendants((node, pos, parent) => {
            //   const blockId = node.attrs.id;
            //   if (!blockId) return;
            //   const relatedEmbeddingIds = lmEmbeddings
            //     .filter((embedding) => embedding.blockId === blockId)
            //     .map((embedding) => embedding.embeddingId);
            //   if (relatedEmbeddingIds.length) {
            //     let embeddings = node.attrs.embeddings;
            //     const remainingEmbeddings = embeddings.filter(
            //       (embedding) =>
            //         !relatedEmbeddingIds.some(
            //           (id) => id === embedding.embeddingId
            //         )
            //     );
            //     // note: we need to update the array in place for this to work. perhaps some strange assumption in the editor
            //     node.attrs.embeddings.length = 0; // clear embeddings
            //     node.attrs.embeddings.push(...remainingEmbeddings);
            //     // console.log('embeddings after removing', relatedEmbeddingIds, remainingEmbeddings, {...node.attrs})
            //     tr.setNodeMarkup(pos, null, { ...node.attrs });
            //     isModified = true;
            //   }
            // });
            // if (isModified) {
            //   return tr;
            // }


            if (oldLms.length > newLms.length) {
              // lm is removed
              const removedLmEmbeddings = oldLms
                .filter(
                  (lm) =>
                    !newLms.find(
                      (nLm) => nLm.liveMessageId === lm.liveMessageId
                    )
                )
                .flatMap((lm) => lm.blockEmbeddings);
              if (!removedLmEmbeddings.length) {
                return;
              }
              // console.log("removed lm embeddings", removedLmEmbeddings)
              const tr = newState.tr;
              let isModified = false;
              newState.doc.descendants((node, pos, parent) => {
                const blockId = node.attrs.id;
                if (!blockId) return;
                const relatedEmbeddingIds = removedLmEmbeddings
                  .filter((embedding) => embedding.blockId === blockId)
                  .map((embedding) => embedding.embeddingId);
                if (relatedEmbeddingIds.length) {
                  let parsedEmbeddings = [];
                  try {
                    if (node.attrs.embeddings) {
                      parsedEmbeddings = JSON.parse(node.attrs.embeddings);
                    }
                  } catch (err) {
                    console.warn("Failed to parse embeddings", node.attrs.embeddings);
                  }

                  const remainingEmbeddings = parsedEmbeddings.filter(
                    (embedding) =>
                      !relatedEmbeddingIds.some(
                        (id) => id === embedding.embeddingId
                      )
                  );
                  node.attrs.embeddings = JSON.stringify(remainingEmbeddings);
                  tr.setNodeMarkup(pos, null, { ...node.attrs });
                  isModified = true;
                }
              });
              if (isModified) {
                return tr;
              }
            } else if (oldLms.length < newLms.length) {
              // lm is created.
              const createdLmEmbeddings = newLms
                .filter(
                  (lm) =>
                    !oldLms.find(
                      (nLm) => nLm.liveMessageId === lm.liveMessageId
                    )
                )
                .flatMap((lm) => lm.blockEmbeddings);
              if (!createdLmEmbeddings.length) {
                return;
              }
              // console.log("created lm embeddings", createdLmEmbeddings)
              const tr = newState.tr;
              let isModified = false;
              newState.doc.descendants((node, pos, parent) => {
                // console.log('node id', node.attrs.id)
                const blockId = node.attrs.id;
                if (!blockId) return;
                let parsedEmbeddings = [];
                try {
                  if (node.attrs.embeddings) {
                    parsedEmbeddings = JSON.parse(node.attrs.embeddings);
                  }
                } catch (err) {
                  console.warn('Failed to parse existing embeddings', node.attrs.embeddings);
                }

                const relatedEmbeddings = createdLmEmbeddings.filter(
                  (embedding) =>
                    embedding.blockId === blockId && parsedEmbeddings &&
                    // this is needed to avoid duplicating exsiting embeddings on document load
                    !parsedEmbeddings.some(
                      (iEmbedding) =>
                        iEmbedding.embeddingId === embedding.embeddingId
                    )
                );
                if (relatedEmbeddings.length > 0) {
                  tr.setNodeMarkup(pos, null, {
                    ...node.attrs,
                    embeddings: JSON.stringify([
                      ...relatedEmbeddings,
                      ...parsedEmbeddings
                    ]),
                  });
                  isModified = true;
                }
              });
              if (isModified) {
                return tr;
              }
            }
          }
          return null;
        },
      }),
    ];
  },
});

export default LiveMessageEmbeddingExtension;
