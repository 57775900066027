import "./Recorder.scss";
import {
  Box,
  Stack,
  IconButton,
  Tooltip,
  Button,
  Divider,
} from "@mui/material";

import { interval } from "rxjs";
import { useState, useEffect } from "react";
import BackspaceRoundedIcon from "@mui/icons-material/BackspaceRounded";
import useLiveMessageReplay from "../recording/useLiveMessageReplay";
import useLiveMessageRecordingController from "./useLiveMessageRecordingController";
import ActiveRecordStopButton from "./ActiveRecordStopButton";
import { getTimeString } from "../../utils/ComputeUtil";
import PauseRoundedIcon from "@mui/icons-material/PauseRounded";
import Forward5RoundedIcon from "@mui/icons-material/Forward5Rounded";
import Replay5RoundedIcon from "@mui/icons-material/Replay5Rounded";
import { trackDocumentEvent } from "../../utils/AmplitudeTrack";

const defaultTime = "0:00";
// const holdToRecordKey = "Alt";
const recordButtonTitle = "Click or hold option to record";
const stopButtonTitle = "Stop recording";
const playButtonTitle = "Play recording";
const deleteButtonTitle = "Delete last recording";
// let holdToRecord = true;
const Recorder = ({ lmId, bottomRecorderMode = false }) => {
  const {
    recordingState,
    onStartToRecord,
    onStopRecording,
    onDeleteLastRecording,
    startHoldToRecordProgressStream,
  } = useLiveMessageRecordingController();
  const [
    replayState,
    {
      onHandleReplayingStart,
      onHandleRecordingReload,
      onHandleReplayingResumed,
      onHandleReplayingStop,
      onHandleReplayingFastForward,
      onHandleReplayingRewind,
      onHandleReplayingSpeedAdjusted
    },
  ] = useLiveMessageReplay();
  const [recordingTime, setRecordingTime] = useState(defaultTime);
  const [activePlayTime, setActivePlayTime] = useState(0);
  useEffect(() => {
    if (replayState.activeRecordingTotalTime > 0) {
      setActivePlayTime(
        (replayState.head / 100) * replayState.activeRecordingTotalTime
      );
    }
  }, [replayState.head]);

  useEffect(() => {
    if (!recordingState.isRecording) {
      setRecordingTime(defaultTime);
    }
  }, [recordingState.isRecording]);

  useEffect(() => {
    if (
      recordingState.isActiveRecordingReady &&
      recordingState.recordings &&
      recordingState.recordings.length > 0
    ) {
      onHandleRecordingReload(recordingState.recordings, lmId);
    }
  }, [recordingState.recordings, recordingState.isActiveRecordingReady]);

  // Handle recording time additon
  useEffect(() => {
    let subscription = null;
    if (recordingState.isRecording) {
      const counter = interval(1000);
      subscription = counter.subscribe((tick) => {
        if (recordingState.activeRecordingStartTime) {
          let delta =
            new Date().getTime() - recordingState.activeRecordingStartTime;
          setRecordingTime(`${getTimeString(delta)}`);
        }
      });
    }

    return () => {
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  }, [recordingState]);

  const onToggleRecordPause = () => {
    if (recordingState.isRecording) {
      onStopRecording();
    } else {
      onStartToRecord();
    }
  };

  const activeRecordingArea = (recordingTime, recordingState) => {
    return (
      <Tooltip title={stopButtonTitle} placement="top">
        <IconButton
          size="small"
          variant="text"
          disableRipple
          sx={{
            opacity: "0.9",
            marginRight: "10px",
            "&:hover": {
              opacity: "1",
            },
          }}
          onClick={onToggleRecordPause}
        >
          <Stack direction="row">
            {recordingState.recordings &&
              recordingState.recordings.length == 0 && (
                <ActiveRecordStopButton
                  recordingState={recordingState}
                  enableTooltip={false}
                  bottomRecorderMode={bottomRecorderMode}
                  startHoldToRecordProgressStream={
                    startHoldToRecordProgressStream
                  }
                />
              )}
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "#fff",
                marginLeft: "4px",
                fontWeight: "medium",
                fontSize: "20px",
              }}
            >
              {recordingTime}
            </Box>
          </Stack>
        </IconButton>
      </Tooltip>
    );
  };

  const allRecordings = (isRecording, recordings, isReplaying) => {
    const lastRecordingId = recordings[recordings.length - 1].recordingId;
    return (
      <Tooltip title={""}>
        <Box
          sx={{
            opacity: "0.9",
            "&:hover": { opacity: "1" },
          }}
          onClick={isRecording ? onToggleRecordPause : null}
        >
          <Stack direction="row">
            {!isReplaying && (
              <ActiveRecordStopButton
                recordingState={recordingState}
                onClickToRecord={onStartToRecord}
                bottomRecorderMode={bottomRecorderMode}
                startHoldToRecordProgressStream={
                  startHoldToRecordProgressStream
                }
              />
            )}
            {isReplaying && (
              <Stack direction="row">
                <Tooltip>
                  <IconButton
                    onClick={() => onHandleReplayingStop()}
                    size="small"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "#fff",
                      // padding:"3px",
                      opacity:"0.9",
                      borderRadius: "5px",
                      "&:hover": {
                        background: "#666",
                        opacity:"1",
                      },
                    }}
                  >
                    <PauseRoundedIcon fontSize="large" />
                  </IconButton>
                </Tooltip>
                <Tooltip>
                  <IconButton
                    onClick={() => onHandleReplayingRewind(5000)}
                    size="small"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      opacity:"0.9",
                      color: "#fff",
                      // padding:"3px",
                      borderRadius: "5px",
                      "&:hover": {
                        background: "#666",
                        opacity:"1",
                      },
                    }}
                  >
                    <Replay5RoundedIcon fontSize="large" />
                  </IconButton>
                </Tooltip>
                <Tooltip>
                  <IconButton
                    onClick={() => onHandleReplayingFastForward(5000)}
                    size="small"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "#fff",
                      opacity:"0.9",
                      // padding:"3px",s
                      borderRadius: "5px",
                      "&:hover": {
                        background: "#666",
                        opacity:"1",
                      },
                    }}
                  >
                    <Forward5RoundedIcon fontSize="large" />
                  </IconButton>
                </Tooltip>
                <Tooltip>
                  <Button
                    onClick={() => {
                      if (replayState.speed == 1) {
                        onHandleReplayingSpeedAdjusted(1.5);
                      } else {
                        onHandleReplayingSpeedAdjusted(1);
                      }
                    }}
                    size="small"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "#fff",
                      opacity:"0.9",
                      // padding:"3px",s
                      borderRadius: "5px",
                      fontSize:"20px",
                      textTransform: "none",
                      "&:hover": {
                        background: "#666",
                        opacity:"1",
                      },
                    }}
                  >
                    {replayState.speed}x
                  </Button>
                </Tooltip>
              </Stack>
            )}
            {recordings
              .filter((recording) => recording.isReady)
              .map((recording) => {
                const elapsed =
                  replayState.activeRecording &&
                  recording.recordingId ===
                    replayState.activeRecording.recordingId
                    ? activePlayTime
                    : 0;

                // Adjust opacity based on current state
                let opacity =
                  !isRecording && recording.recordingId === lastRecordingId
                    ? "0.9"
                    : "0.4";
                if (
                  replayState.activeRecording &&
                  recording.recordingId ===
                    replayState.activeRecording.recordingId
                ) {
                  // Currently playing this recording!
                  opacity = 1.0;
                }
                return (
                  <Tooltip
                    title={!isRecording ? playButtonTitle : ""}
                    key={recording.recordingId}
                    placement="top"
                  >
                    <Stack direction="row">
                      <Button
                        variant="text"
                        size="small"
                        disableRipple
                        disabled={isRecording}
                        sx={{
                          color: "#fff",
                          opacity: opacity,
                          fontSize: "20px",
                          "&:hover": {
                            opacity: "1",
                            background: "#666",
                          },
                          "&:disabled": {
                            // background:"#666"
                            color: "#fff",
                          },
                        }}
                        onClick={() => {
                          // Make sure to resume replay whenever playback starts
                          trackDocumentEvent("click test play recording - bottom lm recorder")
                          onHandleReplayingResumed();
                          onHandleReplayingStart(recording.recordingId, true);
                        }}
                      >
                        {getTimeString(
                          recording.audio.endTime -
                            recording.audio.startTime -
                            elapsed
                        )}
                      </Button>
                      {(recording.recordingId !== lastRecordingId ||
                        isRecording) && (
                        <Divider
                          orientation="vertical"
                          variant="middle"
                          flexItem
                          sx={{ background: "#666" }}
                        />
                      )}
                    </Stack>
                  </Tooltip>
                );
              })}
            {!isRecording && !isReplaying && (
              <Tooltip title={deleteButtonTitle} placement="top">
                <IconButton
                  onClick={()=>{
                    trackDocumentEvent("click delete last recording - bottom lm recorder")
                    onDeleteLastRecording()}}
                  size="small"
                  disableRipple
                  sx={{
                    opacity: "0.3",
                    marginLeft: "4px",
                    color: "#fff",
                    "&:hover": {
                      opacity: "0.6",
                    },
                  }}
                >
                  <BackspaceRoundedIcon />
                </IconButton>
              </Tooltip>
            )}
          </Stack>
        </Box>
      </Tooltip>
    );
  };

  const emptyRecording = () => {
    return (
      <Tooltip title={recordButtonTitle} placement="top">
        <IconButton
          variant="text"
          size="small"
          onClick={onToggleRecordPause}
          disableRipple
          sx={{
            opacity: "0.8",
            marginRight: "12px",
            "&:hover": {
              opacity: "1",
            },
          }}
        >
          <Stack direction="row">
            <ActiveRecordStopButton
              recordingState={recordingState}
              bottomRecorderMode={bottomRecorderMode}
              onClickToRecord={onStartToRecord}
              onClickToStop={onStopRecording}
              startHoldToRecordProgressStream={startHoldToRecordProgressStream}
              enableTooltip={false}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "#fff",
                // opacity: "0.8",
                marginLeft: "8px",
                fontWeight: "medium",
                fontSize: "20px",
              }}
            >
              {defaultTime}
            </Box>
          </Stack>
        </IconButton>
      </Tooltip>
    );
  };

  const recordArea = (liveMessageState, replayState) => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Stack
          className="timer-background"
          direction="row"
          sx={{ paddingLeft: "10px", paddingRight: "8px" }}
        >
          {!liveMessageState.isRecording &&
            liveMessageState.recordings &&
            liveMessageState.recordings.length == 0 &&
            emptyRecording()}
          {liveMessageState.recordings &&
            liveMessageState.recordings.length > 0 &&
            allRecordings(
              liveMessageState.isRecording,
              liveMessageState.recordings,
              replayState.activeRecording && !replayState.activeRecordingPaused
            )}
          {liveMessageState.isRecording &&
            activeRecordingArea(recordingTime, liveMessageState)}
        </Stack>
      </Box>
    );
  };
  // let recordings = recordingState.recordings;
  // console.log(recordings);
  return recordArea(recordingState, replayState);
};

export default Recorder;
