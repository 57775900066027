import * as React from 'react';
import { useEffect, useCallback, useState, useRef } from 'react';

import { Editor } from "@tiptap/core";
import {
  RiBold,
  RiH1,
  RiH2,
  RiH3,
  RiIndentDecrease,
  RiIndentIncrease,
  RiItalic,
  RiLink,
  RiListOrdered,
  RiListUnordered,
  RiStrikethrough,
  RiText,
  RiUnderline,
  RiArrowDownSLine,
  RiCodeSLine,
  RiMarkPenLine,
  RiListCheck,
} from "react-icons/ri";

import InsertCommentOutlinedIcon from '@mui/icons-material/InsertCommentOutlined';
import { useEditorForceUpdate } from "../../../shared/hooks/useEditorForceUpdate";
import { findBlock } from "../../Blocks/helpers/findBlock";
import formatKeyboardShortcut from "../../helpers/formatKeyboardShortcut";
import DropdownBlockItem from "./DropdownBlockItem";
import Stack from '@mui/material/Stack';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { styled } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import { Paper, Button, Box, Tooltip, IconButton, MenuItem, Menu } from '@mui/material';
import InsertLinkOutlinedIcon from '@mui/icons-material/InsertLinkOutlined';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import CodeOutlinedIcon from '@mui/icons-material/CodeOutlined';
import StrikethroughSOutlinedIcon from '@mui/icons-material/StrikethroughSOutlined';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import { HyperlinkEditMenu } from "../../Hyperlinks/menus/HyperlinkEditMenu";
import { bubbleMenuStateStream, bubbleMenueRequestStream } from "../BubbleMenuPlugin";
import { addNewCommentStream } from "../../../../components/comment/CommentViewModel";
const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    margin: theme.spacing(0.1),
    border: 0,
    '&.Mui-disabled': {
      border: 0,
    },
    '&:not(:first-of-type)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-of-type': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}));

const ColorButton = styled(IconButton)(({ theme }) => ({
  color: '#333',
  backgroundColor: '#00000000',
  textTransform: 'none',
  fontWeight: 'normal',
  fontSize: '14px',
  // margin:"0px",
  // marginBottom:theme.spacing(0.5),
  borderRadius: theme.shape.borderRadius,
  '&:hover': {
    backgroundColor: '#eee',
  },
}));

type ListType = "li" | "oli" | "tli";
const formatKeys = ['bold', 'italic', 'underline', 'strike', 'code', 'highlight']

function getBlockName(
  currentBlockHeading: number | undefined,
  currentBlockListType: ListType | undefined
) {
  const headings = ["Heading 1", "Heading 2", "Heading 3"];
  const lists = {
    li: "Bullet List",
    oli: "Numbered List",
    tli: "Task List",
  };
  // A heading that's also a list, should show as Heading
  if (currentBlockHeading) {
    return headings[currentBlockHeading - 1];
  } else if (currentBlockListType) {
    return lists[currentBlockListType];
  } else {
    return "Text";
  }
}

// TODO: add list options, indentation
export const BubbleMenu = (props: { editor: Editor }) => {
  useEditorForceUpdate(props.editor);

  const currentBlock = findBlock(props.editor.state.selection);
  const currentBlockHeading: number | undefined =
    currentBlock?.node.attrs.headingType;
  const currentBlockListType: ListType | undefined =
    currentBlock?.node.attrs.listType;

  const currentBlockName = getBlockName(
    currentBlockHeading,
    currentBlockListType
  );

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const activatedFormat = formatKeys.filter(key => props.editor.isActive(key))

  const showAddLinkView = (editor, setAddLinkView) => {
    const onSubmit = (url: string, text: string) => {
      if (url === "") {
        return;
      }
      const mark = editor.schema.mark("link", { href: url });
      let { from, to } = editor.state.selection;
      editor.view.dispatch(
        editor.view.state.tr
          .insertText(text, from, to)
          .addMark(from, from + text.length, mark)
      );
    };

    // get the currently selected text and url from the document, and use it to
    // create a new creation menu
    const { from, to } = editor.state.selection;
    const selectedText = editor.state.doc.textBetween(from, to);
    const activeUrl = editor.isActive("link")
      ? editor.getAttributes("link").href || ""
      : "";

    setAddLinkView(
      <HyperlinkEditMenu
        key={Math.random() + ""} // Math.random to prevent old element from being re-used
        url={activeUrl}
        text={selectedText}
        onSubmit={onSubmit}
      />
    );
  };
  const [menuState, setMenuState] = useState({ isShown: false });
  useEffect(() => {
    let sub = bubbleMenuStateStream.subscribe(setMenuState)
    return () => {
      sub.unsubscribe();
    }
  }, [])

  const [addLinkView, setAddLinkView] = useState(null);
  useEffect(() => {
    if (!menuState.isShown && addLinkView) {
      setAddLinkView(null);
    }
  }, [menuState, addLinkView])

  return (
    <Paper
      elevation={8}
      sx={{
        padding: "4px",
        height: "42px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}>
      {!addLinkView && <Stack direction="row">
        <ColorButton size="small"
          onClick={handleClickListItem}>
          {currentBlockName}
          <RiArrowDownSLine />
        </ColorButton>
        <Menu
          sx={{ zIndex: '10000' }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}>

          <DropdownBlockItem
            title="Text"
            icon={RiText}
            isSelected={currentBlockName === "Text"}
            onClick={() => {
              handleClose();
              props.editor.chain().focus().unsetBlockHeading().unsetList().run();
            }}
          />
          <DropdownBlockItem
            title="Heading 1"
            icon={RiH1}
            isSelected={currentBlockName === "Heading 1"}
            onClick={() => {
              handleClose();
              props.editor
                .chain()
                .focus()
                .unsetList()
                .setBlockHeading({ level: 1 })
                .run();
            }}
          />
          <DropdownBlockItem
            title="Heading 2"
            icon={RiH2}
            isSelected={currentBlockName === "Heading 2"}
            onClick={() => {
              handleClose();
              props.editor
                .chain()
                .focus()
                .unsetList()
                .setBlockHeading({ level: 2 })
                .run();
            }

            }
          />
          <DropdownBlockItem
            title="Heading 3"
            icon={RiH3}
            isSelected={currentBlockName === "Heading 3"}
            onClick={() => {
              handleClose();
              props.editor
                .chain()
                .focus()
                .unsetList()
                .setBlockHeading({ level: 3 })
                .run();
            }

            }
          />
          <DropdownBlockItem
            title="Bullet List"
            icon={RiListUnordered}
            isSelected={currentBlockName === "Bullet List"}
            onClick={() => {
              handleClose();
              props.editor
                .chain()
                .focus()
                .unsetBlockHeading()
                .setBlockList("li")
                .run();
            }
            }
          />
          <DropdownBlockItem
            title="Numbered List"
            icon={RiListOrdered}
            isSelected={currentBlockName === "Numbered List"}
            onClick={() => {
              handleClose();
              props.editor
                .chain()
                .focus()
                .unsetBlockHeading()
                .setBlockList("oli")
                .run();
            }
            }
          />
          <DropdownBlockItem
            title="Task List"
            icon={RiListCheck}
            isSelected={currentBlockName === "Task List"}
            onClick={() => {
              handleClose();
              props.editor
                .chain()
                .focus()
                .unsetBlockHeading()
                .setBlockList("tli")
                .run();
            }
            }
          />
        </Menu>


        <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1 }} />


        <StyledToggleButtonGroup
          value={activatedFormat}
          size="small"
          exclusive
        >

          <ToggleButton value="bold"
            onClick={() => props.editor.chain().focus().toggleBold().run()}
          >
            <Tooltip title={`Bold ${formatKeyboardShortcut("Mod+B")}`} placement="top">
              <FormatBoldIcon fontSize='small' />

            </Tooltip>
          </ToggleButton>
          <ToggleButton value="italic"
            onClick={() => props.editor.chain().focus().toggleItalic().run()}
          >
            <Tooltip title={`Italic ${formatKeyboardShortcut("Mod+I")}`} placement="top">
              <FormatItalicIcon fontSize='small' />

            </Tooltip>
          </ToggleButton>
          <ToggleButton value="underline"
            onClick={() => props.editor.chain().focus().toggleUnderline().run()}
          >
            <Tooltip title={`Underline ${formatKeyboardShortcut("Mod+U")}`} placement="top">
              <FormatUnderlinedIcon fontSize='small' />

            </Tooltip>
          </ToggleButton>

          <ToggleButton value="strike"
            onClick={() => props.editor.chain().focus().toggleStrike().run()}
          >
            <Tooltip title={`Strike ${formatKeyboardShortcut("Mod+Shift+X")}`} placement="top">
              <StrikethroughSOutlinedIcon fontSize='small' />

            </Tooltip>

          </ToggleButton>


          <ToggleButton value="highlight"
            onClick={() => { (props.editor.chain().focus() as any).toggleHighlight().run(); }}
          >
            <Tooltip title={`Highlight ${formatKeyboardShortcut("Mod+Shift+H")}`} placement="top">
              <BorderColorOutlinedIcon fontSize='small' />

            </Tooltip>
          </ToggleButton>

          {/* <ToggleButton value="code"
                  onClick={() => props.editor.chain().focus().toggleCode().run()}
                  >
                    <Tooltip title={`Code ${formatKeyboardShortcut("Mod+E")}` } placement="top">
                    <CodeOutlinedIcon fontSize='small'/>

        </Tooltip>
          </ToggleButton> */}

        </StyledToggleButtonGroup>
        <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1 }} />

        <Tooltip title="Comment on selected text" placement="top"
          TransitionProps={{ timeout: 0 }}

        >
          <ColorButton onClick={() => {
            addNewCommentStream.next(false);
            bubbleMenueRequestStream.next({ show: false });

          }}>
            <InsertCommentOutlinedIcon fontSize='small' />
          </ColorButton>
        </Tooltip>
        <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1 }} />

        <Tooltip title={`Add link ${formatKeyboardShortcut("Mod+K")}`} placement="top">
          <ColorButton onClick={() => {
            showAddLinkView(props.editor, setAddLinkView)
            // setShouldShowLink(true);
          }}>
            <InsertLinkOutlinedIcon fontSize='small' />
          </ColorButton>
        </Tooltip>
      </Stack>}

      {addLinkView}


    </Paper>
  );
};
