import { useState, useEffect } from "react";
import { Box, Stack, Button, Tooltip, IconButton } from "@mui/material";
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";
import PauseRoundedIcon from "@mui/icons-material/PauseRounded";
import useLiveMessageReplay from "../recording/useLiveMessageReplay";
import useLiveMessageViewModel from "./useLiveMessageViewModel";
import { getTimeString } from "../../utils/ComputeUtil";
import LmTranscriptView from "./LmTranscriptView";
import CardTooltip from "../../utils/CardTooltip";
const replayButtonTitle = "Play this live message";
const pausePlaybackBtnTitle = "Pause playback";

const InLinePlaybackBlock = ({ lmId, lmDuration, selected }) => {
  // console.log(`lmDuration:${lmDuration}, lmId:${lmId}`);
  const {
    selectedLiveMessage,
    useFetchSingleLiveMessageById,
    selectLiveMessage,
  } = useLiveMessageViewModel();

  // TODO: Need some way to figure out if live message upload was complete.
  // If we use InLinePlaybackBlock right after live message creation it might still
  // be processing. We would need to keep refreshing in this case.
  const { liveMessage, isLoading, isError, mutate } =
    useFetchSingleLiveMessageById(lmId);

  const [
    replayState,
    {
      onHandleReplayingStart,
      onHandleRecordingReload,
      onHandleReplayingPaused,
      onHandleReplayingResumed,
      onHandleSeekToTarget,
    },
  ] = useLiveMessageReplay();

  const togglePlayPause = () => {
    if (selectedLiveMessage.liveMessageId !== lmId) {
      // select live message to start playback
      selectLiveMessage(liveMessage);
      onHandleRecordingReload(
        liveMessage.recordings,
        liveMessage.liveMessageId
      );
      // Should unpause always
      onHandleReplayingResumed();
      if (liveMessage.recordings.length > 0) {
        onHandleReplayingStart(liveMessage.recordings[0].recordingId);
      }
    } else {
      if (!replayState.activeRecording) {
        // recording was stopped. restarting again
        onHandleReplayingStart(liveMessage.recordings[0].recordingId);
      } else if (!replayState.activeRecordingPaused) {
        // have active recording, do player action
        onHandleReplayingPaused();
      } else {
        onHandleReplayingResumed();
      }
    }
  };
  // block selected by UI (mouse or text cursor)
  const isBlockSelected = selected;
  const isBlockActive = selectedLiveMessage.liveMessageId === lmId;
  const isPlaying =
    replayState.activeRecording && !replayState.activeRecordingPaused;

  //TODO make it more generic. build a native event layer
  const [openPreview, setOpenPreview] = useState(true);
  useEffect(() => {
    const listener = (e) => {
      setOpenPreview(false);
    };
    window.addEventListener("scroll", listener);
    return () => {
      window.removeEventListener("scroll", listener);
    };
  }, []);
  // console.log('openPreview', openPreview, 'isPlaying', isPlaying, 'liveMessage ',liveMessage);
  return (
    <CardTooltip
      onOpen={() => {
        setOpenPreview(true);
      }}
      onClose={() => {
        setOpenPreview(false);
      }}
      leaveDelay={50}
      placement="left"
      title={
        openPreview && !isPlaying && liveMessage && liveMessage.transcripts ? (
          <LmTranscriptView
            highlightCurrentSentence={false}
            liveMessage={liveMessage}
            isPreview={true}
          />
        ) : null
      }
    >
      <span>
        <Button
          onClick={liveMessage ? togglePlayPause : null}
          size="small"
          disabled={isLoading}
          disableRipple
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: isBlockActive || isBlockSelected ? "#666" : "#ccc",
            opacity: "0.8",
            borderRadius: "20px",
            "&:hover": {
              color: "#666",
              background: "none",
              opacity: "1",
            },
          }}
        >
          <Stack
            direction="row"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: "10px",
              marginRight: "10px",
            }}
          >
            <Box
              // size="small"
              // disableRipple
              sx={{
                color: "#000",
                opacity: "0.8",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {isPlaying && isBlockActive && <PauseRoundedIcon />}
              {(!isPlaying || !isBlockActive) && <PlayArrowRoundedIcon />}
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "#000",
                fontSize: "16px",
                marginLeft: "4px",
                textTransform: "none",
                opacity: isBlockActive || isBlockSelected ? "0.6" : "0.4",
              }}
            >
              {isBlockActive && replayState.activeRecording
                ? getTimeString(
                    replayState.lmTotalTime * (1 - replayState.lmHead / 100)
                  )
                : getTimeString(lmDuration)}
            </Box>
          </Stack>
        </Button>
      </span>
    </CardTooltip>
  );
};

export default InLinePlaybackBlock;
