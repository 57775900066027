import { useEffect, useState, useRef } from "react";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import AppTopBar from "../components/sidebar/AppTopBar";
import { Drawer, Box, IconButton } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import OwlEditorProvider from "../components/editor/OwlEditorProvider";
import LiveMessageBottomRecorder from "../components/liveMessage/LiveMessageBottomRecorder";
import LiveMessageBottomPlayer from "../components/liveMessage/LiveMessageBottomPlayer";
import LmHoldToRecordListener from "../components/liveMessage/LmHoldToRecordListener";
import LmScreenFollowerButton from "../components/liveMessage/LmScreenFollowerButton";
import LmCaptionView from "../components/liveMessage/LmCaptionView";
import useLiveMessageViewModel from "../components/liveMessage/useLiveMessageViewModel";
import { lastMoustCursorEvent$ } from "../utils/useLastMouseCursorEvent";
import ScreenFollowHintFrame from "../components/cursor/ScreenFollowHintFrame";
import LmTranscriptView from "../components/liveMessage/LmTranscriptView";
import { RIGHT_PANEL_WIDTH, TOP_APP_BAR_HEIGHT, BOTTOM_APP_BAR_HEIGHT, DOCUMENT_PADDING_TOP } from "../common/Constants";
import useSideBarOffset from "../components/sidebar/useSideBarOffset";
import CursorDebugOverlay from "../components/cursor/CursorDebugOverlay";
/**
 * Represents document level states. A team would have multiple documents.
 * Load states at document level (editor, top bar, live messages, etc)
 */
const DocumentMain = ({ documents, documentId, onDocumentChangeCallback, isSharedView=false }) => {
    const { selectedLiveMessage } = useLiveMessageViewModel();
    const { onLeftSideOpen, onLeftSideClose, onRightSideOpen, onRightSideClose } = useSideBarOffset();

    useEffect(() => {
        const reportCursor = (e) => {
            lastMoustCursorEvent$.next(e);
        }
        window.addEventListener("pointermove", reportCursor);
        // TODO implement logic after we can hide left bar
        if (isSharedView) {
            onLeftSideClose();
        } else {
            onLeftSideOpen();
        }
        return () => {
            window.removeEventListener("pointermove", reportCursor);
        };
    }, []);

    const [rightPanelOpen, setRightPanelOpen] = useState(false);
    const onToggleRightPanel = () => {
        setRightPanelOpen((open) => {
            if (!open) {
                onRightSideOpen();
            } else {
                onRightSideClose();
            }
            return !open;
        });
    };
    useEffect(() => {
        if (!selectedLiveMessage.liveMessageId) {
            setRightPanelOpen(false);
        }
    }, [selectedLiveMessage.liveMessageId]);
    return (
        <Stack
            direction="column"
            width={"100%"}
            height={"100vh"}
            sx={{ position: "relative" }}
        >
            <AppTopBar documents={documents} documentId={documentId} isSharedView={isSharedView}></AppTopBar>
            <Main
                open={rightPanelOpen}
                sx={{
                    width: "100%",
                    paddingTop: DOCUMENT_PADDING_TOP,
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                {documentId && <OwlEditorProvider documents={documents} documentId={documentId} onDocumentChangeCallback={onDocumentChangeCallback} />}
            </Main>
            <Drawer
                sx={{
                    position: "relative",
                    paddingTop: `${TOP_APP_BAR_HEIGHT}px`,
                    width: `${RIGHT_PANEL_WIDTH}px`,
                    "& .MuiDrawer-paper": {
                        width: `${RIGHT_PANEL_WIDTH}px`,
                        paddingTop: `${TOP_APP_BAR_HEIGHT}px`,
                        background: "#00000000",
                        height: `calc(100% - ${BOTTOM_APP_BAR_HEIGHT}px)`,
                    },
                }}
                variant="persistent"
                anchor="right"
                open={rightPanelOpen}
            >
                <IconButton
                    onClick={() => {
                        onToggleRightPanel();
                    }}
                    sx={{ position: "absolute", top: "16px", left: "8px" }}
                >
                    <ChevronRightRoundedIcon />
                </IconButton>
                <Box
                    sx={{
                        background: "white",
                        display: "flex",
                        justifyContent: "center",
                        height: "100%",
                    }}
                >
                    {selectedLiveMessage.liveMessageId && (
                        <LmTranscriptView
                            liveMessage={selectedLiveMessage}
                            sideSpace={"24px"}
                            hideTitle={true}
                        />
                    )}
                </Box>
            </Drawer>
            <LmScreenFollowerButton />
            {selectedLiveMessage.liveMessageId && (
                <>
                    <Box
                        position="sticky"
                        height={`${BOTTOM_APP_BAR_HEIGHT}px`}
                        sx={{ bottom: BOTTOM_APP_BAR_HEIGHT, boxShadow: "none", pointerEvents: "none" }}>
                        <LmCaptionView liveMessage={selectedLiveMessage}
                            sideSpace={"24px"}
                            AppBarhideTitle={true} />
                    </Box>
                    <AppBar
                        position="sticky"
                        width="100%"
                        height={`${BOTTOM_APP_BAR_HEIGHT}px`}
                        sx={{ bottom: 0, boxShadow: "none", background: 'none' }}
                    >
                        {selectedLiveMessage.isRecordingBlock && (
                            <LiveMessageBottomRecorder documentId={documentId} />
                        )}
                        {!selectedLiveMessage.isRecordingBlock && (
                            <LiveMessageBottomPlayer
                                isTranscriptOpen={rightPanelOpen}
                                onTranscriptClicked={onToggleRightPanel}
                            />
                        )}
                    </AppBar>
                </>
            )}
            <ScreenFollowHintFrame />
            <LmHoldToRecordListener />

            {/* <CursorDebugOverlay></CursorDebugOverlay> */}

        </Stack>
    );
};

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
    ({ theme, open }) => ({
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create("padding", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        paddingRight: 0, // magic number of the right panel size
        ...(open && {
            transition: theme.transitions.create("padding", {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            paddingRight: RIGHT_PANEL_WIDTH,
        }),
    })
);

export default DocumentMain;