
function sanitizeDocumentName(documentName) {
    return documentName.replace(/[^A-Za-z0-9-]/g, ' ').trim().replace(/[ ]+/g, '-');
};

export function encodeRoutePath(documentName, documentId) {
    // sanitize document name
    let sanitized = sanitizeDocumentName(documentName);
    if (sanitized) {
        return sanitized + "-" + documentId;
    } else {
        return documentId;
    }
};


export function decodeRoutePath(documentPath) {
    const uuid = documentPath.substring(documentPath.length - 36, documentPath.length);
    return uuid;
};