import { mergeAttributes, Node, findChildren } from "@tiptap/core";
import { Selection, TextSelection, NodeSelection } from "prosemirror-state";
import { joinBackward } from "../commands/joinBackward";
import { sinkListItem } from "../commands/sinkListItem";
import { liftListItem } from "../commands/liftListItem";
import { findBlock } from "../helpers/findBlock";
import { setBlockHeading } from "../helpers/setBlockHeading";
import { OrderedListPlugin } from "../OrderedListPlugin";
import { TaskListPlugin } from "../TaskListPlugin";
import { BlockMouseCursorPlugin } from "../BlockMouseCursorPlugin";
import { textblockTypeInputRuleSameNodeType } from "../rule";
import styles from "./Block.module.css";

export interface IBlock {
  HTMLAttributes: Record<string, any>;
}

export type Level = 1 | 2 | 3;
export type ListType = "li" | "oli" | "tli";

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    blockHeading: {
      /**
       * Set a heading node
       */
      setBlockHeading: (attributes: { level: Level }) => ReturnType;
      /**
       * Unset a heading node
       */
      unsetBlockHeading: () => ReturnType;

      unsetList: () => ReturnType;

      addNewBlockAsSibling: (attributes?: {
        headingType?: Level;
        listType?: ListType;
      }) => ReturnType;
      addImageAsSibling: (attributes: { src: String }) => ReturnType;
      addImageBlockAsSibling: (attributes: { src: String }) => ReturnType;
      addLiveMessageAsSibling: () => ReturnType;
      setBlockList: (type: ListType) => ReturnType;
    };
  }
}

/**
 * The main "Block node" documents consist of
 */
export const Block = Node.create<IBlock>({
  name: "owlblock",
  group: "block",
  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  // A block always contains content, and optionally a blockGroup which contains nested blocks
  content: "content blockgroup?",

  defining: true,

  addAttributes() {
    return {
      listType: {
        default: undefined,
      },
      headingType: {
        default: undefined,
        keepOnSplit: false,
      },
      embeddings: {
        default: undefined,
      }
    };
  },

  // TODO: should we parse <li>, <ol>, <h1>, etc?
  parseHTML() {
    return [
      // For parsing blocks within the editor.
      {
        tag: "div",
        getAttrs: (element) => {
          if (typeof element === "string") {
            return false;
          }

          // Only adds attributes if they're actually present in the element.
          const attrs = {
            ...(element.getAttribute("data-list-type") && {
              listType: element.getAttribute("data-list-type"),
            }),
            ...(element.getAttribute("data-block-color") && {
              blockColor: element.getAttribute("data-block-color"),
            }),
            ...(element.getAttribute("data-block-style") && {
              blockStyle: element.getAttribute("data-block-style"),
            }),
            ...(element.getAttribute("data-heading-type") && {
              headingType: element.getAttribute("data-heading-type"),
            }),
          };

          if (element.getAttribute("data-node-type") === "block") {
            return attrs;
          }

          return false;
        },
      },
      {
        tag: "h1",
        attrs: { headingType: 1 },
      },
      {
        tag: "h2",
        attrs: { headingType: 2 },
      },
      {
        tag: "h3",
        attrs: { headingType: 3 },
      },
      // For parsing list items copied from outside the editor.
      {
        tag: "li",
        getAttrs: (element) => {
          if (typeof element === "string") {
            return false;
          }

          const parent = element.parentElement;

          if (parent === null) {
            return false;
          }

          // Gets type of list item (ordered/unordered) based on parent element's tag ("ol"/"ul").
          if (parent.tagName === "UL") {
            return { listType: "li" };
          }

          if (parent.tagName === "OL") {
            return { listType: "oli" };
          }

          return false;
        },
      },
      { tag: "pre" },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    const attrs = {
      "data-list-type": HTMLAttributes.listType,
      "data-block-color": HTMLAttributes.blockColor,
      "data-block-style": HTMLAttributes.blockStyle,
      "data-heading-type": HTMLAttributes.headingType,
    };

    return [
      "div",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, attrs, {
        class: styles.blockOuter,
        "data-node-type": "block-outer",
      }),
      [
        "div",
        mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, attrs, {
          class: styles.block,
          "data-node-type": "block",
        }),
        0,
      ],
    ];
  },

  addInputRules() {
    return [
      ...[1, 2, 3].map((level) => {
        // Create a heading when starting with "#", "##", or "###""
        return textblockTypeInputRuleSameNodeType({
          find: new RegExp(`^(#{1,${level}})\\s$`),
          type: this.type,
          getAttributes: {
            headingType: level,
          },
        });
      }),
      // Create a list when starting with "-"
      textblockTypeInputRuleSameNodeType({
        find: /^\s*([-+*])\s$/,
        type: this.type,
        getAttributes: {
          listType: "li",
        },
      }),
      textblockTypeInputRuleSameNodeType({
        find: new RegExp(/^1\.\s/),
        type: this.type,
        getAttributes: {
          listType: "oli",
        },
      }),
      // Create a task list when starting with "[]"
      textblockTypeInputRuleSameNodeType({
        find: /^\s*(\[([( |x])?\])\s$/,
        type: this.type,
        getAttributes: {
          listType: "tli",
        },
      }),
    ];
  },

  addCommands() {
    return {
      setBlockHeading:
        (attributes) =>
          ({ tr, dispatch }) => {
            return setBlockHeading(tr, dispatch, attributes.level);
          },
      unsetBlockHeading:
        () =>
          ({ tr, dispatch }) => {
            return setBlockHeading(tr, dispatch, undefined);
          },
      unsetList:
        () =>
          ({ tr, dispatch }) => {
            const node = tr.selection.$anchor.node(-1);
            const nodePos = tr.selection.$anchor.posAtIndex(0, -1) - 1;

            // const node2 = tr.doc.nodeAt(nodePos);
            if (node.type.name === "owlblock" && node.attrs["listType"]) {
              if (dispatch) {
                tr.setNodeMarkup(nodePos, undefined, {
                  ...node.attrs,
                  listType: undefined,
                });
                return true;
              }
            }
            return false;
          },

      addNewBlockAsSibling:
        (attributes) =>
          ({ tr, dispatch, state }) => {
            // Get current block
            const currentBlock = findBlock(tr.selection);
            if (!currentBlock) {
              return false;
            }

            // If current blocks content is empty dont create a new block
            if (currentBlock.node.firstChild?.textContent.length === 0) {
              if (dispatch) {
                tr.setNodeMarkup(currentBlock.pos, undefined, attributes);
              }
              return true;
            }

            // Create new block after current block
            const endOfBlock = currentBlock.pos + currentBlock.node.nodeSize;
            let newBlock = state.schema.nodes["owlblock"].createAndFill(attributes)!;
            if (dispatch) {
              tr.insert(endOfBlock, newBlock);
              tr.setSelection(new TextSelection(tr.doc.resolve(endOfBlock + 1)));
            }
            return true;
          },
      addImageAsSibling:
        (attributes) => ({ tr, dispatch, state }) => {
          // Get current block
          const currentBlock = findBlock(tr.selection);
          if (!currentBlock) {
            return false;
          }

          // If current blocks content is empty AND not contain a block group dont create a new block
          if (currentBlock.node.firstChild?.textContent.length === 0 && currentBlock.node.childCount <= 1) {
            if (dispatch) {
              const endOfBlock = currentBlock.pos + currentBlock.node.nodeSize;
              let newBlock = state.schema.nodes["image"].createAndFill(attributes)!;
              tr.replaceWith(currentBlock.pos, endOfBlock, newBlock);
              tr.setSelection(new TextSelection(tr.doc.resolve(currentBlock.pos + 1)));
            }
            return true;
          }

          // Create new block after current block
          const endOfBlock = currentBlock.pos + currentBlock.node.nodeSize;
          let newBlock = state.schema.nodes["image"].createAndFill(attributes)!;
          if (dispatch) {
            tr.insert(endOfBlock, newBlock);
            tr.setSelection(new TextSelection(tr.doc.resolve(endOfBlock + 1)));
          }
          return true;
        },
      addImageBlockAsSibling: () => ({ tr, dispatch, state }) => {
        // Get current block
        const currentBlock = findBlock(tr.selection);
        if (!currentBlock) {
          return false;
        }

        // If current blocks content is empty AND not contain a block group dont create a new block
        if (currentBlock.node.firstChild?.textContent.length === 0 && currentBlock.node.childCount <= 1) {
          if (dispatch) {
            const endOfBlock = currentBlock.pos + currentBlock.node.nodeSize;
            let newBlock = state.schema.nodes["imageBlock"].createAndFill()!;
            tr.replaceWith(currentBlock.pos, endOfBlock, newBlock);
            tr.setSelection(new TextSelection(tr.doc.resolve(currentBlock.pos + 1)));
          }
          return true;
        }

        // Create new block after current block
        const endOfBlock = currentBlock.pos + currentBlock.node.nodeSize;
        let newBlock = state.schema.nodes["liveMessageBlock"].createAndFill()!;
        if (dispatch) {
          tr.insert(endOfBlock, newBlock);
          tr.setSelection(new TextSelection(tr.doc.resolve(endOfBlock + 1)));
        }
        return true;
      },
      addLiveMessageAsSibling: () => ({ tr, dispatch, state }) => {
        // Get current block
        const currentBlock = findBlock(tr.selection);
        if (!currentBlock) {
          return false;
        }

        // If current blocks content is empty AND not contain a block group dont create a new block
        if (currentBlock.node.firstChild?.textContent.length === 0 && currentBlock.node.childCount <= 1) {
          if (dispatch) {
            const endOfBlock = currentBlock.pos + currentBlock.node.nodeSize;
            let newBlock = state.schema.nodes["liveMessageBlock"].createAndFill()!;
            tr.replaceWith(currentBlock.pos, endOfBlock, newBlock);
            tr.setSelection(new TextSelection(tr.doc.resolve(currentBlock.pos + 1)));
          }
          return true;
        }

        // Create new block after current block
        const endOfBlock = currentBlock.pos + currentBlock.node.nodeSize;
        let newBlock = state.schema.nodes["liveMessageBlock"].createAndFill()!;
        if (dispatch) {
          tr.insert(endOfBlock, newBlock);
          tr.setSelection(new TextSelection(tr.doc.resolve(endOfBlock + 1)));
        }
        return true;
      },
      setBlockList:
        (type) =>
          ({ tr, dispatch }) => {
            const node = tr.selection.$anchor.node(-1);
            const nodePos = tr.selection.$anchor.posAtIndex(0, -1) - 1;

            // const node2 = tr.doc.nodeAt(nodePos);
            if (node.type.name === "owlblock") {
              if (dispatch) {
                tr.setNodeMarkup(nodePos, undefined, {
                  ...node.attrs,
                  listType: type,
                });

                // if tli, remove checkbox as well
                if (node.attrs["listType"] === "tli") {
                  if (node.content.child(0).attrs.contentType) {
                    tr.setNodeMarkup(nodePos + 1, undefined, {
                      ...node.attrs,
                      contentType: undefined,
                    });
                  }
                }
              }
              return true;
            }
            return false;
          },
      joinBackward:
        () =>
          ({ view, dispatch, state }) =>
            joinBackward(state, dispatch, view), // Override default joinBackward with edited command
      sinkListItem: sinkListItem, // Override default joinBackward with edited command
      liftListItem: liftListItem  // Override default joinBackward with edited command
    };
  },
  addProseMirrorPlugins() {
    return [OrderedListPlugin(), TaskListPlugin(), BlockMouseCursorPlugin()];
  },
  addKeyboardShortcuts() {
    // handleBackspace is partially adapted from https://github.com/ueberdosis/tiptap/blob/ed56337470efb4fd277128ab7ef792b37cfae992/packages/core/src/extensions/keymap.ts
    const handleBackspace = () =>
      this.editor.commands.first(({ commands }) => [
        // Maybe the user wants to undo an auto formatting input rule (e.g.: - or #, and then hit backspace) (source: tiptap)
        () => commands.undoInputRule(),
        // maybe convert first text block node to default node (source: tiptap)
        ({ chain }) =>
          commands.command(({ tr }) => {
            console.log('handleBackspace 1');
            const { selection, doc } = tr;
            const { empty, $anchor } = selection;
            const { pos, parent } = $anchor;
            const isAtStart = Selection.atStart(doc).from === pos;

            if (
              !empty ||
              !isAtStart ||
              !parent.type.isTextblock ||
              parent.textContent.length
            ) {
              return false;
            }

            // Clean up everything to make it a default node
            return chain().clearNodes().unsetList().unsetBlockHeading().run();
          }),
        () => commands.deleteSelection(), // (source: tiptap)
        () =>
          commands.command(({ tr }) => {
            console.log('handleBackspace 2');
            const isAtStartOfNode = tr.selection.$anchor.parentOffset === 0;
            const node = tr.selection.$anchor.node(-1);
            const nodePos = tr.selection.$anchor.posAtIndex(0, -1) - 1;

            if (isAtStartOfNode && node.type.name === "owlblock") {
              // we're at the start of the block, so we're trying to "backspace" the bullet or indentation
              // if tli, remove checkbox as well
              if (node.attrs["listType"] === "tli") {
                if (node.content.child(0).attrs.contentType) {
                  tr.setNodeMarkup(nodePos + 1, undefined, {
                    ...node.attrs,
                    contentType: undefined,
                  });
                }
              }
              return commands.first([
                () => commands.unsetList(), // first try to remove the "list" property
                () => { // try remove indentation
                  const node = tr.selection.$anchor.node(-1);
                  const isEmptyLine = node.textContent === "";
                  const nodeGroup = tr.selection.$anchor.node(-2);
                  console.log('handleBackspace 2.1', nodeGroup, node.childCount);
                  if (nodeGroup.childCount > 1 && node.childCount <= 1 && isEmptyLine) {
                    // Don't lift item if current node is empty (no content + no subtree within the current block)
                    console.log('handleBackspace 2.2', nodeGroup.childCount);
                    return false;
                  } else {
                    console.log('handleBackspace 2.1 out');
                    return commands.liftListItem("owlblock"); // then try to remove a level of indentation
                  }
                },
              ]);
            }
            return false;
          }),
        // if prev block is an atom AND this block is empty. Couldnt join previous block so delete this block
        () =>
          commands.command(({ tr }) => {
            console.log('handleBackspace 3');
            const isAtStartOfNode = tr.selection.$anchor.parentOffset === 0;

            // get the block node. For a typical block node. The logic is
            // node() = content, node(-1) = block, node(-2) = blockgroup.
            // node(0) = doc, node(1) = blockgroup
            const node = tr.selection.$anchor.node(-1);
            const isEmptyLine = node.textContent === "";
            const nodePos = tr.selection.$anchor.posAtIndex(0, -1);
            const resolvedNodePos = tr.doc.resolve(nodePos);

            // Only delete cur line if cur line is empty
            if (isAtStartOfNode && isEmptyLine) {
              const prevBlock = resolvedNodePos.nodeBefore;
              const beforeNodePos = resolvedNodePos.before();
              if (prevBlock && prevBlock.isAtom) {
                tr.delete(nodePos, nodePos + node.nodeSize).setSelection(new NodeSelection(tr.doc.resolve(beforeNodePos - 1))).scrollIntoView();
                return true;
              }
            }
            return false;

          }),
        ({ chain }) =>
          // we are at the start of a block at the root level. The user hits backspace to "merge it" to the end of the block above
          //
          // BlockA
          // BlockB

          // Becomes:

          // BlockABlockB

          chain()
            .command(({ tr, state, dispatch }) => {
              // TODO: "Merge later line with previous indented atom will kill the whole paragraph is handled here" Normally, text merging is handled here.
              console.log('handleBackspace 4');
              const isAtStartOfNode = tr.selection.$anchor.parentOffset === 0;
              const anchor = tr.selection.$anchor;
              const node = anchor.node(-1);
              if (isAtStartOfNode && node.type.name === "owlblock") {
                if (node.childCount === 2) {
                  // BlockB has children. We want to go from this:
                  //
                  // BlockA
                  // BlockB
                  //    BlockC
                  //        BlockD
                  //
                  // to:
                  //
                  // BlockABlockB
                  // BlockC
                  //     BlockD

                  // This parts moves the children of BlockB to the top level
                  const startSecondChild = anchor.posAtIndex(1, -1) + 1; // start of blockgroup
                  const endSecondChild = anchor.posAtIndex(2, -1) - 1;
                  const range = state.doc
                    .resolve(startSecondChild)
                    .blockRange(state.doc.resolve(endSecondChild));

                  if (dispatch) {
                    tr.lift(range!, anchor.depth - 2);
                  }
                }
                return true;
              }
              return false;
            })
            // use joinBackward to merge BlockB to BlockA (i.e.: turn it into BlockABlockB)
            // The standard JoinBackward would break here, and would turn it into:
            // BlockA
            //     BlockB
            //
            // joinBackward has been patched with our custom version to fix this (see commands/joinBackward)
            .joinBackward()
            .run(),
        ({ chain }) =>
          // we are at the start of a block at the root level. The user hits backspace to "merge it" to the end of the block above
          //
          // BlockA
          // BlockB

          // Becomes:

          // BlockABlockB

          chain()
            .command(({ tr, state, dispatch }) => {
              console.log('handleBackspace 5');
              const isAtStartOfNode = tr.selection.$anchor.parentOffset === 0;
              const anchor = tr.selection.$anchor;
              const node = anchor.node(-1);
              if (isAtStartOfNode && node.type.name === "owlblock") {
                if (node.childCount === 2) {
                  // BlockB has children. We want to go from this:
                  //
                  // BlockA
                  // BlockB
                  //    BlockC
                  //        BlockD
                  //
                  // to:
                  //
                  // BlockABlockB
                  // BlockC
                  //     BlockD

                  // This parts moves the children of BlockB to the top level
                  const startSecondChild = anchor.posAtIndex(1, -1) + 1; // start of blockgroup
                  const endSecondChild = anchor.posAtIndex(2, -1) - 1;
                  const range = state.doc
                    .resolve(startSecondChild)
                    .blockRange(state.doc.resolve(endSecondChild));

                  if (dispatch) {
                    tr.lift(range!, anchor.depth - 2);
                  }
                }
                return true;
              }
              return false;
            })
            // use joinBackward to merge BlockB to BlockA (i.e.: turn it into BlockABlockB)
            // The standard JoinBackward would break here, and would turn it into:
            // BlockA
            //     BlockB
            //
            // joinBackward has been patched with our custom version to fix this (see commands/joinBackward)
            .joinBackward()
            .run(),
        () => commands.selectNodeBackward(), // (source: tiptap)
      ]);

    const handleEnter = () =>
      this.editor.commands.first(({ commands }) => [
        // Try to split the current block into 2 items:
        ({ tr, state, dispatch }) => {
          console.log('handleEnter 1');
          const fromParentOffset = tr.selection.$from.parentOffset;
          const $from = tr.selection.$from;
          const node = tr.selection.$anchor.node(-1);

          // Handling if we are at start of line
          if (fromParentOffset === 0 && !node.attrs["listType"]) {
            if (dispatch) {
              console.log('handleEnter 1.1');

              let newBlock = state.schema.nodes["owlblock"].createAndFill({})!;

              tr.insert($from.pos - 2, newBlock).scrollIntoView();
            }
            return true;
          } else {
            console.log('handleEnter 1.2');
            let res = commands.splitListItem("owlblock");
            return res;
          }
        },
        // For atom blocks, enter will create new line
        ({ tr, state, dispatch }) => {
          console.log('handleEnter 2');
          const node = tr.selection.$anchor.nodeAfter;
          // Handling if we are at start of line
          if (node && node.isAtom) {
            if (dispatch) {
              // BUG: Need to understand current indentation state
              let newBlock = state.schema.nodes["owlblock"].createAndFill({})!;
              let endOfBlock = tr.selection.$anchor.pos + node.nodeSize;
              tr.insert(endOfBlock, newBlock).setSelection(new TextSelection(tr.doc.resolve(endOfBlock + 1))).scrollIntoView();
            }
            return true;
          }

          return false;
        },
        // Otherwise, maybe we are in an empty list item. "Enter" should remove the list bullet
        ({ tr, dispatch }) => {
          console.log('handleEnter 3');
          const $from = tr.selection.$from;
          if ($from.depth !== 3) {
            // only needed at root level, at deeper levels it should be handled already by splitListItem
            return false;
          }
          const node = tr.selection.$anchor.node(-1);
          const nodePos = tr.selection.$anchor.posAtIndex(0, -1) - 1;

          if (node.type.name === "owlblock" && node.attrs["listType"]) {
            if (dispatch) {
              tr.setNodeMarkup(nodePos, undefined, {
                ...node.attrs,
                listType: undefined,
              });

              // if tli, remove checkbox as well
              if (node.attrs["listType"] === "tli") {
                if (node.content.child(0).attrs.contentType) {
                  tr.setNodeMarkup(nodePos + 1, undefined, {
                    ...node.attrs,
                    contentType: undefined,
                  });
                }
              }
            }
            return true;
          }
          return false;
        },
        // Otherwise, we might be on an empty line and hit "Enter" to create a new line:
        ({ tr, dispatch }) => {
          console.log('handleEnter 4');
          const $from = tr.selection.$from;
          if (dispatch) {
            tr.split($from.pos, 2).scrollIntoView();
          }
          return true;
        },
      ]);

    return {
      // Some food for thoughts: https://discuss.prosemirror.net/t/backspace-and-enter-and-delete-i-guess/57/16
      Backspace: handleBackspace,
      // Delete: handleDelete,
      Enter: handleEnter,
      Tab: () => {
        this.editor.commands.sinkListItem("owlblock");
        return true; // <- make sure to return true to prevent the tab from blurring.
      },
      // HACK HACK HACK: Because we inserted a label at line start, the cursor movement behavior became odd. This made sure that users can still navigate the doc via left arrow
      ArrowLeft: (editor) => {
        // if at start of a block, move to end of previous block
        return this.editor.chain().selectNodeBackward().command(({ tr, state, dispatch }) => {
          const endOfNode = tr.selection.$head;

          if (tr.selection instanceof NodeSelection) {
            let nodeSelection = tr.selection;
            if (nodeSelection.node.isAtom) {
              // Do nothing else for atom
              return true;
            } else {
              if (dispatch) {
                console.log('tr.selection.node', tr.selection.node.type.name);
                if (tr.selection.node.type.name === 'owlblock') {
                  const contentNodes = findChildren(
                    tr.selection.node,
                    (node) => {
                      return node.type.name === 'content';
                    }
                  );
                  // find end of content block and move on it
                  if (contentNodes.length > 0) {
                    let endOfTextPos = tr.selection.$anchor.pos + contentNodes[contentNodes.length -1].pos + contentNodes[contentNodes.length -1].node.nodeSize;
                    tr.setSelection(new TextSelection(tr.doc.resolve(endOfTextPos))).scrollIntoView();
                  }
                } else {
                  // for content just select the last pos
                  tr.setSelection(new TextSelection(tr.doc.resolve(endOfNode.pos - 1))).scrollIntoView();
                }
              }
              return true;
            }
          }
          return false;
        }).run();
      },
      "Shift-Tab": () => {
        this.editor.commands.liftListItem("owlblock");
        return true; // <- make sure to return true to prevent the tab from blurring.
      },
      "Mod-Alt-0": () =>
        this.editor.chain().unsetList().unsetBlockHeading().run(),
      "Mod-Alt-1": () => this.editor.commands.setBlockHeading({ level: 1 }),
      "Mod-Alt-2": () => this.editor.commands.setBlockHeading({ level: 2 }),
      "Mod-Alt-3": () => this.editor.commands.setBlockHeading({ level: 3 }),
      "Mod-Alt-9": () => this.editor.commands.addImageAsSibling({ src: 'https://source.unsplash.com/8xznAGy4HcY/800x400' }),
      "Mod-Shift-7": () => this.editor.commands.setBlockList("li"),
      "Mod-Shift-8": () => this.editor.commands.setBlockList("oli"),
      "Mod-Shift-9": () => this.editor.commands.setBlockList("tli"),

      // TODO: Add shortcuts for numbered and bullet list
    };
  },
});
