import React, { useMemo } from "react";
import Tooltip from '@mui/material/Tooltip';
import classNames from "classnames";
import { getContrastingColor } from "../../utils/getContrastingColor";
import styles from "./Avatar.module.css";

type BothProps = {
  variant?: "avatar" | "more";
  size?: number;
  outlineColor?: string;
  outlineWidth?: number;
  borderRadius?: number;
  className?: string;
  style?: Record<string, string>;
};

type PictureProps = BothProps & {
  variant?: "avatar";
  name?: string;
  picture?: string;
  color: [string, string];
  statusColor?: string;
  count?: never;
};

type MoreProps = BothProps & {
  variant: "more";
  count: number;
  picture?: never;
  name?: never;
  statusColor?: never;
  color?: never;
};

type AvatarProps = PictureProps | MoreProps;

/**
 * Can present avatars as gradients with letters, as pictures, or as a count (e.g +3)
 * Size, outline color, color, radius can all be changed, a status circle can be added
 */
export function Avatar({
  variant = "avatar",
  picture = "",
  name = "",
  color = ["", ""],
  size = 52,
  statusColor = "",
  outlineColor = "",
  outlineWidth = 3,
  borderRadius = 9999,
  className = "",
  style = {},
  count = 0,
  onClickCallback = () => { }
}: AvatarProps) {
  const innerVariant = variant === "avatar" && !picture ? "letter" : variant;
  const realSize = size - outlineWidth * 2;

  return (
    <Tooltip title={name}>
      <div style={{ cursor: "pointer", userSelect: "none" }} onClick={() => { onClickCallback() }}>
        <div
          style={{
            height: realSize,
            width: realSize,
            boxShadow: `${outlineColor} 0 0 0 ${outlineWidth}px`,
            margin: outlineWidth,
            borderRadius,
            ...style,
          }}
          className={classNames(styles.avatar, className)}
          onClick={() => { console.log(name); }}
          data-tooltip={name}
        >
          {innerVariant === "more" ? (
            <MoreCircle count={count} borderRadius={borderRadius} />
          ) : null}

          {innerVariant === "avatar" ? (
            <PictureCircle
              name={name}
              picture={picture}
              size={realSize}
              borderRadius={borderRadius}
            />
          ) : null}

          {innerVariant === "letter" ? (
            <LetterCircle name={name} color={color} borderRadius={borderRadius} />
          ) : null}

          {statusColor ? (
            <span
              style={{ backgroundColor: statusColor }}
              className={styles.status}
            />
          ) : null}
        </div>
      </div>
    </Tooltip>
  );
}

function LetterCircle({
  name,
  color,
  borderRadius,
}: Pick<PictureProps, "name" | "color" | "borderRadius">) {
  const textColor = useMemo(
    () => (color ? getContrastingColor(color[1]) : undefined),
    [color]
  );
  return (
    <div
      style={{
        backgroundImage: `linear-gradient(to bottom right, ${color[0]}, ${color[1]})`,
        borderRadius,
      }}
      className={styles.letter}
    >
      <div className={styles.letterCharacter} style={{ color: textColor }}>
        {name ? name.charAt(0) : null}
      </div>
    </div>
  );
}

function PictureCircle({
  name,
  picture = "",
  size,
  borderRadius,
}: Pick<PictureProps, "name" | "picture" | "size" | "borderRadius">) {
  return (
    <img
      alt={name}
      src={picture}
      height={size}
      width={size}
      style={{ borderRadius }}
    />
  );
}

function MoreCircle({
  count,
  borderRadius,
}: Pick<MoreProps, "count" | "borderRadius">) {
  return (
    <div style={{ borderRadius }} className={styles.more}>
      +{count}
    </div>
  );
}