import { findBlock } from "../../Blocks/helpers/findBlock";
import { findChildren } from "@tiptap/core";
import { NodeSelection } from "prosemirror-state";


export const selectionToRelativeBlockPos = (selection) => {
    let block = null;
    if (selection instanceof NodeSelection) {
        // Deal with image
        block = selection;
    } else {
        block = findBlock(selection);
    }
    
    if (block) {
        return { blockId: block.node.attrs['id'], anchor: selection.$anchor.pos - block.pos, head: selection.$head.pos - block.pos }
    } else {
        return { anchor: selection.$anchor.pos, head: selection.$head.pos }
    }

};

const findBlockFromId = (doc, blockId) => {
    let foundBlocks = findChildren(doc, (node) => node.attrs['id'] === blockId);
    if (foundBlocks.length > 0) { return foundBlocks[0]; }
    return null;
};

export const relativeBlockPosToAbsolute = (doc, cursor) => {
    let { blockId, anchor, head } = cursor;
    let block = findBlockFromId(doc, blockId);
    // console.log('relativeBlockPosToAbsolute findBlockFromId', blockId, block);
    if (block) {
        // console.log('relativeBlockPosToAbsolute return', { anchor: anchor + block.pos, head: head + block.pos });
        return { anchor: anchor + block.pos, head: head + block.pos }
    } else {
        if (blockId) {
            // block missing
            return { anchor: null, head: null }
        } else {
            // absolute encoding
            return { anchor: anchor, head: head }
        }
        
    }
};