import axios from 'axios';
import {
  createMedia,
  updateMedia,
  getMedia,
} from "../lib/backend";

export const createSingleMedia = async (accountId, imageUrl) => {
  // create recording for each of records
  let created = await createMedia(accountId);

  // upload files for each of records
  const buffer = dataURItoBlob(imageUrl);
  await uploadImage(buffer, created.mediaUploadUrl);
  await updateMedia(accountId, created.mediaId, created.mediaStorageKey);
  let media = await getMedia(accountId, created.mediaId);
  // console.log('getMedia', media);
  return media;
};

export const createSingleMediaFromFile = async (accountId, file) => {
  // create recording for each of records
  let created = await createMedia(accountId);

  // upload files for each of records
  const buffer = await fileReader(file);
  await uploadImage(buffer, created.mediaUploadUrl);
  await updateMedia(accountId, created.mediaId, created.mediaStorageKey);
  let media = await getMedia(accountId, created.mediaId);
  // console.log('getMedia', media);
  return media;
};

const uploadImage = async (buffer, url) => {
  const res = await axios({
    method: "PUT",
    url: url,
    data: buffer,
    // headers: {
    //     Origin: "https://www.owl3d.com",
    // },
    maxContentLength: Infinity,
    maxBodyLength: Infinity,
  });

  console.log("uploadImage complete", res);
};


function dataURItoBlob(dataURI) {
  var binary = atob(dataURI.split(',')[1]);
  var array = [];
  for(var i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
  }
  return new Blob([new Uint8Array(array)], {type: 'image/jpeg'});
}

function fileReader(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      resolve(reader.result);
    };

    reader.readAsArrayBuffer(file);
  });
}