import {
  Editor,
  isNodeSelection,
  isTextSelection,
  posToDOMRect,
} from "@tiptap/core";
import { EditorState, Plugin, PluginKey } from "prosemirror-state";
import { EditorView } from "prosemirror-view";
import tippy, { Instance, Props } from "tippy.js";
import { BehaviorSubject, Subject } from "rxjs";
import { createRoot, Root } from 'react-dom/client';
import rootStyles from "../../root.module.css";
import { BubbleMenu } from "./component/BubbleMenu";

export const bubbleMenuStateStream = new BehaviorSubject({isShown: false});
export const bubbleMenueRequestStream = new Subject<{show:Boolean}>();
// Same as TipTap bubblemenu plugin, but with these changes:
// https://github.com/ueberdosis/tiptap/pull/2596/files
export interface BubbleMenuPluginProps {
  pluginKey: PluginKey | string;
  editor: Editor;
  tippyOptions?: Partial<Props>;
  shouldShow?:
  | ((props: {
    editor: Editor;
    view: EditorView;
    state: EditorState;
    oldState?: EditorState;
    from: number;
    to: number;
  }) => boolean)
  | null;
}

export type BubbleMenuViewProps = BubbleMenuPluginProps & {
  view: EditorView;
};

export class BubbleMenuView {
  public editor: Editor;

  public element: HTMLElement;

  public view: EditorView;

  public preventHide = false;

  public preventShow = false;

  public tippy: Instance | undefined;

  public tippyOptions?: Partial<Props>;

  public root: Root;

  public shouldShow: Exclude<BubbleMenuPluginProps["shouldShow"], null> = ({
    view,
    state,
    from,
    to,
  }) => {
    const { doc, selection } = state;
    const { empty } = selection;

    // Sometime check for `empty` is not enough.
    // Doubleclick an empty paragraph returns a node size of 2.
    // So we check also for an empty text size.
    const isEmptyTextBlock =
      !doc.textBetween(from, to).length && isTextSelection(state.selection);

    const disableShowNodeTypes = ["image", "liveMessageBlock", "imageBlock"];
    const isDisableShowBlock =
      isNodeSelection(state.selection) && disableShowNodeTypes.includes(state.selection.node.type.name);

    // richard: Comment this to fix bubble menu popping issue after click..
    // if (!view.hasFocus() || empty || isEmptyTextBlock) {
    //   return false;
    // }

    const isEditable = view.editable;

    if (empty || isEmptyTextBlock || isDisableShowBlock || !isEditable) {
      return false;
    }

    return true;
  };

  constructor({
    editor,
    view,
    tippyOptions = {},
    shouldShow,
  }: BubbleMenuViewProps) {
    this.element = document.createElement("div");
    this.element.className = rootStyles.bnRoot;
    this.root = createRoot(this.element);
    this.root.render(<BubbleMenu editor={editor} />);

    this.editor = editor;
    this.view = view;

    if (shouldShow) {
      this.shouldShow = shouldShow;
    }

    this.element.addEventListener("mousedown", this.mousedownHandler, {
      capture: true,
    });
    this.view.dom.addEventListener("mousedown", this.viewMousedownHandler);
    this.view.dom.addEventListener("mouseup", this.viewMouseupHandler);
    this.view.dom.addEventListener("dragstart", this.dragstartHandler);

    this.editor.on("focus", this.focusHandler);
    this.editor.on("blur", this.blurHandler);
    this.tippyOptions = tippyOptions;
    // Detaches menu content from its current parent
    this.element.remove();
    this.element.style.visibility = "visible";
    bubbleMenueRequestStream.subscribe((request)=>{
      if (!request.show) {
        this.hide();
      } else {
        // for now we do not allow open the bubble menue from the outside
      }
    })
  }

  mousedownHandler = () => {
    this.preventHide = true;
  };

  viewMousedownHandler = () => {
    this.preventShow = true;
  };

  viewMouseupHandler = () => {
    this.preventShow = false;
    setTimeout(() => this.update(this.editor.view));
  };

  dragstartHandler = () => {
    this.hide();
  };

  focusHandler = () => {
    // we use `setTimeout` to make sure `selection` is already updated
    setTimeout(() => this.update(this.editor.view));
  };

  blurHandler = ({ event }: { event: FocusEvent }) => {
    if (this.preventHide) {
      this.preventHide = false;

      return;
    }

    if (
      event?.relatedTarget &&
      this.element.parentNode?.contains(event.relatedTarget as Node)
    ) {
      return;
    }

    this.hide();
  };

  createTooltip() {
    const { element: editorElement } = this.editor.options;
    const editorIsAttached = !!editorElement.parentElement;

    if (this.tippy || !editorIsAttached) {
      return;
    }

    this.tippy = tippy(editorElement, {
      maxWidth: '30em',
      duration: 20,
      getReferenceClientRect: null,
      content: this.element,
      interactive: true,
      trigger: "manual",
      placement: "top",
      hideOnClick: "toggle",
      ...this.tippyOptions,
    });

    // maybe we have to hide tippy on its own blur event as well
    if (this.tippy.popper.firstChild) {
      (this.tippy.popper.firstChild as HTMLElement).addEventListener(
        "blur",
        (event) => {
          this.blurHandler({ event });
        }
      );
    }
  }

  update(view: EditorView, oldState?: EditorState) {
    const { state, composing } = view;
    const { doc, selection } = state;
    const isSame =
      oldState && oldState.doc.eq(doc) && oldState.selection.eq(selection);

    if (composing || isSame) {
      return;
    }

    this.createTooltip();

    // support for CellSelections
    const { ranges } = selection;
    const from = Math.min(...ranges.map((range) => range.$from.pos));
    const to = Math.max(...ranges.map((range) => range.$to.pos));

    const shouldShow = this.shouldShow?.({
      editor: this.editor,
      view,
      state,
      oldState,
      from,
      to,
    });

    if (!shouldShow || this.preventShow) {
      this.hide();

      return;
    }

    this.tippy?.setProps({
      getReferenceClientRect: () => {
        if (isNodeSelection(state.selection)) {
          const node = view.nodeDOM(from) as HTMLElement;

          if (node) {
            return node.getBoundingClientRect();
          }
        }

        return posToDOMRect(view, from, to);
      },
    });

    this.show();
  }

  show() {
    this.tippy?.show();
    bubbleMenuStateStream.next({isShown: true});
  }

  hide() {
    this.tippy?.hide();
    bubbleMenuStateStream.next({isShown: false});
  }

  destroy() {
    this.tippy?.destroy();
    this.element.removeEventListener("mousedown", this.mousedownHandler, {
      capture: true,
    });
    this.view.dom.removeEventListener("mousedown", this.viewMousedownHandler);
    this.view.dom.removeEventListener("mouseup", this.viewMouseupHandler);
    this.view.dom.removeEventListener("dragstart", this.dragstartHandler);
    this.editor.off("focus", this.focusHandler);
    this.editor.off("blur", this.blurHandler);
    // make sure to unmount react component
    this.root.unmount();
  }
}

export const createBubbleMenuPlugin = (options: BubbleMenuPluginProps) => {
  return new Plugin({
    key: new PluginKey("BubbleMenuPlugin"),
    view: (view) => new BubbleMenuView({ view, ...options }),
  });
};
