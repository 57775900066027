import React from "react";
import recordingState$ from "../../streams/recordingState";
import AudioRecorder from "../../utils/AudioRecorder";
import useLiveMessageRecording from "./useLiveMessageRecording";

const LiveMessageAudioRecorder = () => {
   const [recordingState] = useLiveMessageRecording();
   const [audioRecorder, setAudioRecorder] = React.useState(null);

    // Handle audio recording
    React.useEffect(() => {
        let recorder = null;
        let recordingId = null;
        let startTime = null;
        if (recordingState.isRecording && !audioRecorder) {
            recordingId = recordingState.curRecordingId;
            // Start recorder
            recorder = new AudioRecorder();
            setAudioRecorder(recorder);
            recorder.startRecordAudio();
            startTime = Date.now();
        }

        return () => {
            if (recorder) {
                recorder.endRecordAudio((audiodata) => {
                    // https://medium.com/@bryanjenningz/how-to-record-and-play-audio-in-javascript-faa1b2b3e49b
                    let audioUrl = URL.createObjectURL(audiodata);
                    recordingState$.addAudioRecording(recordingId, { audiodata, audioUrl, startTime, endTime: Date.now() });
                });
                setAudioRecorder(null);
            }
        }
    }, [recordingState.isRecording]);

    return (
        <>
        </>
    )
};

export default LiveMessageAudioRecorder;