import { createClient } from "@liveblocks/client";
import { createRoomContext } from "@liveblocks/react";
import { createAuth } from './lib/authBackend';
import { DEFAULT_ACCOUNT_ID } from './common/Constants';
import { getUserInfo } from "./utils/useUserInfo";

// https://liveblocks.io/docs/api-reference/liveblocks-node
// https://liveblocks.io/docs/api-reference/rest-api-endpoints
// const client = createClient({
//     publicApiKey: "pk_live_NgwPhF0j3x3LhoJuOnemcLn_",
// });

const client = createClient({
    authEndpoint: async (room) => {
        console.log('room', room);
        // get userId, name and color
        let userInfo = getUserInfo();
        let resp = await createAuth(userInfo.userId, room, userInfo.name, userInfo.color);
        let parsed = JSON.parse(resp);
        return parsed;
    }
});

// Optionally, the type of custom events broadcasted and listened for in this
// room. Must be JSON-serializable.
// type RoomEvent = {};

export const {
    RoomProvider,
    useMyPresence,
    useUpdateMyPresence,
    useOthersMapped,
    useSelf,
} = createRoomContext(client);
