import { Fragment, Slice, NodeRange } from "prosemirror-model";
import { ReplaceAroundStep, liftTarget } from "prosemirror-transform";
import { liftListItemWithRange } from './liftListItem';
function getNodeType(nameOrType, schema) {
    if (typeof nameOrType === 'string') {
        if (!schema.nodes[nameOrType]) {
            throw Error(`There is no node type named '${nameOrType}'. Maybe you forgot to add the extension?`);
        }
        return schema.nodes[nameOrType];
    }
    return nameOrType;
}

export const sinkListItem = typeOrName => ({ state, dispatch }) => {
    const type = getNodeType(typeOrName, state.schema);
    return sinkListItemInner(type)(state, dispatch);
};

// Types which are consider as blocks
const blockTypes = ['owlblock', 'liveMessageBlock', 'image'];

// NOTE: Here we use a special implementation because our list can contain different type items!
function sinkListItemInner(itemType) {
    return function (state, dispatch) {
        var _state$selection4 = state.selection,
            $from = _state$selection4.$from,
            $to = _state$selection4.$to;
        // the blockgroup range containing the owlnode on selection
        var range = $from.blockRange($to, function (node) {
            for (let blockType of blockTypes) {
                if (node.firstChild.type === getNodeType(blockType, state.schema)) {
                    return node.childCount > 0;
                }
            }
            return false;
        });

        if (!range) return false;
        var startIndex = range.startIndex;
        if (startIndex === 0) return false;
        var parent = range.parent,
            nodeBefore = parent.child(startIndex - 1);
        if (nodeBefore.type !== itemType) return false;

        if (dispatch) {
            var nestedBefore = nodeBefore.lastChild && nodeBefore.lastChild.type === parent.type;
            var inner = Fragment.from(nestedBefore ? itemType.create() : null);
            var slice = new Slice(Fragment.from(itemType.create(null, Fragment.from(parent.type.create(null, inner)))), nestedBefore ? 3 : 1, 0);
            var before = range.start,
                after = range.end;

            let step = new ReplaceAroundStep(before - (nestedBefore ? 3 : 1), after, before, after, slice, 1, true);
            // console.log(step);
            dispatch(state.tr.step(step).scrollIntoView());

            // HACK: To keep sinking behavior same as notion, we added the following logic so that its child gets lifted back to its previous place
            // TODO: 
            // * Improvements need to be made for range select. The sinking behavior isn't consistent for nested lists.
            // * Currently, if range select, the following sinking logic won't work
            let childNode = parent.child(range.endIndex - 1);
            if (childNode.childCount > 1 && $from.parent === $to.parent) {
                // unindent second child
                let blockGroupNode = childNode.lastChild;
                let pos = findPos(state, blockGroupNode);
                // console.log('pos blockGroupNode', pos, blockGroupNode);
                if (blockGroupNode && pos !== null) {
                    let startOfBlockGroup = pos + (nestedBefore ? 3 : 1);
                    let endOfBlockGroup = pos + blockGroupNode.nodeSize - (nestedBefore ? 3 : 1);
                    let startPos = state.tr.doc.resolve(startOfBlockGroup);
                    let endPos = state.tr.doc.resolve(endOfBlockGroup);
                    let nodeRange = new NodeRange(startPos, endPos, Math.min(startPos.depth, endPos.depth));
                    // console.log('nodeRange', nodeRange);
                    dispatch(state.tr.lift(nodeRange, liftTarget(nodeRange)).scrollIntoView());
                }
            }


            // try {
            //     // end is end of the current node, endOfList is end of current blockgroup
            //     let tr = state.tr, end = range.end, endOfList = range.$to.end(range.depth);
            //     // There are childrens after the sinked items, which must become sibling of the last item
            //     // For each depth layer we must do the same thing. 
            //     console.log('lift', $to.pos, end);
            //     if ($to.pos < end) {

            //         let blockParent = range.parent;
            //         let blockNode = blockParent.child(range.endIndex - 1);
            //         let blockPos = findPos(state, blockNode);
            //         let from = $to.pos - blockPos;
            //         let to = end - blockPos;
            //         console.log('nodesBetween', blockNode, 'from', from, 'to', to);
            //         let parentNodes = [blockNode.lastChild];

            //         // BFS to find available parent nodes
            //         blockNode.descendants((node, pos, parent, index) => {
            //             // console.log('BFS to find', node, pos, from);
            //             if (node.type.name === 'owlblock' && pos <= from) {
            //                 console.log('add parent node', node);
            //                 parentNodes.push(node.lastChild);
            //             }
            //         });

            //         console.log('parentNodes', parentNodes);
            //         blockNode.descendants((node, pos, parent, index) => {
            //             // console.log('descendants', node, pos, index);
            //             // and if parent pos is inside of selection
            //             if (pos >= from && pos <= to && node.type.name === 'owlblock' && parentNodes.includes(parent)) {
            //                 console.log('should lift', node, pos, index);
            //                 let startOfChildNode = blockPos + pos;
            //                 let endOfChildNode = blockPos + pos + node.nodeSize;
            //                 let startPos = state.tr.doc.resolve(startOfChildNode);
            //                 let endPos = state.tr.doc.resolve(endOfChildNode);
            //                 let nodeRange = new NodeRange(startPos, endPos, Math.min(startPos.depth, endPos.depth));
            //                 console.log('nodeRange', nodeRange, startPos, endPos);
            //                 liftListItemWithRange(startPos, nodeRange, itemType, state, dispatch)
            //             }
            //         });


            // for (let i = Math.min(range.$from.depth, range.$to.depth); i < Math.max(range.$from.depth, range.$to.depth); i++) {

            // }
            // There are siblings after the lifted items, which must become
            // children of the last item
            // HACK: We modified this section so that it became consistent with notions behavior. 
            // let parent = range.parent;
            // let blockNode = parent.child(range.endIndex - 1);
            // let blockPos = findPos(state, blockNode);
            // let from = $to.pos - blockPos - 2;
            // let to = end - blockPos;
            // console.log('nodesBetween', blockNode, 'from', from, 'to', to);
            // blockNode.descendants((node, pos, parent, index) => {
            //     console.log('descendants', node, pos, index);
            //     if (pos >= from && pos <= to && node.type.name === 'blockgroup' && parent === blockNode) {
            //         console.log('should lift', node, pos, index);
            //         let startOfBlockGroup = blockPos + pos + 2;
            //         let endOfBlockGroup = blockPos + pos + node.nodeSize;
            //         let startPos = state.tr.doc.resolve(startOfBlockGroup);
            //         let endPos = state.tr.doc.resolve(endOfBlockGroup);
            //         let nodeRange = new NodeRange(startPos, endPos, Math.min(startPos.depth, endPos.depth));
            //         console.log('nodeRange', nodeRange);
            //         dispatch(tr.lift(nodeRange, liftTarget(nodeRange)).scrollIntoView());
            //     }
            // });






            // let isNested = node.lastChild && node.lastChild.type === parent.type;
            // console.log('isNested', isNested, 'node', node);
            // let inner = Fragment.from(isNested ? itemType.create() : null);
            // let step = new ReplaceAroundStep(end - (isNested ? 3 : 1), endOfList, end, endOfList, new Slice(Fragment.from(itemType.create(null, parent.type.create(null, inner))), (isNested) ? 3 : 1, 0), 1, true);
            // console.log('step', step);
            // tr.step(step);
            //         // range = new NodeRange(tr.doc.resolve(range.$from.pos), tr.doc.resolve(endOfList), range.depth);
            //     }

            //     // dispatch(tr.lift(range, liftTarget(range)).scrollIntoView());
            //     return true;
            // } catch (err) {
            //     // HACK: If out of range for some reason, try something else
            //     console.log('liftToOuterList failed, skip silently', err);
            //     return false;
            // }
        }
        return true;
    };
}

function findPos(state, nodeToFind) {
    let targetPos = null;
    state.doc.descendants((node, pos, parent, index) => {
        if (node === nodeToFind) {
            targetPos = pos;
            return false;
        }
    });
    return targetPos;
}