import "./LiveMessageBottomPlayer.scss";
import React, { useCallback, useEffect, useState } from "react";
import { Box, Stack, Button, ToggleButton,  } from "@mui/material";
import ClosedCaptionOffOutlinedIcon from '@mui/icons-material/ClosedCaptionOffOutlined';
import PlaybackControl from "./PlaybackControl.js";
import useLiveMessageReplay from "../recording/useLiveMessageReplay";
import useLiveMessageViewModel from "./useLiveMessageViewModel";
import { track } from '@amplitude/analytics-browser';

const LiveMessageBottomPlayer = ({onTranscriptClicked, isTranscriptOpen=false}) => {
  const { selectedLiveMessage, unselectActiveLiveMessage } = useLiveMessageViewModel();
  const [replayState, { onHandleReplayingStop }] = useLiveMessageReplay();
  const onCloseClick = () => {
    track("close player - lm bottom player")
    onHandleReplayingStop(true);
    unselectActiveLiveMessage();
  };
  return (
    <Box className="live-message-player" sx={{ position: "relative" }}>
      <Stack
        width={"100%"}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Button
          variant="text"
          onClick={onCloseClick}
          sx={{
            color: "#aaa",
            fontWeight: "bold",
            textTransform: "none",
            marginLeft: "16px",
            "&:hover": {
              backgroundColor: "#f2f2f2",
              color: "#000",
            },
          }}
        >
          Close
        </Button>
        <PlaybackControl/>
        <Button
          variant="text"
          onClick={()=>{
            track("click open transcript button - lm bottom player")
            onTranscriptClicked()}}
          sx={{
            color: isTranscriptOpen?"#000":"#aaa",
            opacity: "0.8",
            fontWeight: "bold",
            textTransform: "none",
            marginRight: "16px",
            "&:hover": {
              backgroundColor: "#f2f2f2",
              color:"#000",
              opacity: "1",
            },
          }}
        >
          Transcript
        </Button>
      </Stack>
    </Box>
  );
};

export default LiveMessageBottomPlayer;
