import { Extension } from "@tiptap/core";
import { Plugin, PluginKey } from "prosemirror-state";
import { DecorationSet, Decoration } from "prosemirror-view";
import { setMeta } from "y-prosemirror";
import { relativeBlockPosToAbsolute } from "./helpers/RecordingUtils";
import replayAwareness from "../../../streams/replayAwareness";

/**
 * Default generator for the selection attributes
 *
 * @param {any} user user data
 * @return {import('prosemirror-view').DecorationAttrs}
 */
const defaultSelectionBuilder = user => {
    return {
        style: `background-color: ${user.color}`,
        class: `ProseMirror-yjs-selection`
    }
};

const defaultCursorBuilder = user => {
    const cursor = document.createElement('span')

    cursor.classList.add('collaboration-cursor__caret')
    cursor.setAttribute('style', `border-color: ${user.color}`)

    // const label = document.createElement('div')

    // label.classList.add('collaboration-cursor__label')
    // label.setAttribute('style', `background-color: ${user.color}`)
    // label.insertBefore(document.createTextNode(user.name), null)
    // cursor.insertBefore(label, null)

    return cursor
};

/**
 * @param {any} state
 * @param {Awareness} awareness
 * @return {any} DecorationSet
 */
const createDecorations = (state, aw, createCursor, createSelection) => {
    const decorations = [];
    const user = aw.user;
    if (aw.user.cursor != null) {
        let absoluteCursor = relativeBlockPosToAbsolute(state.doc, aw.user.cursor);
        let anchor = absoluteCursor.anchor;
        let head = absoluteCursor.head;
        if (anchor !== null && head !== null) {
            const maxsize = Math.max(state.doc.content.size - 1, 0);
            anchor = Math.min(anchor, maxsize);
            head = Math.min(head, maxsize);
            decorations.push(Decoration.widget(head, () => createCursor(user), { key: user.clientId + '', side: 10 }));
            const from = Math.min(anchor, head);
            const to = Math.max(anchor, head);
            decorations.push(Decoration.inline(from, to, createSelection(user), { inclusiveEnd: true, inclusiveStart: false }));
        }

        //console.log('newDecorations', decorations);
        //console.log('newDecorations 0', decorations[0]);
        //console.log('newDecorations 1', decorations[1]);
    }
    return DecorationSet.create(state.doc, decorations)
};

const replayPluginKey = new PluginKey('replaying');
/**
 * A prosemirror plugin that listens to awareness information on Yjs.
 * This requires that a `prosemirrorPlugin` is also bound to the prosemirror.
 *
 * @public
 * @param {Awareness} awareness
 * @param {object} [opts]
 * @param {function(any):HTMLElement} [opts.cursorBuilder]
 * @param {function(any):import('prosemirror-view').DecorationAttrs} [opts.selectionBuilder]
 * @param {function(any):any} [opts.getSelection]
 * @param {string} [cursorStateField] By default all editor bindings use the awareness 'cursor' field to propagate cursor information.
 * @return {any}
 */
const replayCursorPlugin = () => new Plugin({
    key: replayPluginKey,
    state: {
        init(tr, state) {
            return DecorationSet.create(state.doc, []);
        },
        apply(tr, prevState, oldState, newState) {
            const pluginState = tr.getMeta(replayPluginKey);
            if (pluginState && pluginState.awarenessUpdated) {
                let aw = pluginState.awareness;
                return createDecorations(newState, aw, defaultCursorBuilder, defaultSelectionBuilder)
            }

            // TODO(HACK): Not sure if we can further optimize here to avoid recreating decoration set each time.
            // @ts-ignore
            return DecorationSet.create(newState.doc, []);
        }
    },
    props: {
        decorations: state => {
            return replayPluginKey.getState(state)
        }
    },
    view: view => {
        const awarenessListener = (state) => {
            // @ts-ignore
            if (view.docView) {
                setMeta(view, replayPluginKey, { awarenessUpdated: true, awareness: state });
            }
        };

        const setAwarenessState = (state) => {
            if (state.startTime) {
                awarenessListener(state);
            } else {
                setMeta(view, replayPluginKey, { awarenessUpdated: false, awareness: null });
            }
        };
        let subscription = replayAwareness.subscribe(setAwarenessState);
        return {
            update: () => { //console.log('replayCursorPlugin update') 
            },
            destroy: () => {
                //console.log('replayCursorPlugin destroy'); 
                subscription.unsubscribe();
            }
        }
    }
});


/**
 * Add a trailing node to the document so the user can always click at the bottom of the document and start typing
 */
const ReplayingExtension = Extension.create({
    name: "replaying",

    addProseMirrorPlugins() {
        return [
            replayCursorPlugin()
        ];
    },
});

export default ReplayingExtension;
