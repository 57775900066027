import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import React from "react";
import SuggestionItem from "../SuggestionItem";
import styles from "./SuggestionGroup.module.css";

const MIN_LEFT_MARGIN = 5;

function SuggestionContent<T extends SuggestionItem>(props: { item: T }) {
  return (
    <div className={styles.suggestionWrapper}>
      <div>
        <div className={styles.buttonName}>{props.item.name}</div>
        {props.item.hint && (
          <div className={styles.buttonHint}>{props.item.hint}</div>
        )}
      </div>
      {props.item.shortcut && (
        <div>
          <div className={styles.buttonShortcut}>{props.item.shortcut}</div>
        </div>
      )}
    </div>
  );
}

function getIcon<T extends SuggestionItem>(
  item: T,
  isButtonSelected: boolean
): JSX.Element | undefined {
  const Icon = item.icon;
  return (
    Icon && (
      <div className={styles.iconWrapper}>
        <Icon
          className={
            styles.icon + " " + (isButtonSelected ? styles.selectedIcon : "")
          }
        />
      </div>
    )
  );
}

type SuggestionComponentProps<T> = {
  item: T;
  index: number;
  selectedIndex?: number;
  clickItem: (item: T) => void;
};

function SuggestionComponent<T extends SuggestionItem>(
  props: SuggestionComponentProps<T>
) {
  let isButtonSelected =
    props.selectedIndex !== undefined && props.selectedIndex === props.index;

  const ButtonIcon = getIcon(props.item, isButtonSelected);
  // TODO: Fix
  return (
    <MenuItem style={isButtonSelected ? { backgroundColor: '#F4F5F7' } : {}} className={styles.buttonItem}
      onClick={(_e) => {
        setTimeout(() => {
          props.clickItem(props.item);
        }, 0);

        // e.stopPropagation();
        // e.preventDefault();
      }}
      >
      <Stack sx={{ width: "100%" }} direction="row" alignItems="center" spacing={"8px"}>
        <div>
          {ButtonIcon}
        </div>
        <SuggestionContent item={props.item} />
      </Stack>
    </MenuItem>
  );
}

type SuggestionGroupProps<T> = {
  /**
   * Name of the group
   */
  name: string;

  /**
   * The list of items
   */
  items: T[];

  /**
   * Index of the selected item in this group; relative to this item group (so 0 refers to the first item in this group)
   * This should be 'undefined' if none of the items in this group are selected
   */
  selectedIndex?: number;

  /**
   * Callback that gets executed when an item is clicked on.
   */
  clickItem: (item: T) => void;
};

export function SuggestionGroup<T extends SuggestionItem>(
  props: SuggestionGroupProps<T>
) {
  return (
    <>
      {props.items.map((item, index) => {
        return (
          <SuggestionComponent
            item={item}
            key={index} // TODO: using index as key is not ideal for performance, better have ids on suggestionItems
            index={index}
            selectedIndex={props.selectedIndex}
            clickItem={props.clickItem}
          />
        );
      })}
    </>
  );
}
