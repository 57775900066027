import './OwlEditor.scss'
import { HocuspocusProvider } from '@hocuspocus/provider'
import React, {
    useState,
    useReducer,
} from 'react'
import * as Y from 'yjs'
import OwlEditor from './OwlEditor'
import { SERVER_GUEST_USER, SERVER_PUBLIC_USER } from "../../common/Constants";
import { OwlEditorProviderReducer, OwlEditorProviderActionType, INITIAL_STATE } from "./OwlEditorProviderReducer";
import useLiveMessageRecordingController from '../liveMessage/useLiveMessageRecordingController';
import useLiveMessageViewModel from '../liveMessage/useLiveMessageViewModel';
import { blockMouseCursorState$ } from '../../blocknode/extensions/Blocks/BlockMouseCursorPlugin';
import CharacterCount from "@tiptap/extension-character-count";
import Collaboration from "@tiptap/extension-collaboration";
import CollaborationCursorConflict from "../../blocknode/extensions/Collaboration/CollaborationCursorConflict";
import { CommentThread } from "../comment/comment";
import { useCommentData } from "../comment/CommentViewModel";
import { Editor } from '@tiptap/core'
import styles from "./OwlEditor.module.css";
import { wrapBlockNodeOptions } from "../../blocknode";
import { IndexeddbPersistence } from 'y-indexeddb'
import { editorState$ } from "../../streams/editorState";


const DEV_COLLAB_SERVER_URL = 'wss://owlnote-server.herokuapp.com';
const PROD_COLLAB_SERVER_URL = 'wss://owlnote-server-prod.herokuapp.com';
const COLLAB_SERVER_URL = process.env.REACT_APP_PUBLIC_API_ENV === "dev" ? DEV_COLLAB_SERVER_URL : PROD_COLLAB_SERVER_URL;

function useForceUpdate() {
    const [, setValue] = useState(0)

    return () => setValue(value => value + 1)
}

const OwlEditorProvider = ({ documents, documentId, onDocumentChangeCallback }) => {
    const forceUpdate = useForceUpdate();
    const { resetLiveMessageRecording } = useLiveMessageRecordingController();
    const { unselectActiveLiveMessage } = useLiveMessageViewModel();
    const [state, dispatch] = useReducer(OwlEditorProviderReducer, INITIAL_STATE);
    const { requestCommentRefresh } = useCommentData();

    const [verifiedDocumentId, setVerifiedDocumentId] = useState(null);
    const [isDocumentLocked, setIsDocumentLocked] = useState(false);
    const [isNetworkSynced, setIsNetworkSynced] = useState(false);
    const [isLocalSynced, setIsLocalSynced] = useState(false);

    React.useEffect(() => {
        // Determine if document eligibility and check if its locked
        if (documents) {
            for (let doc of documents) {
                if (documentId === doc.documentId) {
                    queueMicrotask(() => {
                        setVerifiedDocumentId(documentId);
                        setIsDocumentLocked(doc.editStatus === 1);
                    })
                }
            }
        }
    }, [documents, documentId]);

    // Hook to update document
    React.useEffect(() => {
        if (!verifiedDocumentId) {
            console.warn('do not load doc', documentId);
            return;
        }

        console.log('[Connect] Creating editor instance', verifiedDocumentId);
        const iDocumentId = verifiedDocumentId;
        const iYdoc = new Y.Doc()
        const iWebsocketProvider = new HocuspocusProvider({
            //url: 'ws://localhost:1234',
            url: COLLAB_SERVER_URL,
            token: 'ilovepizza',
            /**
             * Use hocuspocus cloud
             */
            //url: 'wss://connect.hocuspocus.cloud',
            // parameters: {
            //     key: 'write_bqgvQ3Zwl34V4Nxt43zR',
            // },
            name: `document.${iDocumentId}`,
            document: iYdoc,
            parameters: {
                user: isDocumentLocked ? SERVER_GUEST_USER : SERVER_PUBLIC_USER,
            }
        });
        const indexDb = new IndexeddbPersistence(iDocumentId, iYdoc);

        let isMounted = true;
        const iEditor = new Editor(
            wrapBlockNodeOptions({
                extensions: [
                    CommentThread,
                    CharacterCount.configure({
                        limit: 999999,
                    }),
                    Collaboration.configure({
                        document: iYdoc,
                    }),
                    CollaborationCursorConflict.configure({
                        provider: iWebsocketProvider,
                        render: (user) => {
                            const cursor = document.createElement("span");
                            cursor.classList.add("collaboration-cursor__caret");
                            cursor.setAttribute("style", `border-color: ${user.color}`);
                            const label = document.createElement("div");
                            label.classList.add(`${user.userId}-collaboration-cursor__label`);
                            label.classList.add("collaboration-cursor__label");
                            label.setAttribute("style", `background-color: ${user.color}`);
                            label.insertBefore(document.createTextNode(user.name), null);
                            cursor.insertBefore(label, null);
                            // set initial hidden state
                            label.hidden = !user.cursorState.showTextCursorLabel;
                            return cursor;
                        },
                    }),
                ],
                onUpdate({ editor }) {
                    requestCommentRefresh(editor);
                },
                editorProps: {
                    attributes: {
                        class: styles.editor,
                        "data-test": "editor",
                    },
                }
            })
        );


        // Register callback
        const syncIndexDbCallback = (state) => {
            // Inform the remaining that document is ready
            console.log('[Connect] Document is locally synced', iDocumentId);
            setIsLocalSynced(true);
        };
        // This is where document gets officially loaded
        indexDb.on('synced', syncIndexDbCallback);

        iEditor.on('transaction', () => {
            requestAnimationFrame(() => {
                requestAnimationFrame(() => {
                    if (isMounted) {
                        forceUpdate()
                    }
                })
            })
        });

        const syncCallback = ({ state }) => {
            if (state) {
                console.log('[Connect] Document is network synced', iDocumentId);
                setIsNetworkSynced(true);
            }
        };
        const statusCallback = ({ status }) => {
            // Note it's pretty interesting that the status change will be called at every edit!
            // console.log('status', status);
            // publish state
            editorState$.notifyStatusChange(status);
        };

        iWebsocketProvider.on('synced', syncCallback)
        iWebsocketProvider.on('status', statusCallback);

        dispatch({
            type: OwlEditorProviderActionType.CONNECT_DOCUMENT, payload: {
                editor: iEditor,
                documentId: iDocumentId,
                isDocumentLocked: isDocumentLocked,
            }
        });
        onDocumentChangeCallback(iDocumentId);

        // Reset states
        blockMouseCursorState$.clear();
        resetLiveMessageRecording();
        unselectActiveLiveMessage();
        console.log('[Connect] Finished connect to document', iDocumentId);
        return () => {
            // Clean up editor instances in async
            queueMicrotask(() => {
                if (iEditor) {
                    console.log('[Disconnect] Destroy editor instance', iDocumentId);
                    iEditor.destroy();
                }
                if (iWebsocketProvider) {
                    iWebsocketProvider.off('synced', syncCallback);
                    iWebsocketProvider.off('status', statusCallback);
                    console.log('[Disconnect] Disconnect websocket', iDocumentId);
                    iWebsocketProvider.disconnect();
                    iWebsocketProvider.destroy();
                }
                if (indexDb) {
                    indexDb.off('synced', syncIndexDbCallback);
                    indexDb.destroy();
                }
            });

            // Reset everything
            console.log('[Disconnect] Finished connect to document', iDocumentId);
            isMounted = false
            setIsNetworkSynced(false);
            setIsLocalSynced(false);
            dispatch({
                type: OwlEditorProviderActionType.SWITCH_DOCUMENT, payload: {}
            });
        }
    }, [verifiedDocumentId, isDocumentLocked]);

    return (
        <>
            {state.editor &&
                <OwlEditor
                    editor={state.editor}
                    documentId={state.documentId}
                    isDocumentLocked={state.isDocumentLocked}
                    isNetworkSynced={isNetworkSynced}
                    isLocalSynced={isLocalSynced}
                >
                </OwlEditor>
            }
        </>
    );
};

export default OwlEditorProvider;