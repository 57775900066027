import { NodeViewWrapper } from "@tiptap/react";
import { useState, useEffect } from "react";
import useLiveMessageRecordingController from "./useLiveMessageRecordingController";
import Box from "@mui/material/Box";
import InLineRecordBlock from "./InLineRecordBlock";
import InLinePlaybackBlock from "./InLinePlaybackBlock";
import "./LiveMessageBlock.scss";
import { v4 } from "uuid";

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
// TODO HACK HACK HACK HACK HACK HACK this is to avoid calling props.updateAttributes right away which triggers infinite refresh.
const updateProps = async (props, update, callback) => {
  await sleep(500);
  props.updateAttributes(update);
  if (callback) {
    callback();
  }
};

const LiveMessageBlock = (props) => {
  // TODO: This does not yet handle drag selection of such block :/ 
  const { selected } = props;
  const { liveMessageUpdateStream } = useLiveMessageRecordingController();
  const [isLmCloudLinked, setIsLmCloudLinked] = useState(
    props.node.attrs.liveMessageId != null
  );
  const [localId, setLocalId] = useState(props.node.attrs.localId);
  const [justCreated, setJustCreated] = useState(false);
  const [lmUpdate, setLmUpdate] = useState(null);
  useEffect(() => {
    if (!isLmCloudLinked && localId == null) {
      // set a local Id with a newly created lm. A cloudId will be assigned after saving recordings to the cloud.
      setLocalId(v4());
      setJustCreated(true);
    }
  }, [isLmCloudLinked]);

  useEffect(() => {
    let sub = liveMessageUpdateStream.subscribe(setLmUpdate);
    return () => {
      sub.unsubscribe();
    };
  }, []);

  useEffect(() => {
    // console.log("lmupdate");
    // console.log(lmUpdate);
    if (
      lmUpdate &&
      lmUpdate.requestor.liveMessageId === localId &&
      lmUpdate.liveMessage &&
      lmUpdate.liveMessage.liveMessageId &&
      !isLmCloudLinked
    ) {
      updateProps(
        props,
        {
          liveMessageId: lmUpdate.liveMessage.liveMessageId,
          lmDuration: lmUpdate.liveMessage.duration,
          blockEmbeddings: lmUpdate.liveMessage.blockEmbeddings
        },
        () => {
          setIsLmCloudLinked(true);
        }
      );
    }
  }, [lmUpdate, isLmCloudLinked]);

  useEffect(() => {
    if (localId && localId !== props.node.attrs.localId) {
      updateProps(props, { localId: localId });
    }
  }, [localId]);

  return (
    <NodeViewWrapper className="liveMessageBlock" data-id={props.node.attrs.id}>
      <Box sx={{ display: "flex" }}>
        <Box className="message-background">
          {isLmCloudLinked && (
            <InLinePlaybackBlock
              selected={selected}
              lmId={props.node.attrs.liveMessageId}
              lmDuration={props.node.attrs.lmDuration}
            />
          )}
          {!isLmCloudLinked && (
            <InLineRecordBlock
              selected={selected}
              setFocus={justCreated}
              lmId={isLmCloudLinked ? props.node.attrs.liveMessageId : localId}
            />
          )}
        </Box>
      </Box>
    </NodeViewWrapper>
  );
};

export default LiveMessageBlock;
