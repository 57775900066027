import { useEffect, useState } from "react";
import { v4 } from "uuid";
import { BehaviorSubject, Subject, debounce, interval } from "rxjs";
import { CommentThread } from "./comment";
import { getUserInfo } from "../../utils/useUserInfo";
import { trackDocumentEvent } from "../../utils/AmplitudeTrack";

export const addNewCommentStream = new Subject();
export const refreshCommentStream = new BehaviorSubject();
const commentsStream = new BehaviorSubject();
const refreshComment = refreshCommentStream.pipe(
  // From tv series Playlist - use 200ms so human feel miniCommentList position update is instant. 
  debounce((i) => interval(200))
);

// setMark does not work for blocks, thus need this helper instead
const setCommentNode = (editor, pos, comment) => {
  let tr = editor.state.tr;
  let mark = editor.state.schema.marks[CommentThread.name].create({ comment: comment });
  tr.setNodeMarkup(pos, undefined, { ...editor.state.selection.node.attrs, comment: comment }, [mark]);
  editor.view.dispatch(tr);
};

const refreshCommentDataInternal = async (editor) => {
  if (!editor?.view?.docView) {
    return;
  }
  const comments = [];
  editor?.state.doc.descendants((node, pos, parent) => {
    const commentThreadData = node.marks
      .filter((mark) => mark.type.name === CommentThread.name)
      .filter((mark) => mark.attrs.comment && mark.attrs.comment.length > 0)
      .map((mark) => JSON.parse(mark.attrs.comment));
    if (commentThreadData.length > 0) {
      comments.push({
        isNode: node.isAtom && node.isBlock, 
        docPos: pos,
        coords: editor.view.coordsAtPos(pos),
        scrollY: window.scrollY, // also record the current scrollY position so updates on editor won't reflect the comment position right away
        data: commentThreadData,
      });
    }
  });
  // console.log("haha comments", comments);
  commentsStream.next(comments);
};
refreshComment.subscribe((editor) => {
  if (editor) {
    refreshCommentDataInternal(editor);
  }
});
// interface CommentInstance {
//     uuid?: string
//     comments?: any[] // comment:{ content or? liveMessage }
//   }

const createCommentEntity = (content, liveMessage = null) => {
  const userInfo = getUserInfo();
  return {
    userName: userInfo.name,
    userId: userInfo.userId,
    time: Date.now(),
    content: content,
    liveMessageId: liveMessage?.liveMessageId,
    lmDuration: liveMessage?.duration,
    commentId: v4(),
    readers: [userInfo.userId],
  };
};
export const useCommentData = () => {
  const userInfo = getUserInfo();
  const [allCommentThreads, setAllCommentThreads] = useState(null);
  useEffect(() => {
    let sub = commentsStream.subscribe(setAllCommentThreads);
    return () => {
      sub.unsubscribe();
    };
  }, []);
  const markActiveThreadAsRead = (editor, activeCommentThread) => {
    editor?.state.doc.descendants((node, pos, parent) => {
      const activeCommentMark = node.marks.find(
        (mark) =>
          mark.type.name === CommentThread.name &&
          mark.attrs.comment &&
          JSON.parse(mark.attrs.comment).uuid === activeCommentThread.uuid
      );
      if (activeCommentMark) {
        const threadToUpdate = JSON.parse(activeCommentMark.attrs.comment);
        const userInfo = getUserInfo();
        if (threadToUpdate.followers?.indexOf(userInfo.userId) > -1) {
          threadToUpdate.comments.forEach((comment) => {
            if (comment.readers?.indexOf(userInfo.userId) == -1) {
              comment.readers.push(userInfo.userId);
            }
          });
          const currentSelection = editor.state.selection
          console.log('read', threadToUpdate)
          if (node.isAtom && node.isBlock) {
            editor
              .chain()
              .setNodeSelection(pos)
              .command(({ tr, state, dispatch }) => {
                let mark = state.schema.marks[CommentThread.name].create({ comment: JSON.stringify(threadToUpdate) });
                tr.setNodeMarkup(pos, undefined, { ...state.selection.node.attrs, comment: JSON.stringify(threadToUpdate) }, [mark]);
                return true;
              })
              .setTextSelection(currentSelection)
              .run();
          } else {
            editor
              .chain()
              .setTextSelection({ from: pos, to: pos + node.nodeSize })
              .setComment(JSON.stringify(threadToUpdate))
              .setTextSelection(currentSelection)
              .run();
          }
          requestCommentRefresh(editor, false);
        }
      }
    });
  };
  const clearUnfinishedComment = (editor, force = false) => {
    // console.log("clearUnfinishedComment called");
    editor?.state.doc.descendants((node, pos, parent) => {
      const commentMarks = node.marks.filter(
        (mark) => mark.type.name === CommentThread.name
      );
      if (commentMarks && commentMarks.length > 0) {
        // const {['comment']:commentAttrs, ['isTempComment']:tmpCommentAttr, remainingAttrs} = node.attrs
        const newTr = editor.state.tr;
        commentMarks
          .filter((mark) => {
            if (mark.attrs.comment.length == 0 || force) {
              return true;
            }
            const commentData = JSON.parse(mark.attrs.comment);
            const isTmpComment =
              commentData.comments.length == 1 &&
              commentData.comments[0].content.length === 0 &&
              !commentData.comments[0].liveMessageId;
            const isFirstCommentAuthor =
              commentData.comments[0].userId === userInfo.userId;
            return isTmpComment && isFirstCommentAuthor;
          })
          .forEach((mark) => {
            newTr.removeMark(pos, pos + node.nodeSize, mark);
          });
        try {
          editor.dispatchTransaction(newTr);
        } catch (err) {
          console.warn("Error while clearing unfinished comment", err);
        }
      }
    });
  };

  const followCommentThread = (activeCommentThread) => {
    const userInfo = getUserInfo();
    if (activeCommentThread.followers.indexOf(userInfo.userId) == -1) {
      activeCommentThread.followers.push(userInfo.userId);
    }
    return activeCommentThread;
  };

  const commitCurrentComment = (editor, commentText, liveMessage = null) => {
    if (
      (!commentText || commentText.length == 0) &&
      (!liveMessage || !liveMessage.liveMessageId)
    ) {
      return;
    }
    // console.log("commitCurrentComment11", liveMessage, commentText);
    const activeCommentThread = JSON.parse(
      editor.getAttributes(CommentThread.name).comment
    );

    const isTmpComment =
      activeCommentThread.comments.length == 1 &&
      activeCommentThread.comments[0].content.length === 0 &&
      !activeCommentThread.comments[0].liveMessageId;
    const isFirstCommentAuthor =
      activeCommentThread.comments[0].userId === userInfo.userId;

    editor?.state.doc.descendants((node, pos, parent) => {
      const activeCommentMark = node.marks.find(
        (mark) =>
          mark.type.name === CommentThread.name &&
          mark.attrs.comment &&
          JSON.parse(mark.attrs.comment).uuid === activeCommentThread.uuid
      );
      if (activeCommentMark) {
        const threadToUpdate = JSON.parse(activeCommentMark.attrs.comment);
        // auto follow the thread at commentting
        followCommentThread(threadToUpdate);
        if (isTmpComment && isFirstCommentAuthor) {
          threadToUpdate.comments.forEach((comment, index) => {
            if (comment.content.length == 0) {
              threadToUpdate.comments[index].content = commentText;
              threadToUpdate.comments[index].liveMessageId =
                liveMessage?.liveMessageId;
              threadToUpdate.comments[index].lmDuration = liveMessage?.duration;
            }
          });

          if (node.isAtom && node.isBlock) {
            console.log('commitCurrentComment', threadToUpdate);
            editor
              .chain()
              .setNodeSelection(pos)
              .command(({ tr, state, dispatch }) => {
                let mark = state.schema.marks[CommentThread.name].create({ comment: JSON.stringify(threadToUpdate) });
                tr.setNodeMarkup(pos, undefined, { ...state.selection.node.attrs, comment: JSON.stringify(threadToUpdate) }, [mark]);
                return true;
              })
              .run();
          } else {
            // console.log("first comment threadToUpdate", threadToUpdate);
            // setTextSelection(pos+1) is to avoid showing bubble while keeping the comment thread.
            editor
              .chain()
              .setTextSelection({ from: pos, to: pos + node.nodeSize })
              .setComment(JSON.stringify(threadToUpdate))
              .setTextSelection(pos + 1)
              .run();
          }

          trackDocumentEvent("Create new comment");
        } else {
          threadToUpdate.comments.push(
            createCommentEntity(commentText, liveMessage)
          );
          // console.log("adding new comment to a thread", threadToUpdate);
          if (node.isAtom && node.isBlock) {
            editor
              .chain()
              .setNodeSelection(pos)
              .command(({ tr, state, dispatch }) => {
                let mark = state.schema.marks[CommentThread.name].create({ comment: JSON.stringify(threadToUpdate) });
                tr.setNodeMarkup(pos, undefined, { ...state.selection.node.attrs, comment: JSON.stringify(threadToUpdate) }, [mark]);
                return true;
              })
              .run();
          } else {
            editor
              .chain()
              .setTextSelection({ from: pos, to: pos + node.nodeSize })
              .setComment(JSON.stringify(threadToUpdate))
              .setTextSelection(pos + 1)
              .run();
          }
          trackDocumentEvent("Reply to comment");
        }
      }
    });
    // console.log('commitCurrentComment', JSON.parse(editor.getAttributes(CommentThread.name).comment));
    requestCommentRefresh(editor, false);
  };
  const requestCommentRefresh = (editor, debounceRequest = true) => {
    if (debounceRequest) {
      refreshCommentStream.next(editor);
    } else {
      refreshCommentDataInternal(editor);
    }
  };

  const removeCommentThread = (editor) => {
    const activeCommentThread = JSON.parse(
      editor.getAttributes(CommentThread.name).comment
    );
    editor?.state.doc.descendants((node, pos, parent) => {
      const activeCommentMark = node.marks.find(
        (mark) =>
          mark.type.name === CommentThread.name &&
          mark.attrs.comment &&
          JSON.parse(mark.attrs.comment).uuid === activeCommentThread.uuid
      );
      if (activeCommentMark) {
        const tr = editor.state.tr;
        tr.removeMark(pos, pos + node.nodeSize, activeCommentMark);
        editor.dispatchTransaction(tr);
      }
    });
    requestCommentRefresh(editor, false);
  };

  const removeComment = (editor, commentId) => {
    trackDocumentEvent("removeComment");
    editor?.state.doc.descendants((node, pos, parent) => {
      const activeCommentMark = node.marks.find(
        (mark) =>
          mark.type.name === CommentThread.name &&
          mark.attrs.comment &&
          mark.attrs.comment.indexOf(commentId) > -1
      );
      if (activeCommentMark) {
        const threadToUpdate = JSON.parse(activeCommentMark.attrs.comment);
        threadToUpdate.comments = threadToUpdate.comments.filter(
          (comment) => comment.commentId !== commentId
        );
        if (threadToUpdate.comments.length == 0) {
          const tr = editor.state.tr;
          if (node.isAtom && node.isBlock) {
            tr.setNodeMarkup(pos, undefined, { ...node.attrs, comment: undefined }, []);
          } else {
            tr.removeMark(pos, pos + node.nodeSize, activeCommentMark);
          }
          editor.dispatchTransaction(tr);
        } else {
          console.log('removeComment node', node);
          if (node.isAtom && node.isBlock) {
            editor
              .chain()
              .setNodeSelection(pos)
              .command(({ tr, state, dispatch }) => {
                let mark = state.schema.marks[CommentThread.name].create({ comment: JSON.stringify(threadToUpdate) });
                tr.setNodeMarkup(pos, undefined, { ...state.selection.node.attrs, comment: JSON.stringify(threadToUpdate) }, [mark]);
                return true;
              })
              .run();
          } else {
            editor
              .chain()
              .setTextSelection({ from: pos, to: pos + node.nodeSize })
              .setComment(JSON.stringify(threadToUpdate))
              .setTextSelection(pos + 1)
              .run();
          }
        }
      }
    });
    requestCommentRefresh(editor, false);
  };

  return {
    allCommentThreads,
    clearUnfinishedComment,
    requestCommentRefresh,
    commitCurrentComment,
    removeCommentThread,
    removeComment,
    markActiveThreadAsRead,
  };
};

const CommentViewModel = ({ editor, isDocumentReady }) => {
  const { clearUnfinishedComment } = useCommentData();
  useEffect(() => {
    if (editor && isDocumentReady) {
      // console.log("updaing editor", editor?.view?.docView)
      clearUnfinishedComment(editor);
      let sub = addNewCommentStream.subscribe((isNodeSelection) => {
        // create an initial empty comment thread
        const initalComment = JSON.stringify({
          uuid: v4(),
          comments: [createCommentEntity("")],
          followers: [],
        });
        // Deal with image block
        if (isNodeSelection) {
          console.log('editor', editor);
          setCommentNode(editor, editor.state.selection.$anchor.pos, initalComment);
        } else {
          editor.chain().setComment(initalComment).run();
        }
        refreshCommentDataInternal(editor);
      });
      refreshCommentDataInternal(editor, false);
      return () => {
        commentsStream.next([]);
        sub.unsubscribe();
      };
    }
  }, [editor, isDocumentReady]);
};

export default CommentViewModel;
