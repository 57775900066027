import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import CircularProgress from '@mui/material/CircularProgress';
import { createSingleMediaFromFile } from '../../utils/MediaBackendUtils';
import { DEFAULT_ACCOUNT_ID } from "../../common/Constants";
// Import React FilePond
import { FilePond } from "react-filepond";

// Import css
import "filepond/dist/filepond.min.css";
import "./FilePondImage.scss";

export default function FilePondImage({ handleImageSourceUpdate }) {
    const [showUploading, setShowUploading] = useState(false);
    const filePondRef = useRef(null);
    const processFunc = async (fieldName,
        file,
        metadata,
        load,
        error,
        progress,
        abort) => {
        console.log('process Func. will upload here');
        // run process func
        let media = await createSingleMediaFromFile(DEFAULT_ACCOUNT_ID, file);
        
        // set as complete
        handleImageSourceUpdate(media.permanentUrl);
    };

    const setMediaFileCallback = async (file) => {
        // TODO: got media data. Check file type

        // if okay run process
        if (file) {
            console.log('run process', file);
            // set as uploading
            setShowUploading(true);
            await filePondRef.current.processFile();
        }
    };

    return <FilePondImageInner filePondRef={filePondRef} filepondProcessFunc={processFunc} setMediaFileCallback={setMediaFileCallback} showUploading={showUploading} />
}


function FilePondImageInner({
    children,
    filepondProcessFunc,
    filePondRef,
    setMediaFileCallback,
    showUploading,
}) {
    console.log('showUploading', showUploading);
    // Filepond states
    const [mediaFiles, setMediaFiles] = useState([]);

    const onUpdateFiles = (files) => {
        setMediaFiles(files);

        if (files.length > 0) {
            console.log(files[0]);
            setMediaFileCallback(files[0]);
        } else {
            setMediaFileCallback(null);
        }
    };
    const errorMessageFunc = (error) => {
        console.log("errorMessageFunc: ", error);
        return error.body;
    };

    const warningMessageFunc = (error, file) => {
        console.log("Warning", error, file);
        window.alert(error.body);
    };

    return (
        <Stack sx={{ height: '100%', padding: '12px' }} direction={"row"} justifyContent="center" alignItems="center">
            {showUploading && <Stack sx={{ width: '100%', alignItems: 'center' }}><CircularProgress></CircularProgress></Stack>}
            <Box
                className="image-filepond"
                sx={{
                    width: showUploading ? "0px": "100%",
                    cursor: "pointer",
                    margin: '12px',
                    borderRadius: '8px',
                    border: '1px solid #ddd',
                    '&:hover': {
                        background: '#ccc',
                    },
                    visibility: showUploading ? 'hidden' : 'inherit',
                }}
            >
                <FilePond
                    ref={filePondRef}
                    files={mediaFiles}
                    onupdatefiles={onUpdateFiles}
                    allowMultiple={false}
                    allowRevert={false}
                    instantUpload={false}
                    maxParallelUploads={1}
                    name="thumbnail_files"
                    server={{ process: filepondProcessFunc }}
                    stylePanelLayout="integrated"
                    labelTapToRetry=""
                    labelIdle='<div style="text-decoration: none; cursor: pointer;">
                            <div>
                              <div style="font-size:14px;">Upload file</div>
                            </div>'
                    labelFileProcessingError={errorMessageFunc}
                    onwarning={warningMessageFunc}
                />
            </Box>
        </Stack>
    );
}
