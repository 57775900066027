import { useState } from "react";
import { RiExternalLinkFill, RiLinkUnlink } from "react-icons/ri";
import Stack from '@mui/material/Stack';
import { SimpleToolbarButton } from "../../../shared/components/toolbar/SimpleToolbarButton";
import { Toolbar } from "../../../shared/components/toolbar/Toolbar";
import React from "react";

type HyperlinkMenuProps = {
  href: string;
  removeHandler: () => void;
  editMenu: React.ReactElement;
};

/**
 * A hyperlink menu shown when an anchor is hovered over.
 * It shows options to edit / remove / open the link
 */
export const HyperlinkBasicMenu = (props: HyperlinkMenuProps) => {
  const [isEditing, setIsEditing] = useState(false);
  if (isEditing) {
    return props.editMenu;
  }

  function onEditClick(e: React.MouseEvent) {
    setIsEditing(true);
    e.stopPropagation();
  }

  return (
    <Toolbar>
      <Stack direction="row" alignItems="center">
        <SimpleToolbarButton
          mainTooltip="Edit"
          isSelected={false}
          onClick={onEditClick}>
            <div style={{ padding: "5px"}}>
              Edit Link
            </div>
        </SimpleToolbarButton>

        <SimpleToolbarButton
          mainTooltip="Open in new tab"
          isSelected={false}
          onClick={() => {
            window.open(props.href, "_blank");
          }}
          icon={RiExternalLinkFill}
        />

        <SimpleToolbarButton
          mainTooltip="Remove link"
          isSelected={false}
          onClick={props.removeHandler}
          icon={RiLinkUnlink}
        />
      </Stack>
    </Toolbar>
  );
};
