import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import SettingsIcon from '@mui/icons-material/Settings';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import SettingsOverlay, { paywallStream } from '../account/SettingsOverlay';
import PaidOverlay from '../account/PaidOverlay';
import { useSearchParams } from "react-router-dom";
import { trackDocumentEvent } from "../../utils/AmplitudeTrack";

export default function AppLogoMenu({ onSignOut, authUser }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openSettings, setOpenSettings] = React.useState(false);
    const [openPaid, setOpenPaid] = React.useState(false);
    const [search, setSearch] = useSearchParams();

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSettingsOpen = () => {
        trackDocumentEvent('Settings: menu opened');
        setOpenSettings(true);
        handleClose(null);
    };
    const handleSettingsClose = () => {
        setOpenSettings(false);
    };

    const handleSignOut = () => {
        trackDocumentEvent('Settings: sign out');
        onSignOut();
    };

    // Hook to show overlay and reset param
    React.useEffect(() => {
        if (search.get('subscriptionSuccess') === '1') {
            // subscribe success, show overlay
            setOpenPaid(true);
        }
    }, [search]);

    React.useEffect(() => {
        let sub = paywallStream.subscribe(() => {
            // whenever received anything, show settings
            setOpenSettings(true);
        });
        return () => {
            sub.unsubscribe();
        }
    }, []);

    return (
        <>
            <Box sx={{
                paddingLeft: "18px",
                paddingRight: "18px",
                cursor: "pointer"
            }} onClick={handleClick}>
                <h3><Stack direction="row" alignItems="center"><img width="120" height="18" src="/02-full-logo.png" alt="notemad" /></Stack></h3>
            </Box>
            {authUser &&
                <>
                    <Menu
                        id="app-logo-menu"
                        MenuListProps={{
                            'aria-labelledby': 'app-logo-button',
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={handleSettingsOpen}>
                            <ListItemIcon>
                                <SettingsIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>Settings</ListItemText>
                        </MenuItem>
                        <MenuItem onClick={handleSignOut}>
                            <ListItemIcon>
                                <ExitToAppIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>Sign out</ListItemText>
                        </MenuItem>
                    </Menu>
                    <SettingsOverlay open={openSettings} handleClose={handleSettingsClose}></SettingsOverlay>
                    <PaidOverlay open={openPaid} handleClose={() => { setOpenPaid(false); }}></PaidOverlay>
                </>
            }
        </>
    );
}
