import { useState, useEffect } from "react";
import { BehaviorSubject } from "rxjs";
import { DRAWER_WIDTH, RIGHT_PANEL_WIDTH } from "../../common/Constants";
const sideBarOffsetStream = new BehaviorSubject();
const initalSideBarState = {
    leftOffset: 0,
    rightOffset: 0,
}
let state = initalSideBarState;
sideBarOffsetStream.next(initalSideBarState);
const sideBarOffset$ = {
    subscribe: (setState) => {
        return sideBarOffsetStream.subscribe(setState);
    },
    notifyRightSideOffset: (offset) => {
        state = {...state, rightOffset: offset}
        sideBarOffsetStream.next(state);
    },
    notifyLeftSideOffset: (offset) => {
        state = {...state, leftOffset: offset}
        sideBarOffsetStream.next(state);
    }
}

const useSideBarOffset = () => {
    const [sideBarOffset, setSideBarOffset] = useState(initalSideBarState);
    useEffect(() => {
        let followSub = sideBarOffset$.subscribe(setSideBarOffset);
        return () => {
          followSub.unsubscribe();
        };
      }, []);

      const onLeftSideOpen = () => {
        sideBarOffset$.notifyLeftSideOffset(DRAWER_WIDTH);
      }
      const onLeftSideClose = () => {
        sideBarOffset$.notifyLeftSideOffset(0);
      }
      const onRightSideOpen = () => {
        sideBarOffset$.notifyRightSideOffset(RIGHT_PANEL_WIDTH);
      }
      const onRightSideClose = () => {
        sideBarOffset$.notifyRightSideOffset(0);
      }
    return { sideBarOffset, onLeftSideOpen, onLeftSideClose, onRightSideOpen, onRightSideClose }
}

export default useSideBarOffset;