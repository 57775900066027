import { Fragment, Slice, NodeRange } from "prosemirror-model";
import { ReplaceAroundStep, liftTarget } from "prosemirror-transform";

function getNodeType(nameOrType, schema) {
    if (typeof nameOrType === 'string') {
        if (!schema.nodes[nameOrType]) {
            throw Error(`There is no node type named '${nameOrType}'. Maybe you forgot to add the extension?`);
        }
        return schema.nodes[nameOrType];
    }
    return nameOrType;
}

export const liftListItem = typeOrName => ({ state, dispatch }) => {
    const type = getNodeType(typeOrName, state.schema);
    return liftListItemInner(type)(state, dispatch);
};

// Types which are consider as blocks
const blockTypes = ['owlblock', 'liveMessageBlock', 'image'];

// NOTE: Here we use a special implementation because our list can contain different type items!
function liftListItemInner(itemType) {
    return function (state, dispatch) {
        var _state$selection3 = state.selection,
            $from = _state$selection3.$from,
            $to = _state$selection3.$to;
        var range = $from.blockRange($to, function (node) {
            for (let blockType of blockTypes) {
                if (node.firstChild.type === getNodeType(blockType, state.schema)) {
                    return node.childCount > 0;
                }
            }
            return false;
        });

        return liftListItemWithRange($from, range, itemType, state, dispatch);
    };
}

// Export so that sinkListItem can reuse
export function liftListItemWithRange($from, range, itemType, state, dispatch) {
    if (!range) return false;
    if (!dispatch) return true;
    if ($from.node(range.depth - 1).type === itemType) return liftToOuterList(state, dispatch, itemType, range); else return liftOutOfList(state, dispatch, range);
}

function liftToOuterList(state, dispatch, itemType, range) {
    // console.log('liftToOuterList', itemType, range);
    try {
        let tr = state.tr, end = range.end, endOfList = range.$to.end(range.depth);

        if (end < endOfList) {
            // There are siblings after the lifted items, which must become
            // children of the last item
            // HACK: We modified this section so that it became consistent with notions behavior. 
            let parent = range.parent;
            let node = parent.child(range.endIndex - 1);
            let isNested = node.lastChild && node.lastChild.type === parent.type;
            // console.log('isNested', isNested, 'node', node);
            let inner = Fragment.from(isNested ? itemType.create() : null);
            let step = new ReplaceAroundStep(end - (isNested ? 3 : 1), endOfList, end, endOfList, new Slice(Fragment.from(itemType.create(null, range.parent.type.create(null, inner))), (isNested) ? 3 : 1, 0), 1, true);
            // console.log('step', step);
            tr.step(step);
            range = new NodeRange(tr.doc.resolve(range.$from.pos), tr.doc.resolve(endOfList), range.depth);
            // console.log('range new', range);

        }

        dispatch(tr.lift(range, liftTarget(range)).scrollIntoView());
        return true;
    } catch (err) {
        // HACK: If out of range for some reason, try something else
        console.warn('liftToOuterList failed, skip silently', err);
        return false;
    }
}

function liftOutOfList(state, dispatch, range) {
    // console.log('liftToOuterList', range);
    let tr = state.tr, list = range.parent;
    // Merge the list items into a single big item
    for (let pos = range.end, i = range.endIndex - 1, e = range.startIndex; i > e; i--) {
        pos -= list.child(i).nodeSize;
        tr.delete(pos - 1, pos + 1);
    }
    let $start = tr.doc.resolve(range.start), item = $start.nodeAfter;
    if (tr.mapping.map(range.end) !== range.start + $start.nodeAfter.nodeSize)
        return false;
    let atStart = range.startIndex === 0, atEnd = range.endIndex === list.childCount;
    let parent = $start.node(-1), indexBefore = $start.index(-1);
    if (!parent.canReplace(indexBefore + (atStart ? 0 : 1), indexBefore + 1, item.content.append(atEnd ? Fragment.empty : Fragment.from(list))))
        return false;
    let start = $start.pos, end = start + item.nodeSize;
    // Strip off the surrounding list. At the sides where we're not at
    // the end of the list, the existing list is closed. At sides where
    // this is the end, it is overwritten to its end.
    tr.step(new ReplaceAroundStep(start - (atStart ? 1 : 0), end + (atEnd ? 1 : 0), start + 1, end - 1, new Slice((atStart ? Fragment.empty : Fragment.from(list.copy(Fragment.empty)))
        .append(atEnd ? Fragment.empty : Fragment.from(list.copy(Fragment.empty))), atStart ? 0 : 1, atEnd ? 0 : 1), atStart ? 0 : 1));
    dispatch(tr.scrollIntoView());
    return true;
}