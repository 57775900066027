import { mergeAttributes, Node } from "@tiptap/core";
import styles from "./Block.module.css";

export const BlockGroup = Node.create({
  name: "blockgroup",
  // See: https://prosemirror.net/docs/ref/#model.NodeSpec.marks
  // This specified in parent! so that imageBlock can be added commentThread
  marks: 'commentThread',

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  content: "(owlblock|block)+",

  parseHTML() {
    return [
      {
        tag: "div",
        getAttrs: (element) => {
          if (typeof element === "string") {
            return false;
          }

          if (element.getAttribute("data-node-type") === "block-group") {
            // Null means the element matches, but we don't want to add any attributes to the node.
            return null;
          }

          return false;
        }
      },
      {
        tag: "ul",
        context: "owlblock/"
      },
      {
        tag: "ol",
        context: "owlblock/"
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      "div",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
        class: styles.blockGroup,
        "data-node-type": "block-group"
      }),
      0,
    ];
  },
});
