import useSWR from 'swr';
import {
    listDocuments,
    listVisitedDocuments,
    recordDocumentVisit,
    createDocument,
    createDocumentAuthed,
    deleteDocument,
    updateDocument,
    createOnboardingDocument,
} from "../lib/backend";
import { Auth } from "@aws-amplify/auth";


export function useFetchDocuments(teamId, _) {
    const { data, error, mutate } = useSWR('documents_' + teamId, () => fetchDocuments(teamId));
    return {
        documents: (data) ? data : [],
        isLoading: !error && !data,
        isError: error,
        mutate: mutate
    }
}


const fetchDocuments = async (teamId) => {
    if (!teamId) {
        return [];
    }

    let documents = await listDocuments(teamId);
    return documents;
};

export function useFetchVisitedDocuments(accountId, _) {
    const { data, error, mutate } = useSWR('visitedDocuments_' + accountId, () => fetchVisitedDocuments(accountId));
    return {
        documents: (data) ? data : [],
        isLoading: !error && !data,
        isError: error,
        mutate: mutate
    }
}


const fetchVisitedDocuments = async (accountId) => {
    if (!accountId) {
        return [];
    }

    let documents = await listVisitedDocuments(accountId);
    return documents;
};


export const documentVisit = async (accountId, documentId) => {
    let documentVisit = await recordDocumentVisit(accountId, documentId);
    return documentVisit;
};

export const createNewDocument = async (teamId, accountId, documentName) => {
    let document = await createDocument(teamId, accountId, documentName);
    return document;
};

export const createNewDocumentAuthed = async (teamId, documentName) => {
    const currentSession = await Auth.currentSession();
    const authToken = currentSession.idToken.jwtToken;
    let document = await createDocumentAuthed(authToken, teamId, documentName);
    return document;
};

export const createNewOnboardingDocument = async (teamId, accountId, documentName) => {
    let document = await createOnboardingDocument(teamId, accountId, documentName);
    return document;
};

export const deleteSingleDocument = async (documentId) => {
    await deleteDocument(documentId);
};

export const updateSingleDocumentName = async (documentId, documentName) => {
    await updateDocument(documentId, documentName, null, null);
};

export const updateSingleDocumentEditStatus = async (documentId, editStatus) => {
    await updateDocument(documentId, null, editStatus, null);
};

export const updateSingleDocumentArchiveStatus = async (documentId, archiveStatus) => {
    await updateDocument(documentId, null, null, archiveStatus);
}