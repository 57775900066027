import React from "react";
import { useState, useMemo } from "react";
import { BehaviorSubject } from "rxjs";

const cursorStateStream = new BehaviorSubject();
const cursorInitialState = {
    showMouseCursor: true,
    showTextCursorLabel: false,
};
let state = cursorInitialState;

// A state which informs others how cursor should present
export const cursorState$ = {
    init: () => {
        state = { ...state };
        cursorStateStream.next(state);
    },
    subscribe: (setCursorState) => {
        return cursorStateStream.subscribe(setCursorState);
    },
    notifyMouseCursorChanged: (showMouseCursor) => {
        state = {
            ...state,
            showMouseCursor: showMouseCursor
        };
        cursorStateStream.next(state);
    },
    notifyTextCursorChanged: (showTextCursorLabel) => {
        state = {
            ...state,
            showTextCursorLabel: showTextCursorLabel
        };
        cursorStateStream.next(state);
    },
    getState: () => {
        return { ...state };
    },
};


const useCursorState = () => {
    const [cursorState, setCursorState] = useState(
        cursorInitialState
    );

    // This is so that we don't update client all the time
    const memoCursorState = useMemo(() => cursorState, [cursorState.showMouseCursor, cursorState.showTextCursorLabel]);

    React.useEffect(() => {
        let subscription = cursorState$.subscribe(setCursorState);
        cursorState$.init();
        return () => {
            subscription.unsubscribe();
        };
    }, []);

    const onHandleMouseCursorChanged = (showMouseCursor) => {
        cursorState$.notifyMouseCursorChanged(showMouseCursor);
    };

    const onHandleTextCursorChanged = (showTextCursorLabel) => {
        cursorState$.notifyTextCursorChanged(showTextCursorLabel);
    };

    return {
        cursorState: memoCursorState,
        onHandleMouseCursorChanged,
        onHandleTextCursorChanged,
    };
};
export default useCursorState;