import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
const CardTooltip = styled(({ className, ...props }) => (
  <Tooltip
    TransitionProps={{ timeout: 0 }}
    {...props}
    classes={{ popper: className }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: "#eee",
    padding: "0",
    fontSize: 16,
    overflow: "scroll",
    maxHeight: "400px",
    zIndex: 9999999,
  },
}));
export default CardTooltip;
