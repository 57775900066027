import * as React from "react";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import { ListItemText, Box, IconButton, Stack, Tooltip } from "@mui/material";
import DocumentMenu from "./DocumentMenu";
import SharedDocumentMenu from "./SharedDocumentMenu";
import AppLogoMenu from "./AppLogoMenu";
import Divider from "@mui/material/Divider";
import Collapse from '@mui/material/Collapse';
import { Link, useNavigate } from "react-router-dom";
import useLiveMessageReplay from "../recording/useLiveMessageReplay";
import { encodeRoutePath } from "../../utils/DocumentRoutingUtils";
import { useFetchDocuments, useFetchVisitedDocuments, createNewDocument, createNewDocumentAuthed } from "../../utils/DocumentBackendUtils";
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import ShareRoundedIcon from '@mui/icons-material/ShareRounded';
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { DEFAULT_ACCOUNT_ID } from "../../common/Constants";
import { useUserInfo } from "../../utils/useUserInfo";
import { trackDocumentEvent } from "../../utils/AmplitudeTrack";
import { AVAILABLE_TEAMS } from "../../common/Constants";
import { editorState$ } from "../../streams/editorState";
import { BehaviorSubject, take, last, debounce, interval } from "rxjs";
import { updateSingleDocumentName } from '../../utils/DocumentBackendUtils';
import { paywallStream } from '../account/SettingsOverlay';
import { useListShareTargets } from '../../utils/ShareTargetBackendUtils';

const updateDocumentNameStream = new BehaviorSubject();
const updateDocumentName = updateDocumentNameStream.pipe(
  // Wait 400 ms before actually updating title
  debounce((i) => interval(400))
);


const computeUrlTeamId = (teamId) => {
  // Hack to support existing teams
  if (AVAILABLE_TEAMS.includes(teamId)) {
    return teamId;
  } else {
    return 'mine';
  }
}

const createDocumentForTeam = async (teamId) => {
  if (AVAILABLE_TEAMS.includes(teamId)) {
    return await createNewDocument(teamId, DEFAULT_ACCOUNT_ID, "Untitled");
  } else {
    return await createNewDocumentAuthed(teamId, "Untitled");
  }
}

const DocumentItem = ({ document, keyPrefix, editorState, hoveredDocumentId, setHoveredDocumentId, documentId, teamId, mutate, documentShareId = null }) => {
  const key = keyPrefix + '_' + document.documentId;
  const displayDocumentName = (editorState, document) => {
    return (editorState && editorState.documentId === document.documentId && editorState.pageHeading) ? editorState.pageHeading : document.documentName
  };

  return (
    <Link
      key={key}
      to={`/${computeUrlTeamId(document.teamId)}/${encodeRoutePath(
        displayDocumentName(editorState, document),
        document.documentId
      )}`}
      style={{
        color: "black",
        opacity: (documentId === document.documentId) ? "1.0" : "0.6",
        textDecoration: "none",
      }}
    >

      {document.teamId === teamId ? (
        <ListItem
          disablePadding
          secondaryAction={
            <div
              style={{
                visibility:
                  key === hoveredDocumentId
                    ? "inherit"
                    : "hidden",
              }}
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              <DocumentMenu
                documentId={document.documentId}
                editStatus={document.editStatus}
                archiveStatus={document.archiveStatus}
                documentMutate={() => { mutate(); }}
              />
            </div>
          }
          size="small"
          onMouseOver={() => {
            setHoveredDocumentId(key);
          }}
          onMouseOut={() => {
            setHoveredDocumentId(null);
          }}
        >
          <ListItemButton>
            <DescriptionOutlinedIcon fontSize="small" sx={{ opacity: "0.5" }} />
            <ListItemText
              secondary={displayDocumentName(editorState, document)}
              secondaryTypographyProps={{
                // noWrap: true,
                fontSize: 14,
                lineHeight: '16px',
                color: "#222",
              }}
              sx={{ marginLeft: "8px" }}
            />
          </ListItemButton>
        </ListItem>
      ) : (
        <ListItem
          disablePadding
          secondaryAction={
            <div
              style={{
                visibility:
                  key === hoveredDocumentId
                    ? "inherit"
                    : "hidden",
              }}
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              <SharedDocumentMenu
                documentShareId={documentShareId}
                documentMutate={() => { mutate(); }}
              />
            </div>
          }
          size="small"
          onMouseOver={() => {
            setHoveredDocumentId(key);
          }}
          onMouseOut={() => {
            setHoveredDocumentId(null);
          }}
        >
          <ListItemButton>
            <DescriptionOutlinedIcon sx={{ opacity: "0.5" }} />
            <ListItemText
              secondary={displayDocumentName(editorState, document)}
              secondaryTypographyProps={{
                // noWrap: true,
                fontSize: 14,
                lineHeight: '16px',
                color: "#222",
              }}
              sx={{ marginLeft: "8px" }}
            />
          </ListItemButton>
        </ListItem>
      )
      }
    </Link>
  );
};


export default function AppSideBar({ teamId, drawerWidth, documentId }) {
  const navigate = useNavigate();
  const { documents, mutate } = useFetchDocuments(teamId, 'AppSideBar');
  const [currentUser, { onSignOut }, authUser] = useUserInfo();
  const { documents: visitedDocuments, mutate: visitedDocumentMutate } = useFetchVisitedDocuments(currentUser.userId, 'AppSideBar');
  const { shareTargets, mutate: shareTargetsMutate } = useListShareTargets();

  const [hoveredDocumentId, setHoveredDocumentId] = React.useState(null);
  const [replayState, { onHandleReplayingStop }] = useLiveMessageReplay();
  const [editorState, setEditorState] = React.useState(null);

  React.useEffect(() => {
    let subscription = editorState$.subscribe(setEditorState);

    let updateDocumentNameSubscription = updateDocumentName.subscribe((state) => {
      if (state) {
        const { documentId: documentIdToUpdate, documentName: documentNameToUpdate } = state;

        if (documentIdToUpdate && documentNameToUpdate) {
          updateSingleDocumentName(documentIdToUpdate, documentNameToUpdate).then(() => {
            mutate();
            visitedDocumentMutate();
          });
        }
      }
    });
    return () => {
      subscription.unsubscribe();
      updateDocumentNameSubscription.unsubscribe();
    }
  }, []);

  React.useEffect(() => {
    if (editorState && editorState.pageHeading) {
      for (let document of documents) {
        if (document.documentId === editorState.documentId) {
          let existingHeading = document.documentName;
          if (existingHeading !== editorState.pageHeading) {
            // should update
            console.log('should update page name', 'old: ', existingHeading, ', new: ', editorState.pageHeading);
            updateDocumentNameStream.next({ documentId: editorState.documentId, documentName: editorState.pageHeading });
          }
        }
      }
    }
  }, [editorState, documents]);

  // Make sure room change stops replaying
  React.useEffect(() => {
    onHandleReplayingStop(true, false /*do not log from here*/);
  }, [documentId]);

  const handleCreateDocument = async () => {
    try {
      let doc = await createDocumentForTeam(teamId);
      trackDocumentEvent("Create new doc")
      mutate().then(() => {
        navigate(`/${teamId}/${doc.documentId}`);
      });
    } catch (err) {
      console.log('createDocumentForTeam err', err);
      if (err.message === 'Max document limit reached') {
        trackDocumentEvent("Create new doc: max limit reached")
        paywallStream.next({ msg: 'You have reached the document limit, please upgrade to proceed.' });
      }
    }
  };

  const [open, setOpen] = React.useState(true);
  const handleClick = () => {
    setOpen(!open);
  };

  const [sharedOpen, setSharedOpen] = React.useState(true);
  const handleSharedClick = () => {
    setSharedOpen(!sharedOpen);
  };

  const [privateOpen, setPrivateOpen] = React.useState(true);
  const handlePrivateClick = () => {
    setPrivateOpen(!privateOpen);
  };

  const [archivedOpen, setArchivedOpen] = React.useState(false);
  const handleArchivedClick = () => {
    setArchivedOpen(!archivedOpen);
  };

  // A flag to determine if we should show full UI (visited documents, etc) to user
  const shouldShowFullUI = shareTargets.length > 0 || documents.length >= 10;
  const archivedDocuments = documents.filter(doc => doc.archiveStatus === 1);
  const unarchivedDocuments = documents.filter(doc => doc.archiveStatus !== 1);
  const sharedDocuments = shareTargets.map(shareTarget => { return shareTarget?.documentShare?.document; }).filter(doc => doc !== undefined);

  const lookUpDocumentShareId = (shareTargets, documentId) => {
    if (!shareTargets) {
      return null;
    }
    const targets = shareTargets.filter(shareTarget => shareTarget?.documentShare?.document.documentId === documentId);
    return targets.length > 0 ? targets[0].documentShareId : null;
  };

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
          backgroundColor: "#FAF9F9",
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <Stack
        direction="row"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <AppLogoMenu onSignOut={onSignOut} authUser={authUser}></AppLogoMenu>
        <Tooltip title="Start a new page">
          <IconButton onClick={handleCreateDocument} sx={{ color: "#ccc", "&:hover": { color: "#999" } }}>
            <AddRoundedIcon />
          </IconButton>
        </Tooltip>
      </Stack>
      <div>
        {shouldShowFullUI &&
          <ListItem sx={{ fontSize: '12px', color: 'rgba(55, 53, 47, 0.5)', lineHeight: '0.5', fontWeight: '700' }}>
            <Box sx={{ cursor: 'pointer', "&:hover": { color: "#999", background: '#E8E8E8' } }} onClick={handleClick}>Recently edited</Box>
          </ListItem>
        }
        {shouldShowFullUI &&
          <Collapse in={open} timeout={0} unmountOnExit>
            <List>
              {visitedDocuments &&
                visitedDocuments.slice(0, 5).map((document) => (
                  <DocumentItem
                    document={document}
                    key={'visited_' + document.documentId}
                    keyPrefix={'visited'}
                    editorState={editorState}
                    hoveredDocumentId={hoveredDocumentId}
                    setHoveredDocumentId={setHoveredDocumentId}
                    documentId={documentId}
                    teamId={teamId}
                    mutate={mutate}
                    visitedDocumentMutate={visitedDocumentMutate}
                    documentShareId={lookUpDocumentShareId(shareTargets, document.documentId)}
                  />
                ))}
            </List>
          </Collapse>
        }
      </div>
      <div>
        {shouldShowFullUI &&
          <ListItem sx={{ fontSize: '12px', color: 'rgba(55, 53, 47, 0.5)', lineHeight: '0.5', fontWeight: '700' }}>
            <Box sx={{ cursor: 'pointer', "&:hover": { color: "#999", background: '#E8E8E8' } }} onClick={handleSharedClick}>Shared</Box>
          </ListItem>
        }
        {shouldShowFullUI &&
          <Collapse in={sharedOpen} timeout={0} unmountOnExit>
            <List>
              {sharedDocuments &&
                sharedDocuments.map((document) => (
                  <DocumentItem
                    document={document}
                    key={'shared_' + document.documentId}
                    keyPrefix={'shared'}
                    editorState={editorState}
                    hoveredDocumentId={hoveredDocumentId}
                    setHoveredDocumentId={setHoveredDocumentId}
                    documentId={documentId}
                    teamId={teamId}
                    mutate={() => { shareTargetsMutate(); }}
                    documentShareId={lookUpDocumentShareId(shareTargets, document.documentId)}
                  />
                ))}
            </List>
          </Collapse>
        }
      </div>
      <div>
        {shouldShowFullUI &&
          <ListItem sx={{ fontSize: '12px', color: 'rgba(55, 53, 47, 0.5)', lineHeight: '0.5', fontWeight: '700' }}>
            <Box sx={{ cursor: 'pointer', "&:hover": { color: "#999", background: '#E8E8E8' } }} onClick={handlePrivateClick}>Documents</Box>
          </ListItem>
        }
        {shouldShowFullUI &&
          <Collapse in={privateOpen || !shouldShowFullUI} timeout={0} unmountOnExit>
            <List>
              {unarchivedDocuments &&
                unarchivedDocuments.map((document) => (
                  <DocumentItem
                    document={document}
                    key={'private_' + document.documentId}
                    keyPrefix={'private'}
                    editorState={editorState}
                    hoveredDocumentId={hoveredDocumentId}
                    setHoveredDocumentId={setHoveredDocumentId}
                    documentId={documentId}
                    teamId={teamId}
                    mutate={() => { mutate(); visitedDocumentMutate(); }} />
                ))}
            </List>
          </Collapse>
        }
        {!shouldShowFullUI &&
          <Collapse in={privateOpen || !shouldShowFullUI} timeout={0} unmountOnExit>
            <List>
              {documents &&
                documents.map((document) => (
                  <DocumentItem
                    document={document}
                    key={'default_' + document.documentId}
                    keyPrefix={'default'}
                    editorState={editorState}
                    hoveredDocumentId={hoveredDocumentId}
                    setHoveredDocumentId={setHoveredDocumentId}
                    documentId={documentId}
                    teamId={teamId}
                    mutate={() => { mutate(); visitedDocumentMutate(); }} />
                ))}
            </List>
          </Collapse>
        }
      </div>
      <div>
        {shouldShowFullUI &&
          <ListItem sx={{ fontSize: '12px', color: 'rgba(55, 53, 47, 0.5)', lineHeight: '0.5', fontWeight: '700' }}>
            <Box sx={{ cursor: 'pointer', "&:hover": { color: "#999", background: '#E8E8E8' } }} onClick={handleArchivedClick}>Archived</Box>
          </ListItem>
        }
        {shouldShowFullUI &&
          <Collapse in={archivedOpen} timeout={0} unmountOnExit>
            <List>
              {archivedDocuments &&
                archivedDocuments.map((document) => (
                  <DocumentItem
                    document={document}
                    key={'archived_' + document.documentId}
                    keyPrefix={'archived'}
                    editorState={editorState}
                    hoveredDocumentId={hoveredDocumentId}
                    setHoveredDocumentId={setHoveredDocumentId}
                    documentId={documentId}
                    teamId={teamId}
                    mutate={() => { mutate(); visitedDocumentMutate(); }} />
                ))}
            </List>
          </Collapse>
        }
        <div style={{ height: '100px' }}></div>
      </div>
      {/*teamId && <TeamUserTester teamId={teamId}></TeamUserTester>*/}
    </Drawer>
  );
}
