import { useState, useEffect } from "react";
import { Box, Stack, Button, Tooltip, IconButton } from "@mui/material";
import FiberManualRecordRoundedIcon from "@mui/icons-material/FiberManualRecordRounded";
import UploadIcon from '@mui/icons-material/Upload';
import useLiveMessageRecordingController from "./useLiveMessageRecordingController";
import useLiveMessageViewModel from "./useLiveMessageViewModel";
import ActiveRecordStopButton from "./ActiveRecordStopButton";
import { trackDocumentEvent } from "../../utils/AmplitudeTrack";
import { getTimeString } from "../../utils/ComputeUtil";
import { interval } from "rxjs";

const recordButtonTitle = "Click or hold option to record a live message";
const defaultTime = "0:00";

// lmId is a locally generated ID to distinguish different empty live messages.
const InLineRecordBlock = ({ setFocus, lmId, selected }) => {
  const { selectedLiveMessage, selectLiveMessage } = useLiveMessageViewModel();
  const {
    recordingState,
    liveMessageUpdateStream,
    startHoldToRecordProgressStream,
    onStartToRecord,
    onStopRecording,
  } = useLiveMessageRecordingController();
  useEffect(() => {
    if (setFocus) {
      selectLiveMessage({ liveMessageId: lmId, isRecordingBlock: true });
    }
  }, [lmId]);

  const [lmUpdate, setLmUpdate] = useState(null);
  useEffect(() => {
    if (liveMessageUpdateStream) {
      const sub = liveMessageUpdateStream.subscribe(setLmUpdate);
      return () => {
        sub.unsubscribe();
      };
    }
  }, []);

  const onFocusOnCurrentBlock = () => {
    selectLiveMessage({ liveMessageId: lmId, isRecordingBlock: true });
  };

  const toggleRecordStop = () => {
    trackDocumentEvent("Toggle record/stop - inline lm block")
    if (recordingState.isRecording) {
      onStopRecording();
    } else {
      onStartToRecord();
    }
  };
  const [recordingTime, setRecordingTime] = useState(defaultTime);

  const [recordText, setRecordText] = useState("---");
  useEffect(() => {
    if (selectedLiveMessage.liveMessageId === lmId) {
      console.log('selectedLiveMessage.liveMessageId', selectedLiveMessage.liveMessageId, 'lmid', lmId);
      if (recordingState.isRecording) {
        setRecordText(recordingTime);
      } else if (
        recordingState.recordings &&
        recordingState.recordings.length > 0
      ) {
        setRecordText("Resume");
        setRecordingTime(defaultTime);
      } else {
        setRecordText("Record");
      }
    } else if (lmUpdate &&
      lmUpdate.requestor.liveMessageId === lmId && lmUpdate.isUploading) {
      setRecordText("Uploading");
    } else if (recordText !== "Uploading") {
      setRecordText("Select");
    }
  }, [recordingState.isRecording, recordingState.recordings, selectedLiveMessage.liveMessageId, lmUpdate]);

  // Handle recording time additon
  useEffect(() => {
    let subscription = null;
    if (recordingState.isRecording) {
      const counter = interval(1000);
      subscription = counter.subscribe((tick) => {
        if (recordingState.activeRecordingStartTime && selectedLiveMessage.liveMessageId === lmId) {
          let delta =
            new Date().getTime() - recordingState.activeRecordingStartTime;
          setRecordingTime((val) => {
            const newTime = `${getTimeString(delta)}`
            setRecordText(newTime);
            return newTime
          });
        }
      });
    }

    return () => {
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  }, [recordingState]);

  // block selected by UI (mouse or text cursor)
  const isBlockSelected = selected;
  const isBlockActive = selectedLiveMessage.liveMessageId === lmId;
  return (
    <Tooltip title={isBlockActive ? recordButtonTitle : null}>
      <Box
        onClick={isBlockActive ? toggleRecordStop : onFocusOnCurrentBlock}
        size="small"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: (isBlockActive || isBlockSelected) ? "#F95959" : "#ccc",
          opacity: "0.8",
          borderRadius: "20px",
          cursor: 'pointer',
          "&:hover": {
            color: "#F95959",
            opacity: "1",
          },
        }}
      >
        <Stack
          direction="row"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: "10px",
            marginRight: "10px"
          }}
        >
          {isBlockActive && (
            <ActiveRecordStopButton
              recordingState={recordingState}
              startHoldToRecordProgressStream={startHoldToRecordProgressStream}
              enableTooltip={false}
            />
          )}
          {!isBlockActive && (
            <Box
              // size="small"
              // disableRipple
              sx={{
                color: "#ccc",
                opacity: "0.8",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {(recordText === 'Uploading') ? <UploadIcon /> : <FiberManualRecordRoundedIcon />}

            </Box>
          )}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "#000",
              fontSize: "16px",
              marginLeft: "4px",
              textTransform: "none",
              opacity: (isBlockActive || isBlockSelected) ? "0.6" : "0.4",
            }}
          >
            {recordText}
          </Box>
        </Stack>
      </Box>
    </Tooltip>
  );
};

export default InLineRecordBlock;
