/* eslint-disable import/no-extraneous-dependencies */
import { Mark, mergeAttributes } from '@tiptap/react';
import { getUserInfo } from "../../utils/useUserInfo";
export interface CommentOptions {
  HTMLAttributes: Record<string, any>,
}

const userInfo = getUserInfo();

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    comment: {
      /**
       * Set a comment mark
       */
      setComment: (comment: string) => ReturnType,
      /**
       * Toggle a comment mark
       */
      toggleComment: () => ReturnType,
      /**
       * Unset a comment mark
       */
      unsetComment: () => ReturnType,
    }
  }
}

export const CommentThread = Mark.create<CommentOptions>({
  name: 'commentThread',

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  addAttributes() {
    return {
      comment: {
        default: null,
        parseHTML: (el) => (el as HTMLSpanElement).getAttribute('data-comment'),
        renderHTML: (attrs) => {
          if (attrs.comment) {
            const commentData = JSON.parse(attrs.comment)
            const isTmpComment = commentData.comments.length == 1 && commentData.comments[0].content.length === 0 && !commentData.comments[0].liveMessageId;
            const isFirstCommentAuthor = commentData.comments[0].userId === userInfo.userId;
            return { 'data-comment': attrs.comment, style:`background-color: ${(!isTmpComment || isFirstCommentAuthor) ?'#FEF9E5':'none'}; text-decoration:  ${(!isTmpComment || isFirstCommentAuthor) ?'underline':'none'};text-decoration-color: #F8d648;` }  
          }
          },
      },
    };
  },
  parseHTML() {
    return [
      {
        tag: 'span[data-comment]',
        getAttrs: (el) => !!(el as HTMLSpanElement).getAttribute('data-comment')?.trim() && null,
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['span', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
  },

  addCommands() {
    return {
      setComment: (comment: string) => ({ commands }) => commands.setMark(CommentThread.name, { comment }),
      toggleComment: () => ({ commands }) => commands.toggleMark(CommentThread.name),
      unsetComment: () => ({ commands }) => commands.unsetMark(CommentThread.name),
    };
  },

  // addProseMirrorPlugins() {
  //   return [
  //     new Plugin({
  //       props: {
  //         handleClick(view, pos) {
  //           const { schema, doc, tr } = view.state;

  //           const range = getMarkRange(doc.resolve(pos), schema.marks.comment);

  //           if (!range) return false;

  //           const [$start, $end] = [doc.resolve(range.from), doc.resolve(range.to)];

  //           view.dispatch(tr.setSelection(new TextSelection($start, $end)));

  //           return true;
  //         },
  //       },
  //     }),
  //   ];
  // },
});
