
const initialState = {
  events: [],
  startTime: null,
};

let state = initialState;

/**
 * Stores text cursor during recording within blocknote plugin
 * Control by: LiveMessageTextCursorRecorder
 * Used by: RecordingExtension
 */
const textCursorBuffer = {
  init: () => {
    state = {...state}
  },
  startRecord: () => {
    state = {
      ...state,
      startTime: Date.now(),
    };
  },
  stopRecord: () => {
    let events = state.events;
    let startTime = state.startTime;
    let endTime = Date.now();
    state = {
      ...state,
      startTime: null,
      events: [],
    };

    return { events, startTime, endTime };
  },
  notifyUpdate: ({blockId, anchor, head}) => {
    if (!state.startTime) {
      return;
    }
    let event = {anchor: anchor, head: head, blockId: blockId, ts: Date.now() - state.startTime};
    // console.log('notifyUpdate event', event);
    state = {
      ...state,
      events: [...state.events, event]
    };
  }
};

export default textCursorBuffer;