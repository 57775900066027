import { useState, useEffect } from "react";
import useLiveMessageRecording from "../recording/useLiveMessageRecording";
import useLiveMessageReplay from "../recording/useLiveMessageReplay";
import useLiveMessageViewModel from "./useLiveMessageViewModel";
import { DEFAULT_ACCOUNT_ID } from "../../common/Constants";
import { Subject, take, BehaviorSubject } from "rxjs";
import { requestMediaPermissions } from "mic-check";

const startHoldToRecordProgressStream = new Subject();
const liveMessageUpdateStream = new BehaviorSubject();

const useLiveMessageRecordingController = () => {
  const { getCurrentSelectedLiveMessage, unselectActiveLiveMessage } =
    useLiveMessageViewModel();
  const [startHoldToRecordProgress, setStartHoldToRecordProgress] = useState(0);
  const [
    recordingState,
    {
      onHandleRecordingStart,
      onHandleRecordingStop,
      onDeleteLastRecording,
      onAbortCurrentRecording,
      onHandleRecordingSubmit,
      onChangeLanguageType,
      onHandlePermissionUpdate,
    },
  ] = useLiveMessageRecording();
  const [replayState, { onHandleReplayingStop }] = useLiveMessageReplay();

  const resetLiveMessageRecording = () => {
    if (recordingState.isRecording) {
      onHandleRecordingStop();
    }
    // TODO we might want to add some alert here?
    onAbortCurrentRecording();
  };

  const onHoldToRecordProgress = (progress) => {
    setStartHoldToRecordProgress(progress);
  };

  const isMicPermissionGranted = async () => {
    let res = await navigator.permissions.query({ name: "microphone" });
    return res.state === "granted";
  };
  const onStartToRecord = () => {
    isMicPermissionGranted().then((granted) => {
      if (granted) {
        // if already granted, go ahead and start recording
        onHandleRecordingStart();
        return;
      }

      // If not granted, trigger the procedure to request for permission
      onHandlePermissionUpdate(false);
      requestMediaPermissions({ audio: true, video: false })
        .then(() => {
          console.log("requestAudioPermission");
          onHandlePermissionUpdate(true);
          onHandleRecordingStart();
        })
        .catch((err) => {
          console.warn("requestAudioPermission err", err);
        });
    });
  };

  const onStopRecording = () => {
    onHandleRecordingStop();
  };

  const onSaveRecordingToCloud = (
    editor,
    documentId,
    account = DEFAULT_ACCOUNT_ID
  ) => {
    getCurrentSelectedLiveMessage((selectedLm) => {
      liveMessageUpdateStream.next({
        account: account,
        documentId: documentId,
        requestor: selectedLm,
        isUploading: true,
      });
      onHandleRecordingSubmit(account, documentId, (cloudLm) => {
        // console.log("cloud lm returned");
        console.log(cloudLm);
        liveMessageUpdateStream.next({
          account: account,
          documentId: documentId,
          requestor: selectedLm,
          liveMessage: cloudLm,
        });
      });
      closeRecording();
    });
  };

  const closeRecording = () => {
    unselectActiveLiveMessage();
    resetLiveMessageRecording();

    // make sure to stop replay if there's still any
    onHandleReplayingStop(true, false /*do not log from here*/);
  };

  useEffect(() => {
    // console.log(`progress:${startHoldToRecordProgress}`);
    if (startHoldToRecordProgress >= 100) {
      onHandleRecordingStart(true);
    }
    startHoldToRecordProgressStream.next(startHoldToRecordProgress);
  }, [startHoldToRecordProgress]);

  return {
    recordingState,
    startHoldToRecordProgressStream,
    liveMessageUpdateStream,
    onStartToRecord,
    onStopRecording,
    onDeleteLastRecording,
    onHoldToRecordProgress,
    resetLiveMessageRecording,
    closeRecording,
    onSaveRecordingToCloud,
    onChangeLanguageType,
  };
};

export default useLiveMessageRecordingController;
