import { Subject } from "rxjs";
import { v4 } from "uuid";
import { createLiveMessages } from "../utils/RecordingBackendUtils";

const getPreferredLanguageType = () => {
  if (localStorage.getItem("languageType")) {
    return parseInt(localStorage.getItem("languageType"));
  }
  return 0;
};

const setPreferredLanguageType = (languageType) => {
  localStorage.setItem("languageType", languageType);
};


const recordingSubject$ = new Subject();
const recordingInitialState$ = {
  isRecording: false,
  curRecordingId: null,
  // array of recordingId, recording => {mouseCursor, textCursor, audio} map
  recordings: [],
  documentId: null,
  //activeRecording
  activeRecordingStartTime: null,
  isActiveRecordingReady: false,
  permissionGranted: true, // is audio permission granted
  languageType: getPreferredLanguageType() // 0: english, 1: chinese
};

// HACK: Probably not the best way since this doesn't store across sessions. But works for now.
const recordingCache = {}

let state = recordingInitialState$;

/**
 * Receive and publish recording state to its listeners (audio, text cursor, mouse cursor recorders)
 */

const recordingState$ = {
  init: () => {
    state = { ...state };
    recordingSubject$.next(state);
  },
  subscribe: (setState) => recordingSubject$.subscribe(setState),
  // Reload ongoing recordings at page change
  reload: (documentId) => {
    if (state.documentId) {
      // write to cache
      recordingCache[state.documentId] = state.recordings;
    }

    // read from cache
    let recordings = [];
    if (documentId in recordingCache) {
      recordings = recordingCache[documentId];
    }

    state = {
      ...state,
      isRecording: false,
      curRecordingId: null,
      recordings: recordings,
      documentId: documentId,
    };
    recordingSubject$.next(state);
  },
  startRecording: (holdToRecord) => {
    // console.log("startRecording");
    state = {
      ...state,
      isRecording: true,
      holdToRecord: holdToRecord,
      activeRecordingStartTime: new Date().getTime(),
      curRecordingId: v4(),
    };
    recordingSubject$.next(state);
  },
  stopRecording: () => {
    // console.log("stopRecording");
    state = {
      ...state,
      activeRecordingStartTime: null,
      isRecording: false,
      curRecordingId: null,
      recordings: [...state.recordings, { recordingId: state.curRecordingId }],
      isActiveRecordingReady: false // this will be set to true when all sub-recordings are added
    };
    recordingSubject$.next(state);
  },
  deleteLastRecording: () => {
    // console.log("deleteLastRecording");
    let recordings = state.recordings;
    recordings.pop();
    state = {
      ...state,
      activeRecordingStartTime: null,
      isRecording: false,
      curRecordingId: null,
      recordings: [...recordings],
    };
    recordingSubject$.next(state);
  },

  addMouseCursorRecording: (recordingId, mouseCursorRecording) => {
    for (let recording of state.recordings) {
      if (recording.recordingId === recordingId) {
        recording.mouseCursor = mouseCursorRecording;
      }
    }

    tryNotifyRecordingState(recordingId, state);
  },
  addMouseCursorV2Recording: (recordingId, mouseCursorRecording) => {
    for (let recording of state.recordings) {
      if (recording.recordingId === recordingId) {
        recording.mouseCursorV2 = mouseCursorRecording;
      }
    }

    tryNotifyRecordingState(recordingId, state);
  },
  addAudioRecording: (recordingId, audioRecording) => {
    for (let recording of state.recordings) {
      if (recording.recordingId === recordingId) {
        recording.audio = audioRecording;
      }
    }

    tryNotifyRecordingState(recordingId, state);
  },
  addTextCursorRecording: (recordingId, textCursorRecording) => {
    for (let recording of state.recordings) {
      if (recording.recordingId === recordingId) {
        recording.textCursor = textCursorRecording;
      }
    }

    tryNotifyRecordingState(recordingId, state);
  },
  changeLanguageType: (languageType) => {
    state = {
      ...state,
      languageType: languageType,
    };
    setPreferredLanguageType(languageType);
    recordingSubject$.next(state);
  },
  clear: () => {
    state = { ...state, activeRecordingStartTime: null, recordings: [] };
    recordingSubject$.next(state);
  },
  // Send to cloud
  // 
  submit: async (accountId, documentId, onSaved = (liveMessage) => { }) => {
    console.log("submit");
    let savedLiveMessage = await createLiveMessages(accountId, documentId, state.recordings, state.languageType);
    onSaved(savedLiveMessage);
    state = { ...state, activeRecordingStartTime: null, recordings: [] };
    recordingSubject$.next(state);
  },
  updatePermission: (granted) => {
    state = { ...state, permissionGranted: granted };
    recordingSubject$.next(state);
  },
  getState:()=> {
    return state;
  },
  recordingInitialState$,
};

const tryNotifyRecordingState = (recordingId, state) => {
  for (let recording of state.recordings) {
    if (
      recording.recordingId === recordingId &&
      // TODO HACK is it sufficient to use just audio?
      recording.audio
    ) {
      recording.isReady = true;
      state.isActiveRecordingReady = true;
      recordingSubject$.next({ ...state });
    }
  }
};
export default recordingState$;
