
import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useUserInfo } from "../../utils/useUserInfo";

const PaidMain = ({ currentUser, handleClose }) => {
    return (
        <Box>
            <h3 sx={{ fontSize: '18px' }}>Congrats, {currentUser.name}! You are now subscribed to Notemad Plus.</h3>
            <Box>
                <Typography sx={{ fontSize: '14px' }}>
                    Now, enjoy the following benefits.
                </Typography>
                <Stack direction="column" spacing={"6px"} sx={{ marginTop: '20px' }}>
                    <Box>
                        ✅ <span style={{ fontWeight: '700' }}>Unlimited</span> documents
                    </Box>
                    <Box item xs={6} sx={{ fontSize: '14px' }}>
                        ✅ 3-month money-back guarantee
                    </Box>
                    <Box>
                        ✅ Early access to new features
                    </Box>
                    <Box>
                        ✅ Invitation to the Plus exclusive channel
                    </Box>
                </Stack>
            </Box>
            <Box sx={{ marginTop: '24px' }}>
                <Button variant="contained" sx={{ textTransform: 'none', height: '26px', borderRadius: '13px' }} onClick={handleClose}>Close</Button>
            </Box>


        </Box>
    );
};

const PaidOverlay = ({ open, handleClose }) => {
    const [currentUser, { onSaveAvatarInfo }, authUser] = useUserInfo();

    const handleDialogClose = () => {
        handleClose();
    };

    return (
        <Dialog open={open} onClose={handleDialogClose}>
            <Box sx={{ padding: '30px 60px', width: '600px' }}>
                {authUser &&
                    <PaidMain currentUser={currentUser} handleClose={handleDialogClose}></PaidMain>
                }
            </Box>
        </Dialog>
    )
};

export default PaidOverlay;