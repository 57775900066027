import { Box } from "@mui/material";
import useSideBarOffset from "../sidebar/useSideBarOffset";

const ScreenHintFrame = ({frameColor}) => {
  const { sideBarOffset } = useSideBarOffset();
  return (
    <Box
        sx={{
          position: "fixed",
          zIndex: 99999999999, // this is to render the frame above the app top bar.
          width: `calc(100% - ${sideBarOffset.leftOffset}px)`,
          height: `calc(100%)`,
          borderStyle: "solid",
          borderColor: frameColor,
          borderWidth: "5px",
          pointerEvents:"none"
        }}
      />
  );
};
export default ScreenHintFrame;
