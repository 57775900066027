// track { evets, startTime, endTime }
// Assumption:
// need to wait at start of replay (first.ts - starttime)
// need to wait at end of replay (endtime - last.ts)
// need to wait at seek (next.ts - seek time)
// Seek should reset startPlayTime (drift)
export default class EventReplayer {
    constructor() {
        this.shouldStop = false;
        this.hasReplayStarted = false;
    }

    startReplay = (trackName, track, seekTime, speed, onStarted, onStopped, onEndOfTrack, onEvent) => {
        let startWallClockTime = Date.now();
        let startTrackTime = seekTime;
        this.shouldStop = false;
        this.onStopped = onStopped;

        const playAndScheduleNext = (index) => {
            if (this.shouldStop) {
                // Player was hinted to stop. Exit
                return;
            }
            if (index >= track.events.length) {
                // reach end of track
                onEndOfTrack();
                return;
            }

            // Update
            onEvent(track.events[index]);

            // Schedule next
            let trackTimeOffset = (track.events[index].ts - startTrackTime) / speed; // /2
            let wallClockTimeOffset = Date.now() - startWallClockTime;  //
            // drift: delay of track time due to rendering
            let driftTime = wallClockTimeOffset - trackTimeOffset;
            let totalTrackTime = (track.endTime - track.startTime) / speed;
            let eventOffset = (index >= track.events.length - 1) ? (totalTrackTime - track.events[index].ts) / speed : (track.events[index + 1].ts - track.events[index].ts) / speed;
            let adjustedEventOffset = eventOffset - driftTime;
            // console.log(trackName, 'textTime', track.events[index].ts, 'adjustedEventOffset', adjustedEventOffset, 'driftTime', driftTime);

            setTimeout(() => {
                playAndScheduleNext(index + 1);
            }, Math.max(adjustedEventOffset, 0));
        };

        // Look for pos to start according to seek
        let seekIndex = 0;
        for (let i = 0; i < track.events.length; i++) {
            if (seekTime <= track.events[i].ts) {
                seekIndex = i;
                break;
            }
        }

        if (track.events[seekIndex]) {

            onStarted();
            // replayAwareness.startReplay(curRecording.recordingId, "#007A00");
            this.hasReplayStarted = true;
            if (seekIndex > 0) {
                onEvent(track.events[seekIndex - 1]);
            }
            setTimeout(() => {
                playAndScheduleNext(seekIndex);
            }, (track.events[seekIndex].ts - startTrackTime) / speed);
        }
    };


    signalStop = () => {
        if (this.hasReplayStarted) {
            this.onStopped();
        }
        this.shouldStop = true;
    };
};
