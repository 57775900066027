import React from "react";
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

function AccountMediaCallback({ user, callback, signOut }) {
    React.useEffect(() => {
        callback(user.username);
    }, []);

    return (
        <div>
            <main>
                <h1>Hello {user.username}</h1>
                <h3>Email: {user.attributes.email}</h3>
                <button onClick={signOut}>Sign out</button>
            </main>
        </div>
    );
}

export default function SignInBox({ callback }) {

    // https://ui.docs.amplify.aws/components/authenticator
    return (
        <div >
            <Authenticator loginMechanisms={['email']} signUpAttributes={['email']} handleAuthStateChange hideShowPassword>
                {({ signOut, user }) => (
                    <AccountMediaCallback user={user} callback={callback} signOut={signOut}></AccountMediaCallback>
                )}
            </Authenticator>
        </div>
    );
}
