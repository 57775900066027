import React from "react";
import { useState, useEffect } from "react";
import { BehaviorSubject, take, last, debounce, interval } from "rxjs";

const screenFollowStream = new BehaviorSubject();
const scrollToStream = new BehaviorSubject();

const defaultFrameColor = "#7C6FFF";
const screenFollowInitialState = {
  requestorId: null, // the uniqueId of whatever compoent initiated a screen follow request. This helps components differentiate each other.
  isFollowing: false,
  frameColor: defaultFrameColor,
  scroll: { x: 0, y: 0 },
};
let state = screenFollowInitialState;

const result = scrollToStream.pipe(
  debounce(i => interval(200))
);
result.subscribe(scroll => {
  // Only scroll if is still following! Otherwise UI will be flaky
  if (scroll && state.isFollowing) {
    window.scrollTo({
      left: scroll.x,
      top: scroll.y,
      behavior: "smooth"
    });
  }
});

const screenFollowingState$ = {
  init: () => {
    state = { ...screenFollowInitialState };
    screenFollowStream.next(state);
  },
  subscribe: (setFollowState) => {
    return screenFollowStream.subscribe(setFollowState);
  },
  notifyStartScreenFollowing: (requestorId, frameColor) => {
    state = {
      ...state,
      requestorId: requestorId,
      isFollowing: true,
      frameColor: frameColor,
    };
    screenFollowStream.next(state);
  },
  notifyStopScreenFollowing: () => {
    state = screenFollowInitialState;
    screenFollowStream.next(state);
  },
  notifyScrollChange: (scroll, debounced) => {
    if (debounced) {
      //TODO somewat a hack: debounced is used for live cursor synching where the sample rate is low and will cause the scrolling and live cursor giggling
      scrollToStream.next(scroll);
    } else {
      window.scrollTo({
        left: scroll.x,
        top: scroll.y
      });
    }

    // 
  },
  getCurrentRequestorId: () => {
    return state.requestorId;
  },
};

const useScreenFollower = () => {
  const [screenFollowState, setScreenFollowState] = useState(
    screenFollowInitialState
  );
  React.useEffect(() => {
    let followSub = screenFollowingState$.subscribe(setScreenFollowState);
    screenFollowingState$.init();
    return () => {
      followSub.unsubscribe();
    };
  }, []);

  const startScreenFollowing = (
    requestorId,
    frameColor = defaultFrameColor
  ) => {
    const currentRequestorId = screenFollowingState$.getCurrentRequestorId();
    if (requestorId !== currentRequestorId) {
      screenFollowingState$.notifyStopScreenFollowing(requestorId, frameColor);
    }
    screenFollowingState$.notifyStartScreenFollowing(requestorId, frameColor);
  };

  const stopScreenFollowing = () => {
    screenFollowingState$.notifyStopScreenFollowing();
  };

  const updateScreenScroll = (scroll, debounced = false) => {
    screenFollowingState$.notifyScrollChange(scroll, debounced);
  };

  return {
    screenFollowState,
    startScreenFollowing,
    stopScreenFollowing,
    updateScreenScroll,
  };
};
export default useScreenFollower;
