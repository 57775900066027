import { useState, useEffect } from "react";
import useScreenFollower from "./useScreenFollower";
import { Box } from "@mui/material";
import ScreenHintFrame from "./ScreenHintFrame";
import useLiveMessageRecordingController from "../liveMessage/useLiveMessageRecordingController";
const drawerWidth = 240;
const ScreenFollowHintFrame = () => {
  const { screenFollowState } = useScreenFollower();
  const { recordingState } = useLiveMessageRecordingController();

  const showRecordFrame =
    recordingState.isRecording ||
    (recordingState.recordings && recordingState.recordings.length > 0);
  return (
    (screenFollowState.isFollowing || showRecordFrame) && (
      <Box
        sx={{
          position: "fixed",
          zIndex: 99999999999, // this is to render the frame above the app top bar.
          width: `calc(100% - ${drawerWidth}px)`,
          height: `calc(100%)`,
          pointerEvents: "none",
        }}
      >
        <ScreenHintFrame
          frameColor={
            showRecordFrame ? "#ED6C4B" : screenFollowState.frameColor
          }
        />
        {/* {recordingState.isRecording && <Box
          sx={{
            display:'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: "absolute",
            top: 0,
            right: `50%`,
            fontSize: "18px",
            color: "white",
            width:"110px",
            background: "#ED6C4B",
            borderRadius: "8px",
            padding: "8px",
            marginTop: "10px",
          }}
        >
          Recording
        </Box>} */}
      </Box>
    )
  );
};
export default ScreenFollowHintFrame;
