import Cursor from "./Cursor";

let lastPosition = null;
const BlockCursor = (props) => {
    const { blockId, relativeX, relativeY } = props;


    // Find absolute block coords
    const findCoordsByBlock = (blockId) => {
        const elements = document.querySelectorAll(`[data-id="${blockId}"]`);
        if (elements.length > 0) {
            let element = elements[0];
            let rect = element.getBoundingClientRect();
            return { x: rect.left + window.scrollX, y: rect.top + window.scrollY };
        }
        return null;
    }

    const coords = findCoordsByBlock(blockId);
    if (coords) {
        lastPosition = { x: coords.x + relativeX, y: coords.y + relativeY };
    }

    return coords ? (
        <Cursor
            x={coords.x + relativeX}
            y={coords.y + relativeY}
            {...props}
        />
    ) : (lastPosition ?
        <Cursor
            x={lastPosition.x}
            y={lastPosition.y}
            {...props}
        /> :
        <></>);
};

export default BlockCursor;