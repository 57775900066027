export class TooManyRequestError extends Error {
    constructor(args){
        super(args);
        this.name = "TooManyRequestError"
    }
}

export class BadRequestError extends Error {
    constructor(args){
        super(args);
        this.name = "BadRequestError"
    }
}

export class ForbiddenError extends Error {
    constructor(args){
        super(args);
        this.name = "ForbiddenError"
    }
}

export class UnexpectedError extends Error {
    constructor(args){
        super(args);
        this.name = "UnexpectedError"
    }
}