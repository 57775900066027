import { ClientSideSuspense } from "@liveblocks/react";
import { RoomProvider } from "../liveblocks.config.js";
import DocumentMain from "./DocumentMain";
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';

const Loading = () => {
  return (
    <Stack sx={{ minHeight: '100vh', width: '100%' }} direction="row" alignItems="center" justifyContent="center">
      <CircularProgress size={60} />
    </Stack>
  );
};

/**
 * A proxy for liveblock rooms per document
 */
const DocumentRoom = ({ documents, documentId, onDocumentChangeCallback, isSharedView=false }) => {
  return (
    <RoomProvider id={documentId ? `document.${documentId}` : 'default-room-noop'}
      initialPresence={{
        cursor: null,
      }}>
      <ClientSideSuspense fallback={<Loading />}>
        {() => <DocumentMain documents={documents} documentId={documentId} onDocumentChangeCallback={onDocumentChangeCallback} isSharedView={isSharedView} />}
      </ClientSideSuspense>
    </RoomProvider>
  );
};


export default DocumentRoom;