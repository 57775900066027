import React from "react";
import useLiveMessageReplay from "./useLiveMessageReplay";

let player = null;
const LiveMessageAudioReplayer = () => {
    const [replayState] = useLiveMessageReplay();

    // Handle audio replay 
    React.useEffect(() => {
        if (replayState.activeRecording && !replayState.activeRecordingPaused) {
            player = new Audio(replayState.activeRecording.audio.audioUrl);
            // console.log('audioplay', replayState.activeRecording.audio.audioUrl);
            player.currentTime = replayState.activeRecordingSeekTime / 1000;
            player.playbackRate = replayState.speed;
            player.muted = replayState.audioMuteEnabled;
            player.play();
        }

        return () => {
            if (player) {
                // console.log('audioplay pause');
                player.pause();
                player = null;
            }
        };
    }, [replayState.activeRecording, replayState.activeRecordingPaused, replayState.activeRecordingSeekTime, replayState.speed]);

    // Handle audio mute
    React.useEffect(() => {
        if (player) {
            player.muted = replayState.audioMuteEnabled;
        }
    }, [replayState.audioMuteEnabled]);

    return (
        <>
        </>
    );
};

export default LiveMessageAudioReplayer;