import { Extension } from "@tiptap/core";
import { Plugin, PluginKey } from "prosemirror-state";
import textCursorBuffer from "../../../singleton/selectionBuffer";
import { selectionToRelativeBlockPos } from "./helpers/RecordingUtils";
/**
 * Add a trailing node to the document so the user can always click at the bottom of the document and start typing
 */
const RecordingExtension = Extension.create({
  name: "recording",

  addProseMirrorPlugins() {
    const plugin = new PluginKey(this.name);

    return [
      new Plugin({
        key: plugin,
        // view() {
        //     return {
        //       update: async (view, _prevState) => {
        //         console.log('view.state.selection', view.state.selection);
        //       },
        //     };
        //   },
        appendTransaction: (_transactions, oldState, newState) => {
          //console.log('appendTransaction oldState', oldState.selection);
          //console.log('appendTransaction newState', newState.selection);
          if (oldState.selection !== newState.selection) {
            // log new selection movements
            // console.log('moved anchor', newState.selection.$anchor.pos);
            // console.log('moved head', newState.selection.$head.pos);

            // TODO: Can pass selectionBuffer in options instead 
            let update = selectionToRelativeBlockPos(newState.selection);
            textCursorBuffer.notifyUpdate(update);
          }
          return null;
        },
      }),
    ];
  },
});

export default RecordingExtension;