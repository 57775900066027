import { Extension } from "@tiptap/core";
import { DebugPlugin, shouldAdd } from "./DebugPlugin";

/**
 * The menu that is displayed when selecting a piece of text.
 */
export const DebugExtension = Extension.create<{}>({
  name: "DebugExtension",

  addProseMirrorPlugins() {
    return [
      DebugPlugin({}),
    ];
  },
  addKeyboardShortcuts() {
    return {
      // Enable debug overlay
      "Mod-Shift-o": () => { shouldAdd[0] = true; return false; }
    };
  },
});

export default DebugExtension;