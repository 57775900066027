import Tippy from "@tippyjs/react";
import { TextSelection } from "prosemirror-state";
import { EditorView } from "prosemirror-view";
import { useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { findBlock } from "../../Blocks/helpers/findBlock";
import { SlashMenuPluginKey } from "../../SlashMenu/SlashMenuExtension";
import styles from "./DragHandle.module.css";
import DragHandleMenu from "./DragHandleMenu";
import { Box, Stack, Button, Divider, Tooltip, IconButton } from "@mui/material";
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import DragIndicatorOutlinedIcon from '@mui/icons-material/DragIndicatorOutlined';
import HeadphonesRoundedIcon from '@mui/icons-material/HeadphonesRounded';
import CardTooltip from "../../../../utils/CardTooltip"
import { getTimeString } from "../../../../utils/ComputeUtil";
import { onPlayLinkedRecording } from "../../../../components/recording/useLiveMessageReplay";
import { selectLiveMessage } from "../../../../components/liveMessage/useLiveMessageViewModel";
import { trackDocumentEvent } from "../../../../utils/AmplitudeTrack";


export const DragHandle = (props: {
  view: EditorView;
  coords: { left: number; top: number };
  embeddings: Array<{ embeddingId: string, accountId: string, createdAt: number, startTs: number, endTs: number }>;
  onShow?: () => void;
  onHide?: () => void;
  onAddClicked?: () => void;
}) => {
  const [clicked, setClicked] = useState<boolean>(false);
  const [deleted, setDeleted] = useState<boolean>(false);

  const onDelete = () => {
    const pos = props.view.posAtCoords(props.coords);
    if (!pos) {
      return;
    }
    const currentBlock = findBlock(
      TextSelection.create(props.view.state.doc, pos.pos)
    );
    const doDelete = (pos, nodeSize) => {
      if (props.view.dispatch) {
        console.log('doDelete', pos, pos + nodeSize)
        props.view.dispatch(
          props.view.state.tr.deleteRange(
            pos,
            pos + nodeSize
          )
        );
      }
      setDeleted(true);
    };

    if (currentBlock) {
      doDelete(currentBlock.pos, currentBlock.node.nodeSize);
    } else {
      // Couldn't find owlblock. Try deal with atom block (image, live message block)
      let resolved = props.view.state.doc.resolve(pos.inside);
      let atomBlockNode = resolved.nodeAfter;
      if (atomBlockNode) {
        // Use pos.inside here because pos.pos could point to end of the block
        doDelete(pos.inside, atomBlockNode.nodeSize);
      }
    }
  };

  const onAddClick = () => {
    setClicked(true);
    if (props.onAddClicked) {
      props.onAddClicked();
    }
    const pos = props.view.posAtCoords(props.coords);
    if (!pos) {
      return;
    }
    let currentBlock = findBlock(
      TextSelection.create(props.view.state.doc, pos.pos)
    );

    const doInsertContent = (pos, nodeSize) => {
      const endOfBlock = pos + nodeSize;
      let newBlock = props.view.state.schema.nodes["content"].createAndFill()!;
      props.view.state.tr.insert(endOfBlock, newBlock);
      props.view.dispatch(props.view.state.tr.insert(endOfBlock, newBlock));
      props.view.dispatch(
        props.view.state.tr.setSelection(
          new TextSelection(props.view.state.tr.doc.resolve(endOfBlock + 1))
        )
      );
    };

    if (!currentBlock) {
      // Couldn't find owlblock. Try deal with atom block (image, live message block)
      let resolved = props.view.state.doc.resolve(pos.inside);
      let atomBlockNode = resolved.nodeAfter;
      if (atomBlockNode) {
        doInsertContent(pos.inside, atomBlockNode.nodeSize);
      } else {
        // Nothing to do
        return;
      }
    } else {
      // Deal with owlblock
      // If current blocks content is empty dont create a new block
      if (currentBlock.node.firstChild?.textContent.length !== 0) {
        // Create new block after current block
        doInsertContent(currentBlock.pos, currentBlock.node.nodeSize);
      }
    }

    // Focus and activate slash menu
    props.view.focus();
    props.view.dispatch(
      props.view.state.tr.scrollIntoView().setMeta(SlashMenuPluginKey, {
        // TODO import suggestion plugin key
        activate: true,
        type: "drag",
      })
    );
  };

  if (deleted || clicked) {
    return null;
  }

  const onClickRecording = (embedding) => {
    console.log('replay embedding', embedding)
    trackDocumentEvent('play linked recording')
    selectLiveMessage(embedding);
    onPlayLinkedRecording(embedding);
  }
  // const [replayState, { onHandleSeekToTarget, onHandleReplayingStart, onPlayLiveMessage, onHandleReplayingResumed }] = useLiveMessageReplay();
  // const {
  //   selectedLiveMessage,
  //   selectLiveMessage,
  // } = useLiveMessageViewModel();
  return (
    <Stack direction={'row'} style={{ display: "flex", alignItems: "center", opacity: 0.8 }}>
      {props.embeddings?.length > 0 &&
        <CardTooltip placement="bottom" leaveDelay={50}
          title={<Stack direction="column" sx={{ paddingTop: "12px", paddingBottom: "16px" }}      >
            <Box
              sx={{
                marginLeft: "16px",
                marginRight: "16px",
                marginBottom: "8px",
                color: "#aaa",
                fontWeight: "medium",
                fontSize: "16px",
                display: "flex",
                alignItems: "center",
              }}
            >
              Linked recordings
            </Box>
            {props.embeddings?.map(embedding => <Stack key={embedding.embeddingId}
              onClick={() => { onClickRecording(embedding); }}
              sx={{
                paddingLeft: "16px",
                paddingRight: "16px",
                paddingTop: "8px",
                paddingBottom: "8px",
                cursor: "pointer",
                // color: highlightCurrentSentence && isCurrentSentence ? "#7C6FFF" : "#000",
                "&:hover": {
                  background: "#eee",
                },
              }}>
              <Stack
                direction="row"
                sx={{
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box sx={{ fontWeight: "bold", fontSize: "14px" }}>
                  Untitled
                </Box>
                <Box
                  sx={{
                    color: "#999",
                    marginLeft: "8px",
                    fontSize: "14px",
                  }}
                >
                  {getTimeString(embedding.createdAt, "mmmm d @ HH:M")}
                </Box>

              </Stack>

              <Box sx={{
                color: "#999",
                fontSize: "14px",
              }}>
                {getTimeString(embedding.startTs)} to {getTimeString(embedding.endTs)}
              </Box>
            </Stack>)}
          </Stack>}>
          <IconButton size='small' onClick={() => { onClickRecording(props.embeddings[0]); }}
          >
            <HeadphonesRoundedIcon />
          </IconButton>
        </CardTooltip>}
      <Tooltip title="Add" placement="top">
        <IconButton size='small' onClick={onAddClick}>
          <AddRoundedIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Drag" placement="top">
        <IconButton size='small'>
          <DragIndicatorOutlinedIcon sx={{ cursor: "grab" }} />
        </IconButton>
      </Tooltip>
    </Stack>
  );
};
