import { useCommentData } from "./CommentViewModel";
import React, { useEffect, useState, useRef } from "react";
import {
  List,
  Box,
  IconButton,
  Stack,
  Paper,
  Divider,
  Tooltip,
  Typography,
  Button,
} from "@mui/material";
import { v4 } from "uuid";
import useLiveMessageViewModel from "../liveMessage/useLiveMessageViewModel";
import ChatBubbleOutlineRoundedIcon from "@mui/icons-material/ChatBubbleOutlineRounded";
import { CommentInputView } from "./CommentInputView";
import { getTimeString } from "../../utils/ComputeUtil";
import { CommentThread } from "./comment";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import useLiveMessageRecordingController from "../liveMessage/useLiveMessageRecordingController";
import Modal from "@mui/material/Modal";
import InLinePlaybackBlock from "../liveMessage/InLinePlaybackBlock";
import { trackDocumentEvent } from "../../utils/AmplitudeTrack";
import { getUserInfo } from "../../utils/useUserInfo";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
let commentTextTmpCache = null;
const CommentView = ({ editor, isDocumentReady }) => {
  // console.log('[CommentView] refreshed', editor.view.state.doc);
  const userInfo = getUserInfo();
  const {
    allCommentThreads,
    clearUnfinishedComment,
    removeComment,
    markActiveThreadAsRead,
  } = useCommentData();
  const [activeCommentThread, setActiveCommentThread] = useState(null);
  const {
    recordingState,
    liveMessageUpdateStream,
    closeRecording,
    onStartToRecord,
    onSaveRecordingToCloud,
    onChangeLanguageType,
  } = useLiveMessageRecordingController();
  const [isRecordingLm, setIsRecordingLm] = useState(
    recordingState.isRecording ||
    (recordingState.recordings && recordingState.recordings.length > 0)
  );
  const [lmUpdate, setLmUpdate] = useState(null);
  const [attachedLm, setAttachedLm] = useState(null);

  const commentText = useRef();

  // this is to temporarily save comment when recording since the input view will remove commenttext automatically when disabled.
  if (commentText?.current?.value && isRecordingLm) {
    commentTextTmpCache = commentText?.current?.value;
    // console.log('bamboo commentTextTmpCache', commentTextTmpCache)
  } else if (commentTextTmpCache && commentText?.current) {
    // console.log('bamboo restore to', commentTextTmpCache)
    commentText.current.value = commentTextTmpCache;
    commentTextTmpCache = null;
  } else {
    // console.log('bamboo strange', commentTextTmpCache, commentText?.current, lmUpdate)
  }
  const { commitCurrentComment } = useCommentData();
  const [tempLmId, setTempLmId] = useState(v4());
  const { selectedLiveMessage, selectLiveMessage } = useLiveMessageViewModel();

  useEffect(() => {
    setIsRecordingLm((isRecordingLm) => {
      const newVal =
        recordingState.isRecording ||
        (recordingState.recordings && recordingState.recordings.length > 0);
      if (isRecordingLm && !newVal && activeCommentThread && editor) {
        // from recording to stop
        autoSelectComment(editor, activeCommentThread.docPos, activeCommentThread.isNode);
      }
      return newVal;
    });
    if (lmUpdate) {
      if (lmUpdate.liveMessage && lmUpdate.liveMessage.liveMessageId) {
        // No longer recording live message. Updating this so that comment view popup will work again.
        setLmUpdate(null);
      }

      if (lmUpdate.requestor.liveMessageId === tempLmId) {
        if (lmUpdate.liveMessage && lmUpdate.liveMessage.liveMessageId) {
          setAttachedLm(lmUpdate.liveMessage);
        } else if (lmUpdate.isUploading) {
          setAttachedLm({ isUploading: true });
        }
      }
    }
  }, [recordingState, lmUpdate, activeCommentThread, editor]);
  const [miniCommentList, setMiniCommentList] = useState(null);
  const [openDiscardWarning, setOpenDiscardWarning] = React.useState(false);
  const handleClose = () => {
    setOpenDiscardWarning(false);
  };
  const onSelectionUpdate = (
    editor,
    allCommentThreads,
    isRecordingLm,
    attachedLm,
    lmUpdate
  ) => {
    // when recording an lm comment, the comment state is locked
    if (
      isRecordingLm ||
      (lmUpdate && lmUpdate.requestor.liveMessageId !== tempLmId)
    ) {
      return;
    }
    if (editor?.isActive(CommentThread.name)) {
      // console.log('onSelectionUpdate', editor.state.selection)
      const selectedThread = JSON.parse(
        editor.getAttributes(CommentThread.name).comment
      );
      setActiveCommentThread(
        allCommentThreads.find(
          (thread) => thread.data[0].uuid === selectedThread.uuid
        )
      );
    } else if (
      (commentText &&
        commentText.current &&
        commentText.current.value.length > 0) ||
      (attachedLm && attachedLm.liveMessageId) ||
      (lmUpdate && lmUpdate.isUploading)
    ) {
      setOpenDiscardWarning(true);
    } else {
      setActiveCommentThread((activeThread) => {
        if (activeThread) {
          markActiveThreadAsRead(editor, activeThread.data[0]);
          clearUnfinishedComment(editor);
        }
        return null;
      });
      // think about how to invalidate attachedLm
      // continue to work on UI
    }
  };

  useEffect(() => {
    if (editor && editor?.view?.docView && isDocumentReady && allCommentThreads) {
      const update = (e) => {
        onSelectionUpdate(
          editor,
          allCommentThreads,
          isRecordingLm,
          attachedLm,
          lmUpdate
        );
      };
      onSelectionUpdate(
        editor,
        allCommentThreads,
        isRecordingLm,
        attachedLm,
        lmUpdate
      );
      // HACK HACK HACK TODO: Somehow for image block selection, if concurrent update, selection will be updated.
      // Haven't figured out why, but current behavior is the "do you want to discard view" will show if it happens.
      editor.on("selectionUpdate", update);

      // compute minicomment list
      // TODO make it better, for now we only show one mini comment icon for multiple same line comments
      const uniqueTops = allCommentThreads
        .map(
          (commentThread) => editor.view.coordsAtPos(commentThread.docPos).top
        )
        .filter((v, i, a) => a.indexOf(v) === i);
      setMiniCommentList(
        allCommentThreads.filter((commentThread) => {
          const top = editor.view.coordsAtPos(commentThread.docPos).top;
          const index = uniqueTops.indexOf(top);
          if (index > -1) {
            uniqueTops.splice(index, 1);
            return true;
          }
          return false;
        })
      );
      return () => {
        editor.off("selectionUpdate", update);
      };
    }
  }, [editor, isDocumentReady, allCommentThreads, isRecordingLm, attachedLm, lmUpdate]);

  const autoSelectComment = (editor, pos, isNode) => {
    console.log("auto select", pos, isNode);
    if (isNode) {
      editor
      .chain()
      .setNodeSelection(pos)
      .run();
    } else {
      editor
      .chain()
      .setTextSelection(pos + 1)
      .run();
    }
  };

  const onDeleteComment = (commentId) => {
    removeComment(editor, commentId);
    // setActiveCommentThread(null);
  };

  const onCommentButtonClick = (attachedLm) => {
    console.log('onCommentButtonClick', attachedLm);
    commitCurrentComment(editor, String(commentText.current.value), attachedLm);
    // setCommentText("");
    if (commentText) {
      commentText.current.value = "";
    }
    setAttachedLm(null);
  };

  const onRecordLmButtonClick = () => {
    trackDocumentEvent("click record lm button in comment view");
    selectLiveMessage({ liveMessageId: tempLmId, isRecordingBlock: true });
    onStartToRecord();
  };

  const onRemoveLmButtonClick = () => {
    trackDocumentEvent("click remove lm button in comment view");
    setAttachedLm(null);
  };

  useEffect(() => {
    let sub = liveMessageUpdateStream.subscribe(setLmUpdate);
    return () => {
      sub.unsubscribe();
    };
  }, []);

  return (
    miniCommentList && isDocumentReady && (
      <Box
        sx={{
          display: "flex",
        }}
      >
        <Modal
          open={openDiscardWarning}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modalStyle}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Do you want to discard the comment?
            </Typography>
            <Stack sx={{ marginTop: "8px" }}>
              <Button
                onClick={() => {
                  // reset comment text on discard
                  commentText.current.value = "";
                  setAttachedLm((attachedLm) => {
                    onSelectionUpdate(
                      editor,
                      allCommentThreads,
                      isRecordingLm,
                      null
                    );
                    return null;
                  });
                  handleClose();
                }}
                variant="contained"
                disableElevation
                sx={{
                  background: "#F95959",
                  textTransform: "none",
                  "&:hover": {
                    background: "#F95959",
                    opacity: 0.9,
                  },
                }}
              >
                Discard
              </Button>
              <Button
                onClick={() => {
                  // console.log('lol discard', activeCommentThread)
                  autoSelectComment(editor, activeCommentThread.docPos, activeCommentThread.isNode);
                  handleClose();
                }}
                variant="outlined"
                sx={{ textTransform: "none", marginTop: "8px" }}
              >
                Cancel
              </Button>
            </Stack>
          </Box>
        </Modal>
        <Box
          sx={{
            height: "100%",
            width: `calc(50% - ${850 / 2}px)`,
            position: "absolute",
            right: 0,
            top: 0,
          }}
        >
          {(!isRecordingLm || lmUpdate?.isUploading) &&
            miniCommentList.map((commentThread) => {
              const showUnread =
                commentThread.data[0].followers?.indexOf(userInfo.userId) >
                -1 &&
                commentThread.data[0].comments.filter(
                  (comment) => comment.readers?.indexOf(userInfo.userId) == -1
                )?.length > 0;
              // console.log("showUnread", showUnread.valueOf, commentThread, commentThread.data[0].followers?.indexOf(userInfo.userId))
              return (
                <Stack
                  sx={{
                    position: "absolute",
                    top:
                      commentThread.coords.top +
                      commentThread.scrollY -
                      10,
                    left: -30,
                    opacity: "0.8",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  key={commentThread.data[0].uuid}
                  direction="row"
                >
                  <IconButton
                    onClick={() => {
                      autoSelectComment(editor, commentThread.docPos, commentThread.isNode);
                    }}
                    sx={{ color: showUnread ? "#F8d648" : "#666" }}
                  >
                    <ChatBubbleOutlineRoundedIcon />
                  </IconButton>
                  <Box
                    sx={{
                      fontWeight: showUnread ? "bold" : "regular",
                      color: showUnread ? "#F8d648" : "#666",
                    }}
                  >
                    {commentThread.data[0].comments.length}
                  </Box>
                </Stack>
              );
            })}
        </Box>

        {(!isRecordingLm || lmUpdate?.isUploading) && activeCommentThread && (
          <Paper
            elevation={2}
            sx={{
              zIndex: 10000,
              position: "absolute",
              top:
                activeCommentThread.coords.top +
                activeCommentThread.scrollY -
                10,
              right: `max(calc(50% - ${850 / 2}px - 280px), 10px)`,
              // left:0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Stack direction="column" sx={{ width: "300px" }}>
              {activeCommentThread.data[0].comments
                .filter(
                  (comment) =>
                    comment.content.length > 0 || comment.liveMessageId
                )
                .map((comment, idx, array) => {
                  // console.log('comment', comment)
                  const showUnread =
                    activeCommentThread.data[0].followers?.indexOf(
                      userInfo.userId
                    ) > -1 && comment.readers?.indexOf(userInfo.userId) === -1;
                  return (
                    <Stack direction="column" key={comment.commentId} sx={{
                      background: showUnread ? "#FEF9E5" : "white"
                    }}>
                      <Stack direction="row" justifyContent="space-between" sx={{
                        paddingLeft: "14px",
                        paddingRight: "7px",
                      }}>
                        <Stack
                          direction="column"
                          sx={{
                            paddingBottom: idx == array.length - 1 ? "0px" : "8px",
                            paddingTop: idx == 0 ? "8px" : "8px",
                          }}
                        >
                          <Stack
                            direction="row"
                            sx={{
                              position: "relative",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Box sx={{ fontWeight: "bold", fontSize: "14px" }}>
                              {comment.userName}
                            </Box>
                            <Box
                              sx={{
                                color: "#999",
                                marginLeft: "8px",
                                fontSize: "14px",
                              }}
                            >
                              {getTimeString(comment.time, "mmmm d")}
                            </Box>

                          </Stack>
                          <Stack>
                            <Box sx={{ fontSize: "14px" }}>{comment.content}</Box>
                            {comment.liveMessageId && (
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  backgroundColor: "#F5F5F5",
                                  minHeight: "30px",
                                  minWidth: "80px",
                                  maxWidth: "90px",
                                  marginTop: "2px",
                                  marginBottom: "2px",
                                  borderRadius: "20px",
                                  borderStyle: "solid",
                                  borderColor: "#ddd",
                                  borderWidth: "1px",
                                }}
                              >
                                <InLinePlaybackBlock
                                  selected={true}
                                  lmId={comment.liveMessageId}
                                  lmDuration={
                                    comment.lmDuration ? comment.lmDuration : 0
                                  }
                                />
                              </Box>
                            )}
                          </Stack>
                        </Stack>
                        <Stack alignItems="center" justifyContent="flex-start">
                          <IconButton
                            key={"deleteComment-" + comment.commentId}
                            sx={{
                              marginTop: '2px',
                              color: "#ccc",
                              "&:hover": { color: "#000" },
                            }}
                            onClick={() => {
                              onDeleteComment(comment.commentId);
                            }}
                          >
                            <DeleteOutlineRoundedIcon />
                          </IconButton>
                        </Stack>
                      </Stack>

                      {idx == array.length - 1 && (
                        <Divider sx={{ marginTop: "16px" }} />
                      )}
                    </Stack>
                  );
                })}
              <CommentInputView
                commentText={commentText}
                attachedLm={attachedLm}
                autoFocus={
                  activeCommentThread.data[0].comments.length == 1 &&
                  activeCommentThread.data[0].comments[0].content.length ===
                  0 &&
                  !activeCommentThread.data[0].comments[0].liveMessageId
                }
                onCommentButtonClick={() => onCommentButtonClick(attachedLm)}
                onRecordLmButtonClick={onRecordLmButtonClick}
                onRemoveLmButtonClick={onRemoveLmButtonClick}
              />
            </Stack>
          </Paper>
        )}
      </Box >
    )
  );
};

export default CommentView;
