import React, { useEffect } from "react";
import { encodePresence } from "../../utils/ComputeUtil";
import { useBoundingClientRectRef } from "../../utils/useBoundingClientRectRef";
import recordingState$ from "../../streams/recordingState";
import { lastMoustCursorEvent$ } from "../../utils/useLastMouseCursorEvent";
import { useUpdateMyPresence } from "../../liveblocks.config";
import { blockMouseCursorState$ } from '../../blocknode/extensions/Blocks/BlockMouseCursorPlugin';

const LiveCursorRecorder = ({ cursorPanel }) => {
    /**
     * useMyPresence returns a function to update  the current user's presence.
     * updateMyPresence is different to the setState function returned by the useState hook from React.
     * You don't need to pass the full presence object to update it.
     * See https://liveblocks.io/docs/api-reference/liveblocks-react#useUpdateMyPresence for more information
     */
    const updateMyPresence = useUpdateMyPresence();
    const [recordingState, setRecordingState] = React.useState({});

    const rectRef = useBoundingClientRectRef(cursorPanel);

    // handle recording stuff
    useEffect(() => {
        let recordingSubscription = recordingState$.subscribe(setRecordingState);
        recordingState$.init();
        return () => {
            recordingSubscription.unsubscribe();
        };
    }, []);

    useEffect(() => {
        if (!(cursorPanel?.current instanceof HTMLElement)) {
            console.warn(
                'Pass `ref` containing HTMLElement to `<LiveCursors scrollRef=""`.'
            );
            return;
        }

        const events = [];
        const eventsV2 = [];
        const startTime = Date.now();
        const recordingId = recordingState.curRecordingId;

        let scroll = {
            x: window.scrollX,
            y: window.scrollY,
        };



        let lastPosition: { x: number, y: number } | null = null;
        let lastMoveEvent: { x: number, y: number } | null = null;
        const onPresence = (encoded) => {
            updateMyPresence({
                cursor: {
                    x: encoded.x,
                    y: encoded.y,
                },
            });
            if (recordingState.isRecording) {
                events.push({
                    cursor: {
                        x: encoded.x,
                        y: encoded.y,
                    },
                    ts: Date.now() - startTime,
                });
            }
        }

        const onPresenceV2 = (clientX, clientY) => {
            let state = blockMouseCursorState$.getState();
            let rect = null;

            // Find rect of particular block
            if (state && state.block) {
                const elements = document.querySelectorAll(`[data-id="${state.block}"]`);
                if (elements.length > 0) {
                    let element = elements[0];
                    rect = element.getBoundingClientRect();
                }
            }

            if (rect) {
                eventsV2.push({
                    cursor: {
                        blockId: state.block,
                        x: Math.round(clientX - rect.left),
                        y: Math.round(clientY - rect.top),
                    },
                    ts: Date.now() - startTime,
                });
            }
        };
        // If cursorPanel, add live cursor listeners
        const updateCursor = (event) => {
            if (!cursorPanel?.current) {
                return;
            }

            // (Viewport position) - (element position) + (element scroll amount)
            const x = event.clientX - rectRef.current.x + scroll.x;
            const y = event.clientY - cursorPanel.current.offsetTop + scroll.y;

            lastPosition = { x, y };
            let encoded = encodePresence(
                { x: Math.round(x), y: Math.round(y) },
                scroll
            );
            onPresence(encoded);

            // Update event v2
            lastMoveEvent = { x: event.clientX, y: event.clientY };
            onPresenceV2(lastMoveEvent.x, lastMoveEvent.y);
        };

        // Not a hack! By design we want to initalize the mouse cursor position properly.
        if (recordingState.isRecording) {
            if (events.length === 0) {
                updateCursor(lastMoustCursorEvent$.value);
            }
        }

        const onDocumentScroll = () => {
            if (lastPosition) {
                const offsetX = window.scrollX - scroll.x;
                const offsetY = window.scrollY - scroll.y;
                // console.log('onscroll:',window.scrollY, 'offset',offsetY)
                const position = {
                    x: lastPosition.x + offsetX,
                    y: lastPosition.y + offsetY,
                };
                lastPosition = position;
                let encoded = encodePresence(
                    { x: lastPosition.x, y: lastPosition.y },
                    { x: window.scrollX, y: window.scrollY }
                );

                onPresence(encoded);

                // Update event v2
                const moveEvent = {
                    x: lastMoveEvent.x,
                    y: lastMoveEvent.y
                };
                lastMoveEvent = { x: moveEvent.x, y: moveEvent.y };
                onPresenceV2(lastMoveEvent.x, lastMoveEvent.y);

                scroll.x = window.scrollX;
                scroll.y = window.scrollY;
            }
        }

        document.addEventListener("scroll", onDocumentScroll);
        document.addEventListener("pointermove", updateCursor);
        return () => {
            // should wrap up and upload
            if (events.length > 0) {
                recordingState$.addMouseCursorRecording(recordingId, {
                    events,
                    startTime,
                    endTime: Date.now(),
                });
            }
            if (eventsV2.length > 0) {
                recordingState$.addMouseCursorV2Recording(recordingId, {
                    events: eventsV2,
                    startTime,
                    endTime: Date.now(),
                });
            }
            document.removeEventListener("scroll", onDocumentScroll);
            document.removeEventListener("pointermove", updateCursor);
        };
    }, [updateMyPresence, cursorPanel, rectRef, recordingState.isRecording]);

    return <></>;
}

export default LiveCursorRecorder;