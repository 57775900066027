export const DEFAULT_TEAM_ID = 'default';
export const DEFAULT_ACCOUNT_ID = 'testaccount';
export const SERVER_GUEST_USER = 'guest';
export const SERVER_PUBLIC_USER = 'public';
export const REPLAY_CURSOR_COLOR = '#F5537A'; // light maroon
export const REPLAY_CURSOR_COLOR_ALT = '#800080'; // purple

// UI stuff
export const DRAWER_WIDTH = 240;
export const RIGHT_PANEL_WIDTH = 380;
export const TOP_APP_BAR_HEIGHT = 60;
export const DOCUMENT_PADDING_TOP = 20;
export const BOTTOM_APP_BAR_HEIGHT = 80;


export const AVAILABLE_TEAMS = [DEFAULT_TEAM_ID, 'notemad', 'ycdemo', 'richard', 'leon', 'catinclay', 'gemsouls', 'atlascapital', 'ggv', 'oasense', 'bobwei', 'dianahu', 'josephinelin', 'publicdemo', 'elsaho', 'joelin', 'ctlin'];
