import { useState, useEffect } from "react";
import { Avatar } from "./Avatar";
import { shallow } from "@liveblocks/react";
import { useOthersMapped } from "../../liveblocks.config";
import { AnimatePresence, motion } from "framer-motion";
import { decodePresence } from "../../utils/ComputeUtil";
import useScreenFollower from "../cursor/useScreenFollower";
import { useUserInfo } from "../../utils/useUserInfo";
import { v4 } from "uuid";
import { trackDocumentEvent } from "../../utils/AmplitudeTrack";

/**
 * This file shows how to add live avatars like you can see them at the top right of a Google Doc or a Figma file.
 *
 * The users picture and name are not set via the `useMyPresence` hook like the cursors.
 * They are set from the authentication endpoint.
 *
 * See pages/api/auth.ts and https://liveblocks.io/docs/api-reference/liveblocks-node#authorize for more information
 */

const MAX_OTHERS = 3;
const liveUserFollowScreenRequestId = v4();
const animationProps = {
  initial: { width: 0, transformOrigin: "left" },
  animate: { width: "auto", height: "auto" },
  exit: { width: 0 },
  transition: {
    type: "spring",
    damping: 15,
    mass: 1,
    stiffness: 200,
    restSpeed: 0.01,
  },
};

const avatarProps = {
  style: { marginLeft: "-0.45rem" },
  size: 42,
  outlineWidth: 3,
  outlineColor: "white",
};

export default function LiveAvatars() {
  const {
    screenFollowState,
    startScreenFollowing,
    stopScreenFollowing,
    updateScreenScroll,
  } = useScreenFollower();
  const [currentUser, { onSaveAvatarInfo }] = useUserInfo();
  const [followingUser, setFollowingUser] = useState(null);
  //
  // RATIONALE:
  // Using useOthersMapped here and only selecting/subscribing to the "info"
  // part of each user, which is static data that won't change (unlike
  // presence). In this example we don't use presence, but in a real app this
  // makes a difference: if we did not use a selector function here, these
  // avatars would get needlessly re-rendered any time any of the others moved
  // their cursors :)
  //
  const others = useOthersMapped(
    (other) => ({
      cursor: other.presence.cursor,
      info: other.info,
    }),
    shallow
  );
  // This function will trigger refresh on every cursor move!!!! const currentUser = useSelf(); ATTENTION!!!!! 
  const hasMoreUsers = others.length > MAX_OTHERS;

  const onClickAvatarCallback = (key) => {
    trackDocumentEvent("click avatar to follow")
    setFollowingUser(key);
  };

  const onCancelFollowing = () => {
    setFollowingUser(null);
  };

  useEffect(() => {
    if (followingUser) {
      for (let otherUser of others) {
        if (otherUser[0] === followingUser) {
          let followingCursor = otherUser[1].cursor;
  
          if (followingCursor) {
            let decoded = decodePresence(followingCursor);
            const scrollX = decoded.scroll.x;
            const scrollY = decoded.scroll.y;
            const cursorX = decoded.cursor.x;
            const cursorY = decoded.cursor.y;

            let cannotSee = (cursorY > (window.scrollY + window.innerHeight * 2 / 3)) || (cursorY <= (window.scrollY));
            updateScreenScroll({ x: scrollX, y: cannotSee ? (cursorY - window.innerHeight / 3) : window.scrollY }, true);
          }
          // console.log(followingCursor);
          break;
        }
      }
    }

  }, [others, followingUser]);

  useEffect(() => {
    if (followingUser) {
      for (let otherUser of others) {
        if (otherUser[0] === followingUser) {
          let followingInfo = otherUser[1].info;
          startScreenFollowing(liveUserFollowScreenRequestId, followingInfo?.colors[0]);
        }
      }
    } else {
      stopScreenFollowing();
    }
  }, [followingUser])

  useEffect(() => {
    if (!screenFollowState.isFollowing) {
      onCancelFollowing();
    }
  }, [screenFollowState.isFollowing])


  const setName = () => {
    const name = (window.prompt("Name") || "").trim().substring(0, 32);

    if (name) {
      return onSaveAvatarInfo(name);
    }
  };

  return (
    <div
      style={{
        minHeight: avatarProps.size + "px",
        display: "flex",
        paddingLeft: "0.75rem",
        overflow: "hidden",
      }}
    >
      <AnimatePresence>
        {hasMoreUsers ? (
          <motion.div key="count" {...animationProps}>
            <Avatar {...avatarProps} variant="more" count={others.length - 3} />
          </motion.div>
        ) : null}

        {others
          .slice(0, MAX_OTHERS)
          .reverse()
          .map(([key, other]) => (
            <motion.div key={key} {...animationProps}>
              <Avatar
                {...avatarProps}
                name={other.info ? other.info.name : String(key)}
                color={other.info ? [other.info.colors[0], other.info.colors[0]] : ["#FF0099", "#FF7A00"]}
                onClickCallback={() => {
                  onClickAvatarCallback(key);
                }}
              />
            </motion.div>
          ))}

        {<motion.div key="you" {...animationProps}>
          <Avatar
            {...avatarProps}
            name={currentUser.name + " (You)"}
            color={[currentUser.color, currentUser.color]}
            onClickCallback={() => {
              setName();
            }}
          />
        </motion.div>}
      </AnimatePresence>
    </div>
  );
}
