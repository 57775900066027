
import * as React from 'react';
import { Auth } from "@aws-amplify/auth";

import Dialog from '@mui/material/Dialog';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { createNewCheckoutSession, createNewBillingSession } from '../../utils/SubscriptionBackendUtils';
import { useUserInfo } from "../../utils/useUserInfo";
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import IconButton from '@mui/material/IconButton';
import { useAccountStatus } from '../../utils/SubscriptionBackendUtils';
import { Subject } from "rxjs";
import { trackDocumentEvent } from "../../utils/AmplitudeTrack";

export const paywallStream = new Subject();

const UpgradeTable = ({ accountStatus, onUpgradeClick, onDowngradeClick, onLastPage, paywallMessage }) => {
    const subscriptionStatus = accountStatus?.subscription;

    return (
        <Box>
            <IconButton
                onClick={onLastPage}
                sx={{ position: "absolute", top: "16px", left: "8px" }}
            >
                <ArrowBackIosNewRoundedIcon sx={{ fontSize: '18px' }} />
            </IconButton>
            {paywallMessage && <Box sx={{ marginTop: '18px', marginBottom: '12px' }}><Typography sx={{fontSize: '18px', fontWeight: '600'}}>{paywallMessage}</Typography></Box>}
            <Grid container spacing={2} sx={{ marginTop: '18px', marginBottom: '12px' }}>
                <Grid item xs={6}>
                    <Stack direction="column" spacing={"6px"}>
                        <Stack direction="column">
                            <Typography sx={{ fontSize: '20px' }}>Free</Typography>
                            <Typography sx={{ fontSize: '20px' }}>$0</Typography>
                        </Stack>
                        {subscriptionStatus !== 'PLUS' && <Typography sx={{ fontSize: '14px', lineHeight: '26px', }}>Current plan</Typography>}
                        {subscriptionStatus === 'PLUS' && <Button variant="outlined" sx={{ textTransform: 'none', height: '26px' }} onClick={onDowngradeClick}>Downgrade</Button>
                        }
                    </Stack>
                </Grid>
                <Grid item xs={6}>
                    <Stack direction="column" spacing={"6px"}>
                        <Stack direction="column">
                            <Typography sx={{ fontSize: '20px' }}>Plus</Typography>
                            <Stack direction="row" spacing={'8px'} alignItems="center">
                                <Typography sx={{ fontSize: '20px' }}>$8</Typography>
                                <Typography sx={{ color: 'rgba(55, 53, 47, 0.65)', fontSize: '12px' }}>per month</Typography>
                            </Stack>
                        </Stack>
                        {subscriptionStatus === 'PLUS' && <Typography sx={{ fontSize: '14px', lineHeight: '26px', }}>Current plan</Typography>}
                        {subscriptionStatus !== 'PLUS' && <Button variant="contained" sx={{ textTransform: 'none', height: '26px', fontWeight: '700' }} onClick={onUpgradeClick}>Upgrade</Button>}

                    </Stack>
                </Grid>
            </Grid>
            <Divider />
            <Grid container spacing={2} sx={{ marginTop: '4px', marginBottom: '18px' }}>
                <Grid item xs={6} sx={{ fontSize: '14px' }}>
                    Maximum 3 documents
                </Grid>
                <Grid item xs={6} sx={{ fontSize: '14px' }}>
                    ✅ <span style={{ fontWeight: '700' }}>Unlimited</span> documents
                </Grid>
                <Grid item xs={6} sx={{ fontSize: '14px' }}>
                </Grid>
                <Grid item xs={6} sx={{ fontSize: '14px' }}>
                    ✅ 100-day money-back guarantee 
                </Grid>
                <Grid item xs={6} sx={{ fontSize: '14px' }}>
                </Grid>
                <Grid item xs={6} sx={{ fontSize: '14px' }}>
                    ✅ Early access to new features
                </Grid>
                <Grid item xs={6} sx={{ fontSize: '14px' }}>
                </Grid>
                <Grid item xs={6} sx={{ fontSize: '14px' }}>
                    ✅ Invitation to the Plus exclusive channel
                </Grid>
            </Grid>
        </Box>
    );
};

const SettingsMain = ({ authUser, currentUser, onUpgrade, accountStatus }) => {
    const subscriptionStatus = (accountStatus?.subscription === "PLUS") ? "Plus" : "Free";
    return (
        <Box>
            <h3 sx={{ fontSize: '18px' }}>Account</h3>
            <Box sx={{ marginTop: '20px', marginBottom: '20px' }}>
                <Grid container spacing={2}>
                    <Grid item xs={4} sx={{ color: 'rgba(55, 53, 47, 0.65)' }}>
                        Email
                    </Grid>
                    <Grid item xs={8}>
                        {authUser.attributes.email}
                    </Grid>
                    <Grid item xs={4} sx={{ color: 'rgba(55, 53, 47, 0.65)' }}>
                        Preferred name
                    </Grid>
                    <Grid item xs={8}>
                        {currentUser.name}
                    </Grid>
                </Grid>
            </Box>
            <Divider />
            <Box sx={{ marginTop: '20px', marginBottom: '20px' }}>
                <h3 sx={{ fontSize: '18px' }}>Plan</h3>
                <Grid container spacing={2}>
                    <Grid item xs={4} sx={{ color: 'rgba(55, 53, 47, 0.65)' }}>
                        Membership
                    </Grid>
                    <Grid item xs={8}>
                        <Stack direction="row" spacing={"18px"} alignItems="center">
                            <Typography>{subscriptionStatus}</Typography>
                            <Button variant="contained" sx={{ borderRadius: "20px", fontWeight: '700', height: '20px', textTransform: 'none' }} onClick={onUpgrade}>{(subscriptionStatus === 'Plus') ? 'Change' : 'Upgrade'}</Button>
                        </Stack>

                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};


const SettingsOverlay = ({ open, handleClose }) => {
    const [currentUser, { onSaveAvatarInfo }, authUser] = useUserInfo();
    const { accountStatus } = useAccountStatus();
    const [showUpgrade, setShowUpgrade] = React.useState(false);
    const [paywallMessage, setPaywallMessage] = React.useState(null);

    React.useEffect(() => {
        let sub = paywallStream.subscribe((state) => {
            // whenever received anything, show paywall
            setShowUpgrade(true);
            setPaywallMessage(state.msg);
        });
        return () => {
            sub.unsubscribe();
        }
    }, []);

    const onUpgradeClick = async () => {
        trackDocumentEvent('Settings: upgrade clicked');
        try {
            const currentSession = await Auth.currentSession();
            const authToken = currentSession.idToken.jwtToken;
            let url = await createNewCheckoutSession(
                authToken,
                window.location.href);
            window.location.replace(url);
        } catch (e) {
            console.log(e);
        }
    };

    const onDowngradeClick = async () => {
        trackDocumentEvent('Settings: downgrade clicked');
        try {
            const currentSession = await Auth.currentSession();
            const authToken = currentSession.idToken.jwtToken;
            let url = await createNewBillingSession(authToken, window.location.href);
            window.location.replace(url);
        } catch (e) {
            console.log(e);
        }
    };

    const handleUpgrade = () => {
        setShowUpgrade(true);
    };

    const handleLastPage = () => {
        setShowUpgrade(false);
    };

    const handleDialogClose = () => {
        setShowUpgrade(false);
        setPaywallMessage(null);
        handleClose();
    };

    return (
        <Dialog open={open} onClose={handleDialogClose}>
            <Box sx={{ padding: '30px 60px', width: '600px' }}>
                {authUser && !showUpgrade &&
                    <SettingsMain authUser={authUser} currentUser={currentUser} onUpgrade={handleUpgrade} accountStatus={accountStatus}></SettingsMain>
                }
                {authUser && showUpgrade && <UpgradeTable currentUser={currentUser} onUpgradeClick={onUpgradeClick} onDowngradeClick={onDowngradeClick} onLastPage={handleLastPage} accountStatus={accountStatus} paywallMessage={paywallMessage}></UpgradeTable>}
            </Box>
        </Dialog>
    )
};

export default SettingsOverlay;