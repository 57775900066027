import { useState, useEffect } from "react";
import useCursorState from "./useCursorState";

// Responsible for detecting mouse movements and determine if it should be shown in collaboration mode
var mouseMoveTimeout;
var textCursorMoveTimeout;
var mouseMoveCount = 0;
// Make it more difficult to trigger mouse move to avoid ghost cursor effect. 
const MOUSE_MOVE_THRESHOLD = 20;

const CursorStateListener = ({ cursorPanel }) => {
    const { cursorState, onHandleMouseCursorChanged, onHandleTextCursorChanged } = useCursorState();

    // Listen to mouse and keyboard changes
    useEffect(() => {
        const onMouseMove = (e) => {
            if (mouseMoveCount > MOUSE_MOVE_THRESHOLD) {
                onHandleMouseCursorChanged(true);
                onHandleTextCursorChanged(false);
                mouseMoveCount = 0;
            } else {
                mouseMoveCount++;
                return;
            }


            // detect if mouse not moved for 6 sec
            if (mouseMoveTimeout) {
                clearTimeout(mouseMoveTimeout);
            }
            mouseMoveTimeout = setTimeout(() => { onHandleMouseCursorChanged(false) }, 4000);
        };

        const onTextTyped = (e) => {
            var allowedSpecialKeys = 'ArrowLeftArrowRightArrowUpArrowDownDeleteBackspace';
            if (String.fromCharCode(e.keyCode).match(/(\w|\s)/g) || allowedSpecialKeys.includes(e.key)) {
                onHandleTextCursorChanged(true);
                onHandleMouseCursorChanged(false);

                // detect if text not moved for 6 sec
                if (textCursorMoveTimeout) {
                    clearTimeout(textCursorMoveTimeout);
                }
                textCursorMoveTimeout = setTimeout(() => { onHandleTextCursorChanged(false); }, 4000);
            }
        };

        document.addEventListener("mousemove", onMouseMove);


        let addedText = false;
        let panel = cursorPanel.current;
        if (panel) {
            addedText = true;
            panel.addEventListener("keydown", onTextTyped);
        }
        return () => {
            document.removeEventListener("mousemove", onMouseMove);

            if (addedText) {
                panel.removeEventListener("keydown", onTextTyped);
            }
        };
    }, [cursorPanel, cursorState.showMouseCursor, cursorState.showTextCursorLabel]);

    return <></>
};

export default CursorStateListener;
