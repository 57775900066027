import { Subject } from "rxjs";

const getClosedCaptionEnabled = () => {
  if (localStorage.getItem("closedCaptionEnabled") !== null) {
    return localStorage.getItem("closedCaptionEnabled") === 'true';
  }
  return false;
};

const setClosedCaptionEnabled = (closedCaptionEnabled) => {
  localStorage.setItem("closedCaptionEnabled", closedCaptionEnabled);
};

const getAudioMuteEnabled = () => {
  if (localStorage.getItem("audioMuteEnabled") !== null) {
    return localStorage.getItem("audioMuteEnabled") === 'true';
  }
  return false;
};

const setAudioMuteEnabled = (audioMuteEnabled) => {
  localStorage.setItem("audioMuteEnabled", audioMuteEnabled);
};

const replayingSubject$ = new Subject();
const replayingInitialState$ = {
  // Used to identify the current playback session
  playbackSessionId: null,
  recordings: [],
  activeRecording: null,
  // total duration for active recording
  activeRecordingTotalTime: 0,
  // Seek time of active recording
  activeRecordingSeekTime: 0,
  activeRecordingPaused: false,
  head: 0, // 0~100
  speed: 1.0,
  //live message level
  lmTotalTime: 0,
  lmHead: 0, // 0~100
  // playback option
  resetToStartAfterPlayback: false,
  // By default, turn off caption
  closedCaptionEnabled: getClosedCaptionEnabled(),
  // By default, turn on audio
  audioMuteEnabled: getAudioMuteEnabled(),
};

let state = replayingInitialState$;
let progressTimer = undefined;
/**
 * Receive and publish replay state to its listeners (audio, text cursor, mouse cursor replayers)
 */
const replayState$ = {
  init: () => {
    state = { ...state };
    replayingSubject$.next(state);
  },
  subscribe: (setState) => replayingSubject$.subscribe(setState),
  // Reload live message from source
  notifyReload: (recordings, playbackSessionId) => {
    // console.log(recordings);
    const lmTotalTime = recordings
      .map((recording) => recording.audio)
      .reduce((acc, audio) => acc + (audio.endTime - audio.startTime), 0);
    state = {
      ...state,
      recordings: recordings,
      playbackSessionId: playbackSessionId,
      lmTotalTime: lmTotalTime,
    };
    replayingSubject$.next(state);
  },
  // Start replay of a specific recording
  notifyReplayStart: (
    recordingId,
    resetToStartAfterPlayback = state.resetToStartAfterPlayback,
    forcePaused = false
  ) => {
    // console.log(`notifyReplayStart ${resetToStartAfterPlayback}`);
    // console.log(`notifyReplayStart:${recordingId}`)
    let activeRecording = null;
    for (let i = 0; i < state.recordings.length; i++) {
      if (state.recordings[i].recordingId === recordingId) {
        activeRecording = state.recordings[i];
      }
    }

    if (!activeRecording) {
      console.warn("could find recording id in replay state");
      return;
    }
    state = {
      ...state,
      activeRecording: activeRecording,
      activeRecordingTotalTime:
        activeRecording.audio.endTime - activeRecording.audio.startTime,
      activeRecordingSeekTime: 0,
      activeRecordingPaused: forcePaused ? true : state.activeRecordingPaused,
      resetToStartAfterPlayback: resetToStartAfterPlayback,
      head: 0,
    };
    state.lmHead = computeLmHead(state);
    if (!state.activeRecordingPaused) {
      startProgressTimer(state.speed);
    } else {
      stopProgressTimer();
    }
    replayingSubject$.next(state);
  },
  notifyReplayStopInternal: () => {
    state = {
      ...state,
      activeRecording: null,
      resetToStartAfterPlayback: false,
    };
    stopProgressTimer();
    replayingSubject$.next(state);
  },
  notifyReplayStop: (force = false) => {
    if (!replayState$.getActiveRecording()) {
      return;
    }
    const activeRecording = replayState$.getActiveRecording();
    const index = replayState$.findIndexFromRecording(
      activeRecording.recordingId
    );
    const recordings = replayState$.getRecordings();
    if (index === null || index >= recordings.length - 1 || force) {
      if (replayState$.getShouldResetToStartAfterPlayback()) {
        // pause at the beginning when there's no playback anymore
        replayState$.notifyReplayStart(recordings[0].recordingId, true, true);
      } else {
        replayState$.notifyReplayStopInternal();
      }
    } else {
      // continue with next recording
      replayState$.notifyReplayStart(recordings[index + 1].recordingId);
    }
  },
  notifyReplayPaused: () => {
    state = {
      ...state,
      activeRecordingPaused: true,
    };
    stopProgressTimer();
    replayingSubject$.next(state);
  },
  notifyReplayResumed: () => {
    state = {
      ...state,
      activeRecordingPaused: false,
      activeRecordingSeekTime:
        (state.activeRecordingTotalTime * state.head) / 100,
    };
    startProgressTimer(state.speed);
    replayingSubject$.next(state);
  },
  notifySeek: (pos) => {
    state = {
      ...state,
      // calculate seek time
      activeRecordingSeekTime: (state.activeRecordingTotalTime * pos) / 100,
      head: pos,
    };
    state.lmHead = computeLmHead(state);

    replayingSubject$.next(state);
  },

  notifySpeedAdjusted: (speed) => {
    state = {
      ...state,
      speed: speed,
      activeRecordingSeekTime:
        (state.activeRecordingTotalTime * state.head) / 100,
    };
    startProgressTimer(speed);
    replayingSubject$.next(state);
  },
  replayingInitialState$,
  // Helper functions
  findIndexFromRecording: (recordingId) => {
    for (let i = 0; i < state.recordings.length; i++) {
      if (state.recordings[i].recordingId === recordingId) {
        return i;
      }
    }
    return null;
  },
  getState: ()=> {
    return state
  },
  getActiveRecording: () => {
    return state.activeRecording;
  },
  getRecordings: () => {
    return state.recordings;
  },
  getHead: () => {
    return state.head;
  },
  getShouldResetToStartAfterPlayback: () => {
    return state.resetToStartAfterPlayback;
  },
  // This is used for updating UI..
  notifyHeadInternal: (pos, lmPos) => {
    state = {
      ...state,
      head: pos,
      lmHead: lmPos,
    };
    replayingSubject$.next(state);
  },

  notifyToggleClosedCaption: () => {
    state = {
      ...state,
      closedCaptionEnabled: !state.closedCaptionEnabled,
    }
    setClosedCaptionEnabled(state.closedCaptionEnabled);
    replayingSubject$.next(state);
  },

  notifyToggleAudioMute: () => {
    let newMuteState = !state.audioMuteEnabled;
    state = {
      ...state,
      audioMuteEnabled: newMuteState,
      // Auto turn on closed caption if audio is off
      closedCaptionEnabled: (newMuteState) ? true : state.closedCaptionEnabled,
    }
    setClosedCaptionEnabled(state.closedCaptionEnabled);
    setAudioMuteEnabled(state.audioMuteEnabled);
    replayingSubject$.next(state);
  },
};
function stopProgressTimer() {
  if (progressTimer) {
    clearInterval(progressTimer);
  }
}
// TODO Is this the best way to keep track of the progress?
function startProgressTimer(speed) {
  stopProgressTimer();
  progressTimer = setInterval(() => {
    let newHead =
      (((state.activeRecordingTotalTime * state.head) / 100 + 1000) /
        state.activeRecordingTotalTime) *
      100;
    let newLmHead =
      (((state.lmTotalTime * state.lmHead) / 100 + 1000) / state.lmTotalTime) *
      100;
    // this is to avoid head overflow due to a slight off-sync of the timer and the playback at the end.
    newHead = Math.min(100, newHead);
    newLmHead = Math.min(100, newLmHead);

    replayState$.notifyHeadInternal(newHead, newLmHead);
    // check stop
    if (newHead === 100) {
      replayState$.notifyReplayStop();
    }
  }, 1000 / speed);
}

function computeLmHead(state) {
  const activeIndex = replayState$.findIndexFromRecording(
    state.activeRecording.recordingId
  );
  // console.log(`computeLMHead activeIndex:${activeIndex}`)
  const preTotalTime = state.recordings
    .slice(0, activeIndex)
    .map((recording) => recording.audio.endTime - recording.audio.startTime)
    .reduce((acc, duration) => acc + duration, 0);
  // console.log(`computeLMHead new head:${newHead}, lmTotalTime:${state.lmTotalTime} pretotalTime:${preTotalTime}, activeRecordingSeekTime:${state.activeRecordingSeekTime}`)
  return (
    ((preTotalTime + state.activeRecordingSeekTime) / state.lmTotalTime) * 100
  );
}

export default replayState$;
