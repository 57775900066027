import { Extension } from "@tiptap/core";
import { PluginKey } from "prosemirror-state";
import { createBubbleMenuPlugin } from "./BubbleMenuPlugin";

/**
 * The menu that is displayed when selecting a piece of text.
 */
export const BubbleMenuExtension = Extension.create<{}>({
  name: "BubbleMenuExtension",

  addProseMirrorPlugins() {
    return [
      createBubbleMenuPlugin({
        editor: this.editor,
        pluginKey: new PluginKey("BubbleMenuPlugin"),
        tippyOptions: {
          appendTo: document.body,
        },
      }),
    ];
  },

  addKeyboardShortcuts() {
    return {
      // HYPERLINK
      "Mod-k": () => { console.log('Mod-K'); return false; }
    };
  },
});
