import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import LiveAvatars from "../avatar/LiveAvatars";
import AppBar from "@mui/material/AppBar";
import TopCommandSection from './TopCommandSection';
import SharedTopCommandSection from './SharedTopCommandSection';
import { TOP_APP_BAR_HEIGHT } from "../../common/Constants";
import { editorState$ } from "../../streams/editorState";
import { useEffect, useState } from "react";
import WifiOffRoundedIcon from '@mui/icons-material/WifiOffRounded';
import { useUserInfo } from "../../utils/useUserInfo";
import useSideBarOffset from "../../components/sidebar/useSideBarOffset";


export default function AppTopBar({ documents, documentId, isSharedView=false }) {
    const [editorState, setEditorState] = useState(null);
    const [currentUser, { onSignOut }, authUser] = useUserInfo();
    const { sideBarOffset } = useSideBarOffset();

    useEffect(() => {
        let subscription = editorState$.subscribe(setEditorState);
        return () => {
            subscription.unsubscribe();
        }
    }, []);

    let offline = editorState && editorState.status !== 'connected';
    let docs = documents.filter(document => document.documentId === documentId);
    let isOwnerOfDoc = docs.length > 0 && authUser && docs[0].accountId === authUser.username;

    return <AppBar
        sx={{
            height: `${TOP_APP_BAR_HEIGHT}px`,
            width: `calc(100% - ${sideBarOffset.rightOffset}px)`,
            right: `${sideBarOffset.rightOffset}px`,
            backgroundColor: "white",
            boxShadow: "none",
            color: "black",
        }}
    >
        <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-start"
            padding={"12px"}
            spacing={"6px"}
        >
            {offline &&
                <Stack direction="row" alignItems="center" sx={{ height: "100%", opacity: "0.3" }}>
                    <Tooltip title={`Editor offline`} placement="bottom">
                        <WifiOffRoundedIcon></WifiOffRoundedIcon>
                    </Tooltip>
                </Stack>
            }
            <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={"12px"}
            >
                {!isSharedView && isOwnerOfDoc && <TopCommandSection isSharedView={isSharedView} documentId={documentId} /> } 
                {isSharedView && <SharedTopCommandSection />}
                <LiveAvatars />
            </Stack>
        </Stack>
    </AppBar>

}