import { Plugin, PluginKey, TextSelection } from "prosemirror-state";


const PLUGIN_KEY = new PluginKey("CollaborationCursorConflict");

// For collaboration cursor movement. Since my cursor movement could be triggered by others!
export const CollaborationCursorConflictPlugin = () => {
    return new Plugin({
        key: PLUGIN_KEY,
        appendTransaction: (transactions, oldState, newState) => {
            const docChanges =
                transactions.some((transaction) => transaction.docChanged) &&
                !oldState.doc.eq(newState.doc);

            if (!docChanges) {
                return;
            }

            const newTr = newState.tr;
            let modified = false;
            // only apply when my state had not changed (because doc was changed by peer)
            if (newState.selection instanceof TextSelection && newState.selection === oldState.selection) {
                const curNode = newState.selection.$anchor.node();
                const curNodeBefore = newState.selection.$anchor.nodeBefore;
                if (curNode.type.name === 'blockgroup') {
                    // The cursor is lost. Reposition!
                    console.log('reposition', curNode, curNodeBefore);

                    newState.doc.descendants((node, pos) => {
                        if (node.eq(curNodeBefore)) {
                            // end of owlblock - 2 (inside </content></owlblock>)
                            let selection = new TextSelection(newState.doc.resolve(pos + curNodeBefore.nodeSize - 2))
                            newTr.setSelection(selection);
                            modified = true;
                        }
                    });
                }
            }

            if (modified) {
                return newTr;
            }

            return null;
        },

    })
};
