import "./LiveMessageBottomRecorder.scss";
import React, { useCallback, useEffect, useState } from "react";
import { Box, Paper } from "@mui/material";
import Stack from "@mui/material/Stack";
import { Button, IconButton, Divider } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import Recorder from "./Recorder.js";
import useLiveMessageRecordingController from "./useLiveMessageRecordingController";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import useLiveMessageReplay from "../recording/useLiveMessageReplay";
import { trackDocumentEvent } from "../../utils/AmplitudeTrack";


const languageButtonTitle = "Select language for audio transcript";
const LanguageSelectionButton = ({ languageType, setLanguageType }) => {
  const toggleLanguage = () => {
    if (languageType === 0) {
      trackDocumentEvent("toggleLanguage - bottom lm recorder", {language: "Chinese"})
      setLanguageType(1);
    } else {
      trackDocumentEvent("toggleLanguage - bottom lm recorder", {language: "English"})
      setLanguageType(0);
    }
  };

  const languageTypeToStr = (languageType) => {
    if (languageType === 1) {
      return "中文";
    } else if (languageType === 0) {
      return "En";
    }

    return "";
  };

  return (
    <Stack direction="row" alignItems="center">
      <Tooltip title={languageButtonTitle} placement="top">
        <Button
          variant="text"
          onClick={toggleLanguage}
          sx={{
            maxWidth: "50px",
            minWidth: "50px",
            marginLeft: "8px",
            color: "#fff",
            fontWeight: "bold",
            textTransform: "none",
            opacity: "0.4",
            "&:hover": {
              backgroundColor: "#666",
              opacity: "1",
            },
          }}
        >
          {languageTypeToStr(languageType)}
        </Button>
      </Tooltip>
    </Stack>
  );
};

const LiveMessageBottomRecorder = ({ lmId, documentId }) => {
  const {
    recordingState,
    closeRecording,
    onSaveRecordingToCloud,
    onChangeLanguageType,
  } = useLiveMessageRecordingController();

  const [replayState] = useLiveMessageReplay();
  const [canSaveToCloud, setCanSaveToCloud] = useState(false);
  useEffect(() => {
    setCanSaveToCloud(
      recordingState.recordings &&
        recordingState.recordings.length > 0 &&
        !recordingState.isRecording
    );
  }, [recordingState.recordings, recordingState.isRecording]);
  const saveToCloud = () => {
    onSaveRecordingToCloud(documentId);
  };
  return (
    <Stack
      direction="row"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: "90px",
      }}
    >
      {(replayState.activeRecording && !replayState.activeRecordingPaused) || !recordingState.isRecording && (
        <Paper
          elevation={8}
          sx={{
            backgroundColor: "#212121",
            borderRadius: "30px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: "2px solid #000",
            marginRight: "8px",
          }}
        >
          <Tooltip title="Cancel" placement="top">
            <IconButton
              variant="text"
              onClick={closeRecording}
              size="small"
              sx={{
                color: "#aaa",
                fontWeight: "bold",
                textTransform: "none",

                "&:hover": {
                  backgroundColor: "#666",
                  color: "#fff",
                },
              }}
            >
              <CloseRoundedIcon />
            </IconButton>
          </Tooltip>
        </Paper>
      )}

      <Paper
        elevation={8}
        sx={{
          backgroundColor: "#212121",
          borderRadius: "30px",
          height: "60px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: "2px solid #000",
        }}
      >
        <Stack
          width={"100%"}
          direction="row"
          alignItems="center"
          // justifyContent="space-between"
        >
          <Recorder lmId={lmId} bottomRecorderMode={true}/>
          {canSaveToCloud &&
            !(
              replayState.activeRecording && !replayState.activeRecordingPaused
            ) && (
              <Divider
                orientation="vertical"
                variant="middle"
                flexItem
                sx={{ background: "#666", marginLeft: "8px" }}
              />
            )}
          {canSaveToCloud &&
            !(
              replayState.activeRecording && !replayState.activeRecordingPaused
            ) && (
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <LanguageSelectionButton
                  languageType={recordingState.languageType}
                  setLanguageType={(languageType) => {
                    onChangeLanguageType(languageType);
                  }}
                >
                  {" "}
                </LanguageSelectionButton>
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ background: "#666", marginLeft: "8px" }}
                />
                <Button
                  variant="text"
                  onClick={canSaveToCloud ? saveToCloud : null}
                  sx={{
                    color: "#fff",
                    fontWeight: "bold",
                    textTransform: "none",
                    marginRight: "8px",
                    marginLeft: "8px",

                    "&:hover": {
                      backgroundColor: "#666",
                    },
                  }}
                >
                  Save
                </Button>
              </Stack>
            )}
        </Stack>
      </Paper>
    </Stack>
  );
};

export default LiveMessageBottomRecorder;
