import { Plugin, PluginKey } from "prosemirror-state";
import { blockAtCoords } from "../DraggableBlocks/helper/blockPosAtCoords";
import { BehaviorSubject } from "rxjs";

const blockMouseCursorStream = new BehaviorSubject();
const blockMouseCursorInitialState = {
  coords: null,
  block: null
};
let state = blockMouseCursorInitialState;

export const blockMouseCursorState$ = {
  init: () => {
    state = { ...state };
    blockMouseCursorStream.next(state);
  },
  subscribe: (setBlockMouseCursorState) => {
    return blockMouseCursorStream.subscribe(setBlockMouseCursorState);
  },
  notify: (coords, block) => {
    state = {
      ...state,
      coords: coords,
      block: block,
    };
    blockMouseCursorStream.next(state);
  },
  clear: () => {
    state = {
      ...state,
      coords: null,
      block: null
    };
    blockMouseCursorStream.next(state);
  },
  getState: () => {
    return { ...state };
  },
};


export const BlockMouseCursorPlugin = (options) => {
  return new Plugin({
    key: new PluginKey("BlockMouseCursorPlugin"),
    props: {
      handleDOMEvents: {
        mouseover(view, event: any) {
          let coords = {
            left: event.clientX,
            top: event.clientY,
          };

          let block = blockAtCoords(coords, view);
          if (block && block.node) {
            let rect = block.node.getBoundingClientRect();
            let blockCoords = {
              left: rect.left,
              top: rect.top,
            };
            blockMouseCursorState$.notify(blockCoords, block.id);
          }
          return false;
        }
      },
    },
  });
};
