import {
    createCheckoutSession,
    createBillingSession,
    getAccountStatus,
} from "../lib/backend";
import { Auth } from "@aws-amplify/auth";
import useSWR from 'swr';

const PRICE_ID = process.env.REACT_APP_STRIPE_PRICE_ID;
const COUPON_ID = process.env.REACT_APP_STRIPE_COUPON_ID;
export const createNewCheckoutSession = async (authToken, currentUrl) => {
    let url = await createCheckoutSession(authToken, PRICE_ID, null, currentUrl + '?subscriptionSuccess=1', currentUrl);
    return url;
};

export const createNewBillingSession = async (authToken, currentUrl) => {
    let url = await createBillingSession(authToken, currentUrl);
    return url;
};


export function useAccountStatus() {
    const { data, error, mutate } = useSWR('accountStats', () => getMyAccountStatus());
    return {
        accountStatus: (data) ? data : null,
        isLoading: !error && !data,
        isError: error,
        mutate: mutate
    }
}

export const getMyAccountStatus = async () => {
    const currentSession = await Auth.currentSession();
    const authToken = currentSession.idToken.jwtToken;
    let accountStatus = await getAccountStatus(authToken);
    return accountStatus;
}