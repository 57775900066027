import useLiveMessageReplay from "../recording/useLiveMessageReplay";
import { useState, useEffect } from "react";
import { decodePresence } from "../../utils/ComputeUtil";
import EventReplayer from "../../utils/EventReplayer";
import useScreenFollower from "../cursor/useScreenFollower";
import { Button, Card, Box } from "@mui/material";
import { REPLAY_CURSOR_COLOR } from "../../common/Constants";
import { trackDocumentEvent } from "../../utils/AmplitudeTrack";

// Global isAlreadyFollowing state
let isAlreadyFollowing = false;
const LmScreenFollowerButton = () => {
  // handle recording stuff
  const [replayState] = useLiveMessageReplay();
  const {
    screenFollowState,
    startScreenFollowing,
    stopScreenFollowing,
    updateScreenScroll,
  } = useScreenFollower();
  const [replayEvent, setReplayEvent] = useState(null);
  const [replayEventV2, setReplayEventV2] = useState(null);

  // Handle replay
  useEffect(() => {
    if (!replayState.activeRecording) {
      // do nothing if cur playing is empty.
      return;
    }

    if (replayState.activeRecordingPaused) {
      // do nothing if cur playing is paused.
      return;
    }

    let curRecording = replayState.activeRecording;
    let curTrack = curRecording.mouseCursor;
    // console.log(curTrack);
    if (!curTrack) {
      return;
    }

    // console.log("onevent start lmscreenfollower");
    let eventReplayer = new EventReplayer();
    eventReplayer.startReplay(
      "mouseCursorScroll",
      curTrack,
      replayState.activeRecordingSeekTime,
      replayState.speed,
      () => {
        /* onStarted */
      },
      () => {
        /* onStopped */
        setReplayEvent(null);
      },
      () => {
        /* onEndOfTrack */
        setReplayEvent(null);
      },
      (event) => {
        /* onEvent */
        // console.log("onevent lmscreenfollower")
        setReplayEvent(event);
      }
    );

    return () => {
      if (eventReplayer) {
        eventReplayer.signalStop();
      }
    };
  }, [
    replayState.activeRecording,
    replayState.activeRecordingSeekTime,
    replayState.activeRecordingPaused,
    replayState.speed,
  ]);

  // Handle replay
  useEffect(() => {
    if (!replayState.activeRecording) {
      // do nothing if cur playing is empty.
      return;
    }

    if (replayState.activeRecordingPaused) {
      // do nothing if cur playing is paused.
      return;
    }

    let curRecording = replayState.activeRecording;
    let curTrack = curRecording.mouseCursorV2;
    // console.log(curTrack);
    if (!curTrack) {
      return;
    }

    // console.log("onevent start lmscreenfollower");
    let eventReplayer = new EventReplayer();
    eventReplayer.startReplay(
      "mouseCursorScrollV2",
      curTrack,
      replayState.activeRecordingSeekTime,
      replayState.speed,
      () => {
        /* onStarted */
      },
      () => {
        /* onStopped */
        setReplayEventV2(null);
      },
      () => {
        /* onEndOfTrack */
        setReplayEventV2(null);
      },
      (event) => {
        /* onEvent */
        // console.log("onevent lmscreenfollower")
        setReplayEventV2(event);
      }
    );

    return () => {
      if (eventReplayer) {
        eventReplayer.signalStop();
      }
    };
  }, [
    replayState.activeRecording,
    replayState.activeRecordingSeekTime,
    replayState.activeRecordingPaused,
    replayState.speed,
  ]);

  // Old following scroll based on absolute position of mouse. This is flaky as it cannot handle block movements
  useEffect(() => {
    if (
      replayState.activeRecording &&
      screenFollowState.isFollowing &&
      screenFollowState.requestorId ===
      replayState.activeRecording.recordingId &&
      replayEvent &&
      replayEvent.cursor && 
      !replayEventV2 // If v2 is available, disable v1
    ) {
      const scrollX = decodePresence(replayEvent.cursor).scroll.x;
      const scrollY = decodePresence(replayEvent.cursor).scroll.y;
      updateScreenScroll({ x: scrollX, y: scrollY });
    }
  }, [
    screenFollowState.requestorId,
    screenFollowState.isFollowing,
    replayEvent,
    replayEventV2
  ]);

  // New following scroll based on blocks
  useEffect(() => {
    if (
      replayState.activeRecording &&
      screenFollowState.isFollowing &&
      screenFollowState.requestorId ===
      replayState.activeRecording.recordingId &&
      replayEventV2 &&
      replayEventV2.cursor
    ) {
      let blockId = replayEventV2.cursor.blockId;
      const elements = document.querySelectorAll(`[data-id="${blockId}"]`);
      if (elements.length > 0) {
          let element = elements[0];
          let rect = element.getBoundingClientRect();
          updateScreenScroll({ x: rect.left + replayEventV2.cursor.x + window.scrollX, y: rect.top + replayEventV2.cursor.y + window.scrollY - window.innerHeight / 2 }, isAlreadyFollowing);
      }
    }
    isAlreadyFollowing = screenFollowState.isFollowing;
  }, [
    screenFollowState.requestorId,
    screenFollowState.isFollowing,
    replayEventV2,
  ]);


  const onRequestScreenFollow = () => {
    startScreenFollowing(replayState.activeRecording.recordingId, REPLAY_CURSOR_COLOR);
  };

  useEffect(() => {
    if (replayState.activeRecording) {
      if (replayState.activeRecordingPaused) {
        stopScreenFollowing();
      } else {
        onRequestScreenFollow();
      }
    }
  }, [replayState.activeRecording, replayState.activeRecordingPaused]);

  return (
    // screenFollowState.requestorId == null &&
    replayState.activeRecording &&
    !replayState.activeRecordingPaused &&
    screenFollowState.requestorId !==
    replayState.activeRecording.recordingId && (
      <Box
        sx={{
          position: "sticky",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          bottom: "90px",
          pointerEvents: "none"
        }}
      >
        <Card
          sx={{
            background: "#7C6FFF",
            borderRadius: "5px",
          }}
        >
          <Button
            sx={{
              color: "white",
              textTransform: "none",
              fontSize: "16px",
              opacity: "0.9",
              pointerEvents: "auto",
              "&:hover": { opacity: "1" },
            }}
            onClick={()=>{
              trackDocumentEvent("click follow screen button during lm playback")
              onRequestScreenFollow()
            }
              }
          >
            Follow screen
          </Button>
        </Card>
      </Box>
    )
  );
};
export default LmScreenFollowerButton;
