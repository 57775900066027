import { Extensions, extensions } from "@tiptap/core";

import Bold from "@tiptap/extension-bold";
import Code from "@tiptap/extension-code";
import DropCursor from "@tiptap/extension-dropcursor";
import GapCursor from "@tiptap/extension-gapcursor";
import HardBreak from "@tiptap/extension-hard-break";
import { History } from "@tiptap/extension-history";
import Italic from "@tiptap/extension-italic";
import Strike from "@tiptap/extension-strike";
import Text from "@tiptap/extension-text";
import UnderlineConflict from "./extensions/Underline/UnderlineConflict";
import Highlight from '@tiptap/extension-highlight';
import Mention from '@tiptap/extension-mention';
import Image from '@tiptap/extension-image';
import { blocks } from "./extensions/Blocks";
import { BubbleMenuExtension } from "./extensions/BubbleMenu/BubbleMenuExtension";
import { DraggableBlocksExtension } from "./extensions/DraggableBlocks/DraggableBlocksExtension";
import HyperlinkMark from "./extensions/Hyperlinks/HyperlinkMark";
import { Placeholder } from "./extensions/Placeholder/PlaceholderExtension";
import SlashMenuExtension from "./extensions/SlashMenu";
import { TrailingNode } from "./extensions/TrailingNode/TrailingNodeExtension";
import { PageHeadingExtension } from "./extensions/PageHeading/PageHeadingExtension";
import UniqueID from "./extensions/UniqueID/UniqueID";
import ImageBlockExtension from "./extensions/Images/ImageBlockExtension";
import RecordingExtension from "./extensions/Recording/RecordingExtension";
import ReplayingExtension from "./extensions/Recording/ReplayingExtension";
import LiveMessageBlockExtension from "./extensions/LiveMessageBlock/LiveMessageBlockExtension";
import LiveMessageEmbeddingExtension from "./extensions/ContextEmbedding/LiveMessageEmbeddingExtension";
import DebugExtension from "./extensions/Debug/DebugExtension";
import { AmplitudeLoggingExtension } from "./extensions/AmplitudeLogging/AmplitudeLoggingExtension";
import { MentionSuggestion } from "./shared/plugins/mention/MentionSuggestion";
import { teamUsersState$ } from '../streams/teamUserState';

import blockStyles from "./extensions/Blocks/nodes/Block.module.css";

/**
 * Get all the Tiptap extensions BlockNote is configured with by default
 */
export const getBlockNoteExtensions = () => {
  const ret: Extensions = [
    extensions.ClipboardTextSerializer,
    extensions.Commands,
    extensions.Editable,
    extensions.FocusEvents,
    extensions.Tabindex,

    // DevTools,
    GapCursor,

    // DropCursor,
    Placeholder.configure({
      emptyNodeClass: blockStyles.isEmpty,
      hasAnchorClass: blockStyles.hasAnchor,
      isFilterClass: blockStyles.isFilter,
      includeChildren: true,
      showOnlyCurrent: false,
    }),
    UniqueID.configure({
      types: ["owlblock", "image", "liveMessageBlock", "imageBlock"],
    }),
    HardBreak,
    ImageBlockExtension,
    Image,
    // Comments,

    // basics:
    Text,
    // marks:
    Bold,
    Code,
    Italic,
    Strike,
    UnderlineConflict,
    HyperlinkMark,
    // Test if paragraph is necessary..
    //FixedParagraph,
    Highlight,

    // custom blocks:
    ...blocks,
    DraggableBlocksExtension,
    RecordingExtension,
    ReplayingExtension,
    LiveMessageBlockExtension,
    LiveMessageEmbeddingExtension,
    DropCursor.configure({ width: 5, color: "#ddeeff" }),
    // Need to be after Blocks.ts to resolve enter key conflict
    Mention.configure({
      HTMLAttributes: {
        class: 'mention',
      },
      suggestion: MentionSuggestion,
      renderLabel({ options, node }) {
        for (let teamUser of teamUsersState$.getState().teamUsers) {
          if (teamUser.accountId === node.attrs.id) {
            return `${options.suggestion.char}${teamUser.displayName}`
          }
        }
        return `${options.suggestion.char}Unknown`
      },
    }),
    BubbleMenuExtension,
    // IMPORTANT: Need to keep History plugin, otherwise text selection will be messed up when undo 
    History,
    DebugExtension,
    AmplitudeLoggingExtension,
    // This needs to be at the bottom of this list, because Key events (such as enter, when selecting a /command),
    // should be handled before Enter handlers in other components like splitListItem
    SlashMenuExtension,
    PageHeadingExtension, 
    TrailingNode,
  ];
  return ret;
};
