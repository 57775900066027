import useSWR from 'swr';
import {
    listTeamUsers,
    createTeamUser,
} from "../lib/backend";


export function useFetchTeamUsers(teamId) {
    const { data, error, mutate } = useSWR('teamUsers_' + teamId, () => fetchTeamUsers(teamId));

    return {
        teamUsers: (data) ? data : [],
        isLoading: !error && !data,
        isError: error,
        mutate: mutate
    }
}

const fetchTeamUsers = async (teamId) => {
    if (!teamId) {
        return [];
    }
    let teamUsers = await listTeamUsers(teamId);
    return teamUsers;
};

export const createNewTeamUser = async (accountId, teamId, displayName) => {
    let document = await createTeamUser(accountId, teamId, displayName);
    return document;
};
