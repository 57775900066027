import { NodeSelection, Plugin, PluginKey } from "prosemirror-state";
import * as pv from "prosemirror-view";
import { EditorView } from "prosemirror-view";
import { createRoot } from 'react-dom/client';
import { DragHandle } from "./components/DragHandle";
import { blockPosAtCoords, getDraggableBlockFromCoords } from "./helper/blockPosAtCoords";
import { findBlock } from "../Blocks/helpers/findBlock";
import { TextSelection } from "prosemirror-state";

const serializeForClipboard = (pv as any).__serializeForClipboard;
// code based on https://github.com/ueberdosis/tiptap/issues/323#issuecomment-506637799

let horizontalAnchor: number;
function getHorizontalAnchor() {
  if (!horizontalAnchor) {
    const firstBlockGroup = document.querySelector(
      ".ProseMirror > [class*='blockGroup']"
    ) as HTMLElement | undefined; // first block group node
    if (firstBlockGroup) {
      horizontalAnchor = absoluteRect(firstBlockGroup).left;
    } // Anchor to the left of the first block group
  }
  return horizontalAnchor;
}

export function createRect(rect: DOMRect) {
  let newRect = {
    left: rect.left + document.body.scrollLeft,
    top: rect.top + document.body.scrollTop,
    width: rect.width,
    height: rect.height,
    bottom: 0,
    right: 0,
  };
  newRect.bottom = newRect.top + newRect.height;
  newRect.right = newRect.left + newRect.width;
  return newRect;
}

export function absoluteRect(element: HTMLElement) {
  return createRect(element.getBoundingClientRect());
}

function dragStart(e: DragEvent, view: EditorView) {
  if (!e.dataTransfer) {
    return;
  }
  // NOTE: It clicks at the button, which is outside the prosemirror document area.
  let coords = {
    left: view.dom.offsetLeft + view.dom.clientWidth / 2, // take middle of editor
    top: e.clientY,
  };
  let pos = blockPosAtCoords(coords, view);
  if (pos != null) {
    view.dispatch(
      view.state.tr.setSelection(NodeSelection.create(view.state.doc, pos))
    );

    let slice = view.state.selection.content();
    let { dom, text } = serializeForClipboard(view, slice);

    e.dataTransfer.clearData();
    e.dataTransfer.setData("text/html", dom.innerHTML);
    e.dataTransfer.setData("text/plain", text);
    e.dataTransfer.effectAllowed = "move";
    const block = getDraggableBlockFromCoords(coords, view);
    e.dataTransfer.setDragImage(block?.node as any, 0, 0);
    view.dragging = { slice, move: true };
  }
}

export const createDraggableBlocksPlugin = () => {
  let dropElement: HTMLElement | undefined;
  let root;

  const WIDTH = 70;
  const WIDTH_WITH_EMBEDDINGS = 104;

  // When true, the drag handle with be anchored at the same level as root elements
  // When false, the drag handle with be just to the left of the element
  const horizontalPosAnchoredAtRoot = false;

  let menuShown = false;
  let addClicked = false;

  const onShow = () => {
    menuShown = true;
  };
  const onHide = () => {
    menuShown = false;
  };
  const onAddClicked = () => {
    addClicked = true;
  };

  return new Plugin({
    key: new PluginKey("DraggableBlocksPlugin"),
    view(editorView) {
      dropElement = document.createElement("div");
      dropElement.setAttribute("id", "DropElement");
      dropElement.setAttribute("draggable", "true");
      dropElement.style.position = "absolute";
      document.body.append(dropElement);
      root = createRoot(dropElement);
      dropElement.addEventListener("dragstart", (e) =>
        dragStart(e, editorView)
      );

      return {
        // update(view, prevState) {},
        destroy() {
          if (!dropElement) {
            throw new Error("unexpected");
          }
          dropElement.parentNode!.removeChild(dropElement);
          dropElement = undefined;
          // make sure to unmount to avoid leak
          root.unmount();
        },
      };
    },
    props: {
      handleKeyDown(_view, _event) {
        if (!dropElement) {
          throw new Error("unexpected");
        }
        menuShown = false;
        addClicked = false;
        root.render(<></>);
        return false;
      },
      handleDOMEvents: {
        // drag(view, event) {
        //   // event.dataTransfer!.;
        //   return false;
        // },
        mouseleave(_view, _event: any) {
          if (!dropElement) {
            throw new Error("unexpected");
          }
          // TODO
          // dropElement.style.display = "none";
          return true;
        },
        mousedown(_view, _event: any) {
          if (!dropElement) {
            throw new Error("unexpected");
          }
          menuShown = false;
          addClicked = false;
          root.render(<></>);
          return false;
        },
        mousemove(view, event: any) {
          if (!dropElement) {
            throw new Error("unexpected");
          }

          if (menuShown || addClicked) {
            // The submenu is open, don't move draghandle
            // Or if the user clicked the add button
            return true;
          }
          const coords = {
            // left: view.dom.clientWidth / 2, // take middle of editor
            // left: view.dom.offsetLeft + view.dom.clientWidth / 2,
            left: view.dom.offsetLeft + view.dom.clientWidth / 2,
            top: event.clientY,
          };

          const block = getDraggableBlockFromCoords(coords, view);
          if (!block) {
            console.warn("Perhaps we should hide element?");
            return true;
          }

          // I want the dim of the blocks content node
          // because if the block contains other blocks
          // Its dims change, moving the position of the drag handle
          let blockContent = block.node as HTMLElement;

          if (block.node.firstChild) {
            blockContent = block.node.firstChild as HTMLElement;
          }
          // console.log('richard block getDraggableBlockFromCoords', block);
          const pos = view.posAtCoords(coords);
          let hasEmbeddings = false;
          let embeddings = [];

          if (pos) {
            // Try with text selection first
            const currentBlock = findBlock(
              TextSelection.create(view.state.doc, pos.pos)
            );
            if (currentBlock) {
              // Current block is a text selection
              if (currentBlock?.node.attrs?.embeddings) {
                try {
                  embeddings = JSON.parse(currentBlock.node.attrs.embeddings);
                  hasEmbeddings = embeddings.length > 0;
                } catch (err) {
                  console.warn('Failed to parse existing embeddings', currentBlock.node.attrs.embeddings);
                }
              }
            } else {
              // try with node selection
              const currentNodeBlock = NodeSelection.create(view.state.doc, pos.pos);
              if (currentNodeBlock?.node.attrs?.embeddings) {
                try {
                  embeddings = JSON.parse(currentNodeBlock.node.attrs.embeddings);
                  hasEmbeddings = embeddings.length > 0;
                } catch (err) {
                  console.warn('Failed to parse existing embeddings', currentNodeBlock.node.attrs.embeddings);
                }
              }
            }
          }
          let handleWidth = WIDTH;
          if (hasEmbeddings) {
            handleWidth = WIDTH_WITH_EMBEDDINGS
          }
          const rect = absoluteRect(blockContent);
          const win = block.node.ownerDocument.defaultView!;
          const dropElementRect = dropElement.getBoundingClientRect();
          const left =
            (horizontalPosAnchoredAtRoot ? getHorizontalAnchor() : rect.left) -
            handleWidth +
            win.pageXOffset;
          rect.top +=
            rect.height / 2 - dropElementRect.height / 2 + win.pageYOffset;

          dropElement.style.left = left + "px";
          dropElement.style.top = rect.top + "px";

          root.render(<DragHandle
            onShow={onShow}
            onHide={onHide}
            onAddClicked={onAddClicked}
            key={block.id + ""}
            view={view}
            embeddings={embeddings}
            coords={coords}
          />);
          return true;
        },
      },
    },
  });
};
