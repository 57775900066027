// https://stackabuse.com/making-asynchronous-http-requests-in-javascript-with-axios/
import axios from "axios";
import { TooManyRequestError, BadRequestError, UnexpectedError, ForbiddenError } from "./exceptions";

const DEV_URL = "https://1ctysqjgy4.execute-api.us-west-2.amazonaws.com/dev";
const PROD_URL = "https://guvqs4rd43.execute-api.us-west-2.amazonaws.com/prod";

export const createAuth = async (accountId, documentId, userName, userColor) => {
    let data = {
        roomName: `document.${documentId}`,
        userName: userName,
        userColor: userColor,
    };


    try {
        const resp = await axios({
            method: "POST",
            url: getFullUrl("/liveBlocks"),
            data: data,
            params: { accountId },
            headers: { 'x-api-key': getApiKey() },
        });
        return resp.data;
    } catch (err) {
        handleError(err);
    }
};

function handleError(err, logToConsole = true) {
    if (logToConsole) {
        console.log({ err });
    }

    if (err.response && err.response.status === 429) {
        throw new TooManyRequestError("Quota exceeded. Please try later.");
    } else if (err.response && err.response.status === 400 && err.response.data && err.response.data.message) {
        throw new BadRequestError(err.response.data.message)
    } else if (err.response && err.response.status === 403 && err.response.data && err.response.data.message) {
        throw new ForbiddenError(err.response.data.message)
    } else {
        throw new UnexpectedError("Unexpected error happened. Please try later.")
    }
}

function getFullUrl(query) {
    var url = process.env.REACT_APP_PUBLIC_API_ENV === "dev" ? DEV_URL : PROD_URL;
    return url + query;
}

function getApiKey() {
    return process.env.REACT_APP_AUTH_BACKEND_API_KEY;
}