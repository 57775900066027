import useSWR from 'swr';
import {
    createDocumentShare,
    deleteDocumentShare,
    listDocumentShare,
    getDocumentShare,
} from "../lib/backend";
import { Auth } from "@aws-amplify/auth";

export const createNewDocumentShare = async (documentId) => {
    const currentSession = await Auth.currentSession();
    const authToken = currentSession.idToken.jwtToken;
    let documentShare = await createDocumentShare(authToken, documentId);
    return documentShare;
};

export const deleteSingleDocumentShare = async (documentShareid) => {
    const currentSession = await Auth.currentSession();
    const authToken = currentSession.idToken.jwtToken;
    await deleteDocumentShare(authToken, documentShareid);
};

export function useListDocumentShares(documentId) {
    const { data, error, mutate } = useSWR('documentShares_' + documentId, () => listMyDocumentShares(documentId));
    return {
        documentShares: (data) ? data : null,
        isLoading: !error && !data,
        isError: error,
        mutate: mutate
    }
}

export const getSingleDocumentShare = async (documentShareId) => {
    let documentShare = await getDocumentShare(documentShareId);
    return documentShare;
};

export const listMyDocumentShares = async (documentId) => {
    const currentSession = await Auth.currentSession();
    const authToken = currentSession.idToken.jwtToken;
    let documentShares = await listDocumentShare(authToken, documentId);
    return documentShares;
};