import { useState, useEffect } from "react";
import useLiveMessageRecordingController from "./useLiveMessageRecordingController";
import useLiveMessageViewModel from "./useLiveMessageViewModel";
const holdToRecordKey = "Alt";
let progressStartHandle = undefined;
let progressCancelHandle = undefined;
const LmHoldToRecordListener = () => {
  const { selectedLiveMessage } = useLiveMessageViewModel();
  const {
    recordingState,
    onStartToRecord,
    onStopRecording,
    onHoldToRecordProgress,
  } = useLiveMessageRecordingController();

  const [keyUp, setKeyUp] = useState(null);
  const [keyDown, setKeyDown] = useState(null);
  const [progress, setProgress] = useState(0);

  const downHandler = ({ key }) => {
    setKeyDown(key);
  };
  const upHandler = ({ key }) => {
    setKeyUp(key);
  };

  function startHoldToRecordProgress() {
    return setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 100 : prevProgress + 10
      );
    }, 30);
  }

  function fadeOutHoldToRecordProgress() {
    return setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress <= 0 ? 0 : prevProgress - 20
      );
    }, 20);
  }

  useEffect(() => {
    if (progress == 0 || progress == 100) {
      clearInterval(progressCancelHandle);
      clearInterval(progressStartHandle);
    }
    onHoldToRecordProgress(progress);
  }, [progress]);

  const cancelHoldToRecordProgress = () => {
    // console.log("cancel holde to record");
    clearInterval(progressStartHandle);
    clearInterval(progressCancelHandle);
    progressCancelHandle = fadeOutHoldToRecordProgress();
  };

  useEffect(() => {
    if (recordingState.isRecording) {
      setProgress(0);
    }
  }, [recordingState.isRecording]);

  useEffect(() => {
    if (keyDown === holdToRecordKey && !recordingState.isRecording) {
      setKeyDown(null);
      clearInterval(progressCancelHandle);
      clearInterval(progressStartHandle);
      progressStartHandle = startHoldToRecordProgress();
      //   console.log(
      //     `key down, start hold to record progress:${progressStartHandle}`
      //   );
    } else if (keyDown && keyDown !== holdToRecordKey) {
      setKeyDown(null);
      cancelHoldToRecordProgress();
    }
    // console.log(`keyDown:${keyDown} ${recordingState.isRecording}`);
  }, [keyDown, recordingState.isRecording]);

  useEffect(() => {
    if (keyUp === holdToRecordKey) {
      if (recordingState.isRecording && recordingState.holdToRecord) {
        // console.log("key up, recording stop");
        setKeyUp(null);
        onStopRecording();
      } else if (progressStartHandle) {
        setKeyUp(null);
        cancelHoldToRecordProgress();
      }
    }
  }, [keyUp, recordingState.isRecording]);

  // Add event listeners
  useEffect(() => {
    let added = false;
    if (selectedLiveMessage && selectedLiveMessage.liveMessageId && selectedLiveMessage.isRecordingBlock) {
      console.log(`adding event listener:${selectedLiveMessage.liveMessageId}`)
      window.addEventListener("keydown", downHandler);
      window.addEventListener("keyup", upHandler);
      added = true;
    }
    // Remove event listeners on cleanup
    return () => {
      if (added) {
        console.log("unsubscribing key event listener");
        window.removeEventListener("keydown", downHandler);
        window.removeEventListener("keyup", upHandler);
      }
    };
  }, [selectedLiveMessage]);
};

export default LmHoldToRecordListener;
