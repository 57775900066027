import { BehaviorSubject } from "rxjs";

const editorStateStream = new BehaviorSubject();
const editorInitialState = {
    status: "disconnected", // disconnected, connecting, connected
    documentId: null,
    teamId: null,
    pageHeading: null,
};
let state = editorInitialState;

export const editorState$ = {
    init: () => {
        state = { ...state };
        editorStateStream.next(state);
    },
    subscribe: (setEditorState) => {
        return editorStateStream.subscribe(setEditorState);
    },
    notifyStatusChange: (status) => {
        if (status === state.status) {
            return;
        }
        state = {
            ...state,
            status: status
        };
        editorStateStream.next(state);
    },
    notifyDocumentChange: (teamId, documentId) => {
        state = {
            ...state,
            teamId: teamId,
            documentId: documentId,
            pageHeading: null,
        };
        editorStateStream.next(state);
    }, 
    notifyPageHeadingChange: (pageHeading) => {
        state = {
            ...state,
            pageHeading: pageHeading,
        };
        editorStateStream.next(state);
    },
    getState: () =>{
        return { ...state };
    },
};
