import CollaborationCursor from "@tiptap/extension-collaboration-cursor";
import { CollaborationCursorConflictPlugin } from "./CollaborationCursorConflictPlugin";
/**
 * This custom link includes a special menu for editing/deleting/opening the link.
 * The menu will be triggered by hovering over the link with the mouse,
 * or by moving the cursor inside the link text
 */
const CollaborationCursorConflict = CollaborationCursor.extend({
  addProseMirrorPlugins() {
    return [...(this.parent?.() || []), CollaborationCursorConflictPlugin()];
  },
});

export default CollaborationCursorConflict;
