
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import {
    useNavigate
} from "react-router-dom";

const SharedTopCommandSection = () => {
    const navigate = useNavigate();

    const onClickTryNotemad = () => {
        // navigate to log in page
        navigate(`/login`);
    };

    return (
        <Box sx={{ position: 'relative' }}>
            <Button variant="primary" sx={{ textTransform: 'none' }} onClick={onClickTryNotemad}>
                <Stack direction="row" spacing={'12px'} alignItems="center">
                    <img width="16" height="16" src="/02-logo.png" alt="nomad" />
                    <Box sx={{fontSize: '16px'}}>Try Notemad</Box>
                </Stack>
                
            </Button>
        </Box>
    )
};

export default SharedTopCommandSection;