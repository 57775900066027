
import { Plugin, PluginKey } from "prosemirror-state";
import { trackDocumentEvent } from "../../../utils/AmplitudeTrack";


export const createAmplitudeLoggingPlugin = () => {
    let firstEditsTracked = false;
    let editCount = 0;

    return new Plugin({
        key: new PluginKey("AmplitudeLoggingPlugin"),
        props: {
            handleKeyPress(_view, _event) {
                editCount += 1;
                if (!firstEditsTracked && editCount >= 5) {
                    trackDocumentEvent("First doc edit");
                    firstEditsTracked = true;
                }
                if (editCount / 100 >= 1) {
                    console.log('hundred edits', editCount)
                    trackDocumentEvent("Hundred edits");
                    editCount = 0;
                }
                return false;
            },
        },
    });
};
