import React from "react";
import { createRoot } from 'react-dom/client';
import "./index.css";
import { init } from '@amplitude/analytics-browser';
import { Amplify } from '@aws-amplify/core';

import App from "./app/App";
import Login from "./app/Login";
import MobileApp from "./app/MobileApp";
import ShareApp from "./app/ShareApp";

import reportWebVitals from "./reportWebVitals";
import { EditorView } from 'prosemirror-view'

import { ThemeProvider } from '@mui/material/styles';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme } from '@mui/material/styles';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

// Initialze amplitude
init(process.env.REACT_APP_AMPLITUDE_API_KEY);

// Initialize Amplify
Amplify.configure({
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: process.env.REACT_APP_COGNITO_REGION,

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: process.env.REACT_APP_COGNITO_WEB_CLIENT_ID,

    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    authenticationFlowType: 'USER_SRP_AUTH'
  },
  ssr: true,
});


// HACK: https://github.com/ueberdosis/tiptap/issues/1451#issuecomment-953348865
EditorView.prototype.updateState = function updateState(state) {
  if (!this.docView) return // This prevents the matchesNode error on hot reloads
  this.updateStateInner(state, this.state.plugins !== state.plugins)
}


// prepend: true moves MUI styles to the top of the <head> so they're loaded first.
// It allows developers to easily override MUI styles with other styling solutions, like CSS modules.
export default function createEmotionCache() {
  return createCache({ key: 'css', prepend: true });
}

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

// Create a theme instance.
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      xxl: 1920,
    },
  },
  spacing: 4,
  typography: {
    fontFamily: ['sans-serif'].join(','),
  },
});

// root node
const Root = () => {
  return (
    <CacheProvider value={clientSideEmotionCache}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserView>
          <App />
        </BrowserView>
        <MobileView>
          <MobileApp />
        </MobileView>
      </ThemeProvider>
    </CacheProvider>
  );
}

const ShareRoot = () => {
  return (
    <CacheProvider value={clientSideEmotionCache}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserView>
          <ShareApp />
        </BrowserView>
        <MobileView>
          <MobileApp />
        </MobileView>
      </ThemeProvider>
    </CacheProvider>
  );
}


// Configure router
const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
  },
  {
    path: "/:teamId",
    element: <Root />,
  },
  {
    path: "/:teamId/:documentPath",
    element: <Root />,
  },
  {
    path: "/login",
    element: <Login></Login>
  },
  {
    path: "/share/:sharePath",
    element: <ShareRoot />,
  },
]);


const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
