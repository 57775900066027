import { BehaviorSubject } from "rxjs";

const teamUsersStream = new BehaviorSubject();
const teamUsersInitialState = {
    teamUsers: []
};
let state = teamUsersInitialState;

export const teamUsersState$ = {
    init: () => {
        state = { ...state };
        teamUsersStream.next(state);
    },
    subscribe: (setTeamUserState) => {
        return teamUsersStream.subscribe(setTeamUserState);
    },
    notifyTeamUsersChange: (teamUsers) => {
        state = {
            ...state,
            teamUsers: teamUsers
        };
        teamUsersStream.next(state);
    },
    getState: () =>{
        return { ...state };
    },
};
