import { Plugin, PluginKey } from "prosemirror-state";

const PLUGIN_KEY = new PluginKey(`task-list`);

export const TaskListPlugin = () => {
  return new Plugin({
    key: PLUGIN_KEY,
    appendTransaction: (transactions, oldState, newState) => {
      const docChanges =
        transactions.some((transaction) => transaction.docChanged) &&
        !oldState.doc.eq(newState.doc);

      if (!docChanges) {
        return;
      }

      const newTr = newState.tr;
      let modified = false;
      newState.doc.descendants((node, pos, parent) => {
        if (
          node.type.name === "owlblock" &&
          node.attrs.listType === "tli"
        ) {
          // This assumes that the content node is always the first child of the oli block,
          // as the content model grows this assumption may need to change
          if (!node.content.child(0).attrs.contentType) {
            newTr.setNodeMarkup(pos + 1, undefined, {
              ...node.attrs,
              contentType: 'taskitem',
            });
            modified = true;
          }
        }
      });


      if (modified) {
        return newTr;
      }
      return null;
    },
  });
};
