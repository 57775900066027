import { Plugin, PluginKey } from "prosemirror-state";
import { createSingleMedia } from '../../../utils/MediaBackendUtils';
import { DEFAULT_ACCOUNT_ID } from "../../../common/Constants";
import { TextSelection } from "prosemirror-state";
import { findBlock } from "../Blocks/helpers/findBlock";

const PLUGIN_KEY = new PluginKey("ImagePastePlugin");

// See reference: https://github.com/ueberdosis/tiptap/issues/508
export const createImagePastePlugin = () => {
    return new Plugin({
        key: PLUGIN_KEY,
        props: {
            handleDOMEvents: {
                paste(view, event) {
                    let hasFiles = false;
                    let reader = new FileReader();
                    //注册加载文件完毕事件
                    reader.onload = function (event) {
                        //获取object url
                        let localImageUrl = event.target.result;

                        //插入到编辑器中
                        // Get current block
                        const currentBlock = findBlock(view.state.tr.selection);
                        if (!currentBlock) {
                            return false;
                        }

                        // Here we create an image block but provide only localImageUrl. The actual react component will be responsible for upload
                        console.log('currentBlock', currentBlock);
                        const transaction = view.state.tr;
                        // If current blocks content is empty AND not contain a block group dont create a new block
                        if (currentBlock.node.firstChild?.textContent.length === 0 && currentBlock.node.childCount <= 1) {
                            const endOfBlock = currentBlock.pos + currentBlock.node.nodeSize;
                            let newImageBlock = view.state.schema.nodes.imageBlock.create({ localImageUrl: localImageUrl });
                            transaction.replaceWith(currentBlock.pos, endOfBlock, newImageBlock);
                            transaction.setSelection(new TextSelection(transaction.doc.resolve(currentBlock.pos + 1)));
                        } else {
                            // Create new block after current block
                            const endOfBlock = currentBlock.pos + currentBlock.node.nodeSize;
                            let newImageBlock = view.state.schema.nodes.imageBlock.create({ localImageUrl: localImageUrl });
                            transaction.insert(endOfBlock, newImageBlock);
                            transaction.setSelection(new TextSelection(transaction.doc.resolve(endOfBlock + 1)));
                        }

                        view.dispatch(transaction);
                    };
                    //从剪贴板中读取图片文件
                    Array.from(event.clipboardData.files)
                        .filter(item => item.type.startsWith("image"))//提取图片文件
                        .forEach(item => {//读取数据
                            reader.readAsDataURL(item);
                            hasFiles = true;
                        });
                    //扫尾
                    if (hasFiles) {
                        event.preventDefault();
                        return true;
                    }
                },
            }

        }
    })
};
