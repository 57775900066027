import { Plugin, PluginKey } from "prosemirror-state";
import { CommentThread } from "../../../components/comment/comment";

const PLUGIN_KEY = new PluginKey("ImageCommentPlugin");


// This is hack so that comment can be synced to node.attrs. 
// Because prosemirror has problem storing marks for blocks
// We always sync by attrs and marks simply react
export const createImageCommentPlugin = () => {
    return new Plugin({
        key: PLUGIN_KEY,
        appendTransaction: (transactions, oldState, newState) => {
            const docChanges =
                transactions.some((transaction) => transaction.docChanged) &&
                !oldState.doc.eq(newState.doc);

            if (!docChanges) {
                return;
            }

            const newTr = newState.tr;
            let modified = false;

            newState.doc.descendants((node, pos, parent) => {
                if (node.type.name !== "imageBlock") {
                    return;
                }

                const commentThreadDataInAttr = node.attrs?.comment;
                const commentThreadDataInMark = node.marks
                    .filter((mark) => mark.type.name === CommentThread.name)
                    .filter((mark) => mark.attrs.comment && mark.attrs.comment.length > 0)
                    .map((mark) => JSON.parse(mark.attrs.comment));
                // console.log('commentThreadDataInAttr', commentThreadDataInAttr, 'commentThreadDataInMark', commentThreadDataInMark);

                if (commentThreadDataInAttr && (commentThreadDataInMark.length === 0 || JSON.parse(commentThreadDataInAttr).comments.length !== commentThreadDataInMark[0].comments.length)) {
                    console.log('Update comment mark for image');
                    let mark = newState.schema.marks[CommentThread.name].create({ comment: commentThreadDataInAttr });
                    newTr.setNodeMarkup(pos, undefined, {
                        ...node.attrs,
                    }, [mark]);
                    modified = true;
                }
            });

            if (modified) {
                return newTr;
            }
            return null;
        }
    })
};
