import { useState, useEffect } from "react";
import { BehaviorSubject } from "rxjs";
import { ReactRenderer } from '@tiptap/react'
import tippy from 'tippy.js'
import MentionList from './MentionList.js'

const mentionSuggestionStream = new BehaviorSubject();
const mentionSuggestionInitialState = {
    suggestions: []
};
let state = mentionSuggestionInitialState;

export const mentionSuggestionState$ = {
    init: () => {
        state = { ...state };
        mentionSuggestionStream.next(state);
    },
    subscribe: (setSuggestionState) => {
        return mentionSuggestionStream.subscribe(setSuggestionState);
    },
    notifySuggestionChange: (suggestions) => {
        state = {
            ...state,
            suggestions: suggestions
        };
        mentionSuggestionStream.next(state);
    },
    getState: () =>{
        return { ...state };
    },
};

export const MentionSuggestion = {
    items: ({ query }) => {
        return mentionSuggestionState$.getState().suggestions
            .filter(item => item.toLowerCase().startsWith(query.toLowerCase()))
            .slice(0, 5)
    },

    render: () => {
        let component
        let popup

        return {
            onStart: props => {
                component = new ReactRenderer(MentionList, {
                    props,
                    editor: props.editor,
                })

                if (!props.clientRect) {
                    return
                }

                popup = tippy('body', {
                    getReferenceClientRect: props.clientRect,
                    appendTo: () => document.body,
                    content: component.element,
                    showOnCreate: true,
                    interactive: true,
                    trigger: 'manual',
                    placement: 'bottom-start',
                })
            },

            onUpdate(props) {
                component.updateProps(props)

                if (!props.clientRect) {
                    return
                }

                popup[0].setProps({
                    getReferenceClientRect: props.clientRect,
                })
            },

            onKeyDown(props) {
                if (props.event.key === 'Escape') {
                    popup[0].hide()

                    return true
                }

                return component.ref?.onKeyDown(props)
            },

            onExit() {
                popup[0].destroy()
                component.destroy()
            },
        }
    },
}