import { useState, useEffect } from "react";
import useScreenFollower from "./useScreenFollower";

const ScreenFollowScrollAwayListener = () => {
  const { screenFollowState, stopScreenFollowing } = useScreenFollower();
  useEffect(() => {
    const onScrollAway = (e) => {
      stopScreenFollowing();
    };
    let added = false;
    if (screenFollowState.isFollowing) {
      added = true;
      document.addEventListener("mousewheel", onScrollAway);
    }
    return () => {
      if (added) {
        document.removeEventListener("mousewheel", onScrollAway);
      }
    };
  }, [screenFollowState.isFollowing]);
};

export default ScreenFollowScrollAwayListener;
