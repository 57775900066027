
export const INITIAL_STATE = {
    isDocumentLocked: false,
    documentId: null,
    editor: null,
};

export const OwlEditorProviderActionType = {
    CONNECT_DOCUMENT: "CONNECT_DOCUMENT",
    SWITCH_DOCUMENT: "SWITCH_DOCUMENT",
}

export const OwlEditorProviderReducer = (state, action) => {
    switch (action.type) {
        case OwlEditorProviderActionType.CONNECT_DOCUMENT:
            return {
                ...state,
                editor: action.payload.editor,
                documentId: action.payload.documentId,
                isDocumentLocked: action.payload.isDocumentLocked,
            };
            case OwlEditorProviderActionType.SWITCH_DOCUMENT:
                return {
                    ...state,
                    editor: null,
                    documentId: null,
                    isDocumentLocked: false,
                };
        default:
            return state;
    }
}
