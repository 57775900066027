import dateFormat from "dateformat";
const positiveCursorOffest = 10000; // this is a magic number to turn negative cursor position to positive for easier bitwise operations

export function encodePresence(cursor, scroll) {
    // bitwise encode for signed integers. We're using 15 bits for amplitude, 1 bit for the sign.
    // * signed integers use the left most bit for sign so we need to extract it.
    // * we assume cursor position is signed, and scroll is unsigned.
    // TODO clean this up, for now also change the logic in live-avatar for scrolling
    let cursorX = cursor.x + positiveCursorOffest;
    let cursorY = cursor.y + positiveCursorOffest;
    let encodedX = cursorX | (scroll.x << 16);
    let encodedY = cursorY | (scroll.y << 16);
    return {
      x: encodedX,
      y: encodedY
    }
  };

export function decodePresence(encoded) {
    let encodedX = encoded.x;
    let encodedY = encoded.y;

    // bitwise encode
    let scrollX = (encodedX & (0xFFFF << 16)) >> 16;
    let scrollY = (encodedY & (0xFFFF << 16)) >> 16;
    let cursorX = (encodedX & 0x7FFF) - positiveCursorOffest;
    let cursorY = (encodedY & 0x7FFF) - positiveCursorOffest;

    let res = {
      cursor: {
        x: cursorX,
        y: cursorY
      },
      scroll: {
        x: scrollX,
        y: scrollY,
      }
    }
    return res;
  };

  export function getTimeString (time, format = "M:ss") {
    return dateFormat(new Date(time), format);
  };
