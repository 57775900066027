import { Extension } from "@tiptap/core";
import { Plugin, PluginKey } from "prosemirror-state";
import { editorState$ } from "../../../streams/editorState";

// based on https://github.com/ueberdosis/tiptap/blob/40a9404c94c7fef7900610c195536384781ae101/demos/src/Experiments/TrailingNode/Vue/trailing-node.ts

/**
 * Extension based on:
 * - https://github.com/ueberdosis/tiptap/blob/v1/packages/tiptap-extensions/src/extensions/TrailingNode.js
 * - https://github.com/remirror/remirror/blob/e0f1bec4a1e8073ce8f5500d62193e52321155b9/packages/prosemirror-trailing-node/src/trailing-node-plugin.ts
 */

export interface PageHeadingOptions {
  node: string;
}

function findPos(state, nodeToFind) {
  let targetPos = null;
  state.doc.descendants((node, pos, parent, index) => {
    if (node === nodeToFind) {
      targetPos = pos;
      return false;
    }
  });
  return targetPos;
}

/**
 * Add a trailing node to the document so the user can always click at the bottom of the document and start typing
 */
export const PageHeadingExtension = Extension.create<PageHeadingOptions>({
  name: "pageHeading",

  addProseMirrorPlugins() {
    const plugin = new PluginKey(this.name);

    return [
      new Plugin({
        key: plugin,
        appendTransaction: (transactions, oldState, newState) => {
          const docChanges =
            transactions.some((transaction) => transaction.docChanged) &&
            !oldState.doc.eq(newState.doc);

          if (!docChanges) {
            return;
          }
          const newTr = newState.tr;

          // check if last children of current doc is empty owlblock
          let firstNode = newState.doc.firstChild;

          if (!firstNode || firstNode.type.name !== "blockgroup") {
            throw new Error("Expected blockgroup");
          }

          firstNode = firstNode.firstChild;
          if (!firstNode && ["owlblock", "image", "liveMessageBlock", "imageBlock"].includes(firstNode.type.name)) {
            throw new Error("Last node unexpected");
          }

          if (firstNode.type.name === "owlblock") {
            // It is content we should ensure it has heading
            if (firstNode.nodeSize <= 4) {
              // empty sttring, make h1
              let pos = findPos(newState, firstNode);
              newTr.setNodeMarkup(pos, undefined, {
                ...firstNode.attrs,
                headingType: 1,
              });
              editorState$.notifyPageHeadingChange('Untitled');
              return newTr;
            } else {
              // Great. update text
              let cappedTitle = firstNode.textContent.substring(0, 60);
              editorState$.notifyPageHeadingChange(cappedTitle);
            }
          }

          return null;
        },
      }),
    ];
  },
});
