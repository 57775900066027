import React from "react";
import replayState$ from "../../streams/replayState";
import { trackDocumentEvent } from "../../utils/AmplitudeTrack";
import { fetchSingleLiveMessageInternal } from "../../utils/RecordingBackendUtils";
const onPlayLiveMessage = (liveMessage) => {
    trackDocumentEvent("lm replaying: onPlayLiveMessage")
    onHandleRecordingReload(liveMessage.recordings, liveMessage.liveMessageId);
    onHandleReplayingStart(liveMessage.recordings[0].recordingId)
}

export const onPlayLinkedRecording = async (embedding) => {
    trackDocumentEvent("lm replaying: onPlayLinkedRecording")
    // console.log('onPlayLinkedRecording',embedding)
    const liveMessage = await fetchSingleLiveMessageInternal(embedding.liveMessageId)
    console.warn("Downloaded the FULL live message for linked record. Please improve!", liveMessage)
    console.log('replayState$', replayState$.getState())
    onHandleRecordingReload(liveMessage.recordings, liveMessage.liveMessageId);
    onHandleReplayingStart(liveMessage.recordingIds[embedding.recordingIndex])
    if (embedding.startTs > 0) {
        onHandleSeekToTarget(liveMessage.recordingIds[embedding.recordingIndex], embedding.startTs)
    }
    if (replayState$.getState().activeRecordingPaused) {
        onHandleReplayingResumed()
    }
}

const onHandleReplayingStart = (recordingId, resetToStartAfterPlayback = true) => {
    let index = replayState$.findIndexFromRecording(recordingId);
    console.log(`onHandleReplayingStart:${recordingId} index`,index)
        if (index !== null) {
            replayState$.notifyReplayStart(recordingId, resetToStartAfterPlayback);
        }
    };

const onHandleRecordingReload = (recordings, playbackSessionId) => {
    replayState$.notifyReload(recordings, playbackSessionId);
};


const onHandleReplayingStop = (force=false, logging=true) => {
    if (logging) {
        // We call onHandleReplayingStop at every page load. So skip its logging otherwise quite confusing
        trackDocumentEvent("lm replaying: onHandleReplayingStop")
    }
    replayState$.notifyReplayStop(force);
}

const onHandleReplayingPaused = () => {
    trackDocumentEvent("lm replaying: onHandleReplayingPaused")
    replayState$.notifyReplayPaused();
}

const onHandleReplayingResumed = () => {
    trackDocumentEvent("lm replaying: onHandleReplayingResumed")
    replayState$.notifyReplayResumed();
}

const onHandleReplayingSpeedAdjusted = (speed) => {
    // (0.5, 1.0, 1.25, 1.5, 2.0)
    trackDocumentEvent("lm replaying: onHandleReplayingSpeedAdjusted", {speed:speed})
    replayState$.notifySpeedAdjusted(speed);
};

const fastForward = (index, delta, head) => {
    const recordings = replayState$.getRecordings();
    let recordingTotalTime = recordings[index].audio.endTime - recordings[index].audio.startTime;
    let headTime = head * recordingTotalTime / 100;
    if (recordingTotalTime < headTime + delta && index < recordings.length - 1) {
        let residual = headTime + delta - recordingTotalTime;
        fastForward(index + 1, residual, 0);
        return;
    }

    const activeRecording = replayState$.getActiveRecording();
    let activeIndex = replayState$.findIndexFromRecording(activeRecording.recordingId);
    if (index !== activeIndex) {
        replayState$.notifyReplayStart(recordings[index].recordingId);
    }

    let seekTime = Math.min(recordingTotalTime, headTime + delta);
    let seekPos = seekTime / recordingTotalTime * 100;
    replayState$.notifySeek(seekPos);
};

const onHandleReplayingFastForward = (delta) => {
    trackDocumentEvent("lm replaying: onHandleReplayingFastForward")
    const activeRecording = replayState$.getActiveRecording();
    let index = replayState$.findIndexFromRecording(activeRecording.recordingId);
    fastForward(index, delta, replayState$.getHead());
};

const rewind = (index, delta, head) => {
    const recordings = replayState$.getRecordings();
    let recordingTotalTime = recordings[index].audio.endTime - recordings[index].audio.startTime;
    let headTime = head * recordingTotalTime / 100;
    if (delta > headTime && index > 0) {
        let residual = delta - headTime
        rewind(index - 1, residual, 100);
        return;
    }

    const activeRecording = replayState$.getActiveRecording();
    let activeIndex = replayState$.findIndexFromRecording(activeRecording.recordingId);
    if (index !== activeIndex) {
        replayState$.notifyReplayStart(recordings[index].recordingId);
    }

    let seekTime = Math.max(0, headTime - delta);
    let seekPos = seekTime / recordingTotalTime * 100;
    replayState$.notifySeek(seekPos);
};

const onHandleReplayingRewind = (delta) => {
    trackDocumentEvent("lm replaying: onHandleReplayingRewind")
    const activeRecording = replayState$.getActiveRecording();
    let index = replayState$.findIndexFromRecording(activeRecording.recordingId);
    rewind(index, delta, replayState$.getHead());
}

const onHandleSeekToTarget = (recordingId, ts) => {
    console.log('onHandleSeekToTarget', recordingId, ts);
    let index = replayState$.findIndexFromRecording(recordingId);

    const activeRecording = replayState$.getActiveRecording();
    const recordings = replayState$.getRecordings();

    let activeIndex = replayState$.findIndexFromRecording(activeRecording.recordingId);
    if (index !== activeIndex) {
        replayState$.notifyReplayStart(recordingId);
    }

    let recordingTotalTime = recordings[index].audio.endTime - recordings[index].audio.startTime;
    let seekTime = ts;
    let seekPos = seekTime / recordingTotalTime * 100;
    replayState$.notifySeek(seekPos);
};

const onHandleReplayingClosedCaptionToggled = () => {
    trackDocumentEvent("lm replaying: onHandleReplayingClosedCaptionToggled")
    replayState$.notifyToggleClosedCaption();
}

const onHandleReplayingAudioMuteToggled = () => {
    trackDocumentEvent("lm replaying: onHandleReplayingAudioMuteToggled")
    replayState$.notifyToggleAudioMute();
}

const useLiveMessageReplay = () => {
    // WARNING: Don't use this state within this class otherwise there would be race condition!
    const [replayState, setReplayingState] = React.useState({});

    React.useEffect(() => {
        let replaySubscription = replayState$.subscribe(setReplayingState);
        replayState$.init();
        return () => {
            replaySubscription.unsubscribe();
        }
    }, []);

    return [replayState,
        {
            onPlayLiveMessage,
            onHandleReplayingStart,
            onHandleRecordingReload,
            onHandleReplayingStop,
            onHandleReplayingPaused,
            onHandleReplayingResumed,
            onHandleReplayingSpeedAdjusted,
            onHandleReplayingFastForward,
            onHandleReplayingRewind,
            onHandleSeekToTarget,
            onHandleReplayingClosedCaptionToggled,
            onHandleReplayingAudioMuteToggled,
        }];
};

export default useLiveMessageReplay;