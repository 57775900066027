import {
    useNavigate
} from "react-router-dom";
import Stack from "@mui/material/Stack";
import SignInBox from '../components/auth/signInBox';

const Login = () => {
    const navigate = useNavigate();
    const callback = (teamId) => {
        navigate(`/${teamId}`);
    }
    return (
        <Stack sx={{marginTop: '60px'}} direction="column" alignItems="center">
            <h1>Sign in or sign up for Notemad</h1>
            <SignInBox callback={callback}></SignInBox>
            <h3><Stack direction="row" alignItems="center"><img width="120" height="18" src="/02-full-logo.png" alt="nomad" /></Stack></h3>
        </Stack>
    );
}

export default Login;