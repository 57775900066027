import "./OwlEditor.scss";
import { EditorContent } from "@tiptap/react";
import React, { useEffect, useState } from "react";
import LiveCursors from "../cursor/LiveCursors";
import LiveCursorRecorder from "../cursor/LiveCursorRecorder";
import ScreenFollowScrollAwayListener from "../cursor/ScreenFollowScrollAwayListner";
import CursorStateListener from "../cursor/CursorStateListener";
import Box from "@mui/material/Box";
import LiveMessageAudioRecorder from "../recording/LiveMessageAudioRecorder";
import LiveMessageAudioReplayer from "../recording/LiveMessageAudioReplayer";
import LiveMessageTextCursorRecorder from "../recording/LiveMessageTextCursorRecorder";
import LiveMessageTextCursorReplayer from "../recording/LiveMessageTextCursorReplayer";
import AudioGrantDialog from "../liveMessage/AudioGrantDialog";
import UserStateUpdater from "../collab/UserStateUpdater";
import DocumentVisitUpdater from "../document/DocumentVisitUpdater";
import CommentViewModel from "../comment/CommentViewModel";
import CommentView from "../comment/CommentView";

let lastCursorUpdated = 0;
const OwlEditor = ({
  editor,
  documentId,
  isDocumentLocked,
  isNetworkSynced,
  isLocalSynced,
}) => {
  // Handle text label display (with some rate limiting)
  if (
    editor &&
    editor.storage.collaborationCursor.users &&
    Date.now() - lastCursorUpdated > 200
  ) {
    for (let user of editor.storage.collaborationCursor.users) {
      let labelForUser = `${user.userId}-collaboration-cursor__label`;
      let elements = document.getElementsByClassName(labelForUser);
      for (let element of elements) {
        element.hidden = !user.cursorState.showTextCursorLabel;
        lastCursorUpdated = Date.now();
      }
    }
  }

  // Handle lock state
  useEffect(() => {
    if (editor) {
      editor.setOptions({ editable: !isDocumentLocked });
    }
  }, [editor, isDocumentLocked]);

  const cursorPanel = React.useRef(null);

  // 6 is default doc size for new doc. The ready condition is
  // a) is network synced OR
  // b) is local synced AND doc size isn't brand new
  const isDocumentReady = isNetworkSynced || (isLocalSynced && editor.state.doc.content.size > 6);
  return (
    <Box ref={cursorPanel} sx={{ width: "850px", minWidth: "850px" }}>
      <LiveCursors editor={editor} cursorPanel={cursorPanel} />
      <LiveCursorRecorder cursorPanel={cursorPanel} />
      <EditorContent
        className="editor__content"
        style={{ display: isDocumentReady ? "block" : "none" }}
        editor={editor}
      />
      {isDocumentReady && <CommentView editor={editor} isDocumentReady={isDocumentReady} />}
      {/* <LmTranscriptPanel liveMessage={selectedLiveMessage} openPanel={openTranscriptPanel} setOpenPanel={setOpenTranscriptPanel} /> */}

      {/* non-UI components bellow */}
      <CommentViewModel editor={editor} isDocumentReady={isDocumentReady} />
      <LiveMessageAudioRecorder />
      <LiveMessageAudioReplayer />
      <LiveMessageTextCursorRecorder />
      <LiveMessageTextCursorReplayer />
      <ScreenFollowScrollAwayListener />
      <CursorStateListener cursorPanel={cursorPanel} />
      <UserStateUpdater editor={editor} />
      <DocumentVisitUpdater documentId={documentId}></DocumentVisitUpdater>
      <AudioGrantDialog></AudioGrantDialog>
    </Box>
  );
};

export default OwlEditor;
