import { mergeAttributes, Node } from "@tiptap/core";

import styles from "./Block.module.css";
export interface IBlock {
  HTMLAttributes: Record<string, any>;
}

export const ContentBlock = Node.create<IBlock>({
  name: "content",

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },
  addAttributes() {
    return {
      position: {
        default: undefined,
        renderHTML: (attributes) => {
          return {
            "data-position": attributes.position,
          };
        },
        parseHTML: (element) => element.getAttribute("data-position"),
      },
      contentType: {
        default: undefined,
        renderHTML: (attributes) => {
          return {
            "data-contenttype": attributes.contentType,
          };
        },
        parseHTML: (element) => {
          if (element.parentElement.getAttribute("data-list-type") === 'tli') {
            return 'taskitem';
          }
          return element.getAttribute("data-contenttype");
        }
      },
      checked: {
        default: false,
        keepOnSplit: false,
        parseHTML: element => element.getAttribute('data-checked') === 'true',
        renderHTML: attributes => ({
          'data-checked': attributes.checked,
        }),
      },
    };
  },

  content: "inline*",

  parseHTML() {
    return [
      {
        tag: "div",
        getAttrs: (element) => {
          if (typeof element === "string") {
            return false;
          }

          if (element.getAttribute("data-node-type") === "block-content") {
            // Null means the element matches, but we don't want to add any attributes to the node.
            return null;
          }

          return false;
        }
      },
      // For parsing headings & paragraphs copied from outside the editor.
      {
        tag: "p",
        priority: 100,
      },
    ];
  },


  addNodeView() {
    return ({
      node, HTMLAttributes, getPos, editor,
    }) => {
      // Set at TaskListPlugin
      if (node.attrs['contentType'] === 'taskitem') {
        const listItem = document.createElement('div')
        const checkboxWrapper = document.createElement('label')
        const checkboxStyler = document.createElement('span')
        const checkbox = document.createElement('input')
        const content = document.createElement('div')
        listItem.setAttribute('class', styles.blockContent);

        checkboxWrapper.contentEditable = 'false'
        checkbox.type = 'checkbox'
        checkbox.addEventListener('change', event => {
          // if the editor isn’t editable and we don't have a handler for
          // readonly checks we have to undo the latest change
          if (!editor.isEditable) {
            checkbox.checked = !checkbox.checked
            return
          }

          const { checked } = event.target as any

          if (editor.isEditable && typeof getPos === 'function') {
            editor
              .chain()
              .focus(undefined, { scrollIntoView: false })
              .command(({ tr }) => {
                const position = getPos()
                const currentNode = tr.doc.nodeAt(position)

                tr.setNodeMarkup(position, undefined, {
                  ...currentNode?.attrs,
                  checked,
                })

                return true
              })
              .run()
          }
        })

        Object.entries(this.options.HTMLAttributes).forEach(([key, value]) => {
          listItem.setAttribute(key, value)
        })

        listItem.dataset.checked = node.attrs.checked
        if (node.attrs.checked) {
          checkbox.setAttribute('checked', 'checked')
        }

        checkboxWrapper.append(checkbox, checkboxStyler)
        listItem.append(checkboxWrapper, content)

        Object.entries(HTMLAttributes).forEach(([key, value]) => {
          listItem.setAttribute(key, value)
        })

        return {
          dom: listItem,
          contentDOM: content,
          update: updatedNode => {
            if (updatedNode.type !== this.type) {
              return false
            }

            listItem.dataset.checked = updatedNode.attrs.checked
            if (updatedNode.attrs.checked) {
              checkbox.setAttribute('checked', 'checked')
            } else {
              checkbox.removeAttribute('checked')
            }

            return true
          },
        }
      }

      // not taskitem
      return {};
    }
  },

  renderHTML({ node, HTMLAttributes }) {
    if (node.attrs['contentType'] === 'taskitem') {
      return [
        "div",
        mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
          class: styles.blockContent,
          "data-node-type": "block-content",
        }),
        [
          'label',
          {
            'contentEditable': false,
          },
          [
            'input',
            {
              type: 'checkbox',
              checked: node.attrs.checked ? 'checked' : null,
            },
          ],
          ['span'],
        ],
        // TODO: The extra nested div is only needed for placeholders, different solution (without extra div) would be preferable
        // We can't use the other div because the ::before attribute on that one is already reserved for list-bullets
        ["div", 0],
      ];
    }

    return [
      "div",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
        class: styles.blockContent,
        "data-node-type": "block-content"
      }),
      // HACK HACK HACK HACK: Adding a extra label so that collab cursor won't block text insertion
      [
        'label',
        {
          'contentEditable': false,
        },
        ['span'],
      ],
      // TODO: The extra nested div is only needed for placeholders, different solution (without extra div) would be preferable
      // We can't use the other div because the ::before attribute on that one is already reserved for list-bullets
      ["div", 0],
    ];
  },
});
