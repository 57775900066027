import * as React from 'react';

import {
  useParams,
  useNavigate
} from "react-router-dom";
import { AVAILABLE_TEAMS } from "../common/Constants";
import TeamSpace from "./TeamSpace";
import useAuth from '../utils/useAuth';

const App = () => {
  const [teamId, setTeamId] = React.useState(null);
  const [documentPath, setDocumentPath] = React.useState(null);
  const params = useParams();
  const navigate = useNavigate();
  const { currentUser, loading } = useAuth();

  // Determine team routing based on team id
  React.useEffect(() => {
    const moveToDefaultTeam = () => {
      navigate(`/mine`);
    }

    // Check if current team is whitelisted
    if (params.teamId) {
      if (AVAILABLE_TEAMS.includes(params.teamId)) {
        setTeamId(params.teamId);
        setDocumentPath(params.documentPath);
        return;
      }

      if ((params.teamId === 'mine') && currentUser?.username) {
        setTeamId(currentUser.username);
        setDocumentPath(params.documentPath);
        return;
      }
    }

    // Load default team if not specified
    if (currentUser) {
      moveToDefaultTeam(currentUser.username);
    }
    
  }, [params.teamId, params.documentPath, currentUser]);

  React.useEffect(() => {
    // For team space currently don't require log in
    if (!loading && !currentUser && !AVAILABLE_TEAMS.includes(params.teamId)) {
      console.log('navigate to login')
      navigate(`/login`);
    }
  }, [loading, currentUser, params.teamId]);

  return (
    <>
      {!loading && teamId && <TeamSpace teamId={teamId} documentPath={documentPath} />}
    </>
  )
};

export default App;