import * as React from 'react';
import { useEffect, useState, useMemo } from 'react';
import {
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import Stack from "@mui/material/Stack";
import TeamUserLoader from "../components/team/TeamUserLoader";
import AppSideBar from "../components/sidebar/AppSideBar";
import { decodeRoutePath, encodeRoutePath } from "../utils/DocumentRoutingUtils";
import { useFetchDocuments } from "../utils/DocumentBackendUtils";
import { DRAWER_WIDTH, AVAILABLE_TEAMS } from "../common/Constants";
import DocumentRoom from "./DocumentRoom";
import { trackEvent } from "../utils/AmplitudeTrack";
import { DEFAULT_ACCOUNT_ID } from "../common/Constants";
import { trackDocumentEvent } from "../utils/AmplitudeTrack";
import { createNewOnboardingDocument } from "../utils/DocumentBackendUtils";
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { editorState$ } from "../streams/editorState";
import { useListShareTargets } from '../utils/ShareTargetBackendUtils';

const computeUrlTeamId = (teamId) => {
  // Hack to support existing teams
  if (AVAILABLE_TEAMS.includes(teamId)) {
    return teamId;
  } else {
    return 'mine';
  }
}


const WelcomeDialog = () => {
  return (
    <Dialog open={true}>
      <DialogTitle sx={{ fontSize: '18px' }}>Setting up your private space now...</DialogTitle>
    </Dialog>
  );
}

/**
 * Represents team level states. Upon sign in, a user should be long to a team.
 * Loads states at team level (e.g. team users, list of documents)
 */
const TeamSpace = ({ teamId, documentPath }) => {
  const [curDocumentId, setCurDocumentId] = React.useState(null);
  const [showWelcomeDialog, setShowWelcomeDialog] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { documents, isLoading, mutate } = useFetchDocuments(teamId, 'TeamSpace');
  const { shareTargets, isLoading: isSharedLoading } = useListShareTargets();
  const allDocuments = useMemo(() => {
    let allDocs = [];
    if (documents) {
      for (let document of documents) {
        allDocs.push(document);
      }
    }
    if (shareTargets) {
      let sharedDocs = shareTargets.map(shareTarget => {
        return shareTarget?.documentShare?.document;
      }).filter(doc => doc !== undefined);
      for (let sharedDoc of sharedDocs) {
        allDocs.push(sharedDoc);
      }
    }
    return allDocs;
  }, [shareTargets, documents]);
  console.log('rerender TeamSpace', teamId);

  // Determine room routing based on documentPath
  useEffect(() => {
    const moveToFirstPage = () => {
      console.log('moveToFirstPage');
      // move to first page
      if (documents.length > 0) {
        const documentPath = encodeRoutePath(
          documents[0].documentName,
          documents[0].documentId
        );
        // Proxy url param as well for subscribe success
        navigate({ pathname: `/${computeUrlTeamId(documents[0].teamId)}/${documentPath}`, search: searchParams.toString() });
      } else if (!isLoading) {
        console.log('first creation of account, create initial pages', documents);
        setShowWelcomeDialog(true);
        createNewOnboardingDocument(teamId, DEFAULT_ACCOUNT_ID, 'Welcome').then((doc) => {
          trackDocumentEvent("Create new onboarding doc");
          mutate();
          setShowWelcomeDialog(false);
        });
      }
    };


    if (documentPath) {
      let documentId = decodeRoutePath(documentPath);
      // if room name not in document list, move to first page
      let inDocumentList = false;
      for (let doc of documents) {
        if (doc.documentId === documentId) {
          inDocumentList = true;
          // Update document title
          document.title = `${doc.documentName} - Notemad`;
        }
      }

      if (inDocumentList) {
        setCurDocumentId(documentId);
        trackEvent("Open doc", { teamId: teamId, documentId: documentId })
        return;
      }

      if (shareTargets) {
        for (let shareTarget of shareTargets) {
          if (shareTarget && shareTarget.documentShare && shareTarget.documentShare.document && shareTarget.documentShare.document.documentId === documentId) {
            inDocumentList = true;
            // Update document title
            document.title = `${shareTarget.documentShare.document.documentName} - Notemad`;
          }
        }
      }
      if (inDocumentList) {
        setCurDocumentId(documentId);
        trackEvent("Open share doc", { teamId: teamId, documentId: documentId })
        return;
      }
    }

    // Load document for teams if room name is null or not exist
    if (!isLoading && !isSharedLoading) {
      moveToFirstPage();
    }
  }, [documentPath, documents, teamId, isLoading, shareTargets, isSharedLoading]);

  const handleDocumentChangeCallback = (documentId) => {
    editorState$.notifyDocumentChange(teamId, documentId);
  };

  // Here we load states at team-level
  return (
    <Stack direction="row">
      <AppSideBar
        teamId={teamId}
        drawerWidth={DRAWER_WIDTH}
        documentId={curDocumentId}
      />
      <DocumentRoom documents={allDocuments} documentId={curDocumentId} onDocumentChangeCallback={handleDocumentChangeCallback} />
      <TeamUserLoader teamId={teamId} />
      {showWelcomeDialog && <WelcomeDialog></WelcomeDialog>}
    </Stack>
  )
};

export default TeamSpace;