import React from "react";
import recordingState$ from "../../streams/recordingState";
import { trackDocumentEvent } from "../../utils/AmplitudeTrack";
export const onHandleRecordingStop = () => {
    trackDocumentEvent("lm recording: onHandleRecordingStop")
    recordingState$.stopRecording();
};

export const onHandleRecordingStart = (holdToRecord = false) => {
    trackDocumentEvent("lm recording: onHandleRecordingStart", {holdToRecord: holdToRecord})
    recordingState$.startRecording(holdToRecord);
};

export const onHandleRecordingSubmit = (accountId, documentId, onSaved) => {
    trackDocumentEvent("lm recording: onHandleRecordingSubmit")
    recordingState$.submit(accountId, documentId, onSaved);
}

export const onDeleteLastRecording = () => {
    trackDocumentEvent("lm recording: onDeleteLastRecording")
    recordingState$.deleteLastRecording();
}

export const onHandleReloadRecordings = (documentId) => {
    recordingState$.reload(documentId);
}

export const onAbortCurrentRecording = () => {
    recordingState$.clear();
}

export const onChangeLanguageType = (languageType) => {
    recordingState$.changeLanguageType(languageType);
}

export const onHandlePermissionUpdate = (granted) => {
    recordingState$.updatePermission(granted);
}
const useLiveMessageRecording = () => {
    const [recordingState, setRecordingState] = React.useState({});
    
    React.useEffect(() => {
        let subscription = recordingState$.subscribe(setRecordingState);
        recordingState$.init();
        return () => {
            subscription.unsubscribe();
        }
    }, []);

    return [recordingState, {onHandleRecordingStart, onHandleRecordingStop, onDeleteLastRecording, onHandleRecordingSubmit, onHandleReloadRecordings, onAbortCurrentRecording, onChangeLanguageType, onHandlePermissionUpdate}];
};

export default useLiveMessageRecording;