import { useEffect } from 'react';
import { mentionSuggestionState$ } from '../../blocknode/shared/plugins/mention/MentionSuggestion';
import { useFetchTeamUsers } from '../../utils/TeamBackendUtils';
import { teamUsersState$ } from '../../streams/teamUserState';

const TeamUserLoader = ({ teamId }) => {
    const { teamUsers, mutate } = useFetchTeamUsers(teamId);

    useEffect(() => {
        // Update suggestion lists after loaded
        if (teamUsers) {
            mentionSuggestionState$.notifySuggestionChange(teamUsers.map(teamUser => teamUser.accountId));
            teamUsersState$.notifyTeamUsersChange(teamUsers);
        }
    }, [teamUsers]);

    return <></>
}

export default TeamUserLoader;
