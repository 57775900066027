import Tippy from "@tippyjs/react";
import { forwardRef, useEffect, useImperativeHandle } from "react";
import styles from "./SimpleToolbarButton.module.css";
import { TooltipContent } from "../tooltip/TooltipContent";
import React from "react";
import tippy, { Instance, Props } from "tippy.js";
import { createRoot } from 'react-dom/client';

export type SimpleToolbarButtonProps = {
  onClick?: (e: React.MouseEvent) => void;
  icon?: React.ComponentType<{ className: string }>;
  mainTooltip: string;
  secondaryTooltip?: string;
  isSelected?: boolean;
  children?: any;
  isDisabled?: boolean;
};


/**
 * Helper for basic buttons that show in the inline bubble menu.
 */
export const SimpleToolbarButton = forwardRef(
  (props: SimpleToolbarButtonProps, forwardRef) => {
    const ButtonIcon = props.icon;

    const innerRef = React.useRef(null);

    // Make sure innerRef propogates outside
    useImperativeHandle(forwardRef, () => innerRef.current);

    // HACK HACK HACK: This is needed to ensure the tippy goes away when we disable it via keyboard (as opposed to mouse)
    // By default, tippy only react to mouse changes
    useEffect(() => {
      let instance = null;
      let downHandler = null;
      let root = null;
      if (innerRef) {
        const element = document.createElement("div");
        root = createRoot(element);
        root.render(<TooltipContent
          mainTooltip={props.mainTooltip}
          secondaryTooltip={props.secondaryTooltip}
        />);

        instance = tippy(innerRef.current, {
          content: element
        });

        downHandler = () => {
          instance.hide();
        };
        window.addEventListener('keydown', downHandler);
      }

      return () => {
        if (instance) {
          instance.destroy();
          window.removeEventListener('keydown', downHandler);
        }
        if (root) {
          // Make sure to unmount
          root.unmount();
        }
      }
    }, []);

    return (
      <button
        style={{ border: "0px", cursor: "pointer", borderRadius: "3px", padding: "0px", height: "32px", minHeight: "32px", minWidth: "32px" }}
        className={props.isDisabled ? styles.isButtonDisabled : (props.isSelected ? styles.isButtonSelected : styles.isButtonUnselected)}
        ref={innerRef}
        onClick={props.onClick}>
        {ButtonIcon && (
          <ButtonIcon
            className={
              styles.icon +
              " " +
              (props.isSelected ? styles.isSelected : "") +
              " " +
              (props.isDisabled ? styles.isDisabled : "")
            }
          />)
        }
        {props.children}
      </button>
    );
  }
);
