import { EditorView } from "prosemirror-view";


export function blockPosAtCoords(
  coords: { left: number; top: number },
  view: EditorView
) {
  let block = getDraggableBlockFromCoords(coords, view);
  if (block && block.node.nodeType === 1) {
    // TODO: this uses undocumented PM APIs? do we need this / let's add docs?
    const docView = (view as any).docView;
    let desc = docView.nearestDesc(block.node, true);
    if (!desc || desc === docView) {
      return null;
    }
    return desc.posBefore;
  }
  return null;
}

export function blockAtCoords(
  coords: { left: number; top: number },
  view: EditorView
) {
  let block = getDraggableBlockFromCoords(coords, view);
  if (block && block.node.nodeType === 1) {
    return block;
  }
  return null;
}

export function getDraggableBlockFromCoords(
  coords: { left: number; top: number },
  view: EditorView
) {
  if (!view.editable) {
    // if view not editable, nothing is draggable
    return undefined;
  }

  let pos = view.posAtCoords(coords);
  if (!pos) {
    return undefined;
  }
  // HACK: somehow couldn't find atom block from `view.domAtPos()` as it returns the base block, use this to try atom block
  // reference: https://discuss.prosemirror.net/t/domatpos-for-atom-block-nodes/3800/2
  let node = (view.nodeDOM(pos.inside) || view.domAtPos(pos.pos).node) as HTMLElement;

  if (node === view.dom) {
    return undefined;
  }

  // Special handling for node view nodes
  if (node.classList.contains('react-renderer')) {
    // is node view nodes. We need to go down one level deeper to find id.
    node = node.firstChild as HTMLElement;
  }

  // Find the first parent which contains data-id
  while (
    (node &&
      node.parentNode &&
      node.parentNode !== view.dom &&
      !node.hasAttribute?.("data-id")) ||
    // HACK HACK HACK: Mention also has data-id (WHY??) Here we skip draggable block detection when it happenes
    node.getAttribute("data-type") === "mention"
  ) {
    node = node.parentNode as HTMLElement;
  }
  // All draggable blocks should have data-id located
  if (!node || !node.getAttribute("data-id")) {
    return undefined;
  }
  return { node, id: node.getAttribute("data-id")! };
}
