import { mergeAttributes, Node } from '@tiptap/core'
import { ReactNodeViewRenderer } from '@tiptap/react'

import LiveMessageBlock from '../../../components/liveMessage/LiveMessageBlock'

const LiveMessageBlockExtension =  Node.create({
    name: 'liveMessageBlock',
  
    group: 'block',
  
    atom: true,

    addAttributes() {
      return {
        liveMessageId: {
          default: null, // live message id will be available upon saving recordings to the cloud
        },
        localId: {
          default: null, 
        },
        lmDuration: {
          default: 0
        },
        blockEmbeddings: {
          default:[]
        }
      }
    },
  
    parseHTML() {
      return [
        {
          tag: 'liveMessageBlock',
        },
      ]
    },
  
    renderHTML({ HTMLAttributes }) {
      return ['liveMessageBlock', mergeAttributes(HTMLAttributes)]
    },
  
    addNodeView() {
      return ReactNodeViewRenderer(LiveMessageBlock)
    },
  });
export default LiveMessageBlockExtension;