import Underline from "@tiptap/extension-underline";

const UnderlineConflict = Underline.extend({
    parseHTML() {
        return [
            {
                tag: 'u',
            },
            {
                style: 'text-decoration',
                consuming: false,
                getAttrs: (element) => {
                    if (typeof element === "string") {
                        return false;
                    }

                    // This is so that if existing underline in other atom has other color it won't override and and parsed underline by itself
                    if (element.getAttribute("text-decoration") === "underline" && !element.getAttribute("text-decoration-color")) {
                        return {};
                    }

                    return false;
                }
            },
        ]
    },
});

export default UnderlineConflict;
