import { Box, Stack, Button, Card } from "@mui/material";
import EventReplayer from "../../utils/EventReplayer";
import getTimeString from "../../utils/ComputeUtil";
import useLiveMessageReplay from "../recording/useLiveMessageReplay";
import { useEffect, useState } from "react";
import useLiveMessageViewModel from "./useLiveMessageViewModel";
import { trackDocumentEvent } from "../../utils/AmplitudeTrack";

const LmTranscriptView = ({
  liveMessage,
  // onTranscriptSentenceClicked,
  sideSpace = "16px",
  hideTitle = false,
  highlightCurrentSentence = true,
  isPreview = false
}) => {
  const [replayState, { onHandleSeekToTarget, onHandleReplayingStart, onPlayLiveMessage, onHandleReplayingResumed }] = useLiveMessageReplay();
  const {
    selectedLiveMessage,
    selectLiveMessage,
  } = useLiveMessageViewModel();
  const [replayEvent, setReplayEvent] = useState(null);
  // Handle replay
  useEffect(() => {
    if (!replayState.activeRecording) {
      // do nothing if cur playing is empty.
      return;
    }
    if (replayState.activeRecordingPaused) {
      // do nothing if cur playing is paused.
      return;
    }
    let curRecording = replayState.activeRecording;
    let curTrack = curRecording.audioTranscribe;
    // console.log("curRecording", curRecording);
    if (!curTrack) {
      return;
    }

    let eventReplayer = new EventReplayer();

    eventReplayer.startReplay(
      "audioTranscribe",
      curTrack,
      replayState.activeRecordingSeekTime,
      replayState.speed,
      () => {
        /* onStarted */
      },
      () => {
        /* onStopped */
      },
      () => {
        /* onEndOfTrack */
      },
      (event) => {
        setReplayEvent({ recordingId: curRecording.recordingId, event });
      }
    );

    return () => {
      if (eventReplayer) {
        eventReplayer.signalStop();
      }
    };
  }, [
    replayState.activeRecording,
    replayState.activeRecordingSeekTime,
    replayState.activeRecordingPaused,
    replayState.speed,
  ]);
  const onTranscriptSentenceClicked = (recordingId, ts) => {
    trackDocumentEvent("onTranscriptSentenceClicked", {isInLineLMPreview: isPreview});
    // console.log('ispreview:',isPreview, `activeRecording:${replayState.activeRecording} `,liveMessage.liveMessageId, selectedLiveMessage.liveMessageId)
    // when users click a sentence through a lm preview, we want to select that live message and start playing.
    if (isPreview && (!replayState.activeRecording || selectedLiveMessage.liveMessageId !== liveMessage.liveMessageId)) {
      selectLiveMessage(liveMessage);
      onPlayLiveMessage(liveMessage);
    } 
    onHandleSeekToTarget(recordingId, ts)
    if (replayState.activeRecordingPaused) {
      onHandleReplayingResumed()
    }
  }
  const lastTranscript = liveMessage.transcripts ? 
    liveMessage.transcripts[liveMessage.transcripts.length - 1] : null;
  const lastSentence =
    lastTranscript?.sentences[lastTranscript.sentences.length - 1];

  return (
    <Stack
      direction="column"
      sx={{ paddingTop: "16px", paddingBottom: "16px" }}
    >
      {!hideTitle && (
        <Box
          sx={{
            marginLeft: sideSpace,
            marginRight: sideSpace,
            marginBottom: "8px",
            color: "#aaa",
            fontWeight: "bold",
            fontSize: "16px",
            display: "flex",
            alignItems: "center",
          }}
        >
          Transcript
        </Box>
      )}
      {liveMessage.transcripts && liveMessage.transcripts.map((transcript) => {
        return (
          <Box key={transcript.recordingId}>
            {transcript.sentences.map((sentence) => {
              const isCurrentSentence =
                replayEvent &&
                replayEvent.recordingId === transcript.recordingId &&
                replayEvent.event.ts >= sentence.start &&
                replayEvent.event.ts < sentence.end;
              const isLastSentence = lastSentence == sentence;
              return (
                <Box
                  key={sentence.start}
                  onClick={() => {
                    onTranscriptSentenceClicked(
                      transcript.recordingId,
                      sentence.start
                    );
                  }}
                  sx={{
                    paddingLeft: sideSpace,
                    paddingRight: sideSpace,
                    paddingTop: "4px",
                    paddingBottom: isLastSentence ? "0px" : "4px",
                    cursor: "pointer",
                    color: highlightCurrentSentence && isCurrentSentence ? "#7C6FFF" : "#000",
                    "&:hover": {
                      background: "#eee",
                    },
                  }}
                >
                  {sentence.text}
                </Box>
              );
            })}
          </Box>
        );
      })}
    </Stack>
  );
};

export default LmTranscriptView;
