
export default class AudioRecorder {

    constructor() {
        this.mediaRecorder = null;
        this.recordedChunks = [];
        this.audioDataCallback = null;
        this.stream = null;
    }

    startRecordAudio = async () => {
        await this.#initializeAudioRecord("");
        if (this.mediaRecorder) {
            this.mediaRecorder.start();
        }
        
    };

    endRecordAudio = (audioDataCallback) => {
        this.audioDataCallback = audioDataCallback;
        if (this.mediaRecorder) {
            this.mediaRecorder.stop();
        }
    }

    #initializeAudioRecord = async (name) => {
        try {
            this.stream = await navigator.mediaDevices.getUserMedia({
                audio: true,
                video: false
            });
            this.#handleStream(this.stream, name);
        } catch (e) {
            this.#handleError(e)
        }
    };


    #handleError = (e) => {
        console.log('handleError', e);
        // Reset to release reference
        this.mediaRecorder = null;
        this.recordedChunks = [];
        this.audioDataCallback = null;
        // https://stackoverflow.com/questions/44274410/mediarecorder-stop-doesnt-clear-the-recording-icon-in-the-tab/44274928#44274928
        if (this.stream) {
            this.stream.getTracks() // get all tracks from the MediaStream
            .forEach(track => track.stop()); // stop each of them
        }
        this.stream = null;
    };

    #handleStop = async (e) => {
        const blob = new Blob(this.recordedChunks, { type: 'video/webm; codecs=vp9' });
        // const buffer = Buffer.from(await blob.arrayBuffer());

        // console.log('handleStop', buffer);
        //console.log('handleStop', blob);
        this.audioDataCallback(blob);

        // Reset to release reference
        this.mediaRecorder = null;
        this.recordedChunks = [];
        this.audioDataCallback = null;
        // https://stackoverflow.com/questions/44274410/mediarecorder-stop-doesnt-clear-the-recording-icon-in-the-tab/44274928#44274928
        this.stream.getTracks() // get all tracks from the MediaStream
            .forEach(track => track.stop()); // stop each of them
        this.stream = null;
    };

    #handleDataAvailable = (e) => {
        //console.log('audio data available');
        this.recordedChunks.push(e.data);
    };

    #handleStream = (stream, name) => {
        //console.log('stream', stream, 'name', name);
        const options = { mimeType: 'video/webm; codecs=vp9' };
        this.mediaRecorder = new MediaRecorder(stream, options);

        //console.log('audio save start');
        this.mediaRecorder.ondataavailable = this.#handleDataAvailable;
        this.mediaRecorder.onstop = this.#handleStop;
    }
};
