
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useState, useEffect, useRef } from "react";
import ClickAwayListener from '@mui/base/ClickAwayListener';
import Switch from '@mui/material/Switch';
import PublicRoundedIcon from '@mui/icons-material/PublicRounded';
import Fade from '@mui/material/Fade';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import { useListDocumentShares, createNewDocumentShare, deleteSingleDocumentShare } from '../../utils/DocumentShareBackendUtils';
import Tooltip from '@mui/material/Tooltip';
import { editorState$ } from "../../streams/editorState";
import { encodeRoutePath } from "../../utils/DocumentRoutingUtils";
import { trackDocumentEvent } from "../../utils/AmplitudeTrack";

const constructUrl = (documentShareId, pageHeading) => {
    let route = encodeRoutePath(pageHeading, documentShareId);
    if (window.location.host.includes('localhost')) {
        return `http://${window.location.host}/share/${route}`
    } else {
        return `https://${window.location.host}/share/${route}`
    }
    
};

const CustomizedInput = ({ documentShare }) => {
    const [isCopied, setIsCopied] = useState(false);
    const inputRef = useRef(null);
    const onCopyClick = () => {
        //copy to clip board
        trackDocumentEvent("Share: copy share url");

        if (inputRef) {
            console.log(inputRef.current);
            navigator.clipboard.writeText(inputRef.current.value).then(function () {
                setIsCopied(true);
            }, function (err) {
                console.error('Async: Could not copy text: ', err);
            });
        }

    };
    return (
        <Box
            sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: '100%', border: '1px solid rgba(0, 0, 0, 0.12)', borderRadius: '8px', height: '36px' }}
        >
            <InputBase
                inputRef={inputRef}
                sx={{ ml: 1, flex: 1 }}
                inputProps={{ 'aria-label': 'search google maps' }}
                value={constructUrl(documentShare.documentShareId, editorState$.getState().pageHeading)}
            />
            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
            <Tooltip title={isCopied ? "Copied" : "Copy"} placement="bottom">
                <IconButton onClick={onCopyClick}>
                    <ContentCopyRoundedIcon />
                </IconButton>
            </Tooltip>

        </Box>
    );
}
const ShareMenu = ({ documentId, onClose }) => {
    const [shareEnabled, setShareEnabled] = useState(false);
    const { documentShares, mutate } = useListDocumentShares(documentId);

    // Initialize - Load if share is available
    useEffect(() => {
        if (documentShares && documentShares.length > 0) {
            setShareEnabled(true);
        } else {
            setShareEnabled(false);

        }
    }, [documentShares]);

    const onShareChange = (e) => {
        if (e.target.checked) {
            // create share
            console.log('checked');
            trackDocumentEvent("Share: enable share doc");
            createNewDocumentShare(documentId).then(() => {
                mutate();
            });

        } else {
            // delete share
            console.log('unchecked');
            trackDocumentEvent("Share: disable share doc");
            for (let documentShare of documentShares) {
                deleteSingleDocumentShare(documentShare.documentShareId).then(() => {
                    mutate();
                });
            }
        }
    };

    return (<ClickAwayListener onClickAway={onClose}>
        <Paper sx={{ position: 'absolute', top: '40px', right: '0px', width: '400px', padding: '20px' }}>
            <Grid container>
                <Grid item xs={10}>
                    <Stack direction="row" alignItems="center" sx={{ height: '100%' }} spacing={'12px'}>
                        <PublicRoundedIcon />
                        <Stack direction="column">
                            <Typography sx={{ fontSize: '14px' }}>Share</Typography>
                            <Typography sx={{ fontSize: '14px', opacity: '0.5' }}>Anyone with link can edit</Typography>
                        </Stack>
                    </Stack>
                </Grid>
                <Grid item xs={2}>
                    <Stack direction="row" alignItems="center" sx={{ height: '100%' }}>
                        <Switch onChange={onShareChange} checked={shareEnabled} />
                    </Stack>
                </Grid>
            </Grid>

            {shareEnabled && documentShares && documentShares.length > 0 &&
                <Stack direction="row" alignItems="center" sx={{ height: '100%', marginTop: '12px' }}>
                    <CustomizedInput documentShare={documentShares[0]}></CustomizedInput>
                </Stack>
            }
        </Paper>
    </ClickAwayListener>)
}
const TopCommandSection = ({ documentId }) => {
    const [showShareMenu, setShowShareMenu] = useState(false);
    const onClickShare = () => {
        trackDocumentEvent("Share: share button clicked");
        setShowShareMenu(true);
    };

    const handleClose = () => {
        setShowShareMenu(false);
    };

    return (
        <Box sx={{ position: 'relative' }}>
            <Fade in={showShareMenu}>
                <div>
                    {showShareMenu && <ShareMenu documentId={documentId} onClose={handleClose}></ShareMenu>}
                </div>
            </Fade>
            <Button variant="primary" sx={{ textTransform: 'none' }} onClick={onClickShare}>Share</Button>
        </Box>
    )
};

export default TopCommandSection;