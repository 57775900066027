import { mergeAttributes, Node } from '@tiptap/core'
import { ReactNodeViewRenderer } from '@tiptap/react'
import { createImagePastePlugin } from "./ImagePastePlugin";
import { createImageDragDropPlugin } from "./ImageDragDropPlugin";
import { createImageCommentPlugin } from "./ImageCommentPlugin";
import ImageBlock from '../../../components/image/ImageBlock'

const ImageBlockExtension = Node.create({
    name: 'imageBlock',
    group: 'block',

    atom: true,

    addAttributes() {
        return {
            imageSource: {
                default: null, // imageSource will be updated once user selects image
                renderHTML: (attributes) => {
                    return {
                        "src": attributes.imageSource,
                    };
                },
                parseHTML: (element) => element.getAttribute("src"),

            },
            localImageUrl: {
                default: null,
            },
            embeddings: {
                default: undefined,
            },
            comment: {
                default: undefined,
            }
        }
    },

    parseHTML() {
        return [
            {
                tag: 'imageBlock',
            },
            {
                // tag: this.options.allowBase64
                //     ? 'img[src]'
                //     : 'img[src]:not([src^="data:"])',
                tag: 'img[src]:not([src^="data:"])',
            }
        ]
    },

    renderHTML({ HTMLAttributes }) {
        return ['imageBlock', mergeAttributes(HTMLAttributes)]
    },

    addProseMirrorPlugins() {
        return [...(this.parent?.() || []), createImagePastePlugin(), createImageDragDropPlugin(), createImageCommentPlugin()];
    },

    addNodeView() {
        return ReactNodeViewRenderer(ImageBlock)
    },
});
export default ImageBlockExtension;