import * as React from 'react';
import Stack from '@mui/material/Stack';
const MobileApp = () => {

    return (
        <Stack sx={{ padding: "20px"}} direction="column" justifyContent="center" alignItems="center">
            <img style={{width: '80%'}} src={"/stop.png"}></img>
            <h1>Whoops!</h1>
            <p style={{ opacity: 0.8, fontSize: '14px', textAlign: 'center' }}>
                Our product is not yet available on mobile devices or tablets. Please use this website on a desktop or laptop computer instead. 
            </p>
        </Stack>
    )
};

export default MobileApp;