import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { deleteSingleShareTarget } from '../../utils/ShareTargetBackendUtils'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

export default function SharedDocumentMenu({ documentShareId, documentMutate }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDelete = async () => {
        handleClose();
        // TODO: Replace this by a proper UI
        let resp = window.prompt("Unlink shared document from your account? Type 'yes' to confirm")
        if (resp === 'yes') {
            // we have name, create doc
            await deleteSingleShareTarget(documentShareId);
            documentMutate();
        }
    };

    return (
        <div>
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreHorizIcon fontSize={"small"} />
            </IconButton>
            {documentShareId &&
                <Menu
                    id="long-menu"
                    MenuListProps={{
                        'aria-labelledby': 'long-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                >
                    <MenuItem onClick={handleDelete}>
                        <ListItemIcon>
                            <RemoveCircleOutlineIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Remove</ListItemText>
                    </MenuItem>
                </Menu>
            }
        </div>
    );
}
