import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DeleteIcon from '@mui/icons-material/Delete';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import ArchiveIcon from '@mui/icons-material/Archive';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import { updateSingleDocumentEditStatus, updateSingleDocumentArchiveStatus, updateSingleDocumentName, deleteSingleDocument } from '../../utils/DocumentBackendUtils';

export default function DocumentMenu({ documentId, editStatus, archiveStatus, documentMutate, showArchiveOption=true }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLock = async () => {
        handleClose();
        await updateSingleDocumentEditStatus(documentId, 1);
        documentMutate();
    }

    const handleUnlock = async () => {
        handleClose();
        await updateSingleDocumentEditStatus(documentId, 0);
        documentMutate();
    }

    const handleArchive = async () => {
        handleClose();
        await updateSingleDocumentArchiveStatus(documentId, 1);
        documentMutate();
    }

    const handleUnarchive = async () => {
        handleClose();
        await updateSingleDocumentArchiveStatus(documentId, 0);
        documentMutate();
    }

    const handleDelete = async () => {
        handleClose();
        // TODO: Replace this by a proper UI
        let resp = window.prompt("Permanently delete document? Type 'yes' to confirm")
        if (resp === 'yes') {
            // we have name, create doc
            await deleteSingleDocument(documentId);
            documentMutate();
        }
    };

    return (
        <div>
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreHorizIcon fontSize={"small"} />
            </IconButton>
            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {editStatus === 1 &&
                    <MenuItem onClick={handleUnlock}>
                        <ListItemIcon>
                            <LockOpenIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Unlock</ListItemText>
                    </MenuItem>
                }
                {!editStatus &&
                    <MenuItem onClick={handleLock}>
                        <ListItemIcon>
                            <LockIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Lock</ListItemText>
                    </MenuItem>
                }
                {showArchiveOption && archiveStatus === 1 &&
                    <MenuItem onClick={handleUnarchive}>
                        <ListItemIcon>
                            <UnarchiveIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Unarchive</ListItemText>
                    </MenuItem>
                }
                {showArchiveOption && !archiveStatus &&
                    <MenuItem onClick={handleArchive}>
                        <ListItemIcon>
                            <ArchiveIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Archive</ListItemText>
                    </MenuItem>
                }
                <MenuItem onClick={handleDelete}>
                    <ListItemIcon>
                        <DeleteIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Delete</ListItemText>
                </MenuItem>
            </Menu>
        </div>
    );
}
