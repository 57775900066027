import MenuList from '@mui/material/MenuList';
import styles from "./SuggestionList.module.css";
import rootStyles from "../../../../root.module.css";
import { SuggestionGroup } from "./SuggestionGroup";
import SuggestionItem from "../SuggestionItem";

export type SuggestionListProps<T> = {
  /**
   * Object containing all suggestion items, grouped by their `groupName`.
   */
  groups: {
    [groupName: string]: T[];
  };

  /**
   * The total number of suggestion-items
   */
  count: number;

  /**
   * This callback gets executed whenever an item is clicked on
   */
  onSelectItem: (item: T) => void;

  /**
   * The index of the item that is currently selected (but not yet clicked on)
   */
  selectedIndex: number;
};

/**
 * Stateless component that renders the suggestion list
 */
export function SuggestionList<T extends SuggestionItem>(
  props: SuggestionListProps<T>
) {
  const renderedGroups = [];

  let currentGroupIndex = 0;

  for (const groupName in props.groups) {
    const items = props.groups[groupName];

    renderedGroups.push(
      <SuggestionGroup
        key={groupName}
        name={groupName}
        items={items}
        selectedIndex={
          props.selectedIndex >= currentGroupIndex
            ? props.selectedIndex - currentGroupIndex
            : undefined
        }
        clickItem={props.onSelectItem}></SuggestionGroup>
    );

    currentGroupIndex += items.length;
  }

  let shouldShow = renderedGroups.length > 0;
  return shouldShow ? (
    <MenuList className={styles.menuList + " " + rootStyles.bnRoot}>
      {renderedGroups.length > 0 ? (
        renderedGroups
      ) : (
        <></>
      )}
    </MenuList>
  ) : (<></>);
}
