import { useCallback, useEffect, useState } from "react";
import {
  Box,
  Stack,
  Button,
  IconButton,
  Tooltip,
  Divider,
} from "@mui/material";
import PauseRoundedIcon from "@mui/icons-material/PauseRounded";
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";
import Forward5RoundedIcon from "@mui/icons-material/Forward5Rounded";
import Replay5RoundedIcon from "@mui/icons-material/Replay5Rounded";
import ClosedCaptionOffRoundedIcon from '@mui/icons-material/ClosedCaptionOffRounded';
import VolumeUpRoundedIcon from '@mui/icons-material/VolumeUpRounded';
import VolumeOffRoundedIcon from '@mui/icons-material/VolumeOffRounded';
import useLiveMessageReplay from "../recording/useLiveMessageReplay";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { getTimeString } from "../../utils/ComputeUtil";

const pauseTitle = "Pause";
const playTitle = "Play";
const fowardTitle = "Forward 5 seconds";
const rewindTitle = "Rewind 5 seconds";
const enableCaptionTitle = "Enable closed caption";
const disableCaptionTitle = "Disable closed caption";
const processingCaptionTitle = "Closed caption still processing";
const enableAudioMuteTitle = "Enable audio";
const disableAudioMuteTitle = "Mute audio";
const playbackSpeedTitle = "Playback speed";
const speed2x = "2x";
const speed1_5x = "1.5x";
const speed1_25x = "1.25x";
const speed1x = "1x";
const speed0_75x = "0.75x";
const speed0_5x = "0.5x";

const PlaybackControl = () => {
  const [
    replayState,
    {
      onHandleReplayingPaused,
      onHandleReplayingResumed,
      onHandleReplayingFastForward,
      onHandleReplayingRewind,
      onHandleReplayingSpeedAdjusted,
      onHandleReplayingClosedCaptionToggled,
      onHandleReplayingAudioMuteToggled,
    },
  ] = useLiveMessageReplay();
  const [isPlaying, setIsPlaying] = useState(
    replayState.activeRecording && !replayState.activeRecordingPaused
  );
  const togglePlayPause = () => {
    if (isPlaying) {
      onHandleReplayingPaused();
    } else {
      onHandleReplayingResumed();
    }
  };
  const fastForward = () => {
    onHandleReplayingFastForward(5000);
  };
  const rewind = () => {
    onHandleReplayingRewind(5000);
  };
  useEffect(() => {
    setIsPlaying(
      replayState.activeRecording && !replayState.activeRecordingPaused
    );
  }, [replayState.activeRecording, replayState.activeRecordingPaused]);

  const [playbackSpeed, setPlaybackSpeed] = useState(
    replayState.speed ? replayState.speed : 1
  );
  const onSpeedChange = (event, playbackSpeed) => {
    if (playbackSpeed) {
      console.log(`speedchanged:${playbackSpeed}`);
      setPlaybackSpeed(playbackSpeed);
      setDisplaySpeedMenu(false);
      onHandleReplayingSpeedAdjusted(playbackSpeed);
    }
  };

  const [displaySpeedMenu, setDisplaySpeedMenu] = useState(false);
  const onPlaybackSpeedClicked = () => {
    setDisplaySpeedMenu(!displaySpeedMenu);
  };

  let audioTranscribe = replayState?.activeRecording?.audioTranscribe;
  
  return (
    <Stack
      direction="row"
      spacing={3}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      {isPlaying ? (
        <Tooltip title={pauseTitle} placement="top">
          <IconButton onClick={togglePlayPause} >
            <PauseRoundedIcon fontSize="large"/>
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title={playTitle} placement="top">
          <IconButton onClick={togglePlayPause} >
            <PlayArrowRoundedIcon fontSize="large"/>
          </IconButton>
        </Tooltip>
      )}
      <Tooltip title={rewindTitle} placement="top">
        <IconButton onClick={rewind} >
          <Replay5RoundedIcon fontSize="large"/>
        </IconButton>
      </Tooltip>
      <Tooltip title={fowardTitle} placement="top">
        <IconButton onClick={fastForward}>
          <Forward5RoundedIcon fontSize="large" />
        </IconButton>
      </Tooltip>
      <Tooltip title={replayState.audioMuteEnabled ? enableAudioMuteTitle : disableAudioMuteTitle} placement="top">
        <IconButton onClick={onHandleReplayingAudioMuteToggled}>
          {replayState.audioMuteEnabled ? <VolumeOffRoundedIcon sx={{ color: '#ddd' }} fontSize="large"></VolumeOffRoundedIcon> : <VolumeUpRoundedIcon fontSize="large"></VolumeUpRoundedIcon>}
        </IconButton>
      </Tooltip>
      <Tooltip title={!audioTranscribe ? processingCaptionTitle : replayState.closedCaptionEnabled ? disableCaptionTitle : enableCaptionTitle} placement="top">
        <IconButton onClick={onHandleReplayingClosedCaptionToggled} disabled={!audioTranscribe} sx={!audioTranscribe ? { 'pointerEvents': 'all !important'} : {}}>
          <ClosedCaptionOffRoundedIcon sx={{ color: !replayState.closedCaptionEnabled ? '#ddd' : 'inherit' } }fontSize="large"></ClosedCaptionOffRoundedIcon>
        </IconButton>
      </Tooltip>
      <Box
        sx={{
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Button
          onClick={onPlaybackSpeedClicked}
          sx={{
            color: "#666",
            fontSize: "20px",
            fontWeight: "bold",
            textTransform: "none",
            "&:hover": { background: "#f2f2f2", color: "#000" },
          }}
        >
          {playbackSpeed}x
        </Button>

        {displaySpeedMenu && (
          <ToggleButtonGroup
            value={playbackSpeed}
            exclusive
            onChange={onSpeedChange}
            sx={{ position: "absolute", bottom: "50px", background: "#fff" }}
          >
            <ToggleButton
              value={0.5}
              sx={{ width: "60px", fontSize: "16px", fontWeight: "medium" }}
            >
              {speed0_5x}
            </ToggleButton>
            <ToggleButton
              value={0.75}
              sx={{ width: "60px", fontSize: "16px", fontWeight: "medium" }}
            >
              {speed0_75x}
            </ToggleButton>

            <ToggleButton
              value={1}
              sx={{ width: "60px", fontSize: "16px", fontWeight: "medium" }}
            >
              {speed1x}
            </ToggleButton>
            <ToggleButton
              value={1.25}
              sx={{ width: "60px", fontSize: "16px", fontWeight: "medium" }}
            >
              {speed1_25x}
            </ToggleButton>
            <ToggleButton
              value={1.5}
              sx={{ width: "60px", fontSize: "16px", fontWeight: "medium" }}
            >
              {speed1_5x}
            </ToggleButton>
            <ToggleButton
              value={2}
              sx={{ width: "60px", fontSize: "16px", fontWeight: "medium" }}
            >
              {speed2x}
            </ToggleButton>
          </ToggleButtonGroup>
        )}
      </Box>

      {replayState.activeRecording && <Box sx={{ width: "60px", display: "flex", alignItems: "center", justifyContent: "center", color: "#999" }}>
        {getTimeString(replayState.lmTotalTime * (1 - replayState.lmHead / 100))}
      </Box>}

    </Stack>
  );
};
export default PlaybackControl;
