import { Subject } from 'rxjs';

const initialState = {
  user: null,
  startTime: null,
};
const replayAwarenessSubject = new Subject();

let state = initialState;

/**
 * Publish awareness state during replay to blocknote plugin to update text cursor location
 * Control by: LiveMessageTextCursorReplayer
 * Used by: ReplayingExtension
 */
const replayAwareness = {
  init: () => {
    state = { ...state }
  },
  subscribe: setState => replayAwarenessSubject.subscribe(setState),
  startReplay: (name, color) => {
    state = {
      ...state,
      startTime: Date.now(),
      user: {
        name: name,
        clientId: name,
        color: color,
      }
    };
    replayAwarenessSubject.next(state);
  },
  stopReplay: () => {
    state = {
      ...state,
      startTime: null,
      user: null,
    };
    replayAwarenessSubject.next(state);
  },
  notifyUpdate: (event) => {
    if (!state.startTime) {
      return;
    }
    state = {
      ...state,
      user: {
        ...state.user,
        cursor: { anchor: event.anchor, head: event.head, blockId: event.blockId }
      }
    };
    replayAwarenessSubject.next(state);
  }
};

export default replayAwareness;