import { Extension } from "@tiptap/core";
import { Plugin, PluginKey } from "prosemirror-state";

// based on https://github.com/ueberdosis/tiptap/blob/40a9404c94c7fef7900610c195536384781ae101/demos/src/Experiments/TrailingNode/Vue/trailing-node.ts

/**
 * Extension based on:
 * - https://github.com/ueberdosis/tiptap/blob/v1/packages/tiptap-extensions/src/extensions/TrailingNode.js
 * - https://github.com/remirror/remirror/blob/e0f1bec4a1e8073ce8f5500d62193e52321155b9/packages/prosemirror-trailing-node/src/trailing-node-plugin.ts
 */

export interface TrailingNodeOptions {
  node: string;
}

/**
 * Add a trailing node to the document so the user can always click at the bottom of the document and start typing
 */
export const TrailingNode = Extension.create<TrailingNodeOptions>({
  name: "trailingNode",

  addProseMirrorPlugins() {
    const plugin = new PluginKey(this.name);

    return [
      new Plugin({
        key: plugin,
        appendTransaction: (transactions, oldState, newState) => {
          const docChanges =
            transactions.some((transaction) => transaction.docChanged) &&
            !oldState.doc.eq(newState.doc);

          if (!docChanges) {
            return;
          }

          const newTr = newState.tr;
          let modified = false;

          // check if last children of current doc is empty owlblock
          let lastNode = newState.doc.lastChild;

          if (!lastNode || lastNode.type.name !== "blockgroup") {
            throw new Error("Expected blockgroup");
          }
          lastNode = lastNode.lastChild;
          if (!lastNode && ["owlblock", "image", "liveMessageBlock", "imageBlock"].includes(lastNode.type.name)) {
            throw new Error("Last node unexpected");
          }

          if (lastNode.type.name === "owlblock") {
            modified = lastNode.nodeSize > 4; // empty <block><content/></block> is length 4
          } else {
            modified = lastNode.type.name === "image" || lastNode.type.name === "liveMessageBlock" || lastNode.type.name === "imageBlock"
          }

          if (modified) {
            // HACK HACK HACK: TODO: Make sure -1 is the right way. I am not sure actually lol..
            const endPosition = newState.doc.content.size - 1;
            const type = newState.schema.nodes["owlblock"];
            const contenttype = newState.schema.nodes["content"];

            return newTr.insert(
              endPosition,
              type.create(undefined, contenttype.create())
            );
          }
          return null;
        },
      }),
    ];
  },
});
