import { track } from '@amplitude/analytics-browser';
import { editorState$ } from "../streams/editorState";

export const trackDocumentEvent = (eventName, extraFields={}) => {
    let state = editorState$.getState();
    //console.log('[AMPLITUDE] trackDocumentEvent', eventName, { ...extraFields, teamId: state.teamId, documentId: state.documentId });
    track(eventName, { ...extraFields, teamId: state.teamId, documentId: state.documentId })
}

export const trackEvent = (eventName, fields) => {
    //console.log('[AMPLITUDE] trackEvent', eventName, fields);
    track(eventName, fields)
}