import { shallow } from "@liveblocks/react";
import { useOthersMapped } from "../../liveblocks.config";
import { useEffect, useState } from "react";
import Cursor from "./Cursor";
import { useBoundingClientRectRef } from "../../utils/useBoundingClientRectRef";
import { decodePresence } from "../../utils/ComputeUtil";
import BlockCursor from "./BlockCursor";
import EventReplayer from "../../utils/EventReplayer";
import useLiveMessageReplay from "../recording/useLiveMessageReplay";
import { REPLAY_CURSOR_COLOR, REPLAY_CURSOR_COLOR_ALT } from "../../common/Constants";
import useSideBarOffset from "../sidebar/useSideBarOffset";

/**
 * This file shows you how to create a reusable live cursors component for your product.
 * The component takes a reference to another element ref `cursorPanel` and renders
 * cursors according to the location and scroll position of this panel.
 * Make sure that cursorPanel has a CSS position set, and that LiveCursors is placed inside
 */
export default function LiveCursors({ editor, cursorPanel }) {
  const [replayState] = useLiveMessageReplay();
  const [replayCursor, setReplayCursor] = useState(null);
  const [replayCursorV2, setReplayCursorV2] = useState(null);
  const { sideBarOffset } = useSideBarOffset();

  // Handle replay
  useEffect(() => {
    if (!replayState.activeRecording) {
      // do nothing if cur playing is empty.
      return;
    }
    if (replayState.activeRecordingPaused) {
      // do nothing if cur playing is paused.
      return;
    }

    let curRecording = replayState.activeRecording;
    let curTrack = curRecording.mouseCursor;

    if (!curTrack) {
      return;
    }

    let eventReplayer = new EventReplayer();
    eventReplayer.startReplay(
      "mouseCursor",
      curTrack,
      replayState.activeRecordingSeekTime,
      replayState.speed,
      () => {
        /* onStarted */
      },
      () => {
        /* onStopped */
        setReplayCursor(null);
      },
      () => {
        /* onEndOfTrack */
        setReplayCursor(null);
      },
      (event) => {
        /* onEvent */
        setReplayCursor(event.cursor);
      }
    );

    return () => {
      if (eventReplayer) {
        eventReplayer.signalStop();
      }
    };
  }, [
    replayState.activeRecording,
    replayState.activeRecordingSeekTime,
    replayState.activeRecordingPaused,
    replayState.speed,
  ]);

  // Handle replay v2
  useEffect(() => {
    if (!replayState.activeRecording) {
      // do nothing if cur playing is empty.
      return;
    }
    if (replayState.activeRecordingPaused) {
      // do nothing if cur playing is paused.
      return;
    }

    let curRecording = replayState.activeRecording;
    let curTrack = curRecording.mouseCursorV2;

    if (!curTrack) {
      return;
    }

    let eventReplayer = new EventReplayer();
    eventReplayer.startReplay(
      "mouseCursorV2",
      curTrack,
      replayState.activeRecordingSeekTime,
      replayState.speed,
      () => {
        /* onStarted */
      },
      () => {
        /* onStopped */
        setReplayCursorV2(null);
      },
      () => {
        /* onEndOfTrack */
        setReplayCursorV2(null);
      },
      (event) => {
        /* onEvent */
        setReplayCursorV2(event.cursor);
      }
    );

    return () => {
      if (eventReplayer) {
        eventReplayer.signalStop();
      }
    };
  }, [
    replayState.activeRecording,
    replayState.activeRecordingSeekTime,
    replayState.activeRecordingPaused,
    replayState.speed,
  ]);

  /**
   * Return all the other users in the room and their presence (a cursor position in this case)
   */
  const others = useOthersMapped(
    (other) => ({
      cursor: other.presence.cursor,
      info: other.info,
    }),
    shallow
  );

  const rectRef = useBoundingClientRectRef(cursorPanel);

  return (
    <>
      {
        cursorPanel?.current &&
        /**
         * Iterate over other users and display a cursor based on their presence
         */
        others.map(([id, other]) => {
          if (other.cursor == null || other.info == null) {
            return null;
          }

          // handle if mouse cursor should be inactive
          if (editor && editor.storage.collaborationCursor.users) {
            for (let user of editor.storage.collaborationCursor.users) {
              if (user.userId === other.info.userId && !user.cursorState.showMouseCursor) {
                return null;
              }
            }
          }

          let decoded = decodePresence(other.cursor);
          // console.log('cursor:',(cursorPanel.current.offsetTop + decoded.cursor.y));
          return (
            <Cursor
              id={id}
              variant="name"
              name={other.info.name}
              key={id}
              // connectionId is an integer that is incremented at every new connections
              // Assigning a color with a modulo makes sure that a specific user has the same colors on every clients
              color={other.info ? [other.info.colors[0], other.info.colors[0]] : ["#000099", "#007A00"]}
              x={rectRef.current.x - sideBarOffset.leftOffset - sideBarOffset.rightOffset / 2 + decoded.cursor.x}
              y={cursorPanel.current.offsetTop + decoded.cursor.y}
            />
          );
        })
      }
      {cursorPanel?.current && replayCursor && !replayCursorV2 && (
        <Cursor
          id={'replay'}
          variant="name"
          name={'Live message'}
          key={"999999"}
          color={[REPLAY_CURSOR_COLOR, REPLAY_CURSOR_COLOR_ALT]}
          x={rectRef.current.x - sideBarOffset.leftOffset - sideBarOffset.rightOffset / 2 + decodePresence(replayCursor).cursor.x}
          y={cursorPanel.current.offsetTop + decodePresence(replayCursor).cursor.y}
        />
      )}
      {cursorPanel?.current && replayCursorV2 && (
        <BlockCursor
          id={'replay'}
          variant="name"
          name={'Live message'}
          key={"9999999"}
          color={[REPLAY_CURSOR_COLOR, REPLAY_CURSOR_COLOR_ALT]}
          blockId={replayCursorV2.blockId}
          relativeX={replayCursorV2.x - sideBarOffset.leftOffset}
          relativeY={replayCursorV2.y}
        />
      )}
    </>
  );
}
