import { NodeViewWrapper } from "@tiptap/react";
import { useState, useEffect, useRef, forwardRef } from "react";
import Box from "@mui/material/Box";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import PhotoRoundedIcon from '@mui/icons-material/PhotoRounded';
import Popover from '@mui/material/Popover';
import Tooltip from '@mui/material/Tooltip';
import OutlinedInput from '@mui/material/OutlinedInput';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import InsertCommentOutlinedIcon from '@mui/icons-material/InsertComment';
import IconButton from '@mui/material/IconButton';
import FilePondImage from "./FilePondImage";
import { styled } from '@mui/material/styles';
import { createSingleMedia } from '../../utils/MediaBackendUtils';
import { DEFAULT_ACCOUNT_ID } from "../../common/Constants";
import "./ImageBlock.scss";
import { v4 } from "uuid";
import { addNewCommentStream, refreshCommentStream } from "../../components/comment/CommentViewModel";
import { CommentThread } from "../../components/comment/comment";
import { getUserInfo } from "../../utils/useUserInfo";
import { EditorState, NodeSelection, Selection } from "prosemirror-state";

const EmbedImage = ({ imageSource, handleImageSourceUpdate }) => {
  const [url, setUrl] = useState(imageSource ? imageSource : "");
  const inputRef = useRef(null);

  const onChangeText = (e) => {
    setUrl(e.target.value);
  }

  const handleSubmit = () => {
    if (url.length > 0) {
      handleImageSourceUpdate(url);
    }
  }

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
    if (url.length > 0) {
      inputRef.current.value = url;
    }
  }, [inputRef]);

  let disableButton = url.length === 0;
  return (
    <Stack direction="column" alignItems="center" sx={{ padding: '12px' }} spacing={'12px'}>
      <OutlinedInput
        inputRef={inputRef}
        onChange={onChangeText}
        sx={{ width: '450px', height: '40px' }}
        placeholder={"Paste the image link..."}
        inputProps={{ 'aria-label': 'Paste the image link...' }}
      />

      <Box>
        <Button variant="contained" sx={{ textTransform: 'none' }} onClick={handleSubmit} disabled={disableButton}>Embed image</Button>
      </Box>
    </Stack>
  );
};

const StyledTabs = styled(Tabs)({
  height: '40px',
  '& .MuiTabs-indicator': {
    backgroundColor: '#000',
  },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(14),
    marginRight: theme.spacing(1),
    '&.Mui-selected': {
      color: '#000',
    },
    '&.Mui-focusVisible': {
      backgroundColor: '#000',
    },
  }),
);

const ImageSelectionPopup = ({ imageSource, anchorEl, setAnchorEl, setImageSource }) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleImageSourceUpdate = (src) => {
    setImageSource(src);
  }

  const handlePopOverClose = (event) => {
    console.log('handlePopOverClose', event);
    setAnchorEl(null);
  }

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return <Popover
    id={id}
    open={open}
    anchorEl={anchorEl}
    onClose={handlePopOverClose}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    disableRestoreFocus // THIS IS EXTEREMLY IMPORTANT OTHERWISE SCROLL WILL JUMP
  >
    <Box sx={{ width: '500px' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <StyledTabs value={value} onChange={handleChange}>
          <StyledTab label="Upload" />
          <StyledTab label="Embed link" />
        </StyledTabs>
      </Box>
      <Box sx={{ height: '115px' }}>
        {value === 0 &&
          <FilePondImage handleImageSourceUpdate={handleImageSourceUpdate}></FilePondImage>
        }
        {value === 1 &&
          <EmbedImage imageSource={imageSource} handleImageSourceUpdate={handleImageSourceUpdate}></EmbedImage>
        }
      </Box>
    </Box>
  </Popover>
}

const ImagePlaceholder = ({ onClickCallback }) => {
  return <Box sx={{ cursor: 'pointer', background: 'rgba(220, 220, 220, 0.65)', color: 'rgba(80, 80, 80, 0.8)', marginBottom: '7px', padding: '16px', '&:hover': { background: 'rgba(220, 220, 220, 0.9)' } }} onClick={onClickCallback}>
    <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={"8px"}>
      <PhotoRoundedIcon></PhotoRoundedIcon>
      <Typography>Add an image</Typography>
    </Stack>
  </Box>
};

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const updateProps = async (props, update, callback) => {
  await sleep(500);
  console.log("updating node props")
  console.log(update);
  props.updateAttributes(update);
  if (callback) {
    callback();
  }
};

const ImageEditMenu = ({ onEditImage }) => {
  return (
    <Tooltip title={`Replace image`} placement="bottom">
      <IconButton onClick={onEditImage} sx={{ background: 'rgba(0,0,0,0.3)', '&:hover': { background: 'rgba(0,0,0,0.6)' } }}>
        <EditOutlinedIcon sx={{ fontSize: '16px', color: 'white', opacity: 0.8 }}></EditOutlinedIcon>
      </IconButton>
    </Tooltip>
  )
};

const CommentMenu = ({ onComment }) => {
  return (
    <Tooltip title={`Comment on selected image`} placement="bottom">
      <IconButton onClick={onComment} sx={{ background: 'rgba(0,0,0,0.3)', '&:hover': { background: 'rgba(0,0,0,0.6)' } }}>
        <InsertCommentOutlinedIcon sx={{ fontSize: '16px', color: 'white', opacity: 0.8 }}></InsertCommentOutlinedIcon>
      </IconButton>
    </Tooltip>
  )
}

const EditableImage = forwardRef(({ imageSource, onEditImage, onComment }, ref) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box ref={ref} onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}>
      {anchorEl &&
        <Stack sx={{ position: 'absolute', marginTop: '10px', marginLeft: '10px' }} direction="row" spacing={"4px"}>
          <ImageEditMenu onEditImage={onEditImage}></ImageEditMenu>
          <CommentMenu onComment={onComment}></CommentMenu>
        </Stack>
      }
      <Box>
        <img src={imageSource} />
      </Box>
    </Box>
  )
});


const ImageBlock = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [imageSource, setImageSource] = useState(props.node.attrs.imageSource);
  const [localImageUrl, setLocalImageUrl] = useState(props.node.attrs.localImageUrl);
  const imgRef = useRef(null);

  const onClickCallback = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const onSetImageSourceCallback = (src) => {
    setImageSource(src);
    setAnchorEl(null);
  }

  const onEditImageCallback = () => {
    if (imgRef.current) {
      setAnchorEl(imgRef.current);
    }
  }

  const onCommentCallback = () => {
    let editor = props.editor;
    let tr = editor.state.tr;
    tr.setSelection(new NodeSelection(tr.doc.resolve(props.getPos())));
    editor.view.dispatch(tr);
    addNewCommentStream.next(true);
  }

  // Update props whenever imageSource changed
  useEffect(() => {
    if (imageSource && imageSource !== props.node.attrs.imageSource) {
      updateProps(props, { imageSource: imageSource });
    }
  }, [imageSource]);

  const effectCalled = useRef(false);
  useEffect(() => {
    if (localImageUrl) {
      // Make sure only upload once even in STRICT MODE
      if (effectCalled.current) return;
      effectCalled.current = true;

      createSingleMedia(DEFAULT_ACCOUNT_ID, localImageUrl).then((media) => {
        if (media.permanentUrl) {
          // update and clean up
          setImageSource(media.permanentUrl);
          setLocalImageUrl(null);
          updateProps(props, { localImageUrl: undefined });
        }
      })
    }
  }, []);

  return (
    <NodeViewWrapper className="imageBlock" data-id={props.node.attrs.id}>
      <Box sx={{}}>
        {!imageSource && <ImagePlaceholder onClickCallback={onClickCallback}></ImagePlaceholder>}
        {imageSource && <EditableImage ref={imgRef} imageSource={imageSource} onEditImage={onEditImageCallback} onComment={onCommentCallback} />}
        <ImageSelectionPopup imageSource={imageSource} anchorEl={anchorEl} setAnchorEl={setAnchorEl} setImageSource={onSetImageSourceCallback}></ImageSelectionPopup>
      </Box>

    </NodeViewWrapper>
  );
};

export default ImageBlock;
